import {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {
	Loading,
	StatusLabel,
} from "~/components";
import {
	serviceApi
} from "~/api";
import {
	apiError,
	handleRequest
} from "~/utils";

function ServiceFormHostInfo({item}) {

	const [hostInfo, setHostInfo] = useState([]);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchData().then();
	}, [item]);

	const fetchData = async () => {
		let [error, response] = await handleRequest(serviceApi.hostInfo(item.id));
		let message = apiError(`Load thông tin host không thành công`, error, response);
		if (!message) setHostInfo(response.data);
		setLoading(false);
	}

	const hostStatus = (suspended) => {
		suspended = suspended*1;
	  	if(suspended == 0) {
			  return <StatusLabel small background type="red">Đã đóng</StatusLabel>
		}
		else {
			return <StatusLabel small type="green">Đang mở</StatusLabel>
		}
	}

	return (
		<div className="form">
			{loading && <Loading/>}
			<p className="heading">Thông tin host</p>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Tên miền</b></Col>
				<Col span={12}>{hostInfo?.domain}</Col>
			</Row>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Địa chỉ Ip</b></Col>
				<Col span={12}>{hostInfo?.ip}</Col>
			</Row>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Gói host</b></Col>
				<Col span={12}>{hostInfo?.package} ({hostInfo?.quota})</Col>
			</Row>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Dung lượng đã sử dụng</b></Col>
				<Col span={12}>{hostInfo?.quotaUse}</Col>
			</Row>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Băng thông</b></Col>
				<Col span={12}>{hostInfo?.bandwidth}</Col>
			</Row>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Băng thông đã sử dụng</b></Col>
				<Col span={12}>{hostInfo?.bandwidthUse}</Col>
			</Row>
			<Row gutter={10} className="mb-1">
				<Col span={12}><b>Tình trạng</b></Col>
				<Col span={12}>{hostStatus(hostInfo?.suspended)}</Col>
			</Row>
			<hr />
		</div>
	)
}

export default ServiceFormHostInfo;