import style from './SalesAddAll.module.scss';
import {useState} from "react";
import {
	Modal,Icon
} from "~/components";
import SalesAddAllForm from "./SalesAddAllForm";
import className from "classnames/bind";
import {useCan} from "../../hooks";
import {notification} from "antd";
import {apiError, handleRequest, strToTime} from "../../utils";
import {saleApi} from "../../api";
const cn = className.bind(style);
function SalesAddAll() {

	//Model
	const [openModal, setOpenModal] = useState({
		add : false,
	});

	const can = {
		addCustomer: useCan('customerAdd'),
		addContract: useCan('contractAdd'),
		addRevenue: useCan('revenueAdd'),
	}

	if(!can.addCustomer || !can.addContract || !can.addRevenue) return null;

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	const handleSave = async (data) => {
		data.signing            = strToTime(data.signing);
		data.time               = strToTime(data.time);
		let [error, response]   = await handleRequest(saleApi.addAll(data));
		let message = apiError(`Thêm mới thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm mới thông tin thành công`});
			handleModalClose('add')
		}
	}

	return (
		<>
			<div className={cn('button')} onClick={() => {handleModalOpen('add')}}>{Icon.plus}</div>
			{(openModal.add) &&
				<Modal zIndex={99} size="xl" title="Thêm nhanh" visible={openModal.add} onCancel={() => {
					handleModalClose('add')
				}}>
					<SalesAddAllForm onHandleSubmit={handleSave} />
				</Modal>
			}
		</>

	)
}
export default SalesAddAll;