import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Loading,
	Button,
	Modal
} from "~/components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useDevice
} from "~/hooks";

import {
	PapersNumberSearchBar,
	PapersNumberSearchMobile,
	PapersNumberTable,
	FormPapersNumberReceive,
	FormPapersNumberNote,
	FormPapersNumberCancel
} from "../components";

import {
	papersApi
} from "~/api";

import {
	papersNumberActions,
	papersNumberItemsSelector,
	papersNumberLoadingSelector,
	papersNumberErrorSelector,
	papersNumberPaginationSelector,
	papersNumberFilterSelector
} from "../papersNumberSlice";

function PapersNumber() {

	const {isMobile} = useDevice();

	const can = {
		edit    : useCan('papersNumberEdit'),
		delete  : useCan('papersNumberDelete'),
	}

	const dispatch  = useDispatch();

	const items     = useSelector(papersNumberItemsSelector);

	const loading   = useSelector(papersNumberLoadingSelector);

	const error     = useSelector(papersNumberErrorSelector);

	const pagination = useSelector(papersNumberPaginationSelector);

	const filter    = useSelector(papersNumberFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [papersType, setPapersType] = useState([]);

	//Model
	const [openModal, setOpenModal] = useState({
		note    : false,
		reCall  : false,
		receive : false,
		cancel  : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleReLoading();
	}, [filter]);

	const handleReLoading = () => {
		dispatch(papersNumberActions.fetchData(filter));
	}

	useEffect(() => {
		fetchPapersType().then();
	}, []);

	const fetchPapersType = async () => {
		let [error, response] = await handleRequest(papersApi.getsType());
		let message = apiError(`Load danh sách loại đơn thất bại`, error, response);
		if (!message) {
			setPapersType(response.data);
		}
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleNote = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có giấy tờ để cập nhật'});
			return;
		}
		data.id     = item.id;
		let [error, response] = await handleRequest(papersApi.updateNumber(data));
		let message = apiError(`Cập nhật giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật giấy tờ thành công`});
			item.note    = response.data.note;
			dispatch(papersNumberActions.update(item));
			handleModalClose('note');
		}
	}

	const handleReCall = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có giấy tờ để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(papersApi.reCallNumber({id: item.id}));
		let message = apiError(`Cập nhật giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật giấy tờ thành công`});
			item.userId     = response.data.userId;
			item.timePick   = response.data.timePick;
			item.status     = response.data.status;
			item.user       = response.data.user;
			dispatch(papersNumberActions.update(item));
			handleModalClose('reCall');
		}
	}

	const handleReceive = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có giấy tờ để cập nhật'});
			return;
		}
		data.id     = item.id;
		data.time   = strToTime(data.time);
		let [error, response] = await handleRequest(papersApi.receiveNumber(data));
		let message = apiError(`Cập nhật giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật giấy tờ thành công`});
			item.timePay   = response.data.timePay;
			item.status    = response.data.status;
			item.note      = response.data.note;
			dispatch(papersNumberActions.update(item));
			handleModalClose('receive');
		}
	}

	const handleCancel = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có giấy tờ để cập nhật'});
			return;
		}
		data.id     = item.id;
		let [error, response] = await handleRequest(papersApi.cancelNumber(data));
		let message = apiError(`Hủy giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Hủy giấy tờ thành công`});
			item.status    = response.data.status;
			item.note      = response.data.note;
			dispatch(papersNumberActions.update(item));
			handleModalClose('cancel');
		}
	}

	//Search
	const SearchBar = (isMobile) ? PapersNumberSearchMobile : PapersNumberSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(papersNumberActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(papersNumberActions.setFilter(newFilter));
	};

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(papersNumberActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Đơn xin giấy tờ'}>
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading />}
					<SearchBar listGroup={papersType} filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />
					{items && <PapersNumberTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						can={can}
					/>}
				</div>
				{
					(openModal.note && itemEdit?.id) && <Modal title="Ghi chú" visible={openModal.note} onCancel={() => {handleModalClose('note')}}>
						<FormPapersNumberNote item={itemEdit} onHandleSubmit={handleNote} />
					</Modal>
				}
				{
					(openModal.reCall && itemEdit?.id) && <Modal title="Thu hồi" visible={openModal.reCall} onCancel={() => {handleModalClose('reCall')}}>
						<p>Bạn muốn thu hồi đơn <b>{itemEdit?.code}</b>?</p>
						<br />
						<div className="d-flex justify-content-end modal-bottom gap pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('reCall')}}> Đóng </Button>
							<Button primary leftIcon={Icon.reload} onClick={() => handleReCall(itemEdit)}> Thu hồi </Button>
						</div>
					</Modal>
				}
				{
					(openModal.receive && itemEdit?.id) && <Modal title="Nhận lại hợp đồng" visible={openModal.receive} onCancel={() => {handleModalClose('receive')}}>
						<FormPapersNumberReceive item={itemEdit} onHandleSubmit={handleReceive} />
					</Modal>
				}
				{
					(openModal.cancel && itemEdit?.id) && <Modal title="Thu hồi" visible={openModal.cancel} onCancel={() => {handleModalClose('cancel')}}>
						<FormPapersNumberCancel item={itemEdit} onHandleSubmit={handleCancel} />
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default PapersNumber;