import {memo, useMemo} from "react";
import {Col, Row, Table} from "antd";
import {numberFormat} from "~/utils";

function PayDetailRevenue({items = []}) {
	//Lương quản lý khi doanh số phòng đạt KPI
	const columns = [
		{ title: 'Doanh số website', dataIndex: 'websiteTotal', key: "websiteTotal", width: '100px', render: (_, item) => (
			<p>{numberFormat(item?.website)}đ</p>
		)},
		{ title: 'Doanh số Host', dataIndex: 'hostTotal', key: "hostTotal", width: '100px', render: (_, item) => (
			<p>{numberFormat(item?.host)}đ</p>
		)},
		{ title: 'Doanh số phòng', dataIndex: 'revenueTotal', key: "revenueTotal", width: '100px', render: (_, item) => (
			<p><b>{numberFormat(item?.revenue)}đ</b></p>
		)},
		{ title: 'Lương đạt khung', dataIndex: 'pay', key: "pay", width: '100px', render: (_, item) => (
			<p><b>{numberFormat(item?.pay)}đ</b></p>
		)},
	];
	const dataSource = [
		{
			id : 0,
			website: items?.revenueGroup.website,
			host: items?.revenueGroup.host,
			revenue : items?.revenueGroup.total,
			pay : items?.managerMoney
		}
	];

	//Hoa hồng phòng
	const columns2 = [
		{ title: 'Website & SSL (Phòng)', dataIndex: 'groupTotal', key: "groupTotal", width: '100px', render: (_, item) => (
			<p>{numberFormat(item?.group)}đ</p>
		)},
		{ title: 'Website & SSL (Quản lý)', dataIndex: 'personTotal', key: "personTotal", width: '100px', render: (_, item) => (
			<p>{numberFormat(item?.person)}đ</p>
		)},
		{ title: 'Công thức', dataIndex: 'recipeTotal', key: "recipeTotal", width: '100px', render: (_, item) => (
			<p>(<b>{numberFormat(item?.group)} - {numberFormat(item?.person)})/2*15%</b></p>
		)},
		{ title: 'Hoa hồng', dataIndex: 'commission', key: "commission", width: '100px', render: (_, item) => (
			<p><b>{numberFormat(item?.commission)}đ</b></p>
		)},
	];
	const dataSource2 = [
		{
			id : 1,
			group: items?.summaryGroup.group,
			person: items?.summaryGroup.person,
			commission : items?.summaryGroup.total,
		}
	];

	//Hoa hồng phòng dưới
	const dataSourceLevel1 = useMemo(() => {
		return Object.values(items.level1)
	}, [items.level1]);

	const dataSourceLevel2 = useMemo(() => {
		return Object.values(items.level2)
	}, [items.level2]);

	let dataSourceLevel = [...dataSourceLevel1, ...dataSourceLevel2];

	dataSourceLevel= dataSourceLevel.map(row => ({
		id : row.group.id,
		...row
	}));

	const columns3 = [
		{ title: 'Nhóm', dataIndex: 'groupName', key: "groupName", width: '100px', render: (_, item) => (
			<p>{item?.group.name}</p>
		)},
		{ title: 'Doanh số', dataIndex: 'revenueTotal', key: "revenueTotal", width: '100px', render: (_, item) => (
			<p>{numberFormat(item?.revenue.total)}đ</p>
		)},
		{ title: '% Hoa hồng', dataIndex: 'percent', key: "percent", width: '100px', render: (_, item) => (
			<p>{item?.group.percent}%</p>
		)},
		{ title: 'Hoa hồng', dataIndex: 'commission', key: "commission", width: '100px', render: (_, item) => (
			<p><b>{numberFormat(item?.commission)}đ</b></p>
		)},
	];

	return (
		<div className="table-wrapper form">
			<div className="table-box">
				<p className="heading">Lương theo khung</p>
				<Table
					columns={columns}
					rowKey="id"
					dataSource={dataSource}
					scroll={{ x: 'max-content' }}
					pagination={false}
				/>
				<p className="heading mt-2">Hoa hồng phòng</p>
				<Table
					columns={columns2}
					rowKey="id"
					dataSource={dataSource2}
					scroll={{ x: 'max-content' }}
					pagination={false}
				/>
				<p className="heading mt-2">Hoa phòng dưới</p>
				<Table
					columns={columns3}
					rowKey="id"
					dataSource={dataSourceLevel}
					scroll={{ x: 'max-content' }}
					pagination={false}
				/>

				<Row gutter={10} className="mt-2">
					<Col className="gutter-row" md={16} span={24} lg={18}>
						<b>TỔNG</b>
					</Col>
					<Col className="gutter-row" md={8} span={24} lg={6}>
						<span className="color-red"><b style={{fontSize: '20px'}}>{numberFormat(items?.total)}</b>đ</span>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default memo(PayDetailRevenue);