import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import _ from "lodash"
import {
	Loading, Icon, Button, Modal,
} from "~/components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useSocket,
	useCan,
	useDevice
} from "~/hooks";

import {
	FormSabbaticalTable,
	FormSabbaticalAddEdit,
	FormSabbaticalInfo
} from "../components";

import {
	formSabbaticalApi
} from "~/api";

import {
	formSabbaticalActions,
	formSabbaticalItemsSelector,
	formSabbaticalLoadingSelector,
	formSabbaticalErrorSelector,
	formSabbaticalPaginationSelector,
	formSabbaticalFilterSelector
} from "../formSabbaticalSlice";

function FormSabbatical() {

	const {isMobile} = useDevice();

	const {socket} = useSocket();

	const canAdd    = useCan('formSabbaticalAdd');

	const dispatch  = useDispatch();

	const items     = useSelector(formSabbaticalItemsSelector);

	const loading   = useSelector(formSabbaticalLoadingSelector);

	const error     = useSelector(formSabbaticalErrorSelector);

	const pagination = useSelector(formSabbaticalPaginationSelector);

	const filter    = useSelector(formSabbaticalFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(formSabbaticalActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		dispatch(formSabbaticalActions.fetchData(filter));
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin đơn xin nghỉ phép để cập nhật'});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}
		data.startDay   = strToTime(data.startDay);
		data.endDay     = strToTime(data.endDay);
		let [error, response] = (item?.id) ? await handleRequest(formSabbaticalApi.update(data)) : await handleRequest(formSabbaticalApi.add(data));
		let message = apiError(`${messageAction} đơn xin nghỉ phép thất bại`, error, response);
		if(!message) {

			notification.success({message: 'Thành công', description: `${messageAction} đơn thành công`});

			if(item?.id) {
				dispatch(formSabbaticalActions.update(response.data.item));
			}
			else {
				dispatch(formSabbaticalActions.add(response.data.item));
				socket.emit("set-notification-menu-count", response.data.notification);
				socket.emit("set-notification-user", response.data.notification);
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn nghỉ phép để xóa'});
			return;
		}
		let [error, response] = await handleRequest(formSabbaticalApi.delete(itemEdit.id));
		let message = apiError(`xóa đơn nghỉ phép thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa đơn nghỉ phép thành công`});
			dispatch(formSabbaticalActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn nghỉ phép để cập nhật'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(formSabbaticalApi.status(data));
		let message = apiError(`Cập nhật trạng thái đơn nghỉ phép thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái đơn nghỉ phép thành công`});
			item.status         = response.data.item.status;
			item.statusCeo      = response.data.item.statusCeo;
			item.statusAssign   = response.data.item.statusAssign;
			item.statusManager  = response.data.item.statusManager;
			item.noteCeo        = response.data.item.noteCeo;
			item.noteAssign     = response.data.item.noteAssign;
			item.noteManager    = response.data.item.noteManager;
			dispatch(formSabbaticalActions.update(item));
			if(!_.isEmpty(response.data.notification)) {
				socket.emit('set-notification-user', response.data.notification);
			}
			handleModalClose('status')
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(formSabbaticalActions.setFilter({...filter, page }));
	}

	return (
		<Fragment>
			<ActionBar title={'Đơn nghỉ phép'}>
				{canAdd && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>{!isMobile && 'Thêm mới'}</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <FormSabbaticalTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
					/>}
				</div>
				{
					(openModal.addEdit) && <Modal title="Đơn nghỉ phép" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
						<FormSabbaticalAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(itemEdit?.id && openModal.info) && <Modal title="Thông tin" size="xl" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
						<FormSabbaticalInfo item={itemEdit} onHandleSubmit={handleStatus} />
					</Modal>
				}
				{
					(itemEdit?.id && openModal.delete) && <Modal title="Xóa đơn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
						<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
						<br />
						<div className="d-flex justify-content-end gap modal-bottom pd-1">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
						</div>
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default FormSabbatical;