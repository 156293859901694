import {
	Button,
	Icon
} from "~/components";

import {
	FileList
} from "~/components/Forms";

import DOMPurify from "dompurify";
import {useEffect, useState} from "react";
import _ from "lodash";

function DocumentItemInfo({item, group, modalOpen, modalClose}) {

	let [files, setFiles] = useState([]);

	const handleEditClick = () => {
		modalOpen('editItem');
		modalClose('infoItem')
	}

	useEffect(() => {
		if(item?.id) {
			files = [];
			if(!_.isEmpty(item.attachFile)) {
				for (const [path, name] of Object.entries(item.attachFile)) {
					files.push({path, name})
				}
			}
			setFiles(files);
		}
	}, [item]);

	return (
		<div className="form">
			<p className="heading">{item.name}</p>
			<div className="content-html" style={{wordBreak: 'break-word'}}
				 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.content)}}/>
			<p className="heading mt-1">Đính kèm</p>
			<FileList files={files}/>
			<hr className="mb-0"/>
			<div className="form-group d-flex justify-content-center modal-bottom">
				<Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Chinh sữa</Button>
			</div>
		</div>
	)
}

export default DocumentItemInfo;