import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	InputPriceField,
	DateField,
	TextAreaField,
	InputField
} from "~/components/Forms";

function FormPaymentAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const initialValues = {
		time: new Date(),
		price: 0,
		note: '',
		bankNumber : undefined,
		bankName : undefined,
		bank : undefined,
		bankBranch : undefined,
	}

	const validationSchema = Yup.object().shape({
		time: Yup.string().required('Bạn chưa chọn ngày chi'),
		price: Yup.string().required('Số tiền chi không được bỏ trống'),
		note: Yup.string().required('Lý do chi không được bỏ trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.time  = item.time*1000;
			initialValues.price = item.price;
			initialValues.note  = item.note;
			initialValues.bankNumber    = item.bankNumber;
			initialValues.bankName      = item.bankName;
			initialValues.bank          = item.bank;
			initialValues.bankBranch    = item.bankBranch;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Ngày chi" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="price" render={({ field }) => (
						<InputPriceField label="Số tiền chi" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Controller control={control} name="note" render={({ field }) => (
				<TextAreaField label="Chi cho" rows={5} errors={errors} {...field} />
			)}/>
			<p className="heading">Thông tin ngân hàng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="bankNumber" render={({ field }) => (
						<InputField label="Số tài khoản" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="bankName" render={({ field }) => (
						<InputField label="Tên người thụ hưởng" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="bank" render={({ field }) => (
						<InputField label="Ngân hàng" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="bankBranch" render={({ field }) => (
						<InputField label="Chi nhành" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} đơn</Button>
			</div>
		</form>
	)
}

export default FormPaymentAddEdit;