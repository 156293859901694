import style from "./Template.module.scss";
import className from "classnames/bind";
import {numberFormat, renderDate} from "~/utils";
import {useUtilities} from "~/hooks";
const cn = className.bind(style);
function ZaloTemplate({item}) {
	const {utilities} = useUtilities();
	const renderService = (services) => {
		let content = '';
		Object.keys(services).map((itemKey) => {
			let service = services[itemKey];
			if(content.length != 0) content += ', ';
			if(service.service == 'host') {
				content += utilities.services[service.service].label + ' ' + utilities.services[service.service].options[service.option].label;
			}
			else {
				content += utilities.services[service.service].label + ' ' + service.domain;
			}
		})
		return content;
	}
	return (
		<div className={cn('template-zalo')}>
			<div className={cn('wrapper')}>
				<div className={cn('wrapper-inner')}>
					<div className={cn('content')}>
						<p className={cn('title')}>Thông báo dịch vụ sắp hết hạn</p>
						<p>Kính gửi <b>{ (item.customer.for == 'canhan') ? item.customer.name : item.customer.companyName }</b>, thành thật xin lỗi, nếu trong thời gian thư đang chuyển đi mà quý khách đã thực hiện gia hạn. Công ty Sikido xin trân trọng thông báo dịch vụ của quý khách đang sử dụng sắp hết hạn.</p>
						<ul>
							<li><span className={cn('key')}>Mã khách hàng</span><span className={cn('value')}>{item.customer.code}</span></li>
							<li><span className={cn('key')}>Mã đơn hàng</span><span className={cn('value')}>{item.id}</span></li>
							<li><span className={cn('key')}>Ngày hết hạn</span><span className={cn('value')}>{renderDate(item.expired)}</span></li>
							<li><span className={cn('key')}>Dịch vụ</span><span className={cn('value')}>{renderService(item.services)}</span></li>
							<li><span className={cn('key')}>Tổng tiền</span><span className={cn('value')}>{numberFormat(item.total)}</span></li>
						</ul>
						<div className={cn('button')}><a target="_blank" href="02862941556" rel="noreferrer">Liên hệ bộ phận CSKH</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ZaloTemplate