import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
} from "~/components";
import {
	TextAreaField
} from "~/components/Forms";

function FormPapersNumberNote({item, onHandleSubmit}) {

	const initialValues = {
		note: undefined,
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, setValue, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape())
	});


	useEffect(() => {
		if(item?.id) {
			initialValues.note  = item.note;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="note" render={({ field }) => (
				<TextAreaField label="Ghi chú" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Lưu thông tin</Button>
			</div>
		</form>
	)
}

export default FormPapersNumberNote;