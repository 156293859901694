import {useEffect, useState, useContext, useMemo} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import { AppContext } from "~/context/AppProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, notification, Row} from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	GroupRadioField,
	SelectField,
	InputField,
	DateField,
	SelectContractAll
} from "~/components/Forms";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	customerApi
} from "~/api";

function BuyServiceFormAddEdit({item, onHandleSubmit}) {

	const {country} = useContext(AppContext);

	const [loading, setLoading] = useState(false);

	const [itemInfo, setItemInfo] = useState({});

	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);
	let [districtOptions, setDistrictOptions] = useState([]);
	let [wardOptions, setWardOptions] = useState([]);

	let [districtCompanyOptions, setDistrictCompanyOptions] = useState([]);
	let [wardCompanyOptions, setWardCompanyOptions] = useState([]);

	const isEdit = Boolean(item?.id);

	const typeOptions = [
		{value : 'domain',  label : "Tên miền"},
		{value : 'ssl',     label : "Chứng chỉ bảo mật"},
		{value : 'email',   label : "Email Server"},
	];

	const forOptions = [
		{ value: 'canhan', label: 'Cá Nhân'},
		{ value: 'congty', label: 'Công Ty'},
	];

	const genderOptions = [
		{ value: 'Nữ', label: 'Nữ'},
		{ value: 'Nam', label: 'Nam'},
	];

	//const websiteOptions = (isEdit && item.websiteContract?.id) ? [{ label : `HD${item.websiteContract.id} ${item.websiteContract.name}`, value : item.websiteContract.id }] : [];

	const initialValues = {
		type: undefined,
		contractId: undefined,
		for: undefined,
		companyName: '',
		companyAddress: undefined,
		companyProvince: undefined,
		companyDistrict: undefined,
		companyWard: undefined,
		taxCode: undefined,
		name: undefined,
		numberId: undefined,
		email: undefined,
		phone: undefined,
		province: undefined,
		district: undefined,
		ward: undefined,
		birthday: undefined,
		gender: undefined,
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại yêu cầu'),
		contractId: Yup.string().required('Bạn chưa chọn hợp đồng'),
		for: Yup.string().required('Bạn chưa chọn loại khách hàng'),
		companyName: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Tên công ty không được bỏ trống'),
		}).nullable(),
		companyAddress: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Địa chỉ công ty không được bỏ trống'),
		}),
		companyProvince: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Bạn chưa chọn tỉnh thành công ty'),
		}),
		companyDistrict: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Bạn chưa chọn quận huyện công ty'),
		}),
		companyWard: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Bạn chưa chọn phường xã công ty'),
		}),
		taxCode: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Mã số thuế không được bỏ trống'),
		}).nullable(),
		name: Yup.string().required('Họ tên khách hàng không được để trống'),
		numberId: Yup.string().required('CCCD khách hàng không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		province: Yup.string().required('Tỉnh thành không được để trống'),
		address: Yup.string().required('Địa chỉ không được để trống'),
		district: Yup.string().required('Quận huyện không được để trống'),
		ward: Yup.string().required('Phường xã không được để trống'),
		birthday: Yup.string().required('Bạn chưa chọn ngày sinh khách hàng'),
		gender: Yup.string().required('Bạn chưa chọn giới tính khách hàng'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	console.log(errors)

	const typeInput = useWatch({control, name: "type"});

	const forInput = useWatch({control, name: "for"});

	useEffect(() => {
		if(item?.id) {
			initialValues.type = item.type;
		}
		reset(initialValues);
	}, [item]);

	const handleTypeChange = (value) => {
		setValue('contractId', undefined)
	}

	const handleContractChange = async (value) => {
		if(!typeInput) {
			notification.error({message:'Vui lòng chọn loại dịch vụ trước'})
			setValue('contractId', '')
			return;
		}
		setLoading(true);
		let [error, response] = await handleRequest(customerApi.getByContract(value));
		let message = apiError(`Load thông tin hợp đồng thất bài`, error, response);
		if(!message) {
			setValue('city', response.data.city)
			handleCityChange(response.data.city);
			handleDistrictChange(response.data.district);
			setValue('for', response.data.for)
			setValue('companyName', response.data.companyName)
			setValue('taxCode', response.data.taxCode)

			setValue('name', response.data.name)
			setValue('numberId', response.data.numberId)
			setValue('address', response.data.address)

			setValue('district', response.data.district)
			setValue('ward', response.data.ward)
			setValue('phone', response.data.phone)
			setValue('email', response.data.email)
			setItemInfo(response.data);
		}
		setLoading(false);
	}

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleCompanyCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtCompanyOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtCompanyOptions.push({value, label})
				}
				break;
			}
		}
		setValue('companyDistrict', '');
		setValue('companyWard', '');
		setDistrictCompanyOptions(districtCompanyOptions);
	}

	const handleCompanyDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardCompanyOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardCompanyOptions.push({value, label})
				}
				break;
			}
		}
		setValue('companyWard', '');
		setWardCompanyOptions(wardCompanyOptions);
	}

	const renderInfo = () => {
		return (
			<>
				<Controller control={control} name="for" render={({ field }) => (
					<GroupRadioField options={forOptions} errors={errors} {...field}/>
				)}/>
				{renderCompanyInfo(forInput)}
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="name" render={({field}) => (
							<InputField label="Họ tên khách hàng (Người đại diện)" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="numberId" render={({ field }) => (
							<InputField label="CCCD / CMND" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="email" render={({ field }) => (
							<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="phone" render={({ field }) => (
							<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={24}>
						<Controller control={control} name="address" render={({ field }) => (
							<InputField label="Địa chỉ" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="province" render={({ field }) => (
							<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
								handleCityChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="district" render={({ field }) => (
							<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
								handleDistrictChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="ward" render={({ field }) => (
							<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>

				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="birthday" render={({field}) => (
							<DateField label="Ngày sinh" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="gender" render={({ field }) => (
							<SelectField label="Giới tính" options={genderOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			</>
		)
	}

	const renderCompanyInfo = (forValue) => {
		if((forValue == 'congty')) return (
			<>
				<p className="heading">Thông tin công ty</p>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="companyName" render={({ field }) => (
							<InputField label="Tên công ty" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="taxCode" render={({ field }) => (
							<InputField label="Mã số thuế" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={24}>
						<Controller control={control} name="companyAddress" render={({ field }) => (
							<InputField label="Địa chỉ" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="companyProvince" render={({ field }) => (
							<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
								handleCompanyCityChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="companyDistrict" render={({ field }) => (
							<SelectField placeholder="Chọn quận huyện" options={districtCompanyOptions} errors={errors} {...field} onChange={(value, event) => {
								handleCompanyDistrictChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={8}>
						<Controller control={control} name="companyWard" render={({ field }) => (
							<SelectField placeholder="Chọn phường xã" options={wardCompanyOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<p className="heading">Thông tin người đại diện</p>
			</>
		)
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Yêu cầu</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="type" render={({field}) => (
						<SelectField label="Dịch vụ" options={typeOptions} errors={errors} {...field} onChange={(value, event) => {
							handleTypeChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="contractId" render={({ field }) => (
						<SelectContractAll service={typeInput} mine={true}  label="Hợp đồng" options={[]} errors={errors} {...field} onChange={(value, event) => {
							handleContractChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Thông tin</p>
			{loading && <div className="d-flex justify-content" style={{width:'100%'}}><Loading noFixed spin /></div>}
			{renderInfo()}
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} yêu cầu</Button>
			</div>
		</form>
	)
}

export default BuyServiceFormAddEdit;