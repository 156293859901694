import {Tooltip} from "antd";
import {
	Button, Icon
} from "~/components";

function ExpenseAction({item, onClickEdit, onClickDelete}) {

	const handleEditClick = () => {
		if(!onClickEdit) return;
		onClickEdit(item);
	}

	const handleDeleteClick = () => {
		if(!onClickDelete) return;
		onClickDelete(item);
	}

	return (
		<div className="table-action d-flex">
			<Tooltip title="Edit thông tin"><Button background blue leftIcon={Icon.edit} onClick={handleEditClick}></Button></Tooltip>
			<Tooltip title="Xóa"><Button background red leftIcon={Icon.delete} onClick={handleDeleteClick}></Button></Tooltip>
		</div>
	)
}

export default ExpenseAction;