import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {customerGroupActions} from "./customerGroupSlice";
import {customerGroupApi} from "~/api";

function* fetchCustomerList(action) {
	try {
		const response = yield call(customerGroupApi.gets, action.payload);
		yield put(customerGroupActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch customer list', error);
		yield put(customerGroupActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(customerGroupActions.setFilter(action.payload));
}

export default function* customerSaga() {
	yield takeLatest(customerGroupActions.fetchData.type, fetchCustomerList);
	yield debounce(500, customerGroupActions.setFilterWithDebounce.type, handleSearchDebounce)
}