import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import StatusLabel from "~/components/StatusLabel";
import {
	InputField,
	InputPriceField,
	GroupRadioField,
	SelectUserAll,
	SelectField,
	SelectCustomerAll,
	DateField,
	SelectContractAll
} from "~/components/Forms";

import {useUtilities} from "~/hooks";

function ContractFormAddEdit({item, onHandleSubmit}) {

	const {utilities} = useUtilities();

	const isEdit = Boolean(item?.id);

	const userShareOptions = (isEdit && typeof item.userShare?.firstname != "undefined") ? [{ label : `${item.userShare.firstname} ${item.userShare.lastname}`, value : item.userShare.id }] : [];

	const customerOptions = (isEdit && typeof item.customer?.code != "undefined") ? [{ label : `${item.customer.code} ${item.customer.name} ${item.customer.companyName}`, value : item.customer.id }] : [];

	const [serviceValue, setServiceValue] = useState((item?.service) ? item?.service : 'website');

	let serviceOptions = [];

	for (const [key, value] of Object.entries(utilities.services)) {
		serviceOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	let serviceTypeOptions = [];
	for (const [key, value] of Object.entries(utilities.serviceTypes)) {
		serviceTypeOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	const careerOptions = [
		{ value: 'fashion', label: 'Thời trang'},
		{ value: 'cosmetics', label: 'Mỹ phẩm'},
		{ value: 'technology', label: 'Công nghệ'},
		{ value: 'phoneLaptop', label: 'Điện thoại - Laptop'},
		{ value: 'MotorcycleCar', label: 'Xe máy - ô tô'},
		{ value: 'mechanical', label: 'Cơ khí'},
		{ value: 'food', label: 'Thực phẩm'},
		{ value: 'freshFood', label: 'Thực phẩm tươi sóng'},
		{ value: 'functionalFood', label: 'Thực phẩm chức năng'},
		{ value: 'milkTeaDrink', label: 'Trà sữa - thức uống'},
		{ value: 'interior', label: 'Nội thất'},
		{ value: 'householdAppliances', label: 'Đồ gia dụng'},
		{ value: 'build', label: 'Xây dựng'},
		{ value: 'refrigeration', label: 'Điện lạnh'},
		{ value: 'electricity', label: 'Điện máy'},
		{ value: 'electricalEquipment', label: 'Thiết bị điện'},
		{ value: 'sanitaryEquipment', label: 'Thiết bị vệ sinh'},
		{ value: 'realEstate', label: 'Bất động sản'},
		{ value: 'travel', label: 'Du lịch'},
		{ value: 'hotelRestaurant', label: 'Khách sạn - Nhà Hàng'},
		{ value: 'transportation', label: 'Vận tải'},
		{ value: 'beautySpa', label: 'Spa làm đẹp'},
		{ value: 'studio', label: 'Studio'},
		{ value: 'motherBaby', label: 'Mẹ & bé'},
		{ value: 'education', label: 'Giáo dục'},
		{ value: 'stationery', label: 'Văn phòng phẩm'},
		{ value: 'sport', label: 'Thể thao'},
		{ value: 'eventOrganization', label: 'Tổ chức sự kiện'},
		{ value: 'medical', label: 'Y tế'},
		{ value: 'medicinalHerbs', label: 'Dược liệu'},
	];

	const initialValues = {
		customerId: '',
		userShareId: '',
		name: '',
		domain: '',
		career: '',
		signing: new Date(),
		price: 0,
		service: 'website',
		serviceType: '',
		option: '',
		contractParent: undefined,
	}

	const validationSchema = Yup.object().shape({
		customerId: Yup.string().required('Bạn chưa chọn khách hàng'),
		name: Yup.string().required('Tên hợp đồng không được để trống'),
		domain: Yup.string().when('service', {
			is: ['host', 'email', 'ssl'],
			then: Yup.string().required('Tên miền không được bỏ trống'),
		}),
		career: Yup.string().required('Bạn chưa chọn ngành nghề kinh doanh'),
		signing: Yup.string().required('Bạn chưa chọn ngày ký hợp đồng'),
		price: Yup.number().required('Giá trị hợp đồng không được để trống'),
		service: Yup.string().required('Bạn chưa chọn dịch vụ hợp đồng'),
		serviceType: Yup.string().required('Bạn chưa chọn loại dịch vụ hợp đồng'),
		option: Yup.string().when('service', {
			is: 'host',
			then: Yup.string().required('Dung lượng host không được bỏ tróng'),
		}).when('service', {
			is: 'email',
			then: Yup.string().required('Loại email không được bỏ tróng'),
		}).when('service', {
			is: 'ssl',
			then: Yup.string().required('Loại chứng chỉ không được bỏ tróng'),
		}),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.customerId = item.customerId;
			initialValues.userShareId = item.userShareId;
			initialValues.name = item.name;
			initialValues.domain = item.domain;
			initialValues.career = item.career;
			initialValues.signing = item.signing*1000;
			initialValues.price = item.price;
			initialValues.service = item.service;
			initialValues.serviceType = item.serviceType;
			initialValues.option = item.option;
			initialValues.contractParent = item.contractParent;
			setServiceValue(item.service);
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	const handleServiceChange = (value) => {
		setServiceValue(value);
		setValue('option', '');
	}

	const renderServiceOption = () => {

		let options = [];

		serviceTypeOptions = serviceTypeOptions.filter((item) => {
			return utilities.services[serviceValue].type.includes(item.value)
		});

		if(serviceValue == 'host') {
			for (const [key, value] of Object.entries(utilities.services[serviceValue].options)) {
				options.push({value : key, label : value.label})
			}
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Dung lượng" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(serviceValue == 'ssl') {
			for (const [key, value] of Object.entries(utilities.services[serviceValue].options)) {
				options.push({value : key, label : value.label})
			}
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Loại SSL" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(serviceValue == 'email') {
			for (const [key, value] of Object.entries(utilities.services[serviceValue].options)) {
				options.push({value : key, label : value.label})
			}
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Loại Email" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(serviceValue == 'website') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="contractParent" render={({ field }) => (
							<SelectContractAll service="website" label="Hợp đồng cần nâng cấp" options={[]} errors={errors} {...field} />
						)}/>
					</Col>
				</Row>
			)
		}
		else {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Liên quan hợp đồng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="customerId" render={({ field }) => (
						<SelectCustomerAll label="Khách hàng" options={customerOptions} errors={errors} {...field} />
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="userShareId" render={({ field }) => (
						<SelectUserAll label="Share với nhân viên" options={userShareOptions} errors={errors} {...field} />
					)}/>
				</Col>
			</Row>
			<p className="heading">Thông tin hợp đồng</p>
			<Controller control={control} name="name" render={({field}) => (
				<InputField label="Tên hợp đồng" errors={errors} {...field}/>
			)}/>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="domain" render={({ field }) => (
						<InputField label="Tên miền" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="price" render={({field}) => (
						<InputPriceField label="Giá trị hợp đồng" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="career" render={({field }) => (
						<SelectField label="Ngành nghề" options={careerOptions} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="signing" render={({ field }) => (
						<DateField label="Ngày ký" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Dịch vụ</p>
			<Controller control={control} name="service" render={({ field }) => (
				<GroupRadioField options={serviceOptions} errors={errors} {...field} onChange={(event) => {
					handleServiceChange(event.target.value);
					field.onChange(event);
				}}/>
			)}/>
			{renderServiceOption()}
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} hợp đồng</Button>
			</div>
		</form>
	)
}

export default ContractFormAddEdit;