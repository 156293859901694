import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {SelectField} from "~/components/Forms";
import {Select} from "antd";

function PersonnelFormGroupSwitch({item, listGroup, onHandleSubmit}) {

	const initialValues = {
		group: '',
	}

	const validationSchema = Yup.object().shape({
		group: Yup.string().required('Nhóm nhân viên không được để trống'),
	})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.group = +item.group;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Chọn Nhóm</p>
			<Controller control={control} name="group" render={({field }) => (
				<SelectField errors={errors}  {...field}>
					{
						listGroup && listGroup.map((item, index) => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)
					}
				</SelectField>
			)}/>
			<hr />
			<div className="d-flex justify-content_end">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default PersonnelFormGroupSwitch;