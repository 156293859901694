import {Tooltip} from "antd";
import {Button, Icon} from "~/components";
import {useCan} from "~/hooks";

function FormSabbaticalAction({item, onClickEdit, onClickInfo, onClickDelete}) {

	const can = {
	    edit: useCan('formSabbaticalEdit'),
	    delete: useCan('formSabbaticalDelete'),
	}

	const handleEditClick = () => {
		if(!onClickEdit) return;
		onClickEdit(item);
	}

	const handleInfoClick = () => {
		if(!onClickInfo) return;
		onClickInfo(item);
	}

	const handleDeleteClick = () => {
		if(!onClickDelete) return;
		onClickDelete(item);
	}

	return (
		<div className="table-action d-flex">
			{can.edit && <Tooltip title="Edit thông tin"><Button background blue leftIcon={Icon.edit} onClick={handleEditClick}></Button></Tooltip>}
			<Tooltip title="Thông tin"><Button background blue leftIcon={Icon.info} onClick={handleInfoClick}></Button></Tooltip>
			{can.delete && <Tooltip title="Xóa"><Button background red leftIcon={Icon.delete} onClick={handleDeleteClick}></Button></Tooltip>}
		</div>
	)
}

export default FormSabbaticalAction;