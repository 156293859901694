import className from 'classnames/bind';
import MenuItems from "./MenuItems";
import style from './Menu.module.scss';

const cx = className.bind(style);

function Menu({items, className, classNameItem, classNameDropdown}) {
	const depthLevel = 0;
	return (<ul className={cx('menu', className)}>
		{items.map((menu, index) => {
			return (<MenuItems key={index} items={menu} depthLevel={depthLevel} className={classNameItem} classNameDropdown={classNameDropdown} />);
		})}
	</ul>);
}

export default Menu;