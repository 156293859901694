export const DATA_LIMIT = 20;
export const USER_STATUS = {
	'internship'        : {key: 'internship',   title: 'Thực tập',      color : 'yellow'},
	'probation'         : {key: 'probation',    title: 'Thử việc',      color : 'blue'},
	'public'            : {key: 'public',       title: 'Chính thức',    color : 'green'},
	'trash'             : {key: 'trash',        title: 'Nghỉ việc',     color : 'red'},
};
export const LIST_SOURCE = [
	{key: 'facebook',       title: 'Facebook',          color: 'blue-bold'},
	{key: 'zalo',           title: 'Zalo',              color: 'blue'},
	{key: 'timviecnhanh',   title: 'Tim Việc Nhanh',    color: 'blue'},
	{key: 'topcv',          title: 'TopCV',             color: 'green'},
	{key: 'vieclam24h',     title: 'Vieclam24h',        color: 'blue' },
	{key: 'vieccoi',        title: 'Vieccoi',           color: 'blue'},
	{key: 'viectotnhat',    title: 'Viectotnhanh',      color: 'blue'},
	{key: 'introduce',      title: 'Được giới thiệu',   color: 'blue'},
	{key: 'other',          title: 'Khác',              color: 'white'},
];
export const LIST_CONTRACT_STATUS = {
	'pending': {
		label: "Chờ xác nhận",
		color: 'yellow',
	},
	'confirm': {
		label: "Đã xác nhận",
		color: 'green',
	},
	'cancel': {
		label: "Đã hủy",
		color: 'red',
	},
}
export const LIST_REVENUE_STATUS = {
	'pending': {
		label: "Chờ xác nhận",
		color: 'yellow',
	},
	'confirm': {
		label: "Đã xác nhận",
		color: 'green',
	},
	'cancel': {
		label: "Đã hủy",
		color: 'red',
	},
}
export const LIST_PROJECT_STATUS = {
	'pending': {
		label: "Chờ xác nhận",
		color: 'yellow',
	},
	'confirm': {
		label: "Đã xác nhận",
		color: 'green',
	},
	'additional': {
		label: "Bổ sung",
		color: 'red',
	},
	'assign': {
		label: "Đợi tiến hành",
		color: 'blue-mint',
	},
	'doing': {
		label: "Đang tiến hành",
		color: 'blue',
	},
	'doing-demo': {
		label: "Đã lên demo",
		color: 'blue-bold',
	},
	'success': {
		label: "Đã hoàn thành",
		color: 'green',
	},
	'support': {
		label: "Hỗ trợ",
		color: 'green-mint',
	},
	'pause': {
		label: "Tạm dừng",
		color: 'red',
	},
	'failed': {
		label: "Đã hủy",
		color: 'red-bold',
	},
}
export const LIST_PROJECT_PHASE = {
	'design': {
		label: "Thiết kế",
		color: 'blue',
	},
	'program': {
		label: "Lập trình",
		color: 'green',
	},
	'support': {
		label: "Hỗ trợ",
		color: 'white',
	},
}
export const LIST_TASK_STATUS = {
	'pending': {
		label: "Đợi làm",
		color: 'yellow',
	},
	'additional': {
		label: "Bổ sung",
		color: 'red',
	},
	'working': {
		label: "Đang làm",
		color: 'blue',
	},
	'resolved': {
		label: "Đã làm (đợi duyệt)",
		color: 'green',
	},
	'closed': {
		label: "Hoàn thành",
		color: 'green',
	},
	'reopened': {
		label: "Làm lại",
		color: 'red',
	},
	'not-job': {
		label: "Không làm",
		color: 'black',
	},
}
export const LIST_FORM_STATUS = {
	'pending': {
		label: "Đợi duyệt",
		color: 'yellow',
	},
	'allow': {
		label: "Cho phép",
		color: 'green',
	},
	'disallow': {
		label: "Không duyệt",
		color: 'red',
	},
}
export const LIST_PAPER_STATUS = {
	'pending': {
		label: "Đợi phát",
		color: 'yellow',
	},
	'success': {
		label: "Đã phát",
		color: 'green',
	},
	'cancel': {
		label: "Đã hủy",
		color: 'red',
	},
}
export const LIST_PAPER_NUMBER_STATUS = {
	'pending': {
		label: "Đợi phát",
		color: 'yellow',
	},
	'allow': {
		label: "Đã phát",
		color: 'blue',
	},
	'success': {
		label: "Đã thu",
		color: 'green',
	},
	'cancel': {
		label: "Đã hũy",
		color: 'red',
	},
}