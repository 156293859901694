import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {projectActions} from "./projectSlice";
import {projectApi} from "~/api";

function* fetchProjectList(action) {
	try {
		const response = yield call(projectApi.gets, action.payload);
		yield put(projectActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch project list', error);
		yield put(projectActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(projectActions.setFilter(action.payload));
}

export default function* projectSaga() {
	yield takeLatest(projectActions.fetchData.type, fetchProjectList);
	yield debounce(500, projectActions.setFilterWithDebounce.type, handleSearchDebounce)
}