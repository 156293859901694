import request from "~/utils/http";

const extendApi = {
	gets : async (params) => {
		const url = 'extends';
		return await request.get(url, {params});
	},
	updateService : async (params) => {
		const url = `extends/services`;
		return await request.post(url, params);
	},
	statusEmail : async (params) => {
		const url = `extends/status/email`;
		return await request.post(url, params);
	},
	saveEmail : async (params) => {
		const url = `extends/save/email`;
		return await request.post(url, params);
	},
	sendEmail : async (params) => {
		const url = `extends/send/email`;
		return await request.post(url, params);
	},
	statusZalo : async (params) => {
		const url = `extends/status/zalo`;
		return await request.post(url, params);
	},
	sendZalo : async (params) => {
		const url = `extends/send/zalo`;
		return await request.post(url, params);
	},
};

export default extendApi;