import {createSlice} from "@reduxjs/toolkit";
import request from "../../utils/http";
const authSlice = createSlice({
	name : 'auth',
	initialState : {
		loading : false,
		isLoggedIn : false,
		error : null,
		currentUser : undefined,
		permissions : [],
	},
	reducers : {
		login(state) {
			state.loading = true;
			state.error = null;
			state.isLoggedIn = false;
		},
		loginSuccess(state, action) {
			state.loading       = false;
			state.error         = null;
			state.isLoggedIn    = true;
			state.currentUser   = action.payload.user;
			state.permissions   = action.payload.permissions;
			const storedToken  = localStorage.getItem('access_token');
			const loginAsToken = localStorage.getItem('access_token_as');
			if (storedToken) {
				const token = JSON.parse(storedToken)
				request.defaults.headers = {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${token.accessToken}`,
					"loginAsToken" : loginAsToken,
				}
			}
			return state;
		},
		update(state, action) {
			state.currentUser   = action.payload;
			return state;
		},
		loginFailed(state, action) {
			state.loading       = false;
			state.error = action.payload;
		},
		logout(state) {
			state.isLoggedIn = false;
			state.currentUser = undefined;
			state.permissions = [];
		}
	}
});

//Action
export const authActions = authSlice.actions;
//Reducers
export const authReducer = authSlice.reducer;
//Selectors
export const currentUserSelector = (state) => state.auth.currentUser;
export const permissionsUserSelector = (state) => state.auth.permissions;
export const authErrorSelector = (state) => state.auth.error;
export const isLoginSelector = (state) => state.auth.isLoggedIn;
export const authLoadingSelector = (state) => state.auth.loading;