/*
title - New message from Open Chat
icon - image URL from Flaticon
body - main content of the notification
*/
function sendNotification(data) {
	const notification = new Notification(data.heading, {
		icon: data.icon,
		body: `${data.message}`
	})
	if(data?.url) {
		notification.onclick = ()=> function() {
			window.open(data.url)
		}
	}
}

export default function checkNotificationStatus(data) {
	console.log(data);
	if(!("Notification" in window)) {
		alert("This browser does not support system notifications!")
	}
	else if(Notification.permission === "granted") {
		sendNotification(data)
	}
	else if(Notification.permission !== "denied") {
		Notification.requestPermission((permission)=> {
			if (permission === "granted") {
				sendNotification(data)
			}
		})
	}
}