import style from './SalePoint.module.scss';
import {memo, useMemo} from "react";
import className from 'classnames/bind';
const cn = className.bind(style);

function SalePointGroup({item}) {

	return (
		<div className={cn('salePointGroup')}>
			{ item?.name && <div className={cn('header-title')}>
					<p className="heading mt-1">{item.name}</p>
				</div>
			}

			<div className={cn('item')}>
				<div className={cn('heading')}>Số nhân viên</div>
				<div className={cn('txt')}><span>{item?.user}</span></div>
				<div className={cn('txt')}></div>
				<div className={cn('txt')}></div>
			</div>
			<div className={cn('item')}>
				<div className={cn('heading')}>Số tư vấn mới</div>
				<div className={cn('txt')}><span>{item?.newAssign?.meeting + item?.newAssign?.online}</span></div>
				<div className={cn('txt')}><span>{item?.newAssign?.meeting}</span> (Trực Tiếp)</div>
				<div className={cn('txt')}><span>{item?.newAssign?.online}</span> (Online)</div>
			</div>
			<div className={cn('item')}>
				<div className={cn('heading')}>Gặp khách cũ</div>
				<div className={cn('txt')}><span>{item?.oldAssign?.meeting + item?.oldAssign?.online}</span></div>
				<div className={cn('txt')}><span>{item?.oldAssign?.meeting}</span> (Trực Tiếp)</div>
				<div className={cn('txt')}><span>{item?.oldAssign?.online}</span> (Online)</div>
			</div>
			<div className={cn('item')}>
				<div className={cn('heading')}>Số bàn giao</div>
				<div className={cn('txt')}><span>{item?.handOver?.meeting + item?.handOver?.online}</span></div>
				<div className={cn('txt')}><span>{item?.handOver?.meeting}</span> (Trực Tiếp)</div>
				<div className={cn('txt')}><span>{item?.handOver?.online}</span> (Online)</div>
			</div>
		</div>
	)
}

export default memo(SalePointGroup);