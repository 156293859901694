import {useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import StatusLabel from "~/components/StatusLabel";
import {
	GroupRadioField,
	DateField
} from "~/components/Forms";
import {
	LIST_PROJECT_PHASE
} from "~/app/constants";

function ProjectFormPhase({item, onHandleSubmit}) {

	const phaseOptions = useMemo(() => {
		return [...Object.entries(LIST_PROJECT_PHASE).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const initialValues = {
		phase: '',
	}

	const validationSchema = Yup.object().shape({
		phase: Yup.string().required('Giai đoạn dự án không được để trống'),
		deadline: '',
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.phase = item.phase;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Giai đoạn</p>
			<Controller control={control} name="phase" render={({field}) => (
				<GroupRadioField options={phaseOptions} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="deadline" render={({ field }) => (
				<DateField label="Deadline" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Chuyển giai đoạn</Button>
			</div>
		</form>
	)
}

export default ProjectFormPhase;