export function checkAuthorization () {
	// attempt to grab the token from localstorage
	const storedToken = localStorage.getItem('access_token');
	// if it exists
	return !!storedToken;
}

export function getAuthorization () {
	// attempt to grab the token from localstorage
	const storedToken = localStorage.getItem('access_token');

	// if it exists
	if (storedToken) {
		// parse it down into an object
		return JSON.parse(storedToken)
	}

	return {};
}