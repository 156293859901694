import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import {Image} from "../../../../components";
const cn = className.bind(style);
function SaleReportTeam({item, time, onLoad}) {
	return (
		<div className={cn('report-team')} onClick={() => onLoad(time, item.id, 'user')}>
			<div className={cn('avatar') + ' avatar'}><Image src={item?.avatar} /></div>
			<div className={cn('name')}>{item.lastname}</div>
		</div>
	)
}
export default SaleReportTeam;