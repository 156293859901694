import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {papersTypeActions} from "./papersTypeSlice";
import {papersApi} from "~/api";

function* fetchPapersTypeList(action) {
	try {
		const response = yield call(papersApi.getsType, action.payload);
		yield put(papersTypeActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch papersType list', error);
		yield put(papersTypeActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(papersTypeActions.setFilter(action.payload));
}

export default function* papersTypeSaga() {
	yield takeLatest(papersTypeActions.fetchData.type, fetchPapersTypeList);
	yield debounce(500, papersTypeActions.setFilterWithDebounce.type, handleSearchDebounce)
}