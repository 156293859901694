import request from "~/utils/http";

const customerApi = {
	get : async (id) => {
		const url = `customers/${id}`;
		return await request.get(url);
	},
	gets : async (params) => {
		const url = 'customers';
		return await request.get(url, {params});
	},
	getByContract : async (id) => {
		const url = `customers/contract/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'customers';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `customers/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `customers/${id}`;
		return await request.delete(url);
	},
	status : async (id) => {
		const url = `customers/status/${id}`;
		return await request.post(url);
	},
	transfer : async (params) => {
		const url = `customers/transfer/${params.id}`;
		return await request.post(url, params);
	},
	editGroup : async (params) => {
		const url = `customers/groups-up/${params.id}`;
		return await request.post(url, params);
	},
};

export default customerApi;