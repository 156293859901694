import { call, put, take, fork } from "redux-saga/effects";
import { authActions } from "./authSlice";
import {authApi} from "~/api";
import {globalNavigate} from "~/routes/GlobalHistory";
function* handleLogin(username, password) {
	try {
		const response = yield call(authApi.token, username, password);
		yield put(authActions.loginSuccess(response));
		globalNavigate("/");
	}
	catch (error) {
		let message = 'Đăng nhập thất bại';

		if(error.code === 'ERR_NETWORK') {
			message = 'Kết nối server thất bại vui lòng thử lại'
		}
		if(error?.response) {
			if(error.response.status === 401 || error.response.status === 400) {
				message = 'Vui lòng kiểm tra lại thông tin đăng nhập'
			}
		}

		yield put(authActions.loginFailed(message));
	}
}

function* handleLogout() {
	yield put(authActions.logout);
	globalNavigate("/login");
}

function* loginWatcher() {
	while (true) {
		const isLoggedIn = Boolean(localStorage.getItem('access_token'));
		if (!isLoggedIn) {
			const action = yield take(authActions.login.type);
			const {username, password} = action.payload;
			yield fork(handleLogin, username, password)
		}
		else {
			yield take(authActions.logout.type)
			yield fork(handleLogout);
		}
	}
}

export default function* authSaga() {
	yield fork(loginWatcher);
}