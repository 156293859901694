import style from "./RevenuReport.module.scss";
import className from "classnames/bind";
import RevenueReportUser from "./RevenueUser";
import {numberFormat} from "~/utils";
const cn = className.bind(style);
function RevenueReportGroup({item = []}) {

	return (
		<div className={cn('group-wrapper')}>
			<h3 className={cn('group-name', 'text-lg')}>{item?.label} - {numberFormat(item.total)}</h3>
			<RevenueReportUser items={item.users} totalService={item.service} total={item.total} />
		</div>
	)
}

export default RevenueReportGroup;