import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	SelectContractAll
} from "~/components/Forms";

function UploadWebsiteFormAddHost({item, onHandleSubmit}) {

	const hostOptions = [];

	const initialValues = {
		hostId: undefined,
	}

	const validationSchema = Yup.object().shape({
		hostId: Yup.string().required('Hợp đồng host không được bỏ trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});
	useEffect(() => {
		if(item?.id) {
			initialValues.hostId = item.hostId;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Host</p>
			<Controller control={control} name="hostId" render={({ field }) => (
				<SelectContractAll service="host" placeholder="Hợp đồng host" options={hostOptions} errors={errors} {...field} />
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Cập nhật host</Button>
			</div>
		</form>
	)
}

export default UploadWebsiteFormAddHost;