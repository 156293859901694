import {useForm, Controller} from "react-hook-form";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	DateField
} from "~/components/Forms";

function RevenueFormPay({item, onHandleSubmit}) {

	const initialValues = {
		time: '',
	}

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="time" render={({field }) => (
				<DateField picker="month" errors={errors} {...field} />
			)}/>
			<div className="form-group d-flex justify-content-end pd-2 modal-bottom">
				<Button primary type="submit">Tính lương</Button>
			</div>
		</form>
	)
}

export default RevenueFormPay;