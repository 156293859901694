import {useState, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	InputField,
	SelectCustomerAll,
	SelectUserAll
} from "~/components/Forms";
import {
	StatusLabel,
	Button,
	Icon,
	Notification
} from "~/components";
import {
	LIST_CONTRACT_STATUS
} from "~/app/constants";
import {useCan, useUtilities} from "~/hooks";

function ContractSearchMobile({filter, listGroup, onSearchChange, onChange}) {

	const {utilities} = useUtilities();

	const can = {
		listAll: useCan('contractListAll'),
		listGroup: useCan('contractListGroup'),
		add: useCan('contractAdd'),
		edit: useCan('contractEdit'),
		transfer: useCan('contractTransfer'),
		delete: useCan('contractDelete'),
		status: useCan('contractStatus'),
	};

	const serviceOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.services).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_CONTRACT_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	const [searchBar, setSearchBar] = useState(false);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const onSubmit = (data) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			keyword: data.keyword,
			userId: data.userId,
			customerId: data.customerId,
			groupId: data.groupId,
			service: data.service,
			status: data.status,
			page: 1,
		};
		setSearchBar(false);
		onSearchChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<InputField placeholder="Tên miền, tên, số hợp đồng" onChange={handleKeywordChange}/>
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

					<Controller control={control} name="keyword" render={({field}) => (
						<InputField label="Tên miền, tên, số hợp đồng" errors={errors} {...field} />
					)}/>

					<Controller control={control} name="customerId" render={({field}) => (
						<SelectCustomerAll label="Khách hàng" options={[{value:'', label:'Tất cả'}]} />
					)}/>
					{(can.listAll || can.listGroup) &&
						<Controller control={control} name="groupId" render={({ field }) => (
							<SelectField placeholder="Chọn nhóm" options={groupOptions} errors={errors} {...field} />
						)}/>
					}
					{(can.listAll || can.listGroup) &&
						<Controller control={control} name="userId" render={({ field }) => (
							<SelectUserAll placeholder="Chọn nhân viên" options={[{value:'', label:'Tất cả'}]} errors={errors} {...field} />
						)}/>
					}
					<Controller control={control} name="service" render={({field}) => (
						<SelectField label="Dịch vụ" options={serviceOptions} errors={errors} {...field} />
					)}/>
					<Controller control={control} name="status" render={({field}) => (
						<SelectField label="Trạng thái" options={statusOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default ContractSearchMobile;