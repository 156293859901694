import request from "~/utils/http";

const ruleApi = {
	getsGroupWidthItem : async (params) => {
		const url = 'rules/group/items';
		return await request.get(url, {params});
	},
	getsGroup : async (params) => {
		const url = 'rules/group';
		return await request.get(url, {params});
	},
	addGroup : async (params) => {
		const url = 'rules/group';
		return await request.post(url, params);
	},
	updateGroup : async (params) => {
		const url = `rules/group/${params.id}`;
		return await request.patch(url, params);
	},
	deleteGroup : async (id) => {
		const url = `rules/group/${id}`;
		return await request.delete(url);
	},

	countItem : async (params) => {
		const url = 'rules/item/count';
		return await request.get(url, {params});
	},
	getsItem : async (params) => {
		const url = 'rules/item';
		return await request.get(url, {params});
	},
	addItem : async (params) => {
		const url = 'rules/item';
		return await request.post(url, params);
	},
	updateItem : async (params) => {
		const url = `rules/item/${params.id}`;
		return await request.patch(url, params);
	},
	deleteItem : async (id) => {
		const url = `rules/item/${id}`;
		return await request.delete(url);
	},
};

export default ruleApi;