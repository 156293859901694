import style from "../../style/Card.module.scss";
import {useCallback, useRef} from "react";
import className from "classnames/bind";
import images from "../../../../assets/images";
import {Button, Image} from "../../../../components";
import {toJpeg, toPng} from "html-to-image";
import {useUtilities} from "../../../../hooks";
import {QRCode} from "antd";
const cn = className.bind(style);
function PersonnelCard({item}) {

	const {utilities} = useUtilities();

	const ref = useRef(null);

	const handleButtonClick = {
		toPng: useCallback(() => {
			if (ref.current === null) {
				return null;
			}
			toPng(ref.current, { cacheBust: true, })
				.then((dataUrl) => {
					const link = document.createElement('a')
					link.download = 'my-image-name.png'
					link.href = dataUrl
					link.click()
				})
				.catch((err) => {
					console.log(err)
				})

		}, [ref]),
		toJpg: useCallback(() => {
			if (ref.current === null) {
				return null;
			}
			toJpeg(ref.current, { cacheBust: true, })
				.then((dataUrl) => {
					const link = document.createElement('a')
					link.download = 'my-image-name.png'
					link.href = dataUrl
					link.click()
				})
				.catch((err) => {
					console.log(err)
				})

		}, [ref]),
	}

	return (
		<div>
			<div className={cn('card-wrapper')} ref={ref}>
				<div className={cn('card-bg')}><Image src={images.cardUser} /></div>
				<div className={cn('card-avatar')}><Image src={item.avatar} /></div>
				<div className={cn('name')}><span>{item.firstname} {item.lastname}</span></div>
				<div className={cn('role')}><span>{utilities.roles[item.role]?.title}</span></div>
				<div className={cn('info', 'code')}><span>{item.username}</span></div>
				<div className={cn('info', 'email')}><span>{item.email}</span></div>
				<div className={cn('info', 'phone')}><span>{item.phone}</span></div>
				<div className={cn('info', 'qrCode')}><QRCode value={item.qrToken} /></div>
			</div>
			<div className="pd-1 d-flex justify-content-end modal-bottom">
				<Button primary onClick={handleButtonClick.toPng}>Tải ảnh PNG</Button>
				<Button background blue onClick={handleButtonClick.toJpg}>Tải ảnh JPG</Button>
			</div>
		</div>
	)
}

export default PersonnelCard;