import style from "../../style/SaleReport.module.scss";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import className from "classnames/bind";
import ActionBar from "~/layout/ActionBar";
import {Col, notification, Row, Skeleton} from "antd";
import _ from "lodash"
import {
	saleReportActions,
	saleReportErrorSelector, 
	saleReportFilterSelector,
	saleReportItemsSelector,
	saleReportLoadingSelector, 
} from "../../saleReportSlice";

import {
	SaleReportItem,
	SaleReportDetail,
	SaleReportFormAdd
} from "../../components";

import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";

import {
	saleApi
} from "~/api";
import {
	Loading,
	Button,
	Icon
} from "~/components";

const cn = className.bind(style);

function SaleReport() {

	const dispatch  = useDispatch();

	const items     = useSelector(saleReportItemsSelector);

	const loading   = useSelector(saleReportLoadingSelector);

	const error     = useSelector(saleReportErrorSelector);

	const filter    = useSelector(saleReportFilterSelector);

	const [teams, setTeams] = useState([]);

	const [reportData, setReportData] = useState([]);

	const [type, setType]             = useState('');

	const [time, setTime]             = useState(0);

	const [loadingDetail, setLoadingDetail] = useState(false);

	let [files, setFiles] = useState([]);

	//Load data
	useEffect(() => {
		dispatch(saleReportActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleLoadDetail = async (timeReport, id, type) => {

		setLoadingDetail(true);

		let [error, response] = await handleRequest(saleApi.reportGet({
			id   : id,
			type : type,
			time : timeReport,
		}));

		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			setTeams(response.data.teams);
			setType(response.data.type)
			setTime(timeReport)
			setReportData(response.data.data)
		}
		setLoadingDetail(false);
	}

	const handleLoadAdd = async (time) => {
		setLoadingDetail(true);
		setReportData([]);
		setTeams([]);
		setFiles([]);
		if(time != undefined) {
			time = time.get('date')+'-'+(time.get('month')+1)+'-'+time.get('year');
		}
		let [error, response] = await handleRequest(saleApi.reportData({time:time}));
		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			setType('add');
			setReportData(response.data)
			files = [];
			if(!_.isEmpty(response.data.attachFile)) {
				for (const [path, name] of Object.entries(response.data.attachFile)) {
					files.push({
						path: path,
						name: name
					})
				}
			}
			setFiles(files);
		}
		setLoadingDetail(false);
	}

	//Submit
	const handleSaveItem = async (data) => {
		if (!isNaN(data.time)) {
			data.time = Math.round(new Date(+data.time).getTime() / 1000)
		} else if (data.time.search('GMT') != -1) {
			data.time = Math.round(new Date(data.time).getTime() / 1000)
		}
		let [error, response] = await handleRequest(saleApi.reportAdd(data));
		let message = apiError(`Báo cáo thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Báo cáo thành công`});
		}
	}

	//upload
	const handleUpload = async (data) => {
		if (data?.time == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin báo cáo để cập nhật'});
			return;
		}
		data.time = strToTime(data.time);
		let [error, response] = await handleRequest(saleApi.reportFileUpload(data));
		let message = apiError(`Đính kèm file thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
		}
	}

	const handleUploadDelete = async (filename) => {
		let [error, response] = await handleRequest(saleApi.reportFileRemove({filename: filename}));
		let message = apiError(`Xóa file thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Xóa file thành công`});
		}
	}

	return (
		<>
			<ActionBar title={'Báo cáo'}>
				<Button outline leftIcon={Icon.plusCircle} onClick={() => handleLoadAdd()}>Thêm mới</Button>
			</ActionBar>
			<div className="content-scroll">
				<div className={cn('form')+' form'} style={{width:'100%'}}>
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={6}>
							<div className="content-shadow pd-1">
								<p className="heading">Báo cáo</p>
								{loading && <Skeleton />}
								{
									Object.keys(items).map((itemKey) => {
										let item = items[itemKey];
										return <SaleReportItem key={`report-item-${item.id}`} item={item} onClick={() => handleLoadDetail(item.time, item.groupId, item.type)} />
									})
								}
							</div>
						</Col>
						<Col className="gutter-row" xs={24} md={18}>
							<div className="content-shadow pd-1">
								{loadingDetail && <Loading noFixed style={{width:'100%'}} />}
								{(!loadingDetail && !_.isEmpty(reportData) && type == 'add') && <SaleReportFormAdd
									onHandleSubmit={handleSaveItem}
									onTimeChange={handleLoadAdd}
									onUpload={handleUpload}
									onRemoveFile={handleUploadDelete}
									reportData={reportData}
									files={files}
									setFiles={setFiles}/>}
								{(!loadingDetail && (!_.isEmpty(teams) && !_.isEmpty(reportData))) && <SaleReportDetail
									type={type}
									time={time}
									teams={teams}
									onLoad={handleLoadDetail}
									reportData={reportData}/>}
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default SaleReport;