import style from './Notification.module.scss';
import {Link} from "react-router-dom";
import className from 'classnames/bind';
import Image from "../Image";
import DOMPurify from "dompurify";
const cn = className.bind(style);

function MessageItem({item, onClickLink}) {

	let classAvatar = 'avatar ' + cn('avatar');

	return (<div className={cn('message-item', {'isRead' : (item.isOpen == 0)})} onClick={() => onClickLink(item)}>
		<Link to={`/${item.url}`}>
			<div className={cn('wrapper')}>
				<div className={classAvatar}>
					<Image src={item.avatar}></Image>
				</div>
				<div className={cn('info')}>
					<p className={cn('heading')} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.heading) }} />
					<p className={cn('message')}>{item.message}</p>
				</div>
			</div>
		</Link>
	</div>);
}

export default MessageItem;