import {memo, useCallback, useState} from "react";
import {
	SelectUserAll,
} from "~/components/Forms";
import {
	useCan
} from "~/hooks";

function WorkingSearchBar({filter, onChange}) {

	const canViewAll 		= useCan('salesEventListAll');

	const canViewGroup 		= useCan('salesEventListGroup');

	const canViewLeader 	= useCan('salesEventListLeader');

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const handleUserChange = useCallback((value) => {
		if(onChange) onChange('userId', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					{(canViewAll || canViewGroup || canViewLeader) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />}
				</form>
			</div>
		</div>
	)
}

export default memo(WorkingSearchBar);