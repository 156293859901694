import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField,
	DateField,
	TextAreaField
} from "~/components/Forms";

function FormSabbaticalAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const timeStartOptions = [
		{value: '08:00', label: 'Từ 8h sáng'},
		{value: '12:00', label: 'Từ 12h trưa'},
	];

	const timeEndOptions = [
		{value: '17:00', label: 'Đến 5h chiều'},
		{value: '12:00', label: 'Đến 12h trưa'},
	];

	const initialValues = {
		startDay: new Date(),
		timeStart: '08:00',
		endDay: new Date(),
		timeEnd: '17:00',
		note: '',
	}

	const validationSchema = Yup.object().shape({
		startDay: Yup.string().required('Bạn chưa chọn ngày bắt đầu nghỉ'),
		timeStart: Yup.string().required('Bạn chưa chọn thời gian bắt đầu nghỉ'),
		endDay: Yup.string().required('Bạn chưa chọn ngày kết thúc nghỉ'),
		timeEnd: Yup.string().required('Bạn chưa chọn thời gian kết thúc nghỉ'),
		note: Yup.string().required('Lý do xin nghỉ không được bỏ trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.startDay  = item.startDay*1000;
			initialValues.endDay    = item.endDay*1000;
			initialValues.timeStart = item.timeStart;
			initialValues.timeEnd   = item.timeEnd;
			initialValues.note      = item.note;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin</p>

			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="startDay" render={({ field }) => (
						<DateField label="Ngày bắt đầu" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="timeStart" render={({ field }) => (
						<SelectField label="Thời gian" options={timeStartOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="endDay" render={({ field }) => (
						<DateField label="Ngày kết thúc" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="timeEnd" render={({ field }) => (
						<SelectField label="Thời gian" options={timeEndOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<Controller control={control} name="note" render={({ field }) => (
				<TextAreaField label="Lý do" rows={5} errors={errors} {...field} />
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} đơn</Button>
			</div>
		</form>
	)
}

export default FormSabbaticalAddEdit;