import {Fragment, useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Loading,
	Button,
	Modal
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	useCan
} from "~/hooks";

import {
	FormPapersAddEdit,
	PapersTable,
	FormPapersInfo,
} from "../components";

import {
	papersApi
} from "~/api";

import {
	papersActions,
	papersItemsSelector,
	papersLoadingSelector,
	papersErrorSelector,
	papersPaginationSelector,
	papersFilterSelector
} from "../papersSlice";
import {AppContext} from "~/context/AppProvider";

function Papers() {

	const {
		notificationCount,
		setNotificationCount
	} = useContext(AppContext);

	const can = {
	    add     : useCan('papersAdd'),
	    edit    : useCan('papersEdit'),
	    status  : useCan('papersStatus'),
	    delete  : useCan('papersDelete'),
	}
	
	const dispatch  = useDispatch();

	const items     = useSelector(papersItemsSelector);

	const loading   = useSelector(papersLoadingSelector);

	const error     = useSelector(papersErrorSelector);

	const pagination = useSelector(papersPaginationSelector);

	const filter    = useSelector(papersFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [papersType, setPapersType] = useState([]);

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		info        : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleReLoading();
	}, [filter]);

	const handleReLoading = () => {
		dispatch(papersActions.fetchData(filter));
	}

	useEffect(() => {
		fetchPapersType().then();
	}, []);

	const fetchPapersType = async () => {
		let [error, response] = await handleRequest(papersApi.getsType());
		let message = apiError(`Load danh sách loại đơn thất bại`, error, response);
		if (!message) {
			setPapersType(response.data);
		}
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin đơn xin giấy tờ để cập nhật'});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}

		let [error, response] = (item?.id) ? await handleRequest(papersApi.update(data)) : await handleRequest(papersApi.add(data));
		let message = apiError(`${messageAction} đơn xin giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} đơn thành công`});
			if(item?.id) {
				dispatch(papersActions.update(response.data));
			}
			else {
				dispatch(papersActions.add(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn giấy tờ để xóa'});
			return;
		}
		let [error, response] = await handleRequest(papersApi.delete(itemEdit.id));
		let message = apiError(`xóa đơn xin giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa đơn xin giấy tờ thành công`});
			dispatch(papersActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (status, item, data) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có đơn xin giấy tờ để cập nhật'});
			return;
		}
		data.id = item.id;
		data.status = status;
		let [error, response] = await handleRequest(papersApi.status(data));
		let message = apiError(`Cập nhật trạng thái đơn xin giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái đơn xin giấy tờ thành công`});
			item.status    = response.data.status;
			item.code      = response.data.code;
			item.note2     = response.data.note2;
			item.assignId  = response.data.assignId;
			item.assign    = response.data.assign;
			item.time      = response.data.time;
			dispatch(papersActions.update(item));

			if(response.data.status != 'pending') {
				setNotificationCount((prevState) => ({
					...prevState,
					form: {
						...prevState.form,
						papers: prevState.form.papers - 1,
					}
				}))
			}
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(papersActions.setFilter({...filter, page }));
	}

	return (
		<Fragment>
			<ActionBar title={'Đơn xin giấy tờ'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <PapersTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						can={can}
					/>}
				</div>
				{
					(!can.add || (can.edit && openModal.addEdit)) && <Modal title="Đơn xin giấy tờ" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
						<FormPapersAddEdit can={can} item={itemEdit} papersType={papersType} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(can.status && openModal.info && itemEdit?.id) && <Modal title="Thông tin" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
						<FormPapersInfo can={can} item={itemEdit} onHandleSubmit={handleStatus} />
					</Modal>
				}
				{
					(can.delete && openModal.delete && itemEdit?.id) && <Modal title="Xóa đơn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
						<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
						<br />
						<div className="d-flex justify-content-end gap">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
						</div>
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default Papers;