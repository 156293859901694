import { memo } from 'react';
import className from 'classnames/bind';
import style from './Notification.module.scss';
import {FontAwesomeIcon} from "../index";

const cn = className.bind(style);

function Header({title, description, icon, onClose}) {
	return (<div className={cn('header')}>
		<div className={cn('icon')}>{icon}</div>
		<div className={cn('text')}>
			<p className={cn('text-heading')}>{title}</p>
			<p className={cn('text-subHeading')}>{description}</p>
		</div>
		<div className={cn('icon', {'pointer' : true})} onClick={() => onClose()}><FontAwesomeIcon icon="fa-thin fa-xmark-large" /></div>
	</div>);
}

export default memo(Header);