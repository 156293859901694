import request from "~/utils/http";

const callSupportApi = {
	gets : async (params) => {
		const url = 'call-supports';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `call-supports/${id}`;
		return await request.get(url);
	},
	getByCustomer : async (id) => {
		const url = `call-supports/customer/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'call-supports';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `call-supports/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `call-supports/${id}`;
		return await request.delete(url);
	}
};

export default callSupportApi;