import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import {renderDate} from "~/utils";
const cn = className.bind(style);
function SaleReportItem({item, onClick}) {
	return (
		<div className={cn('report-item')+' pointer'} onClick={() => onClick(item)}>
			<p>Báo cáo ngày {renderDate(item.time)}</p>
			<p className={cn('group-name')}>{item.group.name}</p>
		</div>
	)
}
export default SaleReportItem;