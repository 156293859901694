import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import {
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import ActionBar from "~/layout/ActionBar";
import {
	saleConsultationActions,
	saleConsultationErrorSelector,
	saleConsultationFilterSelector,
	saleConsultationItemsSelector,
	saleConsultationLoadingSelector,
	saleConsultationPaginationSelector
} from "../saleConsultationSlice";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useDevice,
	useGroup
} from "~/hooks";
import {
	saleApi
} from "~/api";
import {
	SaleConsultationTable,
	SaleEventFormAddEdit,
	SaleEventFormStart,
	SaleEventFormAssign,
	SaleConsultationFormReport,
	ConsultationSearchBar,
	ConsultationSearchMobile,
	SaleEventFormCopy
} from "../components";
import {saleEventActions} from "../saleEventSlice";

function SaleConsultation() {

	const {isMobile} = useDevice();

	const canAdd = useCan('salesEventAdd');

	const canEdit = useCan('salesEventEdit');

	const canAssign = useCan('salesEventAssign');

	const dispatch  = useDispatch();

	const items     = useSelector(saleConsultationItemsSelector);

	const loading   = useSelector(saleConsultationLoadingSelector);

	const error     = useSelector(saleConsultationErrorSelector);

	const pagination = useSelector(saleConsultationPaginationSelector);

	const filter    = useSelector(saleConsultationFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const listGroup = useGroup();

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		cancel      : false,
		start       : false,
		end         : false,
		assign      : false,
		report      : false,
		copy        : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	useEffect(() => {
		handleReLoading();
	}, [filter]);

	const handleReLoading = () => {
		dispatch(saleConsultationActions.fetchData(filter));
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		if(!canEdit) {
			notification.error({message: 'Thất bại', description: `Bạn không có quyền sử dụng chức năng này`});
			return;
		}
		if(!item?.id) {
			notification.error({message: 'Thất bại', description: `Không có thông tin cuộc tư vấn`});
			return;
		}
		data.id = item.id;
		data.day = strToTime(data.day);
		let [error, response] = await handleRequest(saleApi.eventUpdate(data));
		let message = apiError(`Cập nhật cuộc tư vấn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật cuộc tư vấn thành công`});
			dispatch(saleConsultationActions.update(response.data));
			handleModalClose('addEdit')
		}
	}

	//copy
	const handleCopy = async (data, item) => {
		if(item?.point == 1) {
			notification.error({message: 'Thất bại', description: 'Cuộc tư vấn đang gặp khách không thể dời hẹn'});
			return;
		}
		data.day = strToTime(data.day);
		let [error, response] = await handleRequest(saleApi.eventAdd(data));
		let message = apiError(`Dời lịch tư vấn thất bại`, error, response);
		if(!message) {
			if(!response?.data?.id) {
				notification.error({message: 'Thất bại', description: `Chưa dời được lịch tư vấn`});
			}
			else {
				notification.success({message: 'Thành công', description: `Dời lịch tư vấn thành công`});
				dispatch(saleEventActions.add(response.data));
				handleModalClose('copy')
			}
		}
	}

	//Cancel
	const handleCancel = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin cuộc tư vấn để hủy'});
			return;
		}
		let [error, response] = await handleRequest(saleApi.eventCancel(item.id));
		let message = apiError(`Hủy cuộc tư vấn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Hủy cuộc tư vấn thành công`});
			item.point = response.data.point;
			dispatch(saleConsultationActions.update(item));
			handleModalClose('cancel')
		}
	}

	const handleStart = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin cuộc tư vấn để thay đổi'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(saleApi.eventStart(data));
		let message = apiError(`Bắt đầu cuộc tư vấn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Bắt đầu cuộc tư vấn thành công`});
			item.point = response.data.point;
			dispatch(saleConsultationActions.update(item));
			handleModalClose('start')
		}
	}

	const handleEnd = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin cuộc tư vấn để thay đổi'});
			return;
		}
		let [error, response] = await handleRequest(saleApi.eventEnd(item.id));
		let message = apiError(`Hoàn thành cuộc tư vấn thất bại`, error, response);
		if(!message && response?.data?.point) {
			notification.success({message: 'Thành công', description: `Hoàn thành cuộc tư vấn thành công`});
			item.point = response.data.point;
			dispatch(saleConsultationActions.update(item));
			handleModalClose('end')
		}
		else {
			notification.error({message: 'Thất bại', description: `Cập nhật thông tin thất bại`});
		}
	}

	//assign
	const handleAssign = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có cuộc tư vấn để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(saleApi.eventAssign(data));

		let message = apiError(`Phân công cuộc tư vấn không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Phân công cuộc tư vấn thành công`});
			item.assignId   = response.data.assignId;
			item.assign     = response.data.assign;
			item.supportId  = response.data.supportId;
			item.support    = response.data.support;
			dispatch(saleConsultationActions.update(item));
			handleModalClose('assign');
		}
	}

	//report
	const handleReport = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có cuộc tư vấn để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(saleApi.eventReport(data));

		let message = apiError(`Báo cáo cuộc tư vấn không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Báo cáo cuộc tư vấn thành công`});
			item.result.user    = response.data.user;
			item.result.assign  = response.data.assign;
			item.result.support = response.data.support;
			item.type = response.data.type;
			item.type2 = response.data.type2;
			item.type3 = response.data.type3;
			dispatch(saleConsultationActions.update(item));
			handleModalClose('assign');
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(saleConsultationActions.setFilter({...filter, page }));
	}

	const handleFilterChange = (newFilter) => {
		dispatch(saleConsultationActions.setFilter(newFilter));
	};

	const handleSearchChange = (newFilter) => {
		dispatch(saleConsultationActions.setFilterWithDebounce(newFilter));
	};

	const SearchBar = (isMobile) ? ConsultationSearchMobile : ConsultationSearchBar;

	return (
		<Fragment>
			<ActionBar title={'Tư vấn'}>
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<SearchBar filter={filter} onChange={handleFilterChange} listGroup={listGroup} onSearchChange={handleSearchChange}/>
					{items && <SaleConsultationTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
					/>}
				</div>
			</div>
			{
				(canAdd || (canEdit && itemEdit?.id)) && <Modal title="Lịch hẹn" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
					<SaleEventFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
				</Modal>
			}
			{
				(itemEdit?.id) && <Modal title="Hủy cuộc tư vấn" visible={openModal.cancel} onCancel={() => {handleModalClose('cancel')}}>
					<p className="mt-2">Bạn muốn hủy cuộc tư vấn với khách <b>{itemEdit?.name}</b>?</p>
					<div className="pd-1 d-flex justify-content-end gap modal-bottom">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('cancel')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={() => handleCancel(itemEdit)}> Hủy </Button>
					</div>
				</Modal>
			}
			{
				(itemEdit?.id) && <Modal title="Dời tư vấn" visible={openModal.copy} onCancel={() => {handleModalClose('copy')}}>
					<SaleEventFormCopy item={itemEdit} onHandleSubmit={handleCopy} />
				</Modal>
			}
			{
				(itemEdit?.id) && <Modal title="Giao hẹn" visible={openModal.assign} onCancel={() => {handleModalClose('assign')}}>
					<SaleEventFormAssign item={itemEdit} onHandleSubmit={handleAssign} />
				</Modal>
			}
			{
				(canAssign && itemEdit?.id) && <Modal title="Bắt đầu gặp khách" visible={openModal.start} onCancel={() => {handleModalClose('start')}}>
					<SaleEventFormStart item={itemEdit} onHandleSubmit={handleStart} />
				</Modal>
			}
			{
				(itemEdit?.id) && <Modal title="Hoàn thành cuộc tư vấn" visible={openModal.end} onCancel={() => {handleModalClose('end')}}>
					<p className="mt-2">Xác nhận cuộc tư vấn với khách hàng <b>{itemEdit?.name}</b> đã hoàn thành ?</p>
					<div className="pd-1 d-flex justify-content-end gap modal-bottom">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('end')}}> Đóng </Button>
						<Button blue outline leftIcon={Icon.success} onClick={() => handleEnd(itemEdit)}> Xác nhận </Button>
					</div>
				</Modal>
			}
			{
				(itemEdit?.id) && <Modal title="Báo cáo" visible={openModal.report} onCancel={() => {handleModalClose('report')}}>
					<SaleConsultationFormReport item={itemEdit} onHandleSubmit={handleReport} />
				</Modal>
			}
		</Fragment>
	)
}

export default SaleConsultation;