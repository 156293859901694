import {forwardRef} from "react";
import {contractApi} from "~/api";
import {DebounceSelect} from "../index";

const SelectContractAll = forwardRef(({ options, service, errors, ...props }, ref) => {

	const onSearchCustomer = async (keyword, service, mine = false) => {
		const response = await contractApi.search({keyword, service, mine});
		return response.data.map((contract) => ({
			label: `HD${contract.id} ${contract.name}`,
			value: contract.id,
		}))
	}

	return (
		<DebounceSelect fetchOptions={(keyword) => onSearchCustomer(keyword, service)} errors={errors} optionsDefault={options} {...props} ref={ref}/>
	)
});

export default SelectContractAll;