import {call, put, takeLatest} from "redux-saga/effects";
import {saleReportActions} from "./saleReportSlice";
import {saleApi} from "~/api";

function* fetchReportSaleList(action) {

	try {
		const response = yield call(saleApi.reportGets, action.payload);
		yield put(saleReportActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch saleReport list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(saleReportActions.fetchDataFailed(message));
	}
}

export default function* saleReportSaga() {
	yield takeLatest(saleReportActions.fetchData.type, fetchReportSaleList);
}