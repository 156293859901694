import {memo, useContext} from "react";
import {Pagination, Table} from "antd";
import CustomerGroupAction from "./CustomerGroupAction";
import {AppContext} from "~/context/AppProvider";
import {StatusLabel} from "../../../../components";

function CustomerGroupTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const {tableHeight} = useContext(AppContext);

	const handleEditClick = (item) => {
		setItemEdit(item);
		openModal('addEdit');
	}
	const handleDeleteClick = (item) => {
		setItemEdit(item);
		openModal('delete');
	}
	const columns = [
		{ title: 'Tên nhóm', dataIndex: 'name', key: "name", render: (_, item) => <StatusLabel small type={item.badge}>{item.name}</StatusLabel>},
		{ title: 'Ngày tạo', dataIndex: 'created', key: "created", render: (_, item) => (item?.created)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<CustomerGroupAction
				key={item.id}
				item={item}
				onClickEdit={handleEditClick}
				onClickDelete={handleDeleteClick}
			/>
		)},
	];
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y: tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(CustomerGroupTable);