import {useEffect, useState} from "react";
import style from './BrowserPermission.module.scss';
import className from 'classnames/bind';
import {Button, Icon} from "../../components";
const cn = className.bind(style);

function BrowserPermission() {

	const [permissionCheck, setPermissionCheck] = useState({
		notification : true,
		location : true,
	});

	useEffect(() => {
		if ("Notification" in window) {
			if (Notification.permission !== "denied") {
				Notification.requestPermission().then(function (permission) {
					if (permission === "granted") {
						permissionCheck.notification = true;
					}
				});
			}
			else {
				permissionCheck.notification = false;
			}
			setPermissionCheck(permissionCheck);
		}
	}, []);

	function requestNotificationPermission() {
		if ("Notification" in window) {
			Notification.requestPermission().then(function (permission) {
				if (permission === "granted") {
					alert("Đã cho phép gửi thông báo");
				} else if (permission === "denied") {
					alert("Đã từ chối gửi thông báo");
				} else {
					alert("Chưa quyết định cho phép gửi thông báo");
				}
			});
		} else {
			alert("Trình duyệt của bạn không hỗ trợ gửi thông báo");
		}
	}

	if(permissionCheck.notification == true) return null;

	return (
		<div className={cn('wrapper')}>
			<div className={cn('permission')}>
				<div className={cn('name')}>{Icon.bell} Gửi thông báo</div>
				<div className={cn('icon')}>{Icon.success}</div>
				<div className={cn('button')}>
					{
						(permissionCheck.notification) ? 'Đã bật' : <Button background red onClick={requestNotificationPermission}>Bật</Button>
					}
				</div>
			</div>
		</div>
	)
}

export default BrowserPermission;