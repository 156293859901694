import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	InputField
} from "~/components/Forms";
import {
	Button,
	Icon,
	Notification
} from "~/components";

function UploadWebsiteSearchMobile({filter, onSearchChange}) {

	const typeOptions = [
		{value: '', label: 'Tất cả loại upload'},
		{value: 1, label: 'Upload website bàn giao'},
		{value: 2, label: 'Chạy trước trả tiền sau'},
		{value: 3, label: 'Hỗ trợ khách hàng'}
	];

	const [searchBar, setSearchBar] = useState(false);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: data.keyword,
			type: data.type,
			page: 1,
		};
		setSearchBar(false);
		onSearchChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<InputField placeholder="Tên miền" onChange={handleKeywordChange} />
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="keyword" render={({field}) => (
						<InputField placeholder="Tên miền" errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="type" render={({field}) => (
						<SelectField label="Loại" options={typeOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default UploadWebsiteSearchMobile;