import style from './NavBar.module.scss';
import className from 'classnames/bind';
import {memo} from "react";
import { NavBarItems } from './NavBarItems';
import Menu from "~/components/Menu/Menu";
const cn = className.bind(style);

function NavBar({notificationCount}) {

	let navBarList = NavBarItems({notificationCount});

	return (
		<nav className={cn('navigation')}>
			<Menu items={navBarList} className={cn('menu')} classNameItem={cn('menu-item')} classNameDropdown={cn('menu-dropdown')} />
		</nav>
	);
}

export default memo(NavBar);