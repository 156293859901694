import request from "~/utils/http";

const formSabbaticalApi = {
	gets : async (params) => {
		const url = 'forms/sabbatical';
		return await request.get(url, {params});
	},
	count : async (params) => {
		const url = 'forms/sabbatical/count';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'forms/sabbatical';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `forms/sabbatical/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `forms/sabbatical/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `forms/sabbatical/status/${params.id}`;
		return await request.post(url, params);
	},
};

export default formSabbaticalApi;