import style from './Notification.module.scss';
import {Link} from "react-router-dom";
import className from 'classnames/bind';
import Image from "../Image";
import DOMPurify from "dompurify";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
const cn = className.bind(style);

function NotificationItem({item, onClickLink}) {

	let classAvatar = 'avatar ' + cn('avatar');

	dayjs.extend(relativeTime)

	return (<div className={cn('message-item', {'isRead' : (item.isOpen == 0)})} onClick={() => onClickLink(item)}>
		<Link to={`/${item.url}`}>
			<div className={cn('wrapper')}>
				<div className={classAvatar}>
					<Image src={item.avatar}></Image>
				</div>
				<div className={cn('info')}>
					<p className={cn('heading')} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }} />
					<p className={cn('message')}>{dayjs(item.created*1000).fromNow()}</p>
				</div>
			</div>
		</Link>
	</div>);
}

export default NotificationItem;