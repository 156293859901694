import {memo, useMemo, useState} from "react";
import {
	SelectField,
	SelectUserAll,
	DateRangeField,
	InputField
} from "~/components/Forms";
import {
	useCan
} from "~/hooks";
import {
	strToTime
} from "~/utils";

function EventSearchBar({listGroup, filter, onChange, onSearchChange}) {

	const canViewAll 		= useCan('salesEventListAll');

	const canViewGroup 		= useCan('salesEventListGroup');

	const canViewLeader 	= useCan('salesEventListLeader');

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleGroupChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			group: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleDateChange = (value) => {
		if (!onChange) return;
		let valueNew = [...value]
		valueNew[0] = strToTime(valueNew[0]);
		valueNew[1] = strToTime(valueNew[1]);
		const newFilter = {
			...filter,
			time: valueNew || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleUserChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			userId: value,
			page: 1,
		};
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField defaultValue={filter.keyword} placeholder="Số điện thoại" onChange={handleKeywordChange} />
					<DateRangeField onChange={(value) => handleDateChange(value)} />
					{(canViewAll || canViewGroup) && <SelectField value={filter.group} placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />}
					{(canViewAll || canViewGroup || canViewLeader) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />}
				</form>
			</div>
		</div>
	)
}

export default memo(EventSearchBar);