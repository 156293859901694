import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash"
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	InputPriceField,
	SelectField,
	SelectUserAll,
} from "~/components/Forms";

function ExpenseFormAddEdit({item, listGroup, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const userOptions = (isEdit && typeof item.user?.id != "undefined") ? [{ label : `${item.user.username} - ${item.user.firstname} ${item.user.lastname}`, value : item.user.id }] : [];

	const groupOptions = [];

	if(!_.isEmpty(listGroup)) {
		if(Object.keys(listGroup).length !== 0) {
			for (const [index, value] of Object.entries(listGroup)) {
				groupOptions.push({value : value.id, label: value.name})
			}
		}
	}

	const dayOptions = [];

	for(let i = 1; i <= 31; i++) {
		dayOptions.push({value : i, label: `Ngày ${i} hằng tháng`})
	}

	const initialValues = {
		categoryId: '',
		time: new Date(),
		price: 0,
		userId: '',
		note:'',
	}

	const validationSchema = Yup.object().shape({
		categoryId: Yup.string().required('Bạn chưa chọn loại thu chi'),
		time: Yup.string().required('Bạn chưa chọn ngày chi'),
		price: Yup.number().required('Giá trị chi không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.categoryId = item.categoryId;
			initialValues.time = item.time;
			initialValues.price = item.price;
			initialValues.userId = item.userId;
			initialValues.note = item.note;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin</p>
			<Controller control={control} name="categoryId" render={({ field }) => (
				<SelectField label="Loại chi" options={groupOptions} errors={errors} {...field}/>
			)}/>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="price" render={({field}) => (
						<InputPriceField label="Giá trị chi" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="time" render={({ field }) => (
						<SelectField label="Ngày chi" options={dayOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Đối tượng thu chi</p>
			<Controller control={control} name="userId" render={({ field }) => (
				<SelectUserAll options={userOptions} errors={errors} {...field} />
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} doanh thu</Button>
			</div>
		</form>
	)
}

export default ExpenseFormAddEdit;