const images = {
	noImage : require('~/assets/images/no-image.jpg'),
	iconUser : require('~/assets/images/icon-user.png'),
	dashboard01 : require('~/assets/images/dashboard01.png'),
	dashboard02 : require('~/assets/images/dashboard02.png'),
	dashboard03 : require('~/assets/images/dashboard03.png'),
	dashboard04 : require('~/assets/images/dashboard04.png'),
	dashboard05 : require('~/assets/images/dashboard05.png'),
	dashboard06 : require('~/assets/images/dashboard06.png'),
	dashboard07 : require('~/assets/images/dashboard07.png'),
	dashboard08 : require('~/assets/images/dashboard08.png'),
	dashboard09 : require('~/assets/images/dashboard09.png'),
	dashboardBannerBirthDay : require('~/assets/images/dashboard.banner.birthday.webp'),
	iconCommit : require('~/assets/images/icon-commit.png'),
	iconContract : require('~/assets/images/icon-contract.png'),
	iconCustomer : require('~/assets/images/icon-customer.png'),
	iconEvent : require('~/assets/images/icon-event.png'),
	iconProject : require('~/assets/images/icon-project.png'),
	iconReport : require('~/assets/images/icon-report.png'),
	iconRevenue : require('~/assets/images/icon-revenue.png'),
	iconUpload : require('~/assets/images/icon-upload.png'),
	iconToastRight : require('~/assets/images/icon-toast-right.png'),
	iconToastLeft : require('~/assets/images/icon-toast-left.png'),
	cardUser : require('~/assets/images/user-card-before.png'),
}
export default images