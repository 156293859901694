import style from "./WebsiteReport.module.scss";
import className from "classnames/bind";
import {memo} from "react";
import {Col, Row} from "antd";
import {numberFormat} from "~/utils";
import {useUtilities} from "~/hooks";
const cn = className.bind(style);
function WebsiteReportTotal({items = []}) {

	const {utilities} = useUtilities();

	return (
		<div className={cn('total-wrapper')}>
			<div className={cn('card', {['website']: 'website'})}>
				<p className="heading">{utilities.services['website'].label}</p>
				{
					Object.keys(items.service).map((itemKey) => {
						let total = items.service[itemKey];
						if(itemKey === 'share') {
							return '';
						}
						let cls = (itemKey === 'total') ? 'txt-total-big' : null;
						return (
							<Row key={itemKey} gutter={10} align="middle">
								<Col className="gutter-row" span={10}><span className={cn('txt')}>{(itemKey === 'total') ? 'Tổng' : ((itemKey === 'free') ? 'Không đồng' : utilities.serviceTypes[itemKey].label) }</span></Col>
								<Col className="gutter-row" span={10}><span className={cn('txt-total', [cls])}>{numberFormat(total)}</span></Col>
							</Row>
						)
					})
				}
			</div>
		</div>
	)
}

export default memo(WebsiteReportTotal);