import {useState, useMemo} from "react";
import StatusLabel from "~/components/StatusLabel";
import {InputField, SelectField} from "~/components/Forms";
import {useCan, useCurrentUser, useUtilities} from "~/hooks";

function PersonnelSearchBar({listGroup, filter, onSearchChange, onChange}) {

	const {utilities} = useUtilities();

	const currentUser 		= useCurrentUser();

	const can = {
	    viewAll: useCan('personnelListAll'),
		viewDepartment: useCan('personnelListDepartment'),
		viewGroup: useCan('personnelListGroup'),
	}

	let [listPosition, setListPosition] = useState(utilities.roles);

	if(!can.viewAll && can.viewDepartment && currentUser?.department) {
		listPosition = Object.keys(utilities.roles).filter((key) => {
			return utilities.roles[key].department == currentUser.department;
		}).reduce((obj, key) => {
			obj[key] = utilities.roles[key];
			return obj;
		}, {});
	}

	const positionOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listPosition).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [listPosition]);

	const departmentOptions = [{ value: '', label: <StatusLabel label="Vị trí" type="white" small>Tất cả phòng ban</StatusLabel>}];

	if(can.viewAll) {
		for (const [key, item] of Object.entries(utilities.departments)) {
			departmentOptions.push({ value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>})
		}
	}

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả nhóm' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const listStatus = [
		{value: '', label: 'Tất cả'},
		{value: 'internship', label: 'Thực tập'},
		{value: 'probation', label: 'Thử việc'},
		{value: 'public', label: 'Chính thức'},
		{value: 'trash', label: 'Đã nghỉ'},
	];

	const listMonthBirthday = [
		{value: '', label: 'Tất cả'},
		{value: '1', label: 'Tháng 1'},
		{value: '2', label: 'Tháng 2'},
		{value: '3', label: 'Tháng 3'},
		{value: '4', label: 'Tháng 4'},
		{value: '5', label: 'Tháng 5'},
		{value: '6', label: 'Tháng 6'},
		{value: '7', label: 'Tháng 7'},
		{value: '8', label: 'Tháng 8'},
		{value: '9', label: 'Tháng 9'},
		{value: '10', label: 'Tháng 10'},
		{value: '11', label: 'Tháng 11'},
		{value: '12', label: 'Tháng 12'},
	];

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleDepartmentChange = (value) => {
		if (!onChange) return;

		setListPosition(Object.keys(utilities.roles).filter((key) => {
			return utilities.roles[key].department == currentUser.department;
		}).reduce((obj, key) => {
			obj[key] = utilities.roles[key];
			return obj;
		}, {}));

		const newFilter = {
			...filter,
			department: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleRoleChange = (value) => {
		if (!onChange) return;

		const newFilter = {
			...filter,
			role: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleGroupChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			group: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleStatusChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			status: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleBirthDayChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			birthday: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField name="keyword" placeholder="Tên, email, số điện thoại nhân viên" onChange={handleKeywordChange} />
					{can.viewAll && <SelectField name="department" placeholder="Phòng ban" options={departmentOptions} onChange={(value, event) => handleDepartmentChange(value)} />}
					{(can.viewAll || can.viewDepartment) && <SelectField name="role" placeholder="Chọn chức vụ" options={positionOptions} onChange={(value, event) => handleRoleChange(value)} />}
					{(can.viewAll || ((can.viewDepartment || can.viewGroup) && currentUser.department == 'sale')) && <SelectField name="group" placeholder="Chọn nhóm" options={groupOptions} onChange={(value, event) => handleGroupChange(value)} />}
					<SelectField name="status" placeholder="Trạng thái" options={listStatus} onChange={(value, event) => handleStatusChange(value)}></SelectField>
					<SelectField name="birthday" placeholder="Sinh nhật" options={listMonthBirthday} onChange={(value, event) => handleBirthDayChange(value)}></SelectField>
				</form>
			</div>
		</div>
	)
}

export default PersonnelSearchBar;