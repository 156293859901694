import {useMemo, useState} from "react";
import {
	SelectField,
	SelectUserAll,
	DateField
} from "~/components/Forms";
import {
	useCan
} from "~/hooks";
import {strToTime} from "~/utils";

function CommitSearchBar({listGroup, filter, onChange}) {

	const canViewAll 		= useCan('salesCommitListAll');

	const canViewGroup 		= useCan('salesCommitListGroup');

	const canViewLeader 	= useCan('salesCommitListLeader');

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	const typeOptions = [
		{value : '', label : 'Tất cả'},
		{value : '3-day', label : 'Cam kết 3 ngày'},
		{value : 'mouth', label : 'Cam kết tháng'},
	]

	const handleGroupChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			group: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleDateChange = (value) => {
		if (!onChange) return;
		value = strToTime(value);
		const newFilter = {
			...filter,
			time: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleUserChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			userId: value,
			page: 1,
		};
		onChange(newFilter);
	}

	const handleTypeChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			type: value,
			page: 1,
		};
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<DateField placeholder="Chọn ngày cam kết" onChange={(value, event) => handleDateChange(value)} value={filter.time*1000} />
					{(canViewAll || canViewGroup) && <SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value, event) => handleGroupChange(value)} value={filter.group} />}
					{(canViewAll || canViewGroup || canViewLeader) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />}
					<SelectField placeholder="Loại cam kết" options={typeOptions} onChange={handleTypeChange} value={filter.type} />
				</form>
			</div>
		</div>
	)
}

export default CommitSearchBar;