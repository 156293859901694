import styles from './PopperUserInfo.module.scss';
import classNames from 'classnames/bind';
import {useState} from "react";
import Tippy from '@tippyjs/react/headless';
import {
	PopperWrapper
} from "../Popper";
import {
	Image,
	StatusLabel
} from "~/components";
import {arrayToMap} from "~/utils";
import {useGroup, useUtilities} from "../../hooks";
import {USER_STATUS} from "../../app/constants";
const cn = classNames.bind(styles);
function PopperUserInfo({children, user}) {

	const {utilities} = useUtilities();

	const listGroup = useGroup();

	const groupMap = arrayToMap(listGroup);

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const renderActionButton = () => {
		return (
			<PopperWrapper className="action-wrapper" style={{minWidth: '320px', }}>
				<div className={cn("account-heading")}>
					<div className={cn("account-info")}>
						<div className="d-flex gap align-items justify-content-between">
							<div className={cn('avatar')+' avatar'}><Image src={user?.avatar} /></div>
							<div>
								<b>{user?.firstname + ' ' + user?.lastname}</b>
								<p>{user?.username}</p>
							</div>
							<div>
								<StatusLabel small type={USER_STATUS[user.status]?.color}>{USER_STATUS[user.status]?.title}</StatusLabel>
							</div>
						</div>
					</div>
					<hr />
					<div className={cn("account-group")}>
						<p className="mb-1">{user?.email}</p>
						<StatusLabel small type={utilities.roles[user.role]?.color}>{utilities.roles[user.role]?.title}</StatusLabel>
						<p className="mt-1"><b>{(user.group != 0) ? groupMap[user.group]?.name : utilities.roles[user.role]?.title}</b></p>
					</div>
				</div>
			</PopperWrapper>
		)
	}

	return (
		<Tippy
			interactive
			visible={showActionBox}
			render={renderActionButton}
			onClickOutside={closeActionButton}
			placement="top"
			offset={[0, -10]}
		><span className={cn("box")} onClick={() => setShowActionBox(true)}>{children}</span></Tippy>
	)
}

export default PopperUserInfo;