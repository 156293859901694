import {memo, useMemo} from "react";
import {Table} from "antd";
import {numberFormat} from "~/utils";

import {
	Image,
	PopperUserInfo
} from "~/components";

function WebsiteReportUser({items, totalService}) {

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", render: (_, item) => (
				<>
					<PopperUserInfo user={item.user}>
						<div className="d-flex gap align-items">
							<div className="avatar"><Image src={item?.user?.avatar} /></div>
							<div>
								<b>{`${item?.user?.firstname} ${item?.user?.lastname}`}</b>
								<p>{item?.user?.username}</p>
							</div>
						</div>
					</PopperUserInfo>
				</>
			)},
		{ title: 'Ký mới', dataIndex: 'websiteNew', key: "websiteNew", width: '110px', render: (_, item) => (
			<>{numberFormat(item?.new)}</>
		)},
		{ title: 'Upsource', dataIndex: 'websiteUpsource', key: "websiteUpsource", width: '110px', render: (_, item) => (
			<>{numberFormat(item?.upsource)}</>
		)},
		{ title: 'Nâng cấp', dataIndex: 'websiteUpgrade', key: "websiteUpgrade", width: '110px', render: (_, item) => (
			<>{numberFormat(item?.upgrade)}</>
		)},
		{ title: 'Nâng cấp (BN)', dataIndex: 'websiteUpgradeNew', key: "websiteUpgradeNew", width: '110px', render: (_, item) => (
			<>{numberFormat(item?.upgradenew)}</>
		)},
		{ title: 'Website 0đ', dataIndex: 'websiteFree', key: "websiteFree", width: '110px', render: (_, item) => (
			<>{numberFormat(item?.free)}</>
		)},
		{ title: 'HD Share', dataIndex: 'websiteShare', key: "websiteShare", width: '110px', render: (_, item) => (
			<>{numberFormat(item?.share)}</>
		)},
		{ title: 'Tổng', dataIndex: 'total', key: "total", width: '110px', fixed: 'right', render: (_, item) => (
			<>{numberFormat(item?.total)}</>
		)},
	];

	let dataSource = useMemo(() => {
		return Object.values(items)
	}, [items]);

	dataSource= dataSource.map(row => ({
		id : row.user.id,
		...row
	}));

	return (
		<div className="table-box">
			<Table
				columns={columns}
				rowKey="id"
				dataSource={dataSource}
				scroll={{ x: 'max-content' }}
				pagination={false}
				summary={() => (
					<Table.Summary fixed>
						<Table.Summary.Row>
							<Table.Summary.Cell index={0}><b>Tổng</b></Table.Summary.Cell>
							<Table.Summary.Cell index={1}>{numberFormat(totalService.new)}</Table.Summary.Cell>
							<Table.Summary.Cell index={2}>{numberFormat(totalService.upsource)}</Table.Summary.Cell>
							<Table.Summary.Cell index={3}>{numberFormat(totalService.upgrade)}</Table.Summary.Cell>
							<Table.Summary.Cell index={3}>{numberFormat(totalService.upgradenew)}</Table.Summary.Cell>
							<Table.Summary.Cell index={4}>{numberFormat(totalService.free)}</Table.Summary.Cell>
							<Table.Summary.Cell index={4}>{numberFormat(totalService.share)}</Table.Summary.Cell>
							<Table.Summary.Cell index={5}>{numberFormat(totalService.total)}</Table.Summary.Cell>
						</Table.Summary.Row>
					</Table.Summary>
				)}
			/>
		</div>
	)
}

export default memo(WebsiteReportUser);