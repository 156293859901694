import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {expenseEventActions} from "./expenseEventSlice";
import {expenseApi} from "~/api";

function* fetchExpenseEventList(action) {
	try {
		const response = yield call(expenseApi.eventGets, action.payload);
		yield put(expenseEventActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch expenseEvent list', error);
		yield put(expenseEventActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(expenseEventActions.setFilter(action.payload));
}

export default function* expenseEventSaga() {
	yield takeLatest(expenseEventActions.fetchData.type, fetchExpenseEventList);
	yield debounce(500, expenseEventActions.setFilterWithDebounce.type, handleSearchDebounce)
}