import request from "~/utils/http";

const utilitiesApi = {
	gets : async (params) => {
		const url = 'utilities';
		return await request.get(url, {params});
	},
	ranks : async (params) => {
		const url = 'utilities/ranks';
		return await request.get(url, {params});
	},
};

export default utilitiesApi;