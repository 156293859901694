import request from "~/utils/http";

const countryApi = {
	city : async (params) => {
		const url = 'country/city';
		return await request.get(url, {params});
	},
	district : async () => {
		const url = `country/district`;
		return await request.get(url);
	},
	ward : async () => {
		const url = `country/ward`;
		return await request.get(url);
	}
};

export default countryApi;