import Image from "~/components/Image";
import {Pagination, Table, Tooltip} from "antd";
import PopperUserInfo from "~/components/PopperUserInfo";
import StatusLabel from "~/components/StatusLabel";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {LIST_PAPER_STATUS} from "~/app/constants";
import {Button, Icon} from "~/components";

function PapersTable({items = [], pagination, onPaginationChange, setItemEdit, openModal, can}) {

	const handleButtonClick = {
		edit : (item) => {
			setItemEdit(item)
			openModal('addEdit');
		},
		info : (item) => {
			setItemEdit(item)
			openModal('info');
		},
		delete : (item) => {
			setItemEdit(item)
			openModal('delete');
		}
	}

	const columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (<div>{item?.id}</div>)},
		{ title: 'Người làm đơn', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			<PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{`${item.user?.firstname} ${item.user?.lastname}`}</b>
						<p>{item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Số lượng', dataIndex: 'number', key: "number", width: '120px', render: (_, item) => (<p>{numberFormat(item?.number)}</p>)},
		{ title: 'Ngày tạo', dataIndex: 'created', key: "created", width: '120px', render: (_, item) => (<p>{renderDate(item?.created)}</p>)},
		{ title: 'Người phát', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			item.assign?.id && <PopperUserInfo user={item.assign}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.assign?.avatar} /></div>
					<div>
						<b>{`${item.assign?.firstname} ${item.assign?.lastname}`}</b>
						<p>{item.assign?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Ngày phát', dataIndex: 'time', key: "time", width: '120px', render: (_, item) => (<p>{renderDate(item?.time)}</p>)},
		{ title: 'Trạng thái', dataIndex: 'status', key: 'status',width: '150px', render:(_, item) => (
			<StatusLabel pointer small type={LIST_PAPER_STATUS[item.status].color}>{LIST_PAPER_STATUS[item.status].label}</StatusLabel>
		)},
		{ title: 'Ghi chú (Người xin)', dataIndex: 'note', key: 'note', width: '220px', render:(_, item) => (<div>{item.note}</div>)},
		{ title: 'Ghi chú (Người phát)', dataIndex: 'note2', key: 'note2', width: '220px', render:(_, item) => (<div>{item.note2}</div>)},
		{ title: '#', dataIndex: 'action', key: "action", width: '180px', render: (_, item) => (
			<>
				{(can.status) && <Tooltip title="Thông tin"><Button small background green leftIcon={Icon.info} onClick={() => handleButtonClick.info(item)}></Button></Tooltip>}
				{(can.edit && item.status == 'pending') && <Tooltip title="Edit thông tin"><Button small background blue leftIcon={Icon.edit} onClick={() => handleButtonClick.edit(item)}></Button></Tooltip>}
				{(can.delete && item.status == 'pending') && <Tooltip title="Xóa"><Button small background red leftIcon={Icon.delete} onClick={() => handleButtonClick.delete(item)}></Button></Tooltip>}
			</>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default PapersTable;