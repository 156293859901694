import {useCallback} from "react";
import {
	DateField
} from "~/components/Forms";

function PayReportSearch({filter, onChange}) {

	const handleTimeChange = useCallback((value) => {
		if (!isNaN(value)) {
			value = Math.round(new Date(+value).getTime() / 1000)
		} else if (value.search('GMT') != -1) {
			value = Math.round(new Date(value).getTime() / 1000)
		}
		if(onChange) onChange('time', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<DateField name="time" picker="month" onChange={handleTimeChange}/>
			</form>
		</div>
	)
}

export default PayReportSearch;