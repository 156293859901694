import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import {Col, Row} from "antd";
import {
	Image,
	StatusLabel
} from "../../../../components";
const cn = className.bind(style);
function SaleReportConsultation({item}) {
	return (
		<div className={cn('consultation-item')}>
			<div className={cn('header')}>
				<Row gutter={10}>
					<Col className="gutter-row" span={11}>
						<p>{item.name}</p>
						<p className={cn('address')}>{item.address}</p>
					</Col>
					<Col className="gutter-row" span={3}>
						{item.type == 'new' ? <p className="mb-1"><StatusLabel small type="green">Khách mới</StatusLabel></p> : <p className="mb-1"><StatusLabel small type="red">Khách củ</StatusLabel></p>}
						{item.type2 == 'tuvan' ? <StatusLabel small type="green">Tư vấn</StatusLabel> : <StatusLabel small type="red">Bàn giao</StatusLabel>}
					</Col>
					<Col className="gutter-row" span={3}>
						{item.point == 0 && <StatusLabel small type="yellow">Đợi đi</StatusLabel> }
						{item.point == 1 && <StatusLabel small type="blue">Đang gặp khách</StatusLabel> }
						{item.point == 2 && <StatusLabel small type="green">Đã tư vấn</StatusLabel> }
						{item.point == 3 && <StatusLabel small type="red">Đã hủy</StatusLabel> }
					</Col>
					<Col className="gutter-row" span={3}>
						{item.type3 == 'online' ? <StatusLabel small type="yellow">Online</StatusLabel> : <StatusLabel small type="blue">Trực tiếp</StatusLabel>}
					</Col>
				</Row>
			</div>
			<div className={cn('user-result')}>
				<Row className="mb-1" gutter={10}>
					<Col className="gutter-row" span={8}>
						<div className="d-flex gap align-items">
							<div className="avatar"><Image src={item.user?.avatar}/></div>
							<div>
								<b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
								<p className="color-red font-bold">Gọi điện {(item.assign?.id == item.user?.id) && ' & Tư vấn'}</p>
							</div>
						</div>
					</Col>
					<Col className="gutter-row" span={12}>
						{item.result?.user}
					</Col>
				</Row>
				{
					(item.assign?.id && item.assign?.id != item.user?.id) &&
					<Row className="mb-1" gutter={10}>
						<Col className="gutter-row" span={8}>
							<div className="d-flex gap align-items">
								<div className="avatar"><Image src={item.assign?.avatar}/></div>
								<div>
									<b>{item.assign?.firstname + ' ' + item.assign?.lastname}</b>
									<p className="color-red font-bold">Tư vấn</p>
								</div>
							</div>
						</Col>
						<Col className="gutter-row" span={12}>
							{item.result?.assign}
						</Col>
					</Row>
				}

				{
					item.support?.id &&
					<Row className="mb-1" gutter={10}>
						<Col className="gutter-row" span={8}>
							<div className="d-flex gap align-items">
								<div className="avatar"><Image src={item.support?.avatar}/></div>
								<div>
									<b>{item.support?.firstname + ' ' + item.support?.lastname}</b>
									<p className="color-red font-bold">Hỗ trợ</p>
								</div>
							</div>
						</Col>
						<Col className="gutter-row" span={12}>
							{item.support?.assign}
						</Col>
					</Row>
				}
			</div>
		</div>
	)
}
export default SaleReportConsultation;