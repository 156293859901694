import style from "../../style/Financial.module.scss";
import className from "classnames/bind";
import {useState, useEffect} from "react";
import {Col, Row} from "antd";
import _ from "lodash";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Icon,
} from "~/components";
import {
	apiError,
	handleRequest,
	numberFormat
} from "~/utils";
import {reportApi} from "~/api";
import {Pie} from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import {ExtendReportSearch} from "../../components";

ChartJS.register(
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);
const cn = className.bind(style);

function ExtendReport() {

	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState([]);

	const [reportData, setReportData] = useState({
		total: {
			revenue : 0,
			host : 0,
			ssl : 0,
			email : 0,
		},
		count: {
			revenue : 0,
			host : 0,
			ssl : 0,
			email : 0,
		},
		totalSamePeriod: {
			revenue : 0,
			host : 0,
			ssl : 0,
			email : 0,
		},
		countSamePeriod: {
			revenue : 0,
			host : 0,
			ssl : 0,
			email : 0,
		},
		reasons : {
			chart: {
				labels : [],
				colors : [],
				values : [],
			},
			items: {}
		}
	});

	//Load data
	useEffect(() => { handleLoad().then(); }, [filter]);

	const handleLoad = async () => {
		setLoading(true);
		let [error, response] = await handleRequest(reportApi.extend(filter));
		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			reportData.total = response.data.total;
			reportData.count = response.data.count;
			reportData.totalSamePeriod = response.data.totalSamePeriod;
			reportData.countSamePeriod = response.data.countSamePeriod;

			let reasonsChartData = {
				labels: [],
				values: [],
				colors: [],
			};
			Object.keys(response.data.reasons).map((itemKey) => {
				reasonsChartData.labels.push(response.data.reasons[itemKey]?.label);
				reasonsChartData.colors.push(response.data.reasons[itemKey]?.color);
				reasonsChartData.values.push(response.data.reasons[itemKey]?.count);
			})
			reportData.reasons.chart = reasonsChartData;
			reportData.reasons.items = response.data.reasons;
			setReportData(reportData)
		}
		setLoading(false);
	}

	const extendChartOptions = {
		labels: ['Host', 'SSL', 'Email'],
		datasets: [
			{
				data: [reportData.total.host, reportData.total.ssl, reportData.total.email],
				backgroundColor: [
					'rgb(255, 99, 132)',
					'rgb(81,157,61)',
					'rgb(53, 162, 235)',
				],
			},
		],
	};

	const reasonsChartOptions = {
		labels: reportData.reasons.chart.labels,
		datasets: [
			{
				data: reportData.reasons.chart.values,
				backgroundColor: reportData.reasons.chart.colors,
			},
		],
	};

	const ReportItem = ({item}) => {
		return (
			<div className={cn('item', 'mb-1')}>
				<div className={cn('info', 'w-100', 'd-flex', 'justify-content-between', 'align-items-center')}>
					<h3>{item?.label}</h3>
					<p>{numberFormat(item?.count)}</p>
				</div>
			</div>
		)
	}

	//Search
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		setFilter(newFilter);
	};

	return (
		<>
			<ActionBar title={'Gia hạn'}></ActionBar>
			<div className="container d-block">
				<div className="content pd-1 pdb-6" style={{overflow:'auto'}}>
					<ExtendReportSearch onChange={handleFilterChange} />
					{loading && <Loading />}
					<Row gutter={10}>
						<Col span={8}>
							<p className="heading">Biểu đồ</p>
							{!loading && <Pie options={{plugins: {legend: {position: 'right',},},}} data={extendChartOptions} />}
						</Col>
						<Col span={5}>
							<p className="heading">Số tiền gia hạn</p>
							<div className={cn('item', 'item-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Tổng thu</h3>
									<p>{numberFormat(reportData.total.revenue)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Host</h3>
									<p>{numberFormat(reportData.total.host)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-green', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>SSL</h3>
									<p>{numberFormat(reportData.total.ssl)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-blue', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Email Server</h3>
									<p>{numberFormat(reportData.total.email)}</p>
								</div>
							</div>
						</Col>
						<Col span={5}>
							<p className="heading">Số hợp đồng gia hạn</p>
							<div className={cn('item', 'item-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Tổng số lượng</h3>
									<p>{numberFormat(reportData.count.revenue)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Host</h3>
									<p>{numberFormat(reportData.count.host)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-green', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>SSL</h3>
									<p>{numberFormat(reportData.count.ssl)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-blue', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Email Server</h3>
									<p>{numberFormat(reportData.count.email)}</p>
								</div>
							</div>
						</Col>
						<Col span={6}>
							<p className="heading">Kỹ mới năm trước</p>
							<div className={cn('item', 'item-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Tổng số lượng</h3>
									<p>{numberFormat(reportData.totalSamePeriod.revenue)} / {numberFormat(reportData.countSamePeriod.revenue)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-red', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Host</h3>
									<p>{numberFormat(reportData.totalSamePeriod.host)} / {numberFormat(reportData.countSamePeriod.host)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-green', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>SSL</h3>
									<p>{numberFormat(reportData.totalSamePeriod.ssl)} / {numberFormat(reportData.countSamePeriod.ssl)}</p>
								</div>
							</div>
							<div className={cn('item', 'item-blue', 'mb-1')}>
								<div className={cn('icon')}>{Icon.plusCircle}</div>
								<div className={cn('info')}>
									<h3>Email Server</h3>
									<p>{numberFormat(reportData.totalSamePeriod.email)} / {numberFormat(reportData.countSamePeriod.email)}</p>
								</div>
							</div>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col span={12}>
							<p className="heading">Biểu đồ</p>
							{!loading && <Pie options={{plugins: {legend: {position: 'bottom',},},}} data={reasonsChartOptions} />}
						</Col>
						<Col span={12}>
							<p className="heading">Lý do không gia hạn</p>
							{
								(reportData.reasons.items && !_.isEmpty(reportData.reasons.items)) && Object.keys(reportData.reasons.items).map((itemKey) => {
									return <ReportItem key={`report-item-${itemKey}`} item={reportData.reasons.items[itemKey]} />
								})
							}
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default ExtendReport;