
import {useEffect, useMemo, useState} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	SelectField,
	InputField
} from "~/components/Forms";
import {createdWebsiteApi} from "../../../../api";
import {apiError, handleRequest} from "../../../../utils";

function ProjectFormInstall({item, onHandleSubmit}) {

	const typeOptions = [
		{value : 1, label : "Cài đặt source code"},
		{value : 2, label : "Cài đặt từ link test"},
	];

	const [templates, setTemplates] = useState([]);

	const [info, setInfo] = useState({
		admin: '',
		password: '',
		url: '',
		urlAdmin: '',
	});

	useEffect(() => {
		fetchTemplateType().then();
	}, []);

	const fetchTemplateType = async () => {
		let [error, response] = await handleRequest(createdWebsiteApi.template());
		let message = apiError(`Load danh sách giao diện thất bại`, error, response);
		if (!message) {
			setTemplates(response.data);
		}
	}

	const templateOptions = useMemo(() => {
		return [{ value: 'source', label: 'Source code trắng' }, ...Object.entries(templates).map(([key, template]) => {
			return {
				value: key,
				label: template
			};
		})];
	}, [templates]);

	const initialValues = {
		type: 1,
		template: 'source',
		test: '',
	}

	const {control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape({
			type: Yup.string().required('Bạn chưa chọn loại yêu cầu'),
			template: Yup.string().when('type', {
				is: 1,
				then: Yup.string().required('Bạn chưa chọn nguồn source code'),
			}),
			test: Yup.string().when('type', {
				is: 2,
				then: Yup.string().required('Bạn chưa điền thư mục source code test'),
			}),
		}))
	});

	const handleTypeChange = (value) => {
	}

	const typeInput 		= useWatch({
		control, name: "type"
	});

	const onSubmit = async (data) => {
		let response = await onHandleSubmit(data, item);
		if(response !== false) {
			info.admin      = response.data.admin;
			info.password   = response.data.password;
			info.url        = response.data.url;
			info.urlAdmin   = response.data.urlAdmin;
			setInfo(info);
		}
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="type" render={({field}) => (
				<SelectField options={typeOptions} errors={errors} {...field} onChange={(value, event) => {
					handleTypeChange(value);
					field.onChange(value, event);
				}}/>
			)}/>
			{
				(typeInput === 1) && <Controller control={control} name="template" render={({field}) => (
					<SelectField label="Nguồn source code" options={templateOptions} errors={errors} {...field}/>
				)}/>
			}

			{
				(typeInput === 2) && <Controller control={control} name="test" render={({field}) => (
					<InputField label="Thư mục website test" errors={errors} {...field}/>
				)}/>
			}

			<p className="heading mt-2 mb-2">Tài khoản admin</p>
			<p className="text-sm mb-1"><b>Tên đăng nhập:</b> {info.admin}</p>
			<p className="text-sm mb-1"><b>Mật khẩu đăng nhập:</b> {info.password}</p>
			<p className="heading mt-2 mb-2">Link website demo</p>
			<p className="text-sm mb-1"><b>Admin:</b> <a href={info.urlAdmin} target='_blank'>{info?.urlAdmin}</a></p>
			<p className="text-sm mb-1"><b>Client:</b> <a href={info.url} target='_blank'>{info?.url}</a></p>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Tạo website</Button>
			</div>
		</form>
	)
}

export default ProjectFormInstall;