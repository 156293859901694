import className from 'classnames/bind';
import style from './Header.module.scss';
const cn = className.bind(style);

function LogoText() {
	return (
		<div className={cn('mobile-logo')}>
			<span>SIKIDO</span>
		</div>
	);
}

export default LogoText;