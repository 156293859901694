import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Loading,
	Button,
	Modal
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	useCan
} from "~/hooks";

import {
	FormPapersTypeAdd,
	FormPapersTypeEdit,
	FormPapersTypeImport,
	PapersTypeTable,
} from "../components";

import {
	papersApi
} from "~/api";

import {
	papersTypeActions,
	papersTypeItemsSelector,
	papersTypeLoadingSelector,
	papersTypeErrorSelector,
	papersTypePaginationSelector,
	papersTypeFilterSelector
} from "../papersTypeSlice";

function PapersType() {

	const can = {
		add     : useCan('papersTypeAdd'),
		edit    : useCan('papersTypeEdit'),
		import  : useCan('papersTypeImport'),
		delete  : useCan('papersTypeDelete'),
	}

	const dispatch  = useDispatch();

	const items     = useSelector(papersTypeItemsSelector);

	const loading   = useSelector(papersTypeLoadingSelector);

	const error     = useSelector(papersTypeErrorSelector);

	const pagination = useSelector(papersTypePaginationSelector);

	const filter    = useSelector(papersTypeFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [papersType, setPapersType] = useState([]);

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleReLoading();
	}, [filter]);

	useEffect(() => {
		fetchPapersType().then();
	}, []);

	const handleReLoading = () => {
		dispatch(papersTypeActions.fetchData(filter));
	}

	const fetchPapersType = async () => {
		let [error, response] = await handleRequest(papersApi.getsType());
		let message = apiError(`Load danh sách loại đơn thất bại`, error, response);
		if (!message) {
			setPapersType(response.data);
		}
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin loại giấy tờ để cập nhật'});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}

		let [error, response] = (item?.id) ? await handleRequest(papersApi.updateType(data)) : await handleRequest(papersApi.addType(data));
		let message = apiError(`${messageAction} loại giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} loại giấy tờ thành công`});
			if(item?.id) {
				dispatch(papersTypeActions.update(response.data));
				handleModalClose('edit')
			}
			else {
				dispatch(papersTypeActions.add(response.data));
				handleModalClose('add')
			}
		}
	}

	const handleImport = async (data) => {
		let [error, response] = await handleRequest(papersApi.importType(data));
		let message = apiError(`Cập nhật giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật giấy tờ thành công`});
			Object.values(response.data).map((item) => {
				dispatch(papersTypeActions.update(item));
			})
			handleModalClose('import')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có loại giấy tờ để xóa'});
			return;
		}
		let [error, response] = await handleRequest(papersApi.deleteType(itemEdit.id));
		let message = apiError(`Xóa loại giấy tờ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Xóa loại giấy tờ thành công`});
			dispatch(papersTypeActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(papersTypeActions.setFilter({...filter, page }));
	}

	return (
		<Fragment>
			<ActionBar title={'Loại giấy tờ'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('add')}}>Thêm mới</Button>}
				{can.import && <Button outline leftIcon={Icon.plusCircle} onClick={() => {handleModalOpen('import')}}>Thêm số lượng</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <PapersTypeTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						can={can}
					/>}
				</div>
				{
					(can.add && openModal.add) && <Modal title="Thêm loại giấy tờ" visible={openModal.add} onCancel={() => {handleModalClose('add')}}>
						<FormPapersTypeAdd can={can} item={itemEdit} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(can.edit && openModal.edit) && <Modal title="Cập nhật loại giấy tờ" visible={openModal.edit} onCancel={() => {handleModalClose('edit')}}>
						<FormPapersTypeEdit can={can} item={itemEdit} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(can.import && openModal.import) && <Modal title="Cập nhật loại giấy tờ" visible={openModal.import} onCancel={() => {handleModalClose('import')}}>
						<FormPapersTypeImport can={can} papersType={papersType} onHandleSubmit={handleImport} />
					</Modal>
				}
				{
					(can.delete && openModal.delete && itemEdit?.id) && <Modal title="Xóa loại đơn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
						<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
						<div className="d-flex justify-content-end gap modal-bottom pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
						</div>
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default PapersType;