import {call, put, takeLatest} from "redux-saga/effects";
import {groupActions} from "./groupSlice";
import {groupApi} from "~/api";

function* fetchGroupList(action) {
	try {
		const response = yield call(groupApi.gets, action.payload);
		yield put(groupActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed  to fetch candidate list', error);
		yield put(groupActions.fetchDataFailed(error.message));
	}
}

export default function* groupSaga() {
	yield takeLatest(groupActions.fetchData.type, fetchGroupList);
}