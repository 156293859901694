import style from '../Home.module.scss';
import className from 'classnames/bind';
import {useState, useEffect, useCallback} from "react";
import {Col, Row, Tooltip} from "antd";
import {useCurrentUser} from "../../../hooks";
import images from "../../../assets/images";
import {Image} from "~/components";
import {
	SidebarAccount,
	ContentHeader,
	CardToast
} from "../componets";
import {apiError, handleRequest, numberFormat} from "../../../utils";
import {dashboardApi} from "../../../api";
import {ceil} from "lodash";
const cn = className.bind(style);

function DashboardSaleSupper() {

	const user = useCurrentUser();

	const [dashboardData, setDashboardData] = useState({
		revenueGroup: { all: 0, plus: 0, commit: 0, users : []},
		revenueRankHigh: [],
		revenueRankLow: [],
		contracts: [],
		customer: {allGroup: 0, confirmGroup: 0 },
		saleCommit: { commit: 0, result: 0, commitGroup: 0, resultGroup: 0 },
		kpi: 0,
	});

	useEffect(() => {
		fetchDashboardData().then()
	}, [user]);

	const fetchDashboardData = useCallback(async () => {
		const [
			customerGroupResponse,
			contractResponse,
			saleCommitResponse,
			revenueRankHighResponse,
			revenueRankLowResponse,
			revenueGroupResponse,
			kpiResponse,
		] = await Promise.all([
			handleRequest(dashboardApi.customerGroup()),
			handleRequest(dashboardApi.contractNew()),
			handleRequest(dashboardApi.saleCommit()),
			handleRequest(dashboardApi.revenueRanks()),
			handleRequest(dashboardApi.revenueRanks({sort: 'low'})),
			handleRequest(dashboardApi.revenueGroup()),
			handleRequest(dashboardApi.saleKpi()),
		]);

		//Khách hàng
		let [customerGroupErr, customerGroupData] = customerGroupResponse;
		let customerGroupMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, customerGroupErr, customerGroupData);
		if (!customerGroupMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				customer: {
					allGroup: customerGroupData.data.allGroup,
					confirmGroup: customerGroupData.data.confirmGroup,
				},
			}));
		}

		//Hợp đồng mới
		let [contractErr, contractData] = contractResponse;
		let contractMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, contractErr, contractData);
		if (!contractMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				contracts: contractData.data,
			}));
		}

		//Cam kết
		let [saleCommitErr, saleCommitData] = saleCommitResponse;
		let saleCommitMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, saleCommitErr, saleCommitData);
		if (!saleCommitMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				saleCommit: {
					commit: saleCommitData.data.commit,
					result: saleCommitData.data.result,
					commitGroup: saleCommitData.data.commitGroup,
					resultGroup: saleCommitData.data.resultGroup,
				},
			}));
		}

		//Xếp hạng cao
		let [revenueRankHighErr, revenueRankHighData] = revenueRankHighResponse;
		let revenueRankHighMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueRankHighErr, revenueRankHighData);
		if (!revenueRankHighMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenueRankHigh: revenueRankHighData.data,
			}));
		}

		//Xếp hạng thấp
		let [revenueRankLowErr, revenueRankLowData] = revenueRankLowResponse;
		let revenueRankLowMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueRankLowErr, revenueRankLowData);
		if (!revenueRankLowMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenueRankLow: revenueRankLowData.data,
			}));
		}

		//Doanh số
		let [revenueGroupErr, revenueGroupData] = revenueGroupResponse;
		let revenueGroupMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueGroupErr, revenueGroupData);
		if (!revenueGroupMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenueGroup: {
					all: revenueGroupData.data.all,
					plus: revenueGroupData.data.plus,
					commit: revenueGroupData.data.commit,
					users: revenueGroupData.data.users,
				},
			}));
		}

		//Kpi
		let [kpiErr, kpiData] = kpiResponse;
		let kpiMsg = apiError(`Load thông tin kpi thất bại`, kpiErr, kpiData);
		if (!kpiMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				kpi: kpiData.data.commit,
			}));
		}
	}, [])

	const CardCount = ({image, heading, number1, number2, number3, txt1, txt2, txt3, txtSize}) => {
		return (
			<div className={cn('card', 'card-while', 'pd-1')}>
				<div className={cn('icon')}><Image src={image} /></div>
				<div className={cn('info')}>
					<p className={cn('heading-mini')}>{heading}</p>
					<p className={cn('number', txtSize)}>
						{(typeof number1 != 'undefined') && <Tooltip title={txt1}><span className="color-red">{numberFormat(number1)}</span></Tooltip>}
						{(typeof number2 != 'undefined') && <Tooltip title={txt2}><span> / {numberFormat(number2)}</span></Tooltip>}
						{(typeof number3 != 'undefined') && <Tooltip title={txt3}><span> / {numberFormat(number3)}</span></Tooltip>}
					</p>
				</div>
			</div>
		)
	}

	const CardRanks = ({name, items, cardColor, cardMedal}) => {
		return (
			<div className={cn('card', 'card-ranks', (cardMedal) ? 'card-ranks-medal' : '', cardColor)}>
				<h4 className={cn('heading')}>{name}</h4>
				<div className={cn('list-ranks')}>
					{
						items.map((item) => {
							return <div key={`card-rank-${item.id}`} className={cn('user', 'd-flex', 'gap', 'align-items')}>
								<div className={cn('medal')}>
									<small></small>
								</div>
								<div className={cn('avatar-rank', 'avatar')}>
									<Image src={item.avatar} />
								</div>
								<div>
									<b className={cn('name', 'color-white')}>{item.firstname} {item.lastname}</b>
									<p className={cn('info-more', 'color-white')}>{item.username}</p>
									<p className={cn('revenue', 'text-left')}><b>{numberFormat(item.total)}</b></p>
								</div>
							</div>
						})
					}
				</div>
			</div>
		)
	}

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
				<div className={cn('card', 'card-toDo', 'card-while')}>
					<h4 className={cn('heading')}>Kiểm tra</h4>
					<div className={cn('info', 'text-sm')}>
						<p>Top 3</p>
						<p>Chụp voucher</p>
						<p>Thiết kế và gửi voucher online</p>
						<p>Theme/ Links</p>
						<p>Web40.vn</p>
					</div>
				</div>
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} />
				<Row gutter={20}>
					<Col md={12} xs={24} >
						<CardCount
							image={images.dashboard06}
							heading={'Doanh số'}
							number1={dashboardData.revenueGroup.all} txt1={'Tất cả'}
							number2={dashboardData.revenueGroup.plus} txt2={'Tính lương'}
							number3={dashboardData.revenueGroup.commit} txt3={'Cam kết'}
							txtSize={'text-base'}
						/>
						<CardCount
							image={images.dashboard07}
							heading={'Khách hàng'}
							number1={dashboardData.customer.confirmGroup} txt1={'Đã duyệt'}
							number2={dashboardData.customer.allGroup} txt2={'Tất cả'}
						/>
					</Col>
					<Col md={12} xs={24} >
						<div className={cn('card', 'card-while', 'pd-1')}>
							<div className={cn('icon')}><Image src={images.dashboard08} /></div>
							<div className={cn('info')}>
								<p className={cn('heading-mini')}>{'KPI đến hôm nay'}</p>
								<p className={cn('number')}>
									<Tooltip title={'Đã đạt'}><span className="color-red">{numberFormat(dashboardData.revenueGroup.all)}</span></Tooltip>
									<Tooltip title={'Cam kết'}><span> / {numberFormat(dashboardData.kpi)}</span></Tooltip>
									<Tooltip title={'Phần trăm'}><span> / {ceil(dashboardData.revenueGroup.all/dashboardData.kpi*100) + '%'}</span></Tooltip>
								</p>
							</div>
						</div>
						<CardCount
							image={images.dashboard09}
							heading={'Cam kết 3 ngày'}
							number1={dashboardData.saleCommit.resultGroup} txt1={'Kết quả'}
							number2={dashboardData.saleCommit.commitGroup} txt2={'Cam kết'}
						/>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col md={8} xs={24}>
						<CardRanks
							name={'Top 10 doanh số cao nhất'}
							items={dashboardData.revenueRankHigh}
							cardColor={'card-blue'}
							cardMedal
						/>
					</Col>
					<Col md={8} xs={24}>
						<CardRanks
							name={'Top 10 cần hỗ trợ'}
							items={dashboardData.revenueRankLow}
							cardColor={'card-green'}
						/>
					</Col>
					<Col md={8} xs={24}>
						<div className={cn('card', 'card-person', 'card-while')}>
							<h4 className={cn('heading')}>Nhân sự</h4>
							<div className={cn('info')}>
								<div className={cn('number-person')}>
									<p><span>24</span> / <span>34</span></p>
								</div>
							</div>
						</div>
						<CardToast items={dashboardData.contracts} />
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default DashboardSaleSupper;