import {useState} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import {Alert, notification} from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	GroupRadioField,
	InputField,
	SelectServiceAll
} from "~/components/Forms";
import {apiError, handleRequest} from "~/utils";
import {serviceApi} from "~/api";

function ToDoFormAddDomain({onHandleSubmit}) {

	const [loading, setLoading] = useState(false);

	const [domains, setDomains] = useState([]);
	const [domainsHost, setDomainsHost] = useState([]);
	const [domainsSub, setDomainsSub] = useState([]);

	const typeOptions = [
		{ value: 'new', 	label: 'Thêm tên miền'},
		{ value: 'replace', label: 'Thay thế tên miền'},
		{ value: 'remove', 	label: 'Gở bỏ tên miền'},
	];

	const initialValues = {
		serviceId: undefined,
		domainRemove: undefined,
		type: 'new'
	}

	const validationSchema = Yup.object().shape({
		serviceId: Yup.string().required('Bạn chưa chọn dịch vụ'),
		type: Yup.string().required('Bạn chưa chọn loại yêu cầu'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const typeInput = useWatch({control, name: "type"});

	const handleServiceChange = async (value) => {
		if(!typeInput) {
			notification.error({message:'Vui lòng chọn loại dịch vụ trước'})
			setValue('serviceId', '')
			return;
		}
		setLoading(true);
		let [error, response] = await handleRequest(serviceApi.domains(value));
		let message = apiError(`Load thông tin hợp đồng thất bài`, error, response);
		if(!message) {
			setDomains(response.data.main)
			setDomainsHost(response.data.host)
			setDomainsSub(response.data.sub)
		}
		setLoading(false);
	}

	const RenderMessage = () => {
		let message = '';
		if(typeInput == 'new') {
			message = '<strong>Lưu ý:</strong>';
			message += '<p>Yêu cầu này sẽ thêm tên miền mới vào host và sẽ <b>được chạy song song</b> với những tên miền hiện có trên host</p>'
		}
		if(typeInput == 'replace') {
			message = '<strong>Lưu ý:</strong>';
			message += '<p>Yêu cầu này sẽ thay đổi tên miền được chọn thành tên miền mới, tên miền được chọn sẽ <b>bị gở khỏi host</b> </p>'
		}
		if(typeInput == 'remove') {
			message = '<strong>Lưu ý:</strong>';
			message += '<p>Yêu cầu này sẽ <b>xóa</b> tên miền được chọn khỏi host</p>'
		}
		if(message.length == 0) return null;
		return (
			<Alert
				message={<span dangerouslySetInnerHTML={{ __html: message }} />} type="error" closable
			/>
		)
	}

	const RenderDomain = () => {
		if(!_.isEmpty(domains)) {
			if (typeInput == 'new') {
				return <RenderDomainNew/>
			}
			if (typeInput == 'replace') {
				return <RenderDomainReplace/>
			}
			if (typeInput == 'remove') {
				return <RenderDomainRemove/>
			}
		}
	}

	const RenderDomainNew = () => {
		let domainOptions = [...domainsHost.map((domain) => {
			return {
				value: domain,
				label: domain
			};
		})];
		// console.log(domainOptions)
		return (
			<>
				<br />
				<Controller control={control} name="domain" render={({field}) => (
					<InputField label="Tên miền cần thêm" errors={errors} {...field}/>
				)}/>
				<p className="heading">Chọn tên miền gắn được gắn vào</p>
				<Controller control={control} name="domainRemove" render={({field}) => (
					<GroupRadioField options={domainOptions} errors={errors} {...field}/>
				)}/>
			</>

		)
	}

	const RenderDomainReplace = () => {

		let listDomain = [];

		listDomain = [domains];

		if(!_.isEmpty(domainsSub)) {
			for (const [id, dom] of Object.entries(domainsSub)) {
				listDomain.push(dom.domain)
			}
		}

		listDomain.map((domain, index) => {
			return domain
		})

		let domainOptions = [...listDomain.map((domain) => {
			return {
				value: domain,
				label: domain
			};
		})];
		return (
			<>
				<br />
				<Controller control={control} name="domain" render={({field}) => (
					<InputField label="Tên miền cần thêm" errors={errors} {...field}/>
				)}/>
				<p className="heading">Tên miền cần thay thế</p>
				<Controller control={control} name="domainRemove" render={({field}) => (
					<GroupRadioField options={domainOptions} errors={errors} {...field}/>
				)}/>
			</>

		)
	}

	const RenderDomainRemove = () => {

		let listDomain = [];

		listDomain = [domains];

		if(!_.isEmpty(domainsSub)) {
			for (const [id, dom] of Object.entries(domainsSub)) {
				listDomain.push(dom.domain)
			}
		}

		listDomain.map((domain, index) => {
			return domain
		})

		let domainOptions = [...listDomain.map((domain) => {
			return {
				value: domain,
				label: domain
			};
		})];
		return (
			<>
				<br />
				<p className="heading">Tên miền cần xóa</p>
				<Controller control={control} name="domainRemove" render={({field}) => (
					<GroupRadioField options={domainOptions} errors={errors} {...field}/>
				)}/>
			</>
		)
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Loại yêu cầu</p>
			<Controller control={control} name="serviceId" render={({ field }) => (
				<SelectServiceAll service={"host"} label="Host bạn muốn thay đổi" placeholder={'Điền tên miền hiện tại của dịch vụ host'} options={[]} errors={errors} {...field} onChange={(value, event) => {
					handleServiceChange(value).then();
					field.onChange(value, event);
				}}/>
			)}/>
			<Controller control={control} name="type" render={({field}) => (
				<GroupRadioField options={typeOptions} errors={errors} {...field}/>
			)}/>
			<RenderMessage />
			{loading && <div className="d-flex justify-content" style={{width:'100%'}}><Loading noFixed spin /></div>}
			{!loading && <RenderDomain />}
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Tạo yêu cầu</Button>
			</div>
		</form>
	)
}

export default ToDoFormAddDomain;