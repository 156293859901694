import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {contractActions} from "./contractSlice";
import {contractApi} from "~/api";

function* fetchContractList(action) {
	try {
		const response = yield call(contractApi.gets, action.payload);
		yield put(contractActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch customer list', error);
		yield put(contractActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(contractActions.setFilter(action.payload));
}

export default function* contractSaga() {
	yield takeLatest(contractActions.fetchData.type, fetchContractList);
	yield debounce(500, contractActions.setFilterWithDebounce.type, handleSearchDebounce)
}