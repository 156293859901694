import style from '../Home.module.scss';
import {memo, useState, useCallback, useEffect} from "react";
import className from 'classnames/bind';
import {
	CardMoney,
	ContentHeader,
	SidebarAccount
} from "../componets";
import {Col, Row} from "antd";
import images from "../../../assets/images";
import {apiError, handleRequest, renderDate} from "../../../utils";
import {dashboardApi} from "../../../api";
import {Image} from "../../../components";

const cn = className.bind(style);

function DashboardPersonnel({user, fullTime}) {

	const [dashboardData, setDashboardData] = useState({
		total       : 0,
		groupTotal  : 0,
		newUser     : 0,
		outUser     : 0,
		formPending : 0,
		birthdayList: [],
	});

	useEffect(() => {
		fetchDashboardData().then()
	}, [user]);

	const fetchDashboardData = useCallback(async () => {
		const [
			countResponse,
			birthdayResponse,
		] = await Promise.all([
			handleRequest(dashboardApi.personnelCount()),
			handleRequest(dashboardApi.personnelBirthday()),
		]);
		//Dự án
		let [countErr, countData] = countResponse;
		let countMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, countErr, countData);
		if (!countMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				total: countData.data.total,
				groupTotal: countData.data.groupTotal,
				newUser: countData.data.newUser,
				outUser: countData.data.outUser,
			}));
		}

		let [birthdayErr, birthdayData] = birthdayResponse;
		let birthdayMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, birthdayErr, birthdayData);
		if (!birthdayMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				birthdayList: birthdayData.data,
			}));
		}

	}, [])

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} fullTime={fullTime} />
				<Row gutter={20}>
					<Col span={5}>
						<CardMoney image={images.dashboard06} heading={'Tổng nhân viên'} number1={dashboardData.total}/>
						<CardMoney image={images.dashboard06} heading={'Nhóm kinh doanh'} number1={dashboardData.groupTotal}/>
						<CardMoney image={images.dashboard06} heading={'Nhân viên mới'} number1={dashboardData.newUser}/>
						<CardMoney image={images.dashboard06} heading={'Xin nghỉ phép'} number1={dashboardData.formPending}/>
					</Col>
					<Col span={10}>
						<div className={cn('card', 'card-block')}>
							<h4 className={cn('heading')}>Sinh nhật</h4>
							<div className={cn('list-ranks')}>
								{
									dashboardData.birthdayList.map((item) => {
										return <div key={`card-rank-${item.id}`} className={cn('user', 'd-flex', 'gap', 'align-items')}>
											<div className={cn('avatar-rank', 'avatar')}><Image src={item.avatar} /></div>
											<div>
												<b className={cn('name')}>{item.firstname} {item.lastname}</b>
												<p className={cn('info-more')}>{item.username}</p>
											</div>
											<p className={cn('revenue')}>{renderDate(item.birthday)}</p>
										</div>
									})
								}
							</div>
							<div className={cn('bg')}><Image src={images.dashboardBannerBirthDay} /></div>
						</div>

					</Col>
				</Row>
			</div>
		</div>
	)
}

export default memo(DashboardPersonnel);
