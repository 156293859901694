import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading, Icon, Button, Modal
} from "~/components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	expenseActions,
	expenseErrorSelector,
	expenseFilterSelector,
	expenseItemsSelector,
	expenseLoadingSelector,
	expensePaginationSelector
} from "../expenseSlice";
import {
	ExpenseTable,
	ExpenseFormAddEdit,
	ExpenseSearchBar
} from "../components";
import {
	expenseApi
} from "~/api";
import {
	expenseGroupActions,
	expenseGroupItemsSelector
} from "../expenseGroupSlice";

function Expense() {

	const dispatch  = useDispatch();

	const items     = useSelector(expenseItemsSelector);

	const loading   = useSelector(expenseLoadingSelector);

	const error     = useSelector(expenseErrorSelector);

	const pagination = useSelector(expensePaginationSelector);

	const filter    = useSelector(expenseFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [categories, setCategories] = useState({});

	//Group
	const listGroup = useSelector(expenseGroupItemsSelector);

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	const handleLoadCategories = async () => {

		let [error, response] = await handleRequest(expenseApi.groupGets());

		let message = apiError(`Lấy loại thu chi thất bại`, error, response);

		if(!message) {
			setCategories(response.data);
		}
	}

	//Load data
	useEffect(() => {
		handleLoadCategories();
		dispatch(expenseGroupActions.fetchData({}))
	}, []);

	useEffect(() => {
		dispatch(expenseActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin thu chi để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		data.time = strToTime(data.time);
		let [error, response] = (isEdit) ? await handleRequest(expenseApi.update(data)) : await handleRequest(expenseApi.add(data));
		let message = apiError(`${messageAction} thu chi thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} thu chi thành công`});
			if(!isEdit) {
				dispatch(expenseActions.add(response.data));
			}
			else {
				setItemEdit(response.data);
				dispatch(expenseActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin thu chi để xóa'});
			return;
		}
		let [error, response] = await handleRequest(expenseApi.delete(itemEdit.id));
		let message = apiError(`xóa thu chi thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa thu chi thành công`});
			dispatch(expenseActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(expenseActions.setFilter({...filter, page }));
	}

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(expenseActions.setFilter(newFilter));
	};

	return (
			<Fragment>
				<ActionBar title={'Chi phí'}>
					<Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>
					<Button background blue to="/expense">Thu chi</Button>
					<Button outline blue to="/expense/group">Loại</Button>
					<Button outline blue to="/expense/event">Event</Button>
				</ActionBar>
				<div className="container">
					<div className="content">
						{loading && <Loading/>}
						{<ExpenseSearchBar filter={filter} categories={categories} onChange={handleFilterChange} />}
						{items && <ExpenseTable
							items={items}
							pagination={pagination}
							onPaginationChange={handlePaginationChange}
							setItemEdit={setItemEdit}
							openModal={handleModalOpen}
						/>}
					</div>
					{
						<Modal title="Thu - Chi" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
							<ExpenseFormAddEdit item={itemEdit} listGroup={listGroup} onHandleSubmit={handleSaveItem} />
						</Modal>
					}
					{
						(itemEdit?.id) && <Modal title="Xóa thu chi" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
							<p>Bạn muốn xóa thu chi <b>DT{itemEdit?.id}</b>?</p>
							<br />
							<div className="d-flex justify-content-end gap pd-1 modal-bottom">
								<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
								<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
							</div>
						</Modal>
					}
				</div>
			</Fragment>
	)
}
export default Expense;