import {Pagination} from "antd";
import ProjectItem from "../ProjectItem/ProjectItem";

function ProjectList({items = [], pagination, onPaginationChange, setItemEdit, setProject, openModal}) {
	return (
		<div className="table-wrapper m-0 pd-1">
			<div className="table-box">
				{items.map((item) => <ProjectItem key={`ProjectItem${item.id}`} openModal={openModal} setProject={setProject} setItemEdit={setItemEdit} project={item}/>)}
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default ProjectList
