import {forwardRef} from "react";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
dayjs.locale('vi');

const DateField = forwardRef(({ name, onChange, onBlur, label, value, placeholder, picker, errors, ...props }, ref) => {
	let dateFormat = 'DD/MM/YYYY';
	let valueDate = new Date(value);

	if(picker == 'month') {
		dateFormat = 'MM/YYYY';
		valueDate = ("0" + (valueDate.getMonth() + 1)).slice(-2)+'/'+valueDate.getFullYear();
	}
	else {
		valueDate = ("0" + valueDate.getDate()).slice(-2)+'/'+("0" + (valueDate.getMonth() + 1)).slice(-2)+'/'+valueDate.getFullYear();
	}
	if(label && typeof placeholder == 'undefined') placeholder = label;
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<DatePicker
				name={name}
				onChange={onChange} onBlur={onBlur}
				value={value && dayjs(valueDate, dateFormat)}
				format={dateFormat}
				popupStyle={{zIndex:10000}}
				className={((errors && errors[name]) && 'error') + " form-control"}
				placeholder={placeholder}
				picker={picker}
				{...props}
				ref={ref}
			/>
			{errors && errors[name]?.message && (<p className="error-message">{errors[name]?.message}</p>)}
		</div>
	)
});

export default DateField;