import {Fragment} from "react";
import style from '../../style/RuleGroupItem.module.scss';
import className from 'classnames/bind';
import {Table} from "antd";
import {Button, Icon} from "~/components";
import {renderDate} from "~/utils";
const cn = className.bind(style);
function RuleGroupItem({group = [], setItemEdit, setRuleGroup, openModal, closeModal, can}) {

	const handleButtonClick = {
		add: (project) => {
			setRuleGroup(group);
			openModal('addItem')
		},
		edit: (item) => {
			setRuleGroup(group);
			setItemEdit(item)
			openModal('editItem')
			closeModal('infoItem')
		},
		info: (item) => {
			setRuleGroup(group);
			setItemEdit(item)
			openModal('infoItem')
		},
		delete: (item) => {
			setRuleGroup(group);
			setItemEdit(item)
			openModal('deleteItem')
		}
	}

	let columns = [
		{title: 'Nội quy', dataIndex: 'name', key: "name", width: '430px', render: (_, item) => (
			<p className={cn('name')} onClick={() => handleButtonClick.info(item)} style={{fontSize : '13px'}}>{item?.name}</p>
		)},
		{title: 'Ngày tạo', dataIndex: 'created', key: "created", width: '130px', render: (_, item) => (
			<Fragment>
				<p>{renderDate(item?.created)}</p>
			</Fragment>
		)},
		{title: 'Sửa lần cuối', dataIndex: 'updated', key: "updated", width: '130px', render: (_, item) => (
			<Fragment>
				<p>{renderDate(item?.updated)}</p>
			</Fragment>
		)},
		{title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<Fragment>
				<Button small leftIcon={Icon.info} onClick={() => handleButtonClick.info(item)} background blue></Button>
				{can.edit && <Button small leftIcon={Icon.edit} onClick={() => handleButtonClick.edit(item)} background blue></Button>}
				{can.delete && <Button small leftIcon={Icon.delete} onClick={() => handleButtonClick.delete(item)} background red></Button>}
			</Fragment>
		)}
	];

	const dataSource= group.items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="content">
			<div className={cn('project-info')+ ' d-flex align-items gap justify-content-between'}>
				<div className={cn('project-heading')}>
					<p className={cn('name')}>{group.name}</p>
					<p className={cn('excerpt')}>{group.excerpt}</p>
				</div>
				{
					can.add && <div>
						<Button small outline leftIcon={Icon.plusCircle} onClick={() => handleButtonClick.add(group)}>Thêm mới</Button>
					</div>
				}

			</div>
			<div className={cn('project-task')}>
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} showHeader={false} />
			</div>
		</div>
	)
}
export default RuleGroupItem