import style from "../../style/PayReport.module.scss";
import className from "classnames/bind";
import {Fragment, useState, useEffect, memo} from "react";
import _ from "lodash";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
	Button,
	Icon,
} from "~/components";

import {
	apiError,
	numberFormat,
	handleRequest
} from "~/utils";

import {
	payApi
} from "~/api";

import {
	PayReportSearch
} from "../../components";
import {useUtilities} from "../../../../hooks";

const cn = className.bind(style);

function PayReport() {

	const {utilities} = useUtilities();

	const [items, setItems] = useState({});

	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState([]);

	//Load data
	useEffect(() => { handleLoad(); }, [filter]);

	const handleLoad = async () => {
		setLoading(true);
		let [error, response] = await handleRequest(payApi.report(filter));
		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			setItems(response.data)
		}
		setLoading(false);
	}

	//Search
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		setFilter(newFilter);
	};

	const PayReportItem = ({total, departmentKey}) => {
		return (
			<div className={cn('department')}>
				<p className={cn('name')}>{utilities.departments[departmentKey]?.title}</p>
				<p className={cn('pay')}>{numberFormat(total)}</p>
			</div>
		)
	}

	return (
		<Fragment>
			<ActionBar title={'Lương'}></ActionBar>
			<div className="container">
				<div className="content pd-1">
					{loading && <Loading/>}
					<PayReportSearch filter={filter}  onChange={handleFilterChange} />
					<p className="heading mt-2">Tổng Lương: <span style={{fontSize:'20px'}}>{numberFormat(items.total)}</span></p>
					<div className={cn('pay-wrapper')}>
						{items?.department && !_.isEmpty(items.department) && Object.keys(items.department).map((itemKey) => {
							return <PayReportItem key={`report-pay-item-${itemKey}`} total={items.department[itemKey]} departmentKey={itemKey} />
						})}
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default memo(PayReport);