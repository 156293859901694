import style from './Header.module.scss';
import Tippy from '@tippyjs/react/headless';
import className from 'classnames/bind';
import {useDispatch, useSelector } from "react-redux";
import {memo, useContext, useState} from "react";
import _ from "lodash";
import {
	Image,
	Button,
	PopperWrapper,
	Icon,
	StatusLabel,
	MessageItem,
	Notification,
	NotificationItem
} from "~/components";
import {currentUserSelector, authActions} from "~/features/Auth/authSlice";
import {globalNavigate} from "~/routes/GlobalHistory";
import {useCan, useDevice, useUtilities} from "~/hooks";
import {Drawer} from "antd";
import NavBar from "../NavBar";
import LogoText from "./LogoText";
import {apiError, handleRequest} from "../../utils";
import {userApi} from "../../api";
import {AppContext} from "../../context/AppProvider";
const cn = className.bind(style);
function Header({
	notificationMessage,
	notification,
	onClickNotificationMessageOpen,
	onClickNotificationMessageClose,
	onClickNotificationMessageItem,
	onClickNotificationOpen,
	onClickNotificationClose,
	onClickNotificationItem,
}) {

	const {utilities} = useUtilities();

	const {isMobile, isDesktop} = useDevice();

	const dispatch = useDispatch();

	const currentUser = useSelector(currentUserSelector);

	const useCanRolesView = useCan('rolesView');

	const useCanLoginAs = useCan('loginAs');

	const {
		notificationCount,
		loginAsUsers,
		setLoginAsUsers,
	} = useContext(AppContext);

	const [loginAsOpen, setLoginAsOpen] = useState(false);

	const loginAsShow = async () => {
		setLoginAsOpen(true);
		if (useCanLoginAs == true && _.isEmpty(loginAsUsers)) {
			let [error, response] = await handleRequest(userApi.getAll());
			let message = apiError(`Load danh sách thành viên thất bại`, error, response);
			if (!message) {
				setLoginAsUsers(response.data);
			}
		}
	};

	const loginAsClose = () => {
		setLoginAsOpen(false);
	};

	const handleLogout = () => {
		localStorage.clear();
		dispatch(authActions.logout());
		globalNavigate("/login");
	}

	const handleLoginAs = async (item) => {
		localStorage.setItem('access_token_as', item.id);
		window.location.reload(false)
	};

	const renderAccountMenu = () => {
		return (
			<PopperWrapper className={cn('account-action')}>
				<Button leftIcon={Icon.user} className={cn('button')} to={'/account/info'}> Thông tin tài khoản</Button>
				<Button leftIcon={Icon.lock} className={cn('button')} to={'/account/password'}> Đổi mật khẩu</Button>
				{ useCanLoginAs && <Button leftIcon={Icon.switch} className={cn('button')} onClick={loginAsShow}> Đổi tài khoản</Button>}
				{ useCanRolesView && <Button leftIcon={Icon.lock} className={cn('button')} to={'/Permission'}> Phân quyền</Button>}
				<hr />
				<Button leftIcon={Icon.logout} onClick={handleLogout} className={cn('button')}> Đăng xuất </Button>
			</PopperWrapper>
		)
	}

	return (
		<header className={cn('wrapper')}>
			<div className={cn('inner')}>
				{isDesktop && <NavBar notificationCount={notificationCount} />}
				{isMobile && <LogoText />}
				<div className={cn('right')}>
					<div className={cn('notification-icon')}>
						<div className={cn('icon')} onClick={onClickNotificationMessageOpen}>
							{ (notificationCount.comment != 0) && <span className={cn('number')}></span> }
							<svg viewBox="0 0 28 28" alt="" height="20" width="20">
								<path d="M14 2.042c6.76 0 12 4.952 12 11.64S20.76 25.322 14 25.322a13.091 13.091 0 0 1-3.474-.461.956 .956 0 0 0-.641.047L7.5 25.959a.961.961 0 0 1-1.348-.849l-.065-2.134a.957.957 0 0 0-.322-.684A11.389 11.389 0 0 1 2 13.682C2 6.994 7.24 2.042 14 2.042ZM6.794 17.086a.57.57 0 0 0 .827.758l3.786-2.874a.722.722 0 0 1 .868 0l2.8 2.1a1.8 1.8 0 0 0 2.6-.481l3.525-5.592a.57.57 0 0 0-.827-.758l-3.786 2.874a.722.722 0 0 1-.868 0l-2.8-2.1a1.8 1.8 0 0 0-2.6.481Z"></path>
							</svg>
						</div>
						<div className={cn('icon')} onClick={onClickNotificationOpen}>
							{ (notificationCount.notification != 0) && <span className={cn('number')}></span> }
							<svg viewBox="0 0 28 28" alt="" height="20" width="20">
								<path d="M7.847 23.488C9.207 23.488 11.443 23.363 14.467 22.806 13.944 24.228 12.581 25.247 10.98 25.247 9.649 25.247 8.483 24.542 7.825 23.488L7.847 23.488ZM24.923 15.73C25.17 17.002 24.278 18.127 22.27 19.076 21.17 19.595 18.724 20.583 14.684 21.369 11.568 21.974 9.285 22.113 7.848 22.113 7.421 22.113 7.068 22.101 6.79 22.085 4.574 21.958 3.324 21.248 3.077 19.976 2.702 18.049 3.295 17.305 4.278 16.073L4.537 15.748C5.2 14.907 5.459 14.081 5.035 11.902 4.086 7.022 6.284 3.687 11.064 2.753 15.846 1.83 19.134 4.096 20.083 8.977 20.506 11.156 21.056 11.824 21.986 12.355L21.986 12.356 22.348 12.561C23.72 13.335 24.548 13.802 24.923 15.73Z"></path>
							</svg>
						</div>
					</div>
					{isDesktop &&
						<Tippy interactive render={renderAccountMenu} placement="bottom" offset={[0, 10]}>
							<div className={cn('account')}>
								<div className={cn('avatar')}><Image src={currentUser?.avatar}/></div>
								<div className={cn('name', 'text-sm')}><span>Hi, {currentUser?.lastname}</span></div>
							</div>
						</Tippy>
					}
				</div>
			</div>
			<Notification
				outSide={onClickNotificationMessageClose}
				show={notificationMessage.open}
				title={'Tin nhắn'}
				description={'Nhận thông báo tin nhắn'}
				icon={Icon.comment}>
				{
					notificationMessage.items.map((item, index) => {
						return (<MessageItem key={index} item={item} onClickLink={onClickNotificationMessageItem}></MessageItem>);
					})
				}
			</Notification>
			<Notification
				outSide={onClickNotificationClose}
				show={notification.open}
				title={'Thông Báo'}
				description={'Nhận thông báo công việc'}
				icon={Icon.bell}>
				{
					notification.items.map((item, index) => {
						return (<NotificationItem key={index} item={item} onClickLink={onClickNotificationItem}></NotificationItem>);
					})
				}
			</Notification>
			<Drawer title="Nhân viên" placement="right" onClose={loginAsClose} open={loginAsOpen}>
				{
					loginAsUsers.map((item) => {
						return <div key={`login-as-${item.id}`} className="d-flex gap align-items justify-content-between mb-1">
							<div>
								<b>{item?.firstname + ' ' + item?.lastname}</b>
								<p>{item?.username} <StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel></p>
							</div>
							<Button background red onClick={() => handleLoginAs(item)}>Chuyển</Button>
						</div>
					})
				}
			</Drawer>
		</header>
	);
}
export default memo(Header);