import {
    Button, Image, Icon
} from "~/components";
import {Pagination, Table, Tooltip} from "antd";
import {numberFormat} from "~/utils";
import {useContext} from "react";
import {AppContext} from "~/context/AppProvider";

function ExpenseGroupTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

    const {tableHeight} = useContext(AppContext);

    const runList = {
        'month' : 'Hằng tháng',
    }

    const handleEditClick = (item) => {
        setItemEdit(item);
        openModal('addEdit');
    }

    const handleDeleteClick = (item) => {
        setItemEdit(item);
        openModal('delete');
    }

    const columns = [
        { title: 'Loại chi', dataIndex: 'category', key: 'category', width: '220px', render:(_, item) => (<div>{item.category.name}</div>)},
        { title: 'Ngày chi', dataIndex: 'time', key: "time", width: '120px', render: (_, item) => (<div><p>{item?.time}</p></div>)},
        { title: 'Lặp lại', dataIndex: 'run', key: "run", width: '100px', render: (_, item) => (<p><b>{runList[item?.run]}</b></p>)},
        { title: 'Đối tượng chi', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
        item.user?.id && (
            <div className="d-flex gap align-items">
                <div className="avatar"><Image src={item.user?.avatar}/></div>
                <div>
                    <b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
                    <p>{item.user?.username}</p>
                </div>
            </div>
        ))},
        { title: 'Số tiền chi', dataIndex: 'price', key: "price", width: '180px', render: (_, item) => (<p><b>{numberFormat(item?.price)}đ</b></p>)},
        { title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
            <div className="table-action d-flex">
                <Tooltip title="Edit thông tin"><Button background blue leftIcon={Icon.edit} onClick={() => handleEditClick(item)}></Button></Tooltip>
                <Tooltip title="Xóa"><Button background red leftIcon={Icon.delete} onClick={() => handleDeleteClick(item)}></Button></Tooltip>
            </div>
        )},
    ];

    const dataSource= items.map(row => ({
        key: row.id, // I added this line
        ...row
    }));

    return (
        <div className="table-wrapper">
            <div className="table-box">
                <Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
            </div>
            <Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
        </div>
    )
}

export default ExpenseGroupTable;