import {memo} from "react";
import {Table} from "antd";
import {ceil} from "lodash";

function SaleEventTable({items = []}) {
	const percentEvent = (call, event) => {
		if(event == 0) return 0;
		let percent = ceil(call / event);
		return (
			<><b>{percent}</b> cuộc gọi / hẹn</>
		)
	}
	const percentEventCancel = (event, eventCancel) => {
		if(eventCancel == 0) return 0;
		let percent = ceil(eventCancel / event *100);
		return (
			<>
				<span className={((percent >= 50 && eventCancel > 10) && 'color-red')}>{percent}%</span>
			</>
		)
	}

	const percentContract = (eventSuccess, contract) => {
		if(contract == 0) return 0;
		let percent = ceil(eventSuccess / contract );
		return (
			<><b>{percent}</b> tư vấn  / hợp đồng</>
		)
	}


	const columns = [
		{ title: 'Tên', dataIndex: 'name', key: "name", width: '180px', render: (_, item) => (
			<><b>{`${item?.name}`}</b></>
		)},
		{ title: 'Cuộc gọi', dataIndex: 'call', key: "call", width: '90px', render: (_, item) => (
			<>{item.call}</>
		)},
		{ title: 'Hẹn', dataIndex: 'total', key: "total", width: '70px', render: (_, item) => (
			<b className="text-md text-center">{item?.event}</b>
		)},
		{ title: 'Tỷ lệ hẹn', dataIndex: 'percent', key: "status", width: '100px', render: (_, item) => (
			<>{percentEvent(item.call,item.event)}</>
		)},
		{ title: 'Tư vấn', dataIndex: 'consultation', key: "consultation", width: '70px', render: (_, item) => (
				<>{item.consultation}</>
		)},
		{ title: 'Bị hủy', dataIndex: 'cancel', key: "cancel", width: '70px', render: (_, item) => (
			<>{item?.cancel} ({percentEventCancel(item.event, item.cancel)})</>
		)},
		{ title: 'Hẹn mới', dataIndex: 'new', key: "new", width: '70px', render: (_, item) => (
				<>{item?.new}</>
			)},
		{ title: 'Hẹn củ', dataIndex: 'old', key: "old", width: '70px', render: (_, item) => (
				<>{item?.old}</>
			)},
		{ title: 'Bàn giao', dataIndex: 'handOver', key: "handOver", width: '70px', render: (_, item) => (
				<>{item?.handOver}</>
			)},
		{ title: 'Trực tiếp', dataIndex: 'meeting', key: "meeting", width: '70px', render: (_, item) => (
				<>{item?.meeting}</>
			)},
		{ title: 'Online', dataIndex: 'online', key: "online", width: '60px', render: (_, item) => (
			<>{item?.online}</>
		)},
		{ title: 'Hợp đồng', dataIndex: 'contract', key: "contract", width: '200px', render: (_, item) => (
			<><b>{item.contract}</b> ({percentContract(item.consultation, item.contract)})</>
		)},
	];
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:550, x: 'max-content' }} pagination={false} />
			</div>
		</div>
	)
}

export default memo(SaleEventTable);