import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {candidateActions} from "./candidatesSlice";
import {candidateApi} from "~/api";

function* fetchCandidateList(action) {
	try {
		const response = yield call(candidateApi.gets, action.payload);
		yield put(candidateActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed  to fetch candidate list', error);
		yield put(candidateActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(candidateActions.setFilter(action.payload));
}

export default function* candidateSaga() {
	yield takeLatest(candidateActions.fetchData.type, fetchCandidateList);
	yield debounce(500, candidateActions.setFilterWithDebounce.type, handleSearchDebounce)
}