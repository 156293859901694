import request from "~/utils/http";

const contractApi = {
	gets : async (params) => {
		const url = 'contracts';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `contracts/${id}`;
		return await request.get(url);
	},
	search : async (params) => {
		const url = 'contracts/search';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'contracts';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `contracts/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `contracts/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `contracts/status/${params.id}`;
		return await request.post(url, params);
	},
	transfer : async (params) => {
		const url = `contracts/transfer/${params.id}`;
		return await request.post(url, params);
	},
	print : async (params) => {
		const url = `contracts/prints/${params.id}`;
		return await request.post(url, params);
	},
};

export default contractApi;