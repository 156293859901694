import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import {renderDate} from "~/utils";
import {Col, Row} from "antd";
import StatusLabel from "../../../../components/StatusLabel";
const cn = className.bind(style);
function SaleReportEvent({item}) {
	return (
		<div className={cn('event-item')}>
			<Row gutter={10}>
				<Col className="gutter-row" span={8}>
					<p>{item.name}</p>
					<p className={cn('address')}>{item.address}</p>
				</Col>
				<Col className="gutter-row" span={8}>
					{item.type == 'new' ? <StatusLabel small type="green">Khách mới</StatusLabel> : <StatusLabel small type="red">Khách củ</StatusLabel>}
				</Col>
			</Row>
		</div>
	)
}
export default SaleReportEvent;