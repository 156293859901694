import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,Loading,StatusLabel
} from "~/components";
import {
	SelectField,
	SelectContractAll,
	DateField,
	EditorField
} from "~/components/Forms";
import {
	LIST_PROJECT_PHASE
} from "~/app/constants";

function ProjectFormAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const contractOptions = (isEdit && typeof item.contract?.id != "undefined") ? [{ label : `HD${item.contract.id} ${item.contract.name}`, value : item.contract.id }] : [];

	const phaseOptions = [];

	for (const [key, value] of Object.entries(LIST_PROJECT_PHASE)) {
		phaseOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	const initialValues = {
		contractId: '',
		deadline: new Date(),
		phase: '',
		note: '',
	}

	const validationSchema = Yup.object().shape({
		contractId: Yup.string().required('Bạn chưa chọn hợp đồng'),
		deadline: Yup.string().required('Bạn chưa chọn ngày thu'),
		phase: Yup.string().required('Giai đoạn dự án không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.contractId = item.contractId;
			initialValues.deadline = item.deadline*1000;
			initialValues.phase = item.phase;
			if(item.phase == 'design') {
				initialValues.note = item.noteDesign;
			}
			else {
				initialValues.note = item.note;
			}
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Hợp đồng</p>
			<Controller control={control} name="contractId" render={({ field }) => (
				<SelectContractAll service="website" placeholder="Hợp đồng" options={contractOptions} errors={errors} {...field} />
			)}/>
			<p className="heading">Thông tin dự án</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phase" render={({field}) => (
						<SelectField label="Giai đoạn" options={phaseOptions} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="deadline" render={({ field }) => (
						<DateField label="Deadline" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Controller control={control} name="note" render={({ field }) => (
				<EditorField label="Ghi chú" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} dự án</Button>
			</div>
		</form>
	)
}

export default ProjectFormAddEdit;