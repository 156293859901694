import {useState} from "react";
import {
	Button,
	Loading
} from "~/components";

function ToDoFormFinished({item, onHandleSubmit}) {

	const [loading, setLoading] = useState(false);

	const onClickSubmit = async () => {
		setLoading(true);
		await onHandleSubmit(item, 'success', false);
		setLoading(false);
	}

	const onClickServer = async () => {
		setLoading(true);
		await onHandleSubmit(item, 'success', true);
		setLoading(false);
	}

	return (
		<div>
			<br />
			<div className="form modal-bottom mb-2">
				{loading && <Loading/>}
				<Button primary onClick={onClickServer} className="d-block w-100 mb-2">Xử lý trên <b>Server</b> và <b>Hệ thống</b></Button>
				<Button white onClick={onClickSubmit} className="d-block w-100 m-0">Xử lý trên hệ thống</Button>
			</div>
		</div>

	)
}

export default ToDoFormFinished;