import {useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {Col, Row} from "antd";
import {
	InputField,
	SelectField
} from "~/components/Forms";
import {useGroup} from "~/hooks";

function GroupFormAddEdit({item, onHandleSubmit}) {

	const listGroup = useGroup();

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);


	const isEdit = Boolean(item?.id);

	const initialValues = {
		name: undefined,
		parentId: undefined,
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Bạn chưa điền tên phòng kinh doanh'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item.name;
			initialValues.parentId = item.parent;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading"></p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="name" render={({ field }) => (
						<InputField label="Tên phòng kinh doanh" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="parentId" render={({ field }) => (
						<SelectField label="Cấp trên" options={groupOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} hợp đồng</Button>
			</div>
		</form>
	)
}

export default GroupFormAddEdit;