import style from './Comments.module.scss';
import className from 'classnames/bind';
import {useState, useRef, useEffect} from "react";
import _ from "lodash";
import {Button, FontAwesomeIcon, Loading, Message} from "../index";
import {Empty, notification} from "antd";
import {apiError, handleRequest} from "../../utils";
import commentApi from "../../api/commentApi";
import {useSocket} from "../../hooks";

const cn = className.bind(style);

function Comments({type, objectId}) {

	const {socket} = useSocket();

	let [comments, setComments] = useState([]);
	let [commentLoading, setCommentLoading] = useState(false);

	const [inputValue, setInputValue] = useState("");
	const [fileUpload, setFileUpload] = useState(null);
	const [previewFileInput, setPreviewFileInput] = useState();

	const inputRef      = useRef();
	const imageInputRef = useRef();
	const roomRef       = useRef(null);

	const handleLoadComments = async () => {
		setCommentLoading(true);
		let [error, response] = await handleRequest(commentApi.gets(type, {id: objectId}));
		let message = apiError(`Load bình luận thất bại`, error, response);
		if(!message) {
			setComments(response.data)
		}
		if (roomRef.current) {
			roomRef.current.scrollTop = roomRef.current.scrollHeight;
		}
		setCommentLoading(false);
	}

	useEffect(() => {
		handleLoadComments().then();
	}, [objectId, type])

	// Handle input change
	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	// Press "Enter" to send messages
	const handleKeyUp = (e) => {
		if (e.key === "Enter") {
			handleOnSubmit().then();
		}
	};

	// Handle image input change and file size limit
	const handleFileInput = (e) => {
		if (
			e.target.files[0].size <= 25000000 &&
			e.target.files[0].type.includes("image")
		) {
			setFileUpload(e.target.files[0]);
			setPreviewFileInput({
				type: "@image",
				data: URL.createObjectURL(e.target.files[0]),
			});
			inputRef.current.focus();
		}
		else if (e.target.files[0].type.includes("video")) {
			setFileUpload(e.target.files[0]);
			setPreviewFileInput({
				type: "@video",
				data: URL.createObjectURL(e.target.files[0]),
			});
			inputRef.current.focus();
		}
		else if (e.target.files[0].size > 25000000) {
			notification.error({message: 'Không tải tệp lên được', description: 'File bạn đã chọn quá lớn. Kích thước file tối đa là 25MB'});
			imageInputRef.current.value = "";
		}
		else {
			notification.error({message: 'Không tải tệp lên được', description: 'File bạn đã chọn không phù hợp. Hiện tại chưa hỗ trợ định dạng này.'});
			imageInputRef.current.value = "";
		}
	};

	// Clear preview
	const handleClearPreview = () => {
		setFileUpload(null);
		setPreviewFileInput("");
		//clear input
		imageInputRef.current.value = "";
		inputRef.current.focus();
	};

	const handlePaste = (event) => {
		const items = event.clipboardData && event.clipboardData.items;
		if (items) {
			for (let i = 0; i < items.length; i++) {
				if (items[i].type.indexOf("image") !== -1) {
					const blob = items[i].getAsFile();
					const file = new File([blob], "image.png", { type: blob.type });
					setFileUpload(file);
					setPreviewFileInput({
						type: "@image",
						data: URL.createObjectURL(file),
					});
					inputRef.current.focus();
				}
			}
		}
	};

	const handleOnSubmit = async () => {
		if (fileUpload) {
			if (fileUpload.type.includes("image")) {
				const formData = new FormData();
				formData.append("file", fileUpload, fileUpload.name);
				let [error, response] = await handleRequest(commentApi.fileUpload(formData));
				let message = apiError(`Không tải tệp lên được`, error, response);
				if (!message) {

					let url = response.data.url;

					if (inputValue.trim()) {
						[error, response] = await handleRequest(commentApi.add({
							id : objectId,
							objectType: type,
							message: inputValue,
							type: "@text"
						}));
						message = apiError(`Không gửi được tin nhắn`, error, response);
						if (!message) {
							socket.emit("set-notification-comment", response.data.notification);
							comments = [...comments, response.data.item];
							setComments(comments);
						}
					}

					[error, response] = await handleRequest(commentApi.add({
						id : objectId,
						objectType: type,
						message: 'Hình ảnh',
						url: url,
						type: "@image"
					}));

					message = apiError(`Không tải tệp lên được`, error, response);

					if (!message) {
						comments = [...comments, response.data.item];
						setComments(comments);
					}
				}
			}
		}
		else if (inputValue) {
			// Send text only
			let [error, response] = await handleRequest(commentApi.add({
				id : objectId,
				objectType: type,
				message: inputValue,
				type: "@text"
			}));
			let message = apiError(`Không tải tệp lên được`, error, response);
			if (!message) {
				socket.emit("set-notification-comment", response.data.notification);
				comments = [...comments, response.data.item];
				setComments(comments);
			}
		}

		handleClearPreview();
		setInputValue("");
		if (roomRef.current) {
			roomRef.current.scrollTop = roomRef.current.scrollHeight;
		}
	}

	return (
		<div className={cn("container")}>
			<div className={cn("wrapper")}>
				<div className={cn("area")} ref={roomRef}>
					{
						commentLoading && <Loading noFixed style={{width:'100%'}} />
					}
					{
						(_.isEmpty(comments)) && <Empty />
					}
					{
						Object.keys(comments).map((itemKey) => {
							let comment     = comments[itemKey];
							let preComment  = 0;
							if(itemKey != 0) {
								preComment = comments[itemKey - 1].user.id
							}
							return (
								<Message key={`message-${comment.id}`} item={comment} preCommentUser={preComment} />
							)
						})
					}
				</div>
				<div className={cn("typing-area-wrapper")}>
					<div className={cn("typing-area")}>
						<div className={cn("media-wrapper")}>
							<input
								className={cn("input-image")} onChange={handleFileInput}
								type="file" accept="image/*, video/*" name="" id=""
								ref={imageInputRef}
							/>
							<Button rounded noneBorder onClick={() => {imageInputRef.current.click();}} className={cn("media-btn")}>
								<FontAwesomeIcon icon="fa-solid fa-image" />
							</Button>
						</div>

						<div className={cn("input-wrapper")}>
							{previewFileInput && (
								<div className={cn("media-preview")}>
									<div className={cn("media-preview-file-wrap")}>
										{previewFileInput.type === "@image" && (
											<img className={cn("media-preview-file")} src={previewFileInput.data} alt=""/>
										)}
										<Button onClick={handleClearPreview} className={cn("remove-preview-file-btn")}><FontAwesomeIcon icon="fa-light fa-xmark" /></Button>
									</div>
								</div>
							)}
							<input
								ref={inputRef}
								onChange={handleInputChange}
								onKeyUp={handleKeyUp}
								value={inputValue}
								type="text"
								spellCheck="false"
								placeholder="Aa"
								className={cn("input")}
								onPaste={handlePaste}
							/>
						</div>
						<div className={cn("send-wrapper")}>
							<Button background theme rounded noneBorder className={cn('btnSend')} onClick={handleOnSubmit}><FontAwesomeIcon icon="fa-light fa-paper-plane" /></Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Comments;