import {Col, Row} from "antd";
import {Button, Icon} from "~/components";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	useCan
} from "~/hooks";

function RevenueFormInfo({item, modalOpen, onClickStatus}) {

	const can = {
		edit: useCan('revenueEdit'),
		status: useCan('revenueStatus'),
	};

	const handleStatusClick = (status) => {
		if(!onClickStatus) return;
		onClickStatus(status);
	}

	const handleEditClick = () => {
		modalOpen('addEdit');
	}

	return (
		<div className="form">
			<p className="heading">Thông tin</p>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Hợp đồng</Col>
				<Col className="gutter-row" span={18}>{`${item.contract.code} ${item.contract.name}`}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tên miền</Col>
				<Col className="gutter-row" span={18}>{`${item.contract.domain}`}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Giá trị thu</Col>
				<Col className="gutter-row" span={18}>{`${numberFormat(item.price)}`} đ</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Ngày thu</Col>
				<Col className="gutter-row" span={18}>{`${renderDate(item.time)}`}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tính lương</Col>
				<Col className="gutter-row" span={18}>{ (item.plus == 'confirm') ? `Tháng ${renderDate(item.timePlus, 'month')}` : 'Chưa tính'}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Doanh số</Col>
				<Col className="gutter-row" span={18}>{ (item.roomSales == 1) ? 'Tính doanh số phòng' : 'Không tính doanh số phòng'}</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				{
					can.edit && <Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Cập nhật</Button>
				}
				{
					(can.status && item.status == 'pending') && (<Button onClick={() => handleStatusClick('confirm')} background green leftIcon={Icon.success}>Duyệt doanh thu</Button>)
				}
				{
					(can.status && item.status == 'confirm') && (<Button onClick={() => handleStatusClick('pending')} background white leftIcon={Icon.arrowRotateLeft}>Bỏ duyệt doanh thu</Button>)
				}
			</div>

		</div>
	)
}

export default RevenueFormInfo;