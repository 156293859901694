import {useCallback, useState} from "react";
import {
	InputField,
	SelectUserAll,
	DateField,
	SelectField
} from "~/components/Forms";
import { useCan } from "~/hooks";
import {strToTime} from "~/utils";

function CustomerSearchBar({filter, onSearchChange, onChange, groupOptions}) {

	const can = {
		listAll: useCan('customerListAll'),
		listGroup: useCan('customerListGroup'),
		groups: useCan('customerListGroups'),
	};

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleUserChange = (value) => {
		if (!onChange) return;
		onChange('userId', value);
	}

	const handleGroupChange = (value) => {
		if (!onChange) return;
		onChange('groups', value);
	}

	const handleTimeChange = useCallback((value) => {
		value = strToTime(value);
		if(onChange) onChange('time', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField name="keyword" placeholder="Tên, email, số điện thoại nhân viên" onChange={handleKeywordChange} />
					{(can.listAll || can.listGroup) && <SelectUserAll name="user" placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />}
					{can.groups && <SelectField name="group" options={[
						{ value: '', label: 'Nhóm khách hàng'},
						...groupOptions
					]} placeholder="Nhóm khách hàng" onChange={handleGroupChange} ></SelectField>
					}
					<DateField name="time" picker="month" placeholder="Tháng tạo khách hàng" onChange={handleTimeChange}/>
				</form>
			</div>
		</div>
	)
}

export default CustomerSearchBar;