import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import StatusLabel from "~/components/StatusLabel";
import {SelectField} from "~/components/Forms";
import {
	useCan,
	useUtilities
} from "~/hooks";

function PersonnelFormRole({item, onHandleSubmit}) {

	const {utilities} = useUtilities();

	const canEditRoleDepartment	= useCan('personnelRoleDepartment');

	const [listPosition, setListPosition] = useState(utilities.roles);

	const roleOptions = [];

	for (const [key, item] of Object.entries(listPosition)) {
		roleOptions.push({value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>})
	}

	const initialValues = {
		role: '',
	}

	const validationSchema = Yup.object().shape({
		role: Yup.string().required('Chức vụ nhân viên không được để trống'),
	})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			if(canEditRoleDepartment) {
				let role = Object.keys(utilities.roles).filter((key) => {
					return utilities.roles[key].department == item.department;
				}).reduce((obj, key) => {
					obj[key] = utilities.roles[key];
					return obj;
				}, {})
				setListPosition(role);
			}
			initialValues.role = item.role;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Chức vụ</p>
			<Controller control={control} name="role" render={({field }) => (
				<SelectField label="Chức vụ" options={roleOptions} errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="d-flex justify-content_end">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default PersonnelFormRole;