import StatusLabel from "../../StatusLabel";
import {useUtilities} from "../../../hooks";
function SearchUserOption({item}) {
	const {utilities} = useUtilities();
	return (
		<div className="d-flex gap-1">
			{`${item.firstname} ${item.lastname}`}
			<StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>
		</div>
	)
}

export default SearchUserOption;