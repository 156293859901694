import {useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import { Col, Row } from "antd";
import {
	InputField,
	SelectField,
	DateField,
	GroupRadioField
} from "~/components/Forms";
import {
	useGroup,
	useUtilities
} from "~/hooks";
function CandidateFormAddUser({item, onHandleSubmit}) {

	const {utilities} = useUtilities();

	const listGroup = useGroup();

	const date = new Date();

	const dateEnd = new Date();

	dateEnd.setMonth(dateEnd.getMonth() + 2)

	const initialValues = {
		firstname: undefined,
		lastname: undefined,
		email: undefined,
		phone: undefined,
		birthday : undefined,
		role : undefined,
		password : '123456789',
		department : undefined,
		gender : 'male',
		groupId: undefined,
		status: 'probation',
		startTime: date.getTime(),
		endTime: dateEnd.getTime(),
	}

	const validationSchema = Yup.object().shape({
		firstname: Yup.string().required('Họ tên đệm nhân viên không được để trống'),
		lastname: Yup.string().required('Tên nhân viên không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		birthday: Yup.string().required('Ngày sinh không được để trống'),
		role: Yup.string().required('Bạn chưa chọn chức vụ nhận việc'),
		department: Yup.string().required('Bạn chưa chọn phòng ban'),
		password: Yup.string().required('Bạn chưa điền mật khẩu'),
		gender: Yup.string().required('Bạn chưa chọn giới tính nhân viên'),
		groupId: Yup.string().when('department', {
			is: 'sales',
			then: Yup.string().required('Phòng kinh doanh không được để trống'),
		}),
	})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const positionOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.roles).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [utilities.roles]);

	const departmentOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.departments).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [utilities.departments]);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	const statusOptions = [
		{ value: 'internship', label: 'Thực tập' },
		{ value: 'probation', label: 'Thử việc' },
		{ value: 'public', label: 'Chính thức' },
	];

	useEffect(() => {
		if(item?.id) {
			initialValues.firstname = item?.fullname.split(' ').slice(0, -1).join(' ');
			initialValues.lastname = item?.fullname.split(' ').slice(-1).join(' ');
			initialValues.email = item.email;
			initialValues.phone = item.phone;
			initialValues.birthday = item?.birthday*1000;
			initialValues.role = item.apply;
			initialValues.department = utilities.roles[item.apply]?.department;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}

			<p className="heading">Thông tin nhân viên</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="firstname" render={({ field }) => (
						<InputField label="Họ nhân viên" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="lastname" render={({ field }) => (
						<InputField label="Tên nhân viên" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField type="email" placeholder="Email" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phone" render={({ field }) => (
						<InputField type="tel" placeholder="Số điện thoại" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="birthday" render={({ field }) => (<DateField label="Ngày sinh" errors={errors} {...field}/>)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="gender" render={({ field }) => (<GroupRadioField label="Giới tính" options={[{value : 'male', label : 'Nam'}, {value : 'female', label : 'Nữ'}]} errors={errors} {...field}/>)}/>
				</Col>
			</Row>
			<p className="heading">Thông tin đăng nhập</p>
			<Controller control={control} name="password" render={({ field }) => (
				<InputField label="Mật khẩu" errors={errors} {...field}/>
			)}/>
			<p className="heading">Phòng ban</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="department" render={({field }) => (
						<SelectField label="Phòng ban" options={departmentOptions} errors={errors} {...field} />
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="role" render={({field }) => (
						<SelectField label="Chức vụ" options={positionOptions} errors={errors} {...field} />
					)}/>
				</Col>
			</Row>
			<Controller control={control} name="groupId" render={({field }) => (
				<SelectField label="Phòng kinh doanh" options={groupOptions} errors={errors} {...field} />
			)}/>
			<p className="heading">Loại nhân viên</p>


			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="status" render={({field }) => (
						<GroupRadioField label="Loại nhân viên" options={statusOptions} errors={errors} {...field} />
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="startTime" render={({ field }) => (
						<DateField label="Thời gian nhận việc" errors={errors} {...field}/>
					)}/>
					<Controller control={control} name="endTime" render={({ field }) => (
						<DateField label="Thời gian kết thúc" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<div className="form-group d-flex justify-content-end modal-bottom">
				<hr />
				<Button primary type="submit">Nhận việc</Button>
			</div>
		</form>
	)
}

export default CandidateFormAddUser;