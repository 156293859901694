import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import { InputField, DateField, GroupRadioField } from "~/components/Forms";
import { Col, Row } from "antd";

function PersonnelFormEdit({item, onHandleSubmit}) {

	const initialValues = {
		firstname: undefined,
		lastname: undefined,
		email: undefined,
		phone: undefined,
		birthday : undefined,
		gender : 'male'
	}

	const validationSchema = Yup.object().shape({
		firstname: Yup.string().required('Họ tên đệm nhân viên không được để trống'),
		lastname: Yup.string().required('Tên nhân viên không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		birthday: Yup.string().required('Ngày sinh không được để trống'),
		gender: Yup.string().required('Bạn chưa chọn giới tính nhân viên')
	})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.firstname = item.firstname;
			initialValues.lastname = item.lastname;
			initialValues.email = item.email;
			initialValues.phone = item.phone;
			initialValues.birthday = item?.birthday*1000;
			initialValues.gender = item.gender;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin nhân viên</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="firstname" render={({ field }) => (
						<InputField label="Họ nhân viên" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="lastname" render={({ field }) => (
						<InputField label="Tên nhân viên" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField type="email" label="Email ứng viên" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phone" render={({ field }) => (
						<InputField type="tel" label="Số điện thoại ứng viên" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="birthday" render={({ field }) => (<DateField label="Ngày sinh" errors={errors} {...field}/>)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="gender" render={({ field }) => (<GroupRadioField label="Giới tính" options={[{value : 'male', label : 'Nam'}, {value : 'female', label : 'Nữ'}]} errors={errors} {...field}/>)}/>
				</Col>
			</Row>
			<hr />
			<div className="d-flex justify-content_end">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default PersonnelFormEdit;