import {memo} from "react";
import {
	Table
} from "antd";
import {
	Icon,
	StatusLabel
} from "~/components";
import {
	numberFormat,
} from "~/utils";

function PayDetailWorkday({items = []}) {

	const renderIconWorking = (type) => {

		if(type === 1) {
			return <StatusLabel bold type="green">{Icon.success}</StatusLabel>
		}
		else {
			return <StatusLabel bold type="red">{Icon.close}</StatusLabel>
		}
	}

	const columns = [
		{title: 'Làm việc (100%)', dataIndex: 'work', key: "work", width: '100px', render: (_, item) => (
			renderIconWorking(item?.work)
		)},
		{title: 'Nghỉ (0%)', dataIndex: 'off', key: "off", width: '100px', render: (_, item) => (
			renderIconWorking(item?.off)
		)},
		{title: 'Online (70%)', dataIndex: 'online', key: "online", width: '100px', render: (_, item) => (
				renderIconWorking(item?.online)
			)},
		{title: 'Thử việc (85%)', dataIndex: 'probation', key: "probation", width: '100px', render: (_, item) => (
				renderIconWorking(item?.probation)
			)},
		{title: 'Số ngày', dataIndex: 'number', key: "number", width: '100px', render: (_, item) => (<div><p>{item?.number}</p></div>)},
		{title: 'Lương', dataIndex: 'pay', key: "pay", width: '180px', render: (_, item) => (<p>{numberFormat(item?.pay)}đ</p>)},
	];

	let total = items.reduce((partialSum, item) => partialSum + item?.pay, 0);

	let days = items.reduce((partialSum, item) => partialSum + item?.number, 0);

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table
					columns={columns}
					rowKey="id"
					dataSource={items}
					scroll={{ y:550 }}
					pagination={false}
					summary={() => (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}><b>Tổng</b></Table.Summary.Cell>
								<Table.Summary.Cell index={1}><b></b></Table.Summary.Cell>
								<Table.Summary.Cell index={2}><b></b></Table.Summary.Cell>
								<Table.Summary.Cell index={3}><b></b></Table.Summary.Cell>
								<Table.Summary.Cell index={4}><b>{numberFormat(days)}</b></Table.Summary.Cell>
								<Table.Summary.Cell index={5}><b>{numberFormat(total)}</b></Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
				/>
			</div>
		</div>
	)
}

export default memo(PayDetailWorkday);