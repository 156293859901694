import mem from "mem";
import axios from "axios";

const request = axios.create({
	baseURL: process.env.REACT_APP_SERVICE_URL,
	headers: {
		'Content-Type': 'application/json',
	}
})

const refreshToken = async () => {

	const token = localStorage.getItem("reload_token");

	try {

		const response = await request.post("/auth/refreshToken", {token});

		if (!response?.data?.accessToken) {
			localStorage.clear();
		}

		const accessToken = {
			accessToken   : response.data.accessToken,
			expires       : response.data.expires,
		}

		localStorage.setItem('access_token', JSON.stringify(accessToken));

		localStorage.setItem('reload_token', response.data.refreshToken);

		return accessToken;

	}
	catch (err) {
		localStorage.clear();
	}
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshToken, {
	maxAge,
});