import {Col, Row} from "antd";
import _ from "lodash";
import {
	Icon,
	Button,
	StatusLabel
} from "~/components";
import {
	renderDate,
	numberFormat,
} from "~/utils";
import {
	useCan,
	useUtilities
} from "~/hooks";

function ContractFormInfo({item, modalOpen, onClickStatus}) {

	const {utilities} = useUtilities();

	const can = {
		edit: useCan('contractEdit'),
		transfer: useCan('contractTransfer'),
		delete: useCan('contractDelete'),
		status: useCan('contractStatus'),
	};

	const careerOptions = {
		'fashion' : { value: 'fashion', label: 'Thời trang'},
		'cosmetics' : { value: 'cosmetics', label: 'Mỹ phẩm'},
		'technology' : { value: 'technology', label: 'Công nghệ'},
		'phoneLaptop' : { value: 'phoneLaptop', label: 'Điện thoại - Laptop'},
		'MotorcycleCar' : { value: 'MotorcycleCar', label: 'Xe máy - ô tô'},
		'mechanical' : { value: 'mechanical', label: 'Cơ khí'},
		'food' : { value: 'food', label: 'Thực phẩm'},
		'freshFood' : { value: 'freshFood', label: 'Thực phẩm tươi sóng'},
		'functionalFood' : { value: 'functionalFood', label: 'Thực phẩm chức năng'},
		'milkTeaDrink' : { value: 'milkTeaDrink', label: 'Trà sữa - thức uống'},
		'interior' : { value: 'interior', label: 'Nội thất'},
		'householdAppliances' : { value: 'householdAppliances', label: 'Đồ gia dụng'},
		'build' : { value: 'build', label: 'Xây dựng'},
		'refrigeration' : { value: 'refrigeration', label: 'Điện lạnh'},
		'electricity' : { value: 'electricity', label: 'Điện máy'},
		'electricalEquipment' : { value: 'electricalEquipment', label: 'Thiết bị điện'},
		'sanitaryEquipment' : { value: 'sanitaryEquipment', label: 'Thiết bị vệ sinh'},
		'realEstate' : { value: 'realEstate', label: 'Bất động sản'},
		'travel' : { value: 'travel', label: 'Du lịch'},
		'hotelRestaurant' : { value: 'hotelRestaurant', label: 'Khách sạn - Nhà Hàng'},
		'transportation' : { value: 'transportation', label: 'Vận tải'},
		'beautySpa' : { value: 'beautySpa', label: 'Spa làm đẹp'},
		'studio' : { value: 'studio', label: 'Studio'},
		'motherBaby' : { value: 'motherBaby', label: 'Mẹ & bé'},
		'education' : { value: 'education', label: 'Giáo dục'},
		'stationery' : { value: 'stationery', label: 'Văn phòng phẩm'},
		'sport' : { value: 'sport', label: 'Thể thao'},
		'eventOrganization' : { value: 'eventOrganization', label: 'Tổ chức sự kiện'},
		'medical' : { value: 'medical', label: 'Y tế'},
		'medicinalHerbs' : { value: 'medicinalHerbs', label: 'Dược liệu'},
	};

	const renderServiceOption = (service) => {
		if(service == 'host') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={6}>Dung lượng</Col>
					<Col className="gutter-row" span={18}>{utilities.services[service].options[item.option].label}</Col>
				</Row>
			)
		}
		else if(service == 'ssl') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={6}>Gói chứng chỉ</Col>
					<Col className="gutter-row" span={18}>{utilities.services[service].options[item.option].label}</Col>
				</Row>
			)
		}
		else if(service == 'email') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={6}>Gói email server</Col>
					<Col className="gutter-row" span={18}>{utilities.services[service].options[item.option].label}</Col>
				</Row>
			)
		}
	}

	const handleStatusClick = (status) => {
		if(!onClickStatus) return;
		onClickStatus(status);
	}

	const handleEditClick = () => {
		modalOpen('addEdit');
	}

	return (
		<div className="form">
			<p className="heading">Thông tin</p>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Khách hàng</Col>
				<Col className="gutter-row" span={18}>
					{`${item.customer.code} ${item.customer.name} ${item.customer.companyName}`}
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tên HĐ</Col>
				<Col className="gutter-row" span={18}>
					{`${item.name}`}
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tên miền</Col>
				<Col className="gutter-row" span={18}>
					{`${item.domain}`}
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Giá trị HĐ</Col>
				<Col className="gutter-row" span={18}>
					{`${numberFormat(item.price)}`} đ
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Giá trị chưa thu</Col>
				<Col className="gutter-row" span={18}>
					{`${numberFormat(item.amountRemaining)}`} đ
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Ngành nghề</Col>
				<Col className="gutter-row" span={18}>
					{(!_.isEmpty(item.career)) && `${careerOptions[item.career].label}`}
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Ngày ký</Col>
				<Col className="gutter-row" span={18}>
					{`${renderDate(item.signing)}`}
				</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Ngày hết hạn</Col>
				<Col className="gutter-row" span={18}>
					{`${renderDate(item.expired)}`}
				</Col>
			</Row>

			<p className="heading mt-2">Dịch vụ</p>

			<Row className="mb-1"  gutter={10}>
				<Col className="gutter-row" span={6}>Dịch vụ</Col>
				<Col className="gutter-row" span={18}>
					<StatusLabel type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
				</Col>
			</Row>
			<Row className="mb-1"  gutter={10}>
				<Col className="gutter-row" span={6}>Loại dịch vụ</Col>
				<Col className="gutter-row" span={18}>
					<StatusLabel type={utilities.services[item.service].color}>{utilities.serviceTypes[item.serviceType].label}</StatusLabel>
				</Col>
			</Row>

			{renderServiceOption(item.service)}
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				{ can.edit && <Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Cập nhật</Button>}
				{
					(can.status && item.status == 'pending') && (<Button onClick={() => handleStatusClick('confirm')} background green leftIcon={Icon.success}>Duyệt hợp đồng</Button>)
				}
				{
					(can.status && item.status == 'confirm') && (<Button onClick={() => handleStatusClick('pending')} background white leftIcon={Icon.arrowLeft}>Bỏ duyệt hợp đồng</Button>)
				}
				{
					(can.status && item.status != 'cancel') && (<Button onClick={() => handleStatusClick('cancel')} background red leftIcon={Icon.close}>Hủy hợp đồng</Button>)
				}
			</div>

		</div>
	)
}

export default ContractFormInfo;