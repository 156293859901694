import style from "../../style/SaleReport.module.scss";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import className from "classnames/bind";
import {Col, Row, Tabs} from "antd";
import {ceil} from "lodash";
import {
	numberFormat
} from "~/utils";
import {
	StatusLabel
} from "~/components";
import {
	InputField,
	DateField,
	FileUpload,
	FileList,
	TextAreaField
} from "~/components/Forms";
import SaleReportEvent from "./ReportEvent";
import SaleReportConsultation from "./ReportConsultation";
import {
	Button,
	Loading
} from "~/components";
const cn = className.bind(style);
function SaleReportFormAdd({reportData, onTimeChange, onHandleSubmit, files, setFiles, onUpload, onRemoveFile}) {

	const initialValues = {
		time: reportData.time*1000,
		call: reportData.working.call,
		source: reportData.working.source,
		resultCall: '0 Hẹn; 0 Không Nhu Cầu; 0 Không Nghe Máy, 0 Bận; 0 Gửi Mail, Zalo; 0 Thuê bao; 0 Nghỉ KD; 0 Khác',
		note: reportData.note,
	}

	const validationSchema = Yup.object().shape({
		time: Yup.string().required('Bạn chưa chọn ngày bắt đầu nghỉ'),
		call: Yup.number().required('Bạn chưa điền số cuộc gọi'),
		source: Yup.number().required('Bạn chưa điền số nguồn tìm được'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const handleRemoveFileClick = (filename) => {
		setFiles(files.filter(file => file.name !== filename))
		onRemoveFile(filename);
	}

	const handleUpload = (data) => {
		data.time = getValues("time");
		onUpload(data)
	}

	const renderPercent = (number, total) => {
		const percent = (total == 0) ? 0 : ceil((number/total)*100);
		return <StatusLabel small type={(percent > 50) ? 'green' : 'red'}>{percent}%</StatusLabel>
	}

	const ReportInfo = () => {
		return (
			<>
				<div className={cn('report-detail')}>
					<h3 className="heading text-lg">Cam kết</h3>
					<Row gutter={10} className="mb-2">
						<Col className="gutter-row" span={8}>Cam kết tháng</Col>
						<Col className="gutter-row" span={4}>{numberFormat(reportData.mouth)}</Col>
						<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.revenue)}</Col>
						<Col className="gutter-row" span={4}>{renderPercent(reportData.revenue, reportData.mouth)}</Col>
					</Row>
					<Row gutter={10} className="mb-2">
						<Col className="gutter-row" span={8}>
							<p>Cam 3 ngày trước</p>
							<p style={{fontSize:'12px', color:'#ccc'}}>{reportData.threeDayLast.date}</p>
						</Col>
						<Col className="gutter-row" span={4}>{numberFormat(reportData.threeDayLast.price)}</Col>
						<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.threeDayLast.result)}</Col>
						<Col className="gutter-row" span={4}>{renderPercent(reportData.threeDayLast.result, reportData.threeDayLast.price)}</Col>
					</Row>
					<Row gutter={10} className="mb-2">
						<Col className="gutter-row" span={8}>
							<p>Cam 3 ngày tiếp</p>
							<p style={{fontSize:'12px', color:'#ccc'}}>{reportData.threeDayNow.date}</p>
						</Col>
						<Col className="gutter-row" span={4}>{numberFormat(reportData.threeDayNow.price)}</Col>
						<Col className="gutter-row" span={4}>Đạt {numberFormat(reportData.threeDayNow.result)}</Col>
						<Col className="gutter-row" span={4}>{renderPercent(reportData.threeDayNow.result, reportData.threeDayNow.price)}</Col>
					</Row>

					<h3 className="heading text-lg">Kết quả gọi điện</h3>

					<Row gutter={10} className="mb-2">
						<Col className="gutter-row" span={4}>
							<Controller control={control} name="source" render={({ field }) => (
								<InputField label="Tìm nguồn" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={4}>
							<Controller control={control} name="call" render={({ field }) => (
								<InputField label="Gọi điện" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={16}>
							<Controller control={control} name="resultCall" render={({ field }) => (
								<InputField label="Chi tiết" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>

					<h3 className="heading mb-1 text-lg">Lịch hẹn ({reportData.events.total})</h3>
					{
						Object.keys(reportData.events.data).map((itemKey) => {
							let item = reportData.events.data[itemKey];
							return <SaleReportEvent key={`SaleReportEvent${item.id}`} item={item} />
						})
					}

					<h3 className="heading mt-2 text-lg">Tư vấn ({reportData.consultation.total})</h3>
					{
						Object.keys(reportData.consultation.data).map((itemKey) => {
							let item = reportData.consultation.data[itemKey];
							return <SaleReportConsultation key={`SaleReportEvent${item.id}`} item={item} />
						})
					}

					<Controller control={control} name="note" render={({ field }) => (
						<TextAreaField label="Bổ sung báo cáo" rows={5} errors={errors} {...field} />
					)}/>
					<hr />
					<div className="form-group d-flex justify-content-end">
						<Button primary type="submit">Báo cáo</Button>
					</div>
				</div>
			</>
		)
	}

	const ReportAttach = () => {
		return (
			<>
				<FileUpload name="file" files={files} setFiles={setFiles} onChange={(data) => handleUpload(data)} />
				<FileList files={files} removeFile={handleRemoveFileClick} />
			</>
		)
	}

	const listTabs = [
		{
			key: 'reportInfo',
			label: `Thông tin`,
			children: <ReportInfo />,
		},
		{
			key: 'reportAttach',
			label: 'Đính kèm',
			children: <ReportAttach />,
		},
	];

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="time" render={({ field }) => (
				<DateField label="Ngày báo cáo" errors={errors} {...field} onChange={(value, event) => {
					onTimeChange(value);
					field.onChange(value, event);
				}}/>
			)}/>
			<Tabs defaultActiveKey="1" items={listTabs}/>
		</form>
	)
}
export default SaleReportFormAdd;