import style from './DefaultLayout.module.scss';
import className from 'classnames/bind';
import {useContext, useState} from "react";
import Header from '~/layout/Header'
import NavMobile from "../NavBar/NavMobile";
import {
	useDevice
} from "~/hooks";
import {
	apiError,
	handleRequest
} from "~/utils";
import BrowserPermission from "../BrowserPermission/BrowserPermission";
import {AppContext} from "~/context/AppProvider";
import {notificationApi} from "~/api";
import SalesAddAll from "../../components/SalesAddAll/SalesAddAll";

const cn = className.bind(style);

function DefaultLayout({children}) {

	const [notification, setNotification] = useState({
		loading	: true,
		items	:[],
		open	: false,
	});

	const [notificationMessage, setNotificationMessage] = useState({
		loading	: true,
		items	:[],
		open	: false,
	});

	const {isMobile} = useDevice();

	const {
		notificationCount,
		setNotificationCount,
	} = useContext(AppContext);

	const handleNotificationMessageOpen = async () => {
		setNotificationMessage((prevState) => ({
			...prevState,
			open: true,
		}))
		if(notificationMessage.loading) {
			const [error, response] = await handleRequest(notificationApi.message());
			let message = apiError(`Load data thông báo tin nhắn thất bại`, error, response);
			if(!message) {
				setNotificationMessage((prevState) => ({
					...prevState,
					items: response.data,
				}))
				if(notificationCount.comment != 0) {
					await handleRequest(notificationApi.messageRead());
					setNotificationCount((prevState) => ({
						...prevState,
						comment: 0,
					}))
				}
			}
			setNotificationMessage((prevState) => ({
				...prevState,
				loading: false,
			}))
		}
	};

	const handleNotificationMessageItemClick = async (item) => {
		let [error, response] = await handleRequest(notificationApi.messageOpen({id: item.id}));
		let message = apiError(`Cập nhật thông báo thất bại`, error, response);
		if (!message) {
			let objIndex = notificationMessage.items.findIndex((mess => mess.id == item.id));
			notificationMessage.items[objIndex].isOpen = 1;
			setNotificationMessage((prevState) => ({
				...prevState,
				items: notificationMessage.items,
			}))
			handleNotificationMessageClose();
		}
	}

	const handleNotificationMessageClose = () => {
		setNotificationMessage((prevState) => ({
			...prevState,
			open: false,
		}))
	}

	const handleNotificationOpen = async () => {
		setNotification((prevState) => ({
			...prevState,
			open: true,
		}))
		if(notification.loading) {
			const [error, response] = await handleRequest(notificationApi.gets());
			let message = apiError(`Load data thông báo tin nhắn thất bại`, error, response);
			if(!message) {
				setNotification((prevState) => ({
					...prevState,
					items: response.data,
				}))
				if(notificationCount.notification != 0) {
					await handleRequest(notificationApi.read());
					setNotificationCount((prevState) => ({
						...prevState,
						notification: 0,
					}))
				}
			}
			setNotification((prevState) => ({
				...prevState,
				loading: false,
			}))
		}
	};

	const handleNotificationItemClick = async (item) => {
		let [error, response] = await handleRequest(notificationApi.open({id: item.id}));
		let message = apiError(`Cập nhật thông báo thất bại`, error, response);
		if (!message) {
			let objIndex = notification.items.findIndex((mess => mess.id == item.id));
			notification.items[objIndex].isOpen = 1;
			setNotification((prevState) => ({
				...prevState,
				items: notification.items,
			}))
			handleNotificationClose();
		}
	}

	const handleNotificationClose = () => {
		setNotification((prevState) => ({
			...prevState,
			open: false,
		}))
	}

	return (
		<div className={cn('wrapper')}>
			<div className={cn('wrapper-inner')}>
				<Header
					notification={notification}
					notificationMessage={notificationMessage}
					onClickNotificationMessageOpen={handleNotificationMessageOpen}
					onClickNotificationMessageClose={handleNotificationMessageClose}
					onClickNotificationMessageItem={handleNotificationMessageItemClick}
					onClickNotificationOpen={handleNotificationOpen}
					onClickNotificationClose={handleNotificationClose}
					onClickNotificationItem={handleNotificationItemClick} />
				<main>
					{children}
					<BrowserPermission />
					<SalesAddAll />
				</main>
				{isMobile && <NavMobile onClickNotificationOpen={handleNotificationOpen} />}
			</div>
		</div>
	);
}

export default DefaultLayout;