import {useCallback, useMemo, useState} from "react";
import StatusLabel from "~/components/StatusLabel";
import {InputField, SelectField, SelectUserAll} from "~/components/Forms";
import {LIST_PAPER_NUMBER_STATUS} from "../../../../app/constants";

function PapersNumberSearchBar({listGroup, filter, onSearchChange, onChange}) {

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_PAPER_NUMBER_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_PAPER_NUMBER_STATUS]);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả loại đơn' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('type', value);
	}, [onChange]);

	const handleStatusChange = useCallback((value) => {
		if(onChange) onChange('status', value);
	}, [onChange]);

	const handleUserChange = useCallback((value) => {
		if(onChange) onChange('userId', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField name="keyword" value={filter.keyword} placeholder="Mã giấy tờ" onChange={handleKeywordChange} />
					<SelectUserAll name="userId" value={filter.userId} placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />
					<SelectField name="type" value={filter.type} placeholder="Chọn loại giấy tờ" options={groupOptions} onChange={(value, event) => handleGroupChange(value)} />
					<SelectField name="status" value={filter.status} placeholder="Trạng thái" options={statusOptions} onChange={(value, event) => handleStatusChange(value)}></SelectField>
				</form>
			</div>
		</div>
	)
}

export default PapersNumberSearchBar;