import {memo, useContext} from "react";
import {Pagination, Table} from "antd";
import ExtendAction from "./ExtendAction";
import {StatusLabel} from "~/components";
import {numberFormat, renderDate} from "~/utils";
import {useUtilities} from "~/hooks";
import {AppContext} from "~/context/AppProvider";

function ExtendTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {
	const {tableHeight} = useContext(AppContext);
	const {utilities} = useUtilities();
	const renderService = (services) => {
		return Object.keys(services).map((itemKey) => {
			let service = services[itemKey];
			return (
				<div key={`ServiceColumn-${itemKey}`} className="mb-1 d-flex justify-content-between">
					<div className="service">
						<StatusLabel small type={utilities.services[service.service].color}>{utilities.services[service.service].label} {service.domain}</StatusLabel>
						&nbsp;{service.option.length != 0 && <StatusLabel small type={utilities.services[service.service].color}>{utilities.services[service.service].options[service.option].label}</StatusLabel>}
					</div>
				</div>
			)
		})
	}

	const renderStatus = (status) => {
		if(status == 'pending') return <StatusLabel small type="yellow">Đợi duyệt</StatusLabel>
		if(status == 'pending') return <StatusLabel small type="blue">Đợi gửi</StatusLabel>
		if(status == 'success') return <StatusLabel small type="green">Đã gửi</StatusLabel>
		if(status == 'error') return <StatusLabel small type="red">Gửi thất bại</StatusLabel>
	}

	const renderSend = (send) => {
		return (send == 0) ? <StatusLabel small type="red">Chưa gửi</StatusLabel> :renderTime(send)
	}

	const renderTime = (time) => {
		let day = new Date(time*1000);
		return renderDate(time)+' '+("0" + (day.getHours())).slice(-2)+':'+("0" + (day.getMinutes())).slice(-2);
	}

	const columns = [
		{ title: 'Khách hàng', dataIndex: 'name', key: "name", width: '200px', render: (_, item) => (
			<>
				<b style={{color : 'var(--primary)'}}>{item.customer.companyName}</b>
				<p><b>{item.customer.name}</b></p>
				<p>{item.email}</p>
				<p>{item.phone}</p>
			</>
		)},
		{ title: 'Dịch vụ', dataIndex: 'services', key: "services", width: '200px', render: (_, item) => (renderService(item?.services))},
		{ title: 'Ngày hết hạn', dataIndex: 'expired', key: "expired", width: '100px', render: (_, item) => (<>{renderDate(item?.expired)}</>)},
		{ title: 'Email tự động', dataIndex: 'emailStatus', key: "emailStatus", width: '100px', render: (_, item) => (
			<>
				<p>{renderStatus(item?.emailStatus)}</p>
				<p>{renderTime(item?.emailTime)}</p>
			</>
		)},
		{ title: 'Gửi lần cuối', dataIndex: 'emailTime', key: "emailTime", width: '100px', render: (_, item) => (renderSend(item?.emailSend))},
		{ title: 'Zalo tự động', dataIndex: 'zaloStatus', key: "zaloStatus", width: '100px', render: (_, item) => (
			<>
				<p>{renderStatus(item?.zaloStatus)}</p>
				<p>{renderTime(item?.zaloTime)}</p>
			</>
		)},
		{ title: 'Gửi lần cuối', dataIndex: 'zaloTime', key: "zaloTime", width: '100px', render: (_, item) => (renderSend(item?.zaloSend))},
		{ title: 'Tổng tiền', dataIndex: 'total', key: "total", width: '100px', render: (_, item) => (numberFormat(item?.total))},
		{ title: '#', dataIndex: 'action', key: "action", width: '50px', render: (_, item) => (
			<ExtendAction
				key={item.id}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
			/>
		)},
	];
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(ExtendTable);