import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {buyServiceActions} from "./buyServiceSlice";
import {buyServiceApi} from "~/api";

function* fetchBuyServiceList(action) {
	try {
		const response = yield call(buyServiceApi.gets, action.payload);
		yield put(buyServiceActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch buyService list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(buyServiceActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(buyServiceActions.setFilter(action.payload));
}

export default function* buyServiceSaga() {
	yield takeLatest(buyServiceActions.fetchData.type, fetchBuyServiceList);
	yield debounce(500, buyServiceActions.setFilterWithDebounce.type, handleSearchDebounce)
}