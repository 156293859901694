import {useState} from "react";
import Tippy from '@tippyjs/react/headless';
import {
	Button,
	Icon,
	PopperWrapper
} from "~/components";

function ContractCustomerAction({item, setItemEdit, openModal, onChangeFilter}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const buttonHandlers = {
		info: () => {
			setItemEdit(item);
			openModal('infoCustomer');
			setShowActionBox(false);
		},
		search: () => {
			if (!onChangeFilter) return;
			onChangeFilter('customerId', item.customerId, true);
			setShowActionBox(false);
		},
	};

	const actionButtons = [
		<Button key="revenue" leftIcon={Icon.info} onClick={buttonHandlers.info}>Thông tin</Button>,
		<Button key="edit" leftIcon={Icon.edit} onClick={buttonHandlers.search}>Hợp đồng liên quan</Button>,
	];

	return (
		<Tippy
			interactive
			visible={showActionBox}
			onClickOutside={() => setShowActionBox(false)}
			render={() => (
				<PopperWrapper className="action-wrapper" style={{ minWidth: '220px', minHeight: 'auto' }}>
					{actionButtons}
				</PopperWrapper>
			)}
			placement="right"
			offset={[0, -10]}
		>
			<Button onClick={() => setShowActionBox(!showActionBox)}><b style={{color : 'var(--primary)'}}>KH{item.customerId}</b></Button>
		</Tippy>
	)
}

export default ContractCustomerAction;