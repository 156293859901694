import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	TextAreaField,
	DateField
} from "~/components/Forms";

function ServiceFormTemporary({item, onHandleSubmit}) {

	const {control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: {
			time: new Date(),
			note: undefined,
		},
		resolver: yupResolver(Yup.object().shape({}))
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="time" render={({field}) => (
				<DateField label="Mở đến" errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="note" render={({field}) => (
				<TextAreaField label="Lý do mở" errors={errors} {...field}/>
			)}/>
			<div className="form-group d-flex justify-content-end mt-2 modal-bottom">
				<Button primary type="submit">Tạo yêu cầu</Button>
			</div>
		</form>
	)
}

export default ServiceFormTemporary;