import {useMemo, useContext} from "react";
import {AppContext} from "~/context/AppProvider";
import {Col, Row} from "antd";
import {
	Icon,
	Button,
} from "~/components";
import {
	renderDate,
} from "~/utils";
import {useCan} from "~/hooks";

function CustomerFormInfo({item, onClickStatus}) {

	const {country} = useContext(AppContext);

	const can = {
		status: useCan('customerStatus'),
	};

	const companyAddress = useMemo(() => {
		if(item.for == 'canhan') return '';
		let province = '';
		let district = '';
		let ward = '';
		if(item.companyProvince == '') return '';
		if(country.city[item.companyProvince] != undefined) {
			province = country.city[item.companyProvince];
		}
		if(province == '' || item.companyDistrict == '') return province;

		if(country.district[item.companyDistrict] != undefined && country.district[item.province][item.companyDistrict] != undefined) {
			district = country.district[item.companyProvince][item.companyDistrict];
		}
		if(district == '') return province;
		if(item.companyWard == '') return district+', '+province;
		if(country.ward[item.companyDistrict] != undefined && country.ward[item.companyDistrict][item.companyWard] != undefined) {
			ward = country.ward[item.companyDistrict][item.companyWard];
		}
		if(ward == '') return district+', '+province;
		return ward+', '+district+', '+province;
	}, [
		item.for,
		item.companyProvince,
		item.companyDistrict,
		item.companyWard
	]);

	const address = useMemo(() => {
		let province = '';
		let district = '';
		let ward = '';
		if(item.province == '') return '';
		if(country.city[item.province] != undefined) {
			province = country.city[item.province];
		}
		if(province == '' || item.district == '') return province;

		if(country.district[item.province] != undefined && country.district[item.province][item.district] != undefined) {
			district = country.district[item.province][item.district];
		}
		if(district == '') return province;
		if(item.ward == '') return district+', '+province;
		if(country.ward[item.district] != undefined && country.ward[item.district][item.ward] != undefined) {
			ward = country.ward[item.district][item.ward];
		}
		if(ward == '') return district+', '+province;
		return ward+', '+district+', '+province;
	}, [
		item.province,
		item.district,
		item.ward,
	]);

	const renderCompanyInfo = (item, companyAddress) => {
		return (
			<>
				<p className="heading">Thông tin công ty</p>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Tên công ty</Col>
					<Col className="gutter-row" span={18}>{item.companyName}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Mã số thuế</Col>
					<Col className="gutter-row" span={18}>{item.taxCode}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Địa chỉ</Col>
					<Col className="gutter-row" span={18}>{`${item.companyAddress}, ${companyAddress}`}</Col>
				</Row>
			</>
		)
	}
	const renderCustomerInfo = (item, address) => {
		return (
			<>
				<p className="heading">Thông tin khách hàng</p>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Tên khách hàng</Col>
					<Col className="gutter-row" span={18}>{item.name}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Số CCCD/CMND</Col>
					<Col className="gutter-row" span={18}>{item.numberId}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Email</Col>
					<Col className="gutter-row" span={18}>{item.email}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Phone</Col>
					<Col className="gutter-row" span={18}>{item.phone}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Địa chỉ</Col>
					<Col className="gutter-row" span={18}>{`${item.address}, ${address}`}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Sinh nhật</Col>
					<Col className="gutter-row" span={18}>{renderDate(item.birthday)}</Col>
				</Row>
				<Row className="mb-2" gutter={10}>
					<Col className="gutter-row" span={6}>Giới tính</Col>
					<Col className="gutter-row" span={18}>{item.gender}</Col>
				</Row>
			</>
		)
	}
	const handleStatusClick = (item) => {
		if(!onClickStatus) return;
		onClickStatus(item);
	}

	return (
		<div className="form">
			{item.for == 'congty' && renderCompanyInfo(item, companyAddress)}
			{renderCustomerInfo(item, address)}
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				{
					(can.status && item.status == 'pending') && (<Button onClick={() => handleStatusClick(item)} background green leftIcon={Icon.success}>Duyệt khách hàng</Button>)
				}
				{
					(can.status && item.status == 'confirm') && (<Button onClick={() => handleStatusClick(item)} background white leftIcon={Icon.arrowLeft}>Bỏ duyệt khách hàng</Button>)
				}
			</div>
		</div>
	)
}

export default CustomerFormInfo;