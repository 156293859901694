import {useRef} from "react";
import {Tooltip} from "antd";
import {Button, Icon} from "~/components";
import {useCan} from "~/hooks";
import {PrintSalary} from "../index";
import ReactToPrint from "react-to-print";

function FormSalaryAction({item, onClickEdit, onClickInfo, onClickDelete}) {

	const printRef = useRef();

	const can = {
		edit: useCan('formSalaryEdit'),
		delete: useCan('formSalaryDelete'),
	}

	const handleEditClick = () => {
		if(!onClickEdit) return;
		onClickEdit(item);
	}

	const handleInfoClick = () => {
		if(!onClickInfo) return;
		onClickInfo(item);
	}

	const handleDeleteClick = () => {
		if(!onClickDelete) return;
		onClickDelete(item);
	}

	return (
		<div className="table-action d-flex">
			{can.edit && <Tooltip title="Edit thông tin"><Button background blue leftIcon={Icon.edit} onClick={handleEditClick}></Button></Tooltip>}
			<Tooltip title="Thông tin"><Button background blue leftIcon={Icon.info} onClick={handleInfoClick}></Button></Tooltip>
			{(item.status == 'allow') && <ReactToPrint key={`btn-print-${item.id}`}
			                                           trigger={() => <Button leftIcon={Icon.print}></Button>}
			                                           content={() => printRef.current}
			/>}
			{can.delete && <Tooltip title="Xóa"><Button background red leftIcon={Icon.delete} onClick={handleDeleteClick}></Button></Tooltip>}
			{(item.status == 'allow') &&<PrintSalary key={`salary-print-${item.id}`} item={item} ref={printRef} />}
		</div>
	)
}

export default FormSalaryAction;