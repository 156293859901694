import {forwardRef} from "react";
import style from "./Print.module.scss";
import className from "classnames/bind";
import {numberFormat, numberToWords} from "~/utils";
const cn = className.bind(style);
export const PrintPayments = forwardRef(({ item, ...props }, ref) => {

	const printStyle = {
		p1 : {
			textAlign : 'center',
			margin : '0',
			fontSize: '14px'
		},
		p2 : {
			marginTop : '2px',
			fontSize: '14px'
		},
		p3 : {
			marginTop : '2px',
			marginBottom : '0px',
			fontSize: '14px'
		}
	}

	const today = new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	const yyyy = today.getFullYear();

	return (
		<div ref={ref} className={cn('declaration-domain-international')}>
			<p className={cn('title-mini')}><b>Công Ty TNHH Truyền Thông Và Quảng Cáo Siêu Kinh Doanh</b></p>
			<p className={cn('title-mini')}>36 Đường D5, Phường 25, Quận Bình Thạnh, TP.HCM</p>
			<p className={cn('title-mini')}><b>ĐT: 028 6294 1556</b></p>
			<h1>PHIẾU ĐỀ NGHỊ THANH TOÁN</h1>
			<p style={printStyle.p1}><i>Ngày {dd} tháng {mm} năm {yyyy}</i></p>
			<br />
			<p>Người đề nghị: {item.user.firstname + ' ' + item.user.lastname}</p>
			<p>Phương thức thanh toán : Chuyển khoản</p>
			<p>Lý do thanh toán : {item.note}</p>
			<p>Số tiền : {numberFormat(item.price)}</p>
			<p>Bằng chữ: {numberToWords(item.price*1)}</p>

			<p><b>Thông tin chuyển khoản hoặc tiền mặc</b></p>
			<p>Số tài khoản NH: {item.bankNumber}</p>
			<p>Tên người thụ hưởng: {item.bankName}</p>
			<p>Ngân hàng: {item.bank}</p>
			<p>Chi nhánh: {item.bankBranch}</p>

			<table>
				<tr>
					<td style={{border:0, width: '60%'}}></td>
					<td style={{border:0}}>
						<p className="text-center"><i>Hồ Chí Minh, ngày {dd} tháng {mm} năm {yyyy}.</i></p>
						<p className="text-center"><b>Xác nhận của người lập đơn</b></p>
						<p className="text-center"><i>(Ký và ghi rõ họ tên)</i></p>
					</td>
				</tr>
			</table>
		</div>
	);
});
export default PrintPayments;