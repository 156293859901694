import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	InputField,
	SelectField,
	DateField,
	TextAreaField
} from "~/components/Forms";

function SaleEventFormCopy({item, onHandleSubmit}) {

	const typeOptions = [
		{value : 'new', label : 'Khách mới'},
		{value : 'old', label : 'Khách củ'},
	];

	const type2Options = [
		{value : 'tuvan', label : 'Tư vấn'},
		{value : 'bangiao', label : 'Bàn giao'},
	];

	const timeOptions = [
		{value : '07:00', label : '07:00 sáng'},
		{value : '07:30', label : '07:30 sáng'},
		{value : '08:00', label : '08:00 sáng'},
		{value : '08:30', label : '08:30 sáng'},
		{value : '09:00', label : '09:00 sáng'},
		{value : '09:30', label : '09:30 sáng'},
		{value : '10:00', label : '10:00 sáng'},
		{value : '10:30', label : '10:30 sáng'},
		{value : '11:00', label : '11:00 sáng'},
		{value : '11:30', label : '11:30 sáng'},
		{value : '12:00', label : '12:00 trưa'},
		{value : '12:30', label : '12:30 trưa'},
		{value : '13:00', label : '01:00 chiều'},
		{value : '13:30', label : '01:30 chiều'},
		{value : '14:00', label : '02:00 chiều'},
		{value : '14:30', label : '02:30 chiều'},
		{value : '15:00', label : '03:00 chiều'},
		{value : '15:30', label : '03:30 chiều'},
		{value : '16:00', label : '04:00 chiều'},
		{value : '16:30', label : '04:30 chiều'},
		{value : '17:00', label : '05:00 chiều'},
		{value : '17:30', label : '05:30 chiều'},
		{value : '18:00', label : '06:00 chiều'},
		{value : '18:30', label : '06:30 chiều'},
		{value : '19:00', label : '07:00 chiều'},
		{value : '19:30', label : '07:30 chiều'},
		{value : '20:00', label : '08:00 chiều'},
		{value : '20:30', label : '08:30 chiều'},
		{value : '21:00', label : '09:00 chiều'},
		{value : '21:30', label : '09:30 chiều'},
	];

	const initialValues = {
		name:'',
		type: 'new',
		type2: 'tuvan',
		day: new Date(),
		time: '',
		phone: '',
		address: '',
		note:'',
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Bạn chưa điền tên khách hàng'),
		type: Yup.string().required('Bạn chưa chọn loại khách'),
		type2: Yup.string().required('Bạn chưa chọn loại tư vấn'),
		day: Yup.string().required('Bạn chưa chọn ngày hẹn'),
		phone: Yup.string().required('Bạn chưa điền số điện thoại khách'),
		address: Yup.string().required('Bạn chưa điền địa chỉ khách'),
		time: Yup.string().required('Bạn chưa chọn thời gian hẹn'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item.name;
			initialValues.time = item.hours;
			initialValues.type = item.type;
			initialValues.type2 = item.type2;
			initialValues.note = item.note;
			initialValues.phone = item.phone;
			initialValues.address = item.address;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin</p>
			<Controller control={control} name="name" render={({field}) => (
				<InputField label="Tên (khách hàng / công ty)" errors={errors} {...field}/>
			)}/>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phone" render={({field}) => (
						<InputField label="Số điện thoại" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="address" render={({ field }) => (
						<InputField label="Địa chỉ" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="type" render={({field}) => (
						<SelectField label="Loại khách" options={typeOptions} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="type2" render={({ field }) => (
						<SelectField label="Loại tư vấn" options={type2Options} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="day" render={({field}) => (
						<DateField label="Ngày hẹn" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="time" render={({ field }) => (
						<SelectField label="Giờ" options={timeOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<Controller control={control} name="note" render={({ field }) => (
				<TextAreaField label="Ghi chú" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Dời lịch hẹn</Button>
			</div>
		</form>
	)
}

export default SaleEventFormCopy;