import {useContext, useEffect, useState} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AppContext} from "~/context/AppProvider";
import * as Yup from "yup";
import { Col, Row } from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	InputField,
	GroupRadioField,
	SelectUserAll,
	SelectField
} from "~/components/Forms";

function CustomerFormAddEdit({item, onHandleSubmit}) {

	const isEdit = Boolean(item?.id);

	const {country} = useContext(AppContext);

	let cityOptions = [];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const [forValue, setForValue] = useState((isEdit) ? item.for : 'canhan');

	const forOptions = [
		{ value: 'canhan', label: 'Cá Nhân'},
		{ value: 'congty', label: 'Công Ty'},
	];

	const initialValues = {
		for: 'canhan',
		companyName: '',
		taxCode: '',
		name: undefined,
		numberId: undefined,
		email: undefined,
		phone: undefined,
		address: undefined,
		userShareId: undefined,
		...item
	}

	const validationSchema = Yup.object().shape({
		for: Yup.string().nullable().required('Bạn chưa chọn loại khách hàng'),
		companyName: Yup.string().nullable().when('for', {
			is: 'congty',
			then: Yup.string().required('Tên công ty không được bỏ trống'),
		}),
		taxCode: Yup.string().nullable().when('for', {
			is: 'congty',
			then: Yup.string().required('Mã số thuế không được bỏ trống'),
		}),
		name: Yup.string().required('Họ tên khách hàng không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		city: Yup.string().required('Tỉnh thành không được để trống'),
		district: Yup.string().required('Quận huyện không được để trống'),
		ward: Yup.string().required('Phường xã không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	//Thay đổi loại khách hàng
	const forInput = useWatch({control, name: "for"});
	if(forInput != forValue) setForValue(forInput);

	//Thay đổi tỉnh thành
	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	useEffect(() => {
		if(item?.id) {
			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == item.city) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == item.district) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
		reset(initialValues);
	}, [item]);

	const renderCompanyInfo = (forValue) => {
		if((forValue == 'congty')) return (
			<>
				<p className="heading">Thông tin khách hàng</p>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="companyName" render={({ field }) => (
							<InputField label="Tên công ty" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="taxCode" render={({ field }) => (
							<InputField label="Mã số thuế" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			</>
		)
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Loại khách hàng</p>
			<Controller control={control} name="for" render={({ field }) => (
				<GroupRadioField options={forOptions} errors={errors} {...field}/>
			)}/>
			{renderCompanyInfo(forValue)}
			<p className="heading">Thông tin khách hàng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="name" render={({field}) => (
						<InputField label="Họ tên khách hàng (Người đại diện)" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="numberId" render={({ field }) => (
						<InputField label="CCCD / CMND" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phone" render={({ field }) => (
						<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={24}>
					<Controller control={control} name="address" render={({ field }) => (
						<InputField label="Địa chỉ" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="city" render={({ field }) => (
						<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
							handleCityChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="district" render={({ field }) => (
						<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
							handleDistrictChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="ward" render={({ field }) => (
						<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Kinh doanh</p>
			<Controller control={control} name="userShareId" render={({ field }) => (
				<SelectUserAll label="Share với nhân viên" options={[]} errors={errors} {...field} />
			)}/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm khách hàng'}</Button>
			</div>
		</form>
	)
}

export default CustomerFormAddEdit;