import {useEffect, useState} from "react";
import {apiError, handleRequest} from "~/utils";
import {saleApi} from "~/api";
import ActionBar from "~/layout/ActionBar";
import {Col, Row, Skeleton} from "antd";
import {
	SalePointGroup,
	SalePointSearch
} from "../../components";
import _ from "lodash";

function SalePoint() {

	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState({});

	useEffect(() => {
		handleLoadData().then();
	}, [filter])

	const handleLoadData = async () => {

		setLoading(true);

		let [error, response] = await handleRequest(saleApi.reportPoint(filter));

		let message = apiError(`Load data không thành công`, error, response);

		if(!message) {
			setItems(response.data);
		}

		setLoading(false);
	}

	return (
		<>
			<ActionBar title={'Điểm Làm Việc'}></ActionBar>
			<Row gutter={10} style={{width:'100%'}}>
				<Col className="gutter-row" md={16} span={24} lg={18} style={{ overflow:'auto', maxHeight: '80vh'}}>
					<div className="content pd-1">
						<SalePointSearch filter={filter} setFilter={setFilter}/>
					</div>
					<div className="content pd-1">
						<Row gutter={10} style={{width:'100%'}}>
						{loading && <Skeleton />}
						{(!loading && !_.isEmpty(items)) &&
							Object.keys(items.groups).map((itemKey) => {
								let item = items.groups[itemKey];
								return <Col className="gutter-row" md={8} lg={8}>
									<SalePointGroup key={`SaleReportPointGroup${itemKey}`} item={item}/>
								</Col>
							})
						}
						</Row>
					</div>
				</Col>
				<Col className="gutter-row" md={8} span={24} lg={6} style={{ overflow:'auto', maxHeight: '80vh'}}>
					<div className="content pd-1">
						{loading && <Skeleton />}
						{(!loading && !_.isEmpty(items)) && <SalePointGroup item={items} />}
					</div>
				</Col>
			</Row>
		</>
	)
}

export default SalePoint;