import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	InputPriceField,
	GroupRadioField,
	SelectContractAll,
	DateField
} from "~/components/Forms";

function RevenueFormAddEdit({item, onHandleSubmit, can}) {

	const isEdit = Boolean(item?.id);

	const contractOptions = (isEdit && typeof item.contract?.id != "undefined") ? [{ label : `HD${item.contract.id} ${item.contract.name}`, value : item.contract.id }] : [];

	const paymentOptions = [
		{ value: 'cod', label: 'Tiền mặc'},
		{ value: 'bank', label: 'Chuyển khoản'},
		{ value: 'momo', label: 'Ví Momo'},
		{ value: 'swipe', label: 'Quẹt thẻ'},
		{ value: 'alePay', label: 'AlePay'},
	];

	const roomSales = [
		{ value: 0, label: 'Không tính cho phòng'},
		{ value: 1, label: 'Tính cho phòng'},
	];

	const initialValues = {
		contractId: '',
		time: new Date(),
		price: 0,
		payment: 'cod',
	}

	const validationSchema = Yup.object().shape({
		contractId: Yup.string().required('Bạn chưa chọn hợp đồng'),
		time: Yup.string().required('Bạn chưa chọn ngày thu'),
		price: Yup.number().required('Giá trị thu không được để trống'),
		payment: Yup.string().required('Bạn chưa chọn phương thức thanh toán'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.contractId = item.contractId;
			initialValues.time = item.time*1000;
			initialValues.price = item.price;
			initialValues.payment = item.payment;
			initialValues.roomSales = item.roomSales;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Liên quan hợp đồng</p>
			<Controller control={control} name="contractId" render={({ field }) => (
				<SelectContractAll label="Hợp đồng" options={contractOptions} errors={errors} {...field} />
			)}/>
			<p className="heading">Thông tin doanh thu</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="price" render={({field}) => (
						<InputPriceField label="Giá trị thu" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Ngày thu" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Thanh toán</p>
			{
				can.plus && <Controller control={control} name="roomSales" render={({ field }) => (
					<GroupRadioField label="Doanh số" options={roomSales} errors={errors} {...field}/>
				)}/>
			}

			<Controller control={control} name="payment" render={({ field }) => (
				<GroupRadioField label="Hình thức thanh toán" options={paymentOptions} errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} doanh thu</Button>
			</div>
		</form>
	)
}

export default RevenueFormAddEdit;