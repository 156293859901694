import {call, put, takeLatest} from "redux-saga/effects";
import {saleCommitActions} from "./saleCommitSlice";
import {saleApi} from "~/api";

function* fetchSaleCommitList(action) {
	try {
		const response = yield call(saleApi.commitGets, action.payload);
		yield put(saleCommitActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch saleCommit list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(saleCommitActions.fetchDataFailed(message));
	}
}

export default function* saleCommitSaga() {
	yield takeLatest(saleCommitActions.fetchData.type, fetchSaleCommitList);
}