import {useCurrentUser} from "~/hooks";
import {
    DashboardDefault,
    DashboardDesignManager,
    DashboardSales,
    DashboardSaleManager,
    DashboardSaleSupper,
    DashboardPersonnel,
    DashBoardTechnician
} from "../Dashboard";
function Home() {

    const user = useCurrentUser();
    
    return (
        <>
            {user?.role == 'sales' && <DashboardSales user={user} />}
            {user?.role == 'salesLeader' && <DashboardSales user={user} />}
            {user?.role == 'salesManager' && <DashboardSaleManager user={user} />}
            {user?.role == 'salesSenior' && <DashboardSaleManager user={user} />}
            {user?.role == 'salesSupper' && <DashboardSaleSupper user={user} />}

            {user?.role == 'personnel' && <DashboardPersonnel user={user} />}
            {user?.role == 'personnelManager' && <DashboardPersonnel user={user} />}

            {user?.role == 'technician' && <DashBoardTechnician user={user} />}
            {user?.role == 'technicianLeader' && <DashBoardTechnician user={user} />}
            {user?.role == 'technicianSystem' && <DashboardDefault user={user} />}
            {user?.role == 'chiefTechnician' && <DashBoardTechnician user={user} />}

            {user?.role == 'design' && <DashboardDesignManager user={user} />}
            {user?.role == 'designDeputy' && <DashboardDesignManager user={user} />}
            {user?.role == 'designManager' && <DashboardDesignManager user={user} />}

            {user?.role == 'accounting' && <DashboardDefault user={user} />}
            {user?.role == 'accountingManager' && <DashboardDefault user={user} />}

            {user?.role == 'customerService' && <DashboardDefault user={user} />}
            {user?.role == 'customerManager' && <DashboardDefault user={user} />}

            {user?.role == 'marketing' && <DashboardDefault user={user} />}
            {user?.role == 'marketingContent' && <DashboardDefault user={user} />}
            {user?.role == 'marketingTech' && <DashboardDefault user={user} />}
            {user?.role == 'marketingManager' && <DashboardDefault user={user} />}
            {user?.role == 'ceo' && <DashboardDefault user={user} />}
        </>
    )
}

export default Home;