import request from "~/utils/http";

const reportApi = {
	financial : async (params) => {
		const url = 'reports/financial';
		return await request.get(url, {params});
	},
	extend : async (params) => {
		const url = 'reports/extend';
		return await request.get(url, {params});
	},
	saleEvent : async (params) => {
		const url = 'reports/sale-event';
		return await request.get(url, {params});
	},
	point : async (params) => {
		const url = 'reports/point';
		return await request.get(url, {params});
	},
	website : async (params) => {
		const url = 'reports/website';
		return await request.get(url, {params});
	},
};

export default reportApi;