import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {SelectField, DateField} from "~/components/Forms";
import {Col} from "antd";

function PersonnelFormStatus({item, onHandleSubmit}) {

	const listStatus = [
		{value: '', label: 'Tất cả'},
		{value: 'internship', label: 'Thực tập'},
		{value: 'probation', label: 'Thử việc'},
		{value: 'public', label: 'Chính thức'},
		{value: 'trash', label: 'Đã nghỉ'},
	];

	const initialValues = {
		status: '',
		time: '',
	}

	const validationSchema = Yup.object().shape({
		status: Yup.string().required('Bạn chưa chọn loại trạng thái nhân viên'),
	})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.status = item.status;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Chức vụ</p>
			<Controller control={control} name="status" render={({field }) => (
				<SelectField label="Trạng thái" options={listStatus} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="time" render={({ field }) => (
				<DateField label="Ngày kết thúc" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="d-flex justify-content_end">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default PersonnelFormStatus;