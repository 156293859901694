import style from './CandidateTable.module.scss';
import className from 'classnames/bind';
import {useState} from "react";
import Tippy from '@tippyjs/react/headless';
import {
	Button, PopperWrapper, Icon
} from "~/components";
import {useCan} from "~/hooks";

const cn = className.bind(style);

function CandidateAction({item, openModal, setItemEdit}) {

	const can = {
		edit    : useCan('candidatesEdit'),
		addUser : useCan('personnelAdd'),
		delete  : useCan('candidatesDelete'),
	};

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const buttonHandlers = {
		addUser: () => {
			setItemEdit(item);
			openModal('user');
			closeActionButton();
		},
		edit: () => {
			setItemEdit(item);
			openModal('add');
			closeActionButton();
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
			closeActionButton();
		}
	};

	const renderActionButton = () => {
		return (
			<PopperWrapper className={cn('action-wrapper')} style={{ minWidth: '220px', minHeight: 'auto' }}>
				{can.addUser && <Button leftIcon={Icon.plusUser} onClick={buttonHandlers.addUser}> Nhận việc </Button>}
				{can.edit && <Button leftIcon={Icon.edit} onClick={buttonHandlers.edit}> Cập nhật </Button>}
				{can.delete && <Button outline red noneBorder leftIcon={Icon.delete} onClick={buttonHandlers.delete}> Xóa ứng viên </Button>}
			</PopperWrapper>
		)
	}

	return (
		<Tippy
			interactive
			visible={showActionBox}
			render={renderActionButton}
			onClickOutside={closeActionButton}
			placement="left"
			offset={[0, -10]}
		>
			<div onClick={() => setShowActionBox(true)} className="action-btn">
				{Icon.menuVertical}
			</div>
		</Tippy>
	)
}

export default CandidateAction;