import request from "~/utils/http";

const taskApi = {
	count : async (params) => {
		const url = 'tasks/count';
		return await request.get(url, {params});
	},
	gets : async (params) => {
		const url = 'tasks';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'tasks';
		return await request.post(url, params);
	},
	adds : async (params) => {
		const url = 'tasks';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `tasks/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `tasks/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `tasks/status/${params.id}`;
		return await request.post(url, params);
	},
	fileUpload : async (params) => {
		const url = `tasks/upload/${params.id}`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	fileRemove : async (params) => {
		const url = `tasks/upload/${params.id}`;
		return await request.patch(url, params);
	},
};

export default taskApi;