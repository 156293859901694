import request from "~/utils/http";

const saleApi = {
	eventGets : async (params) => {
		const url = 'sales/event';
		return await request.get(url, {params});
	},
	eventAdd : async (params) => {
		const url = 'sales/event';
		return await request.post(url, params);
	},
	eventUpdate : async (params) => {
		const url = `sales/event/${params.id}`;
		return await request.patch(url, params);
	},
	eventStart : async (params) => {
		const url = `sales/event/start/${params.id}`;
		return await request.post(url, params);
	},
	eventEnd : async (id) => {
		const url = `sales/event/end/${id}`;
		return await request.post(url);
	},
	eventAssign : async (params) => {
		const url = `sales/event/assign/${params.id}`;
		return await request.post(url, params);
	},
	eventReport : async (params) => {
		const url = `sales/event/report/${params.id}`;
		return await request.post(url, params);
	},
	eventDelete : async (id) => {
		const url = `sales/event/${id}`;
		return await request.delete(url);
	},
	eventCancel : async (id) => {
		const url = `sales/event/cancel/${id}`;
		return await request.delete(url);
	},
	workingGets : async (params) => {
		const url = 'sales/working';
		return await request.get(url, {params});
	},
	commitGets : async (params) => {
		const url = 'sales/commit';
		return await request.get(url, {params});
	},
	commitAdd : async (params) => {
		const url = 'sales/commit';
		return await request.post(url, params);
	},
	commitUpdate : async (params) => {
		const url = `sales/commit/${params.id}`;
		return await request.patch(url, params);
	},
	commitDelete : async (id) => {
		const url = `sales/commit/${id}`;
		return await request.delete(url);
	},
	kpi : async (params) => {
		const url = 'sales/kpi';
		return await request.get(url, {params});
	},
	addAll : async (params) => {
		const url = 'sales/add-all';
		return await request.post(url, params);
	},
	reportGets : async (params) => {
		const url = 'sales/report';
		return await request.get(url, {params});
	},
	reportGet : async (params) => {
		const url = `sales/report/${params.id}`;
		return await request.get(url, {params});
	},
	reportData : async (params) => {
		const url = `sales/report/data`;
		return await request.get(url,{params});
	},
	reportAdd : async (params) => {
		const url = `sales/report`;
		return await request.post(url,params);
	},
	reportFileUpload : async (params) => {
		const url = `sales/report/upload`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	reportFileRemove : async (params) => {
		const url = `sales/report/upload/delete`;
		return await request.post(url, params);
	},
	reportAnalytic : async (params) => {
		const url = 'sales/report/analytic';
		return await request.get(url, {params});
	},
	reportPoint : async (params) => {
		const url = 'sales/report/point';
		return await request.get(url, {params});
	},
};

export default saleApi;