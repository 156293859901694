import request from "~/utils/http";

const createdWebsiteApi = {
	gets : async (params) => {
		const url = 'created-website';
		return await request.get(url, {params});
	},
	template : async (params) => {
		const url = 'created-website/template';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'created-website';
		return await request.post(url, params);
	},
	reInsert : async (id) => {
		const url = `created-website/re-insert/${id}`;
		return await request.post(url);
	},
	status : async (params) => {
		const url = `created-website/status/${params.id}`;
		return await request.post(url, params);
	},
	delete : async (id) => {
		const url = `created-website/${id}`;
		return await request.delete(url);
	}
};

export default createdWebsiteApi;