import {useEffect} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {v4 as uuidv4} from "uuid";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,
	Loading,
	Icon
} from "~/components";
import {
	InputField,
} from "~/components/Forms";
import {
	serviceApi,
} from "~/api";
import {
	apiError,
	handleRequest
} from "~/utils";

function ServiceFormDomain({item, onHandleSubmit}) {

	const initialValues = {
		domain: item.domain,
		domains: []
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape())
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "domains"
	});

	useEffect(() => {
		fetchDomain().then();
	}, [item]);

	const fetchDomain = async () => {
		if(item.service !== 'domain') {
			let [error, response] = await handleRequest(serviceApi.domains(item.id));
			let message = apiError(`Load danh sách tên miền thất bại`, error, response);
			if (!message) {
				setValue('domains', response.data.sub)
			}
		}
	}

	const handleAddDomain = () => {
		append({
			id: uuidv4(),
			domain: ''
		});
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Tên miền chính</p>
			<Controller control={control} name="domain" render={({ field }) => (
				<InputField errors={errors} {...field}/>
			)}/>
			<p className="heading">Tên miền phụ</p>
			{fields.map(({ id, domain }, index) => {
				return (

				<Row gutter={10} key={id}>
					<Col className="gutter-row" span={21}>
						<Controller control={control} name={`domains[${index}].domain`} render={({ field }) => (
							<InputField defaultValue={domain} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={3}>
						<Button primary type="button" onClick={() => remove(index)}>{Icon.delete}</Button>
					</Col>
				</Row>
				);
			})}
			<div className="d-flex justify-content-end">
				<Button blue background type="button" onClick={handleAddDomain}>Thêm tên miền</Button>
			</div>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Lưu thông tin</Button>
			</div>
		</form>
	)
}

export default ServiceFormDomain;