import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField,
} from "~/components/Forms";

function SaleEventFormStart({item, onHandleSubmit}) {

	const typeOptions = [
		{value : 'new', label : 'Khách mới'},
		{value : 'old', label : 'Khách củ'},
	];

	const type2Options = [
		{value : 'tuvan', label : 'Tư vấn'},
		{value : 'bangiao', label : 'Bàn giao'},
	];

	const initialValues = {
		type: 'new',
		type2: 'tuvan',
	}

	const validationSchema = Yup.object().shape({
		type: Yup.string().required('Bạn chưa chọn loại khách'),
		type2: Yup.string().required('Bạn chưa chọn loại tư vấn'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.type = item.type;
			initialValues.type2 = item.type2;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="type" render={({field}) => (
						<SelectField label="Loại khách" options={typeOptions} errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="type2" render={({ field }) => (
						<SelectField label="Loại tư vấn" options={type2Options} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Xác nhận</Button>
			</div>
		</form>
	)
}

export default SaleEventFormStart;