import * as Yup from "yup";
import _ from "lodash";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Button} from "~/components";
import {Col, Row} from "antd";
import {InputField} from "~/components/Forms";

function ServiceFormHostAdd({item, onHandleHost}) {

	const [hostData, setHostData] = useState({});

	console.log(typeof item.hostName)

	let userName = (item.hostName.length == 0 ) ? item.domain.replace(/[^a-zA-Z0-9]/g, '').slice(0, 9) : item.hostName;

	function generateRandomString(length) {
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	}

	const { control: controlHost, handleSubmit: handleSubmitHost, formState: { isSubmitting: isSubmittingHost, errors: errorsHost }, setValue} = useForm({
		defaultValues: {
			hostIp: item.hostIp,
			hostUser: userName,
			hostPass: '',
		},
		resolver: yupResolver(Yup.object().shape({
			hostIp: Yup.string().required('Bạn chưa điền tên ip host'),
			hostUser: Yup.string().required('Bạn chưa điền tên đăng nhập host'),
			hostPass: Yup.string().required('Bạn chưa điền mật khẩu host'),
		}))
	});

	useEffect(() => {
		setHostData({})
		setValue('hostPass', generateRandomString(10));
	}, [item])

	const onSubmitHost = async (data) => {
		let response = await onHandleHost(data, item);
		if(response !== false) {
			setHostData(response.data.host)
		}
	}

	return (
		<div className="form">
			<p className="heading">Thông tin</p>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tên miền</Col>
				<Col className="gutter-row" span={18}>{`${item.domain}`}</Col>
			</Row>
			<p className="heading">Thông tin host</p>
			<form className="form" onSubmit={handleSubmitHost(onSubmitHost)}>
				<Controller control={controlHost} name="hostIp" render={({field}) => (
					<InputField label="IP host" errors={errorsHost} {...field}/>
				)}/>
				<Controller control={controlHost} name="hostUser" render={({field}) => (
					<InputField label="Tên đăng nhập host" errors={errorsHost} {...field}/>
				)}/>
				<Controller control={controlHost} name="hostPass" render={({field}) => (
					<InputField label="Mật khẩu đăng nhập host" errors={errorsHost} {...field}/>
				)}/>

				<Row className="mb-2" gutter={10}>
					{
						(!_.isEmpty(hostData)) && <Col className="gutter-row" span={12}>
							<div className="pd-1" style={{background:'#c6fbc5', borderRadius:'5px'}}>
								<p><b>Thông tin host</b></p>
								<p>Ip: {hostData?.hostIp}</p>
								<p>Username: {hostData?.hostUser}</p>
								<p>Password: {hostData?.hostPass}</p>
								<p>Package: {hostData?.package}</p>
							</div>
						</Col>
					}
				</Row>
				<div className="form-group d-flex justify-content-end modal-bottom">
					<Button loading={isSubmittingHost} background red>Tạo host</Button>
				</div>
			</form>
		</div>
	)
}

export default ServiceFormHostAdd;