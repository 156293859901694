import {useRef} from "react";
import {Tooltip} from "antd";
import {
	Button, Icon
} from "~/components";
import { useCan } from "~/hooks";
import ReactToPrint from "react-to-print";
import {PrintPayments} from "../index";

function FormPaymentAction({item, setItemEdit, openModal}) {
	const printRef = useRef();
	const can = {
	    edit: useCan('formPaymentEdit'),
	    delete: useCan('formPaymentDelete'),
	}
	const handelButtonClick = {
	    edit: () => {
		    setItemEdit(item);
		    openModal('addEdit');
	    },
	    info: () => {
		    setItemEdit(item);
		    openModal('info');
	    },
	    delete: () => {
		    setItemEdit(item);
		    openModal('delete');
	    },
	}

	return (
		<div className="table-action d-flex">
			{(item.status != 'allow' && can.edit) && <Tooltip title="Edit thông tin"><Button background blue leftIcon={Icon.edit} onClick={handelButtonClick.edit}></Button></Tooltip>}
			<Tooltip title="Thông tin"><Button background blue leftIcon={Icon.info} onClick={handelButtonClick.info}></Button></Tooltip>
			{(item.status == 'allow') && <ReactToPrint key={`btn-print-${item.id}`}
				trigger={() => <Button leftIcon={Icon.print}></Button>}
				content={() => printRef.current}
			/>}
			{can.delete && <Tooltip title="Xóa"><Button background red leftIcon={Icon.delete} onClick={handelButtonClick.delete}></Button></Tooltip>}
			{(item.status == 'allow') &&<PrintPayments key={`payment-print-${item.id}`} item={item} ref={printRef} />}
		</div>
	)
}

export default FormPaymentAction;