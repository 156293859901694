import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
	Button,
	Icon,
} from "~/components";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useDevice
} from "~/hooks";
import {
	uploadWebsiteApi
} from "~/api";
import {
	uploadWebsiteActions,
	uploadWebsiteErrorSelector,
	uploadWebsiteFilterSelector,
	uploadWebsiteItemsSelector,
	uploadWebsiteLoadingSelector,
	uploadWebsitePaginationSelector
} from "../uploadWebsiteSlice";
import {
	UploadWebsiteTable,
	UploadWebsiteSearchBar,
	UploadWebsiteSearchMobile,
	UploadWebsiteFormAddEdit,
	UploadWebsiteFormInfo,
	UploadWebsiteFormStep, UploadWebsiteFormAddHost
} from "../components";

function UploadWebsite() {

	const {isMobile} = useDevice();

	const can = {
		add: useCan('uploadWebsiteAdd'),
		edit: useCan('uploadWebsiteEdit'),
		status: useCan('uploadWebsiteStatus'),
		delete: useCan('uploadWebsiteDelete'),
		deadline: useCan('uploadWebsiteDeadline'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(uploadWebsiteItemsSelector);

	const loading   = useSelector(uploadWebsiteLoadingSelector);

	const error     = useSelector(uploadWebsiteErrorSelector);

	const pagination = useSelector(uploadWebsitePaginationSelector);

	const filter    = useSelector(uploadWebsiteFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [loadingDelete, setLoadingDelete] = useState(false);

	//Model
	const [openModal, setOpenModal] = useState({
		add         : false,
		edit        : false,
		delete      : false,
		info        : false,
		status      : false,
		upload      : false,
		addHost      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(uploadWebsiteActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		dispatch(uploadWebsiteActions.fetchData(filter));
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			data.id = item.id;
			messageAction = 'Cập nhật';
		}
		if(data?.deadline) {
			data.deadline = strToTime(data?.deadline);
		}
		let [error, response] = (item?.id) ? await handleRequest(uploadWebsiteApi.update(data)) : await handleRequest(uploadWebsiteApi.add(data));
		let message = apiError(`${messageAction} upload website thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} upload website thành công`});
			if(!item?.id) {
				dispatch(uploadWebsiteActions.add(response.data));
			}
			else {
				dispatch(uploadWebsiteActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	const handleAssignStatus = async (status, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(uploadWebsiteApi.status({id: item.id, status, type: 'accounting'}));
		let message = apiError(`Cập nhật trạng thái yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái yêu cầu thành công`});
			item.accountingStatus = status;
			dispatch(uploadWebsiteActions.update(item));
			handleModalClose('status')
		}
	}

	const handleDevStatus = async (status, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(uploadWebsiteApi.status({id: item.id, status, type: 'dev'}));
		let message = apiError(`Cập nhật trạng thái yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái yêu cầu thành công`});
			item.devStatus = status;
			dispatch(uploadWebsiteActions.update(item));
			handleModalClose('status')
		}
	}

	const handleHost = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return false;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(uploadWebsiteApi.host(data));
		let message = apiError(`Chia host cho yêu cầu thất bại`, error, response);
		if (!message) {
			notification.success({ message: 'Thành công', description: `Chia host cho yêu cầu thành công`});
			return response;
		}
		return false;
	}

	const handleAddHost = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return false;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(uploadWebsiteApi.addHost(data));
		let message = apiError(`Chia host cho yêu cầu thất bại`, error, response);
		if (!message) {
			notification.success({ message: 'Thành công', description: `Chia host cho yêu cầu thành công`});
			item.hostId = response.data.hostId;
			item.hostContract = response.data.hostContract;
			item.hostType = response.data.hostType;
			dispatch(uploadWebsiteActions.update(item));
			return response;
		}
		return false;
	}

	const handleSource = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin yêu cầu để cập nhật'});
			return false;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(uploadWebsiteApi.source(data));
		let message = apiError(`Nén source cho yêu cầu thất bại`, error, response);
		if (!message) {
			notification.success({ message: 'Thành công', description: `Nén source cho yêu cầu thành công`});
			return response;
		}
		return false;
	}

	const handleDeadline = async (date, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có yêu cầu để cập nhật'});
			return;
		}

		date = date.get('date')+'-'+(date.get('month')+1)+'-'+date.get('year');

		let [error, response] = await handleRequest(uploadWebsiteApi.deadline({id : item.id, deadline: date}));
		let message = apiError(`Cập nhật deadline yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật deadline yêu cầu thành công`});
			item.deadline = response.data.deadline;
			dispatch(uploadWebsiteActions.update(item));
		}
	}

	//Delete
	const handleDelete = async () => {
		setLoadingDelete(true);
		if (itemEdit?.id == 'undefined') {
			setLoadingDelete(false);
			notification.error({message: 'Lỗi', description: 'Không có thông yêu cầu án để xóa'});
			return;
		}
		if (itemEdit.accountingStatus == 'confirm' || itemEdit.devStatus == 'success') {
			notification.error({message: 'Lỗi', description: 'Yêu cầu đã duyệt không thể xóa'});
			setLoadingDelete(false);
			return;
		}
		let [error, response] = await handleRequest(uploadWebsiteApi.delete(itemEdit.id));
		let message = apiError(`xóa yêu cầu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa yêu cầu thành công`});
			dispatch(uploadWebsiteActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
		setLoadingDelete(false);
	}

	//Search
	const SearchBar = (isMobile) ? UploadWebsiteSearchMobile : UploadWebsiteSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(uploadWebsiteActions.setFilter({...filter, page }));
	}
	const handleSearchChange = (newFilter) => {
		dispatch(uploadWebsiteActions.setFilterWithDebounce(newFilter));
	};
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(uploadWebsiteActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Upload Website'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({}); handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<SearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />
					{items && <UploadWebsiteTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
						can={can}
						onChangeDeadline={handleDeadline}
					/>}
				</div>
			</div>
			{
				((can.add || can.edit) && openModal.addEdit) && <Modal title="Upload Website" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
					<UploadWebsiteFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.info) && <Modal zIndex={99} title="Thông tin" size="xl" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
					<UploadWebsiteFormInfo
						item={itemEdit}
						modalOpen={handleModalOpen}
						onClickAssignStatus={handleAssignStatus}
						onClickDevStatus={handleDevStatus} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.addHost) && <Modal zIndex={99} title="Thêm thông tin host" visible={openModal.addHost} onCancel={() => {handleModalClose('addHost')}}>
					<UploadWebsiteFormAddHost item={itemEdit} onHandleSubmit={handleAddHost} />
				</Modal>
			}
			{
				(itemEdit?.id && openModal.upload) && <Modal zIndex={99} title="Thông tin" size="xl" visible={openModal.upload} onCancel={() => {handleModalClose('upload')}}>
					<UploadWebsiteFormStep item={itemEdit} onHandleHost={handleHost} onHandleSource={handleSource} onClickDevStatus={handleDevStatus} />
				</Modal>
			}
			{
				(can.delete && itemEdit?.id && openModal.delete) && <Modal title="Xóa upload Website" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
					<p>Bạn muốn xóa uploadWebsite <b>{itemEdit?.name}</b>?</p>
					<br />
					<div className="d-flex justify-content-end gap">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
						<Button loading={loadingDelete} primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
					</div>
				</Modal>
			}
		</Fragment>
	)
}
export default UploadWebsite;