import request from "~/utils/http";

const commentApi = {
	gets : async (type, params) => {
		const url = `comments/${type}/${params.id}`;
		return await request.get(url, {params});
	},
	uploadWebsite : async (params) => {
		const url = `comments/upload-website/${params.id}`;
		return await request.get(url, {params});
	},
	buyService : async (params) => {
		const url = `comments/buy-service/${params.id}`;
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = `comments/${params.id}`;
		return await request.post(url, params);
	},
	fileUpload : async (params) => {
		const url = `comments/upload`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
};

export default commentApi;