import style from './StatusLabel.module.scss';
import { forwardRef } from "react";
import className from "classnames/bind";
const cn = className.bind(style);
const StatusLabel = forwardRef(({
    children,
	border,
    type,
    small,
	bold,
	background,
    pointer,
    className, ...props }, ref) => {
	const classes = cn('status', 'text-xs', {bold, small, border, pointer, background, [type] : type, [className] : className});
	return (
		<span className={classes} {...props} ref={ref}>{children}</span>
	);
});

export default StatusLabel;