import style from './Message.module.scss';
import className from 'classnames/bind';
import {useMemo} from "react";
import {Image} from "antd";
import {useCurrentUser} from "../../hooks";
import {renderDate} from "../../utils";
import DOMPurify from "dompurify";
const cn = className.bind(style);

function Message({item, preCommentUser}) {

	const currentUser = useCurrentUser();

	const {id, created, media, userId, type, message, user} = item;

	function addLinks(text) {
		let urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function(url) {
			return '<a href="' + url + '" target="_blank">' + url + '</a>';
		});
	}

	// Handle display message
	const renderMessageContent = useMemo(() => {
		if (type === "@unsentmsg") {
			return <p className={cn("unsent-message")}>Tin nhắn đã bị thu hồi</p>;
		}
		else if (type === "@image") {
			return <Image className={cn("message-photo", "text-inner")} src={media} />
		}
		else if (type === "@video") {
			return <p className={cn("unsent-message")}>Định dạng chưa hỗ trợ</p>;
		}
		else if (type === "@sticker") {
			return <p className={cn("unsent-message")}>Định dạng chưa hỗ trợ</p>;
		}
		else if (type === "@time") {
			return <p className={cn("message-time")}>{renderDate(message)}</p>;
		}
		else if (type === "@text" || type === "@icon") {
			if (message.includes("http://") || message.includes("https://") || message.includes("www.")) {
				return (
					<p className={cn("text-inner")} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addLinks(message)) }} />
				);
			} else {
				return <p className={cn("text-inner")}>{message}</p>;
			}
		}
	}, [type, message, media]);

	return (
		<div className={cn("message-wrapper", {
			'reverse' : (currentUser.id == userId),
			'group' : (userId == preCommentUser),
			'time' : (type == '@time')
		})}>
			<div className={cn("profile-picture")}>
				<Image src={user.avatar} />
				{user.avatar}
			</div>
			<div className={cn("message-content")}>
				<p className={cn("name")}>{user.firstname} {user.lastname}</p>
				{renderMessageContent}
			</div>
		</div>
	)
}

export default Message;