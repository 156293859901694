import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button, Loading
} from "~/components";
import {
	InputPriceField,
	GroupRadioField,
	DateField
} from "~/components/Forms";

function ContractFormRevenue({item, onHandleSubmit}) {

	const paymentOptions = [
		{ value: 'cod', label: 'Tiền mặc'},
		{ value: 'bank', label: 'Chuyển khoản'},
		{ value: 'momo', label: 'Ví Momo'},
		{ value: 'swipe', label: 'Quẹt thẻ'},
		{ value: 'alePay', label: 'AlePay'},
	];

	const initialValues = {
		time: new Date(),
		price: 0,
		payment: 'cod',
	}

	const validationSchema = Yup.object().shape({
		time: Yup.string().required('Bạn chưa chọn ngày thu'),
		price: Yup.number().required('Giá trị thu không được để trống'),
		payment: Yup.string().required('Bạn chưa chọn phương thức thanh toán'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin doanh thu</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="price" render={({field}) => (
						<InputPriceField label="Giá trị thu" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Ngày thu" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Thanh toán</p>
			<Controller control={control} name="payment" render={({ field }) => (
				<GroupRadioField options={paymentOptions} errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Thêm doanh thu</Button>
			</div>
		</form>
	)
}

export default ContractFormRevenue;