import { useState } from "react";
import Tippy from '@tippyjs/react/headless';
import {Button, Icon} from "~/components";
import {PopperWrapper} from "~/components/Popper";

function PapersNumberAction({item, setItemEdit, openModal}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const handleButtonClick = {
		note: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('note');
		},
		reCall: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('reCall');
		},
		receive: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('receive');
		},
		cancel: () => {
			closeActionButton();
			setItemEdit(item);
			openModal('cancel');
		},
	}

	const renderActionButton = () => {
		return (
			<PopperWrapper className={'action-wrapper'}>
				<Button leftIcon={Icon.briefcase} onClick={handleButtonClick.note}>Ghi chú</Button>
				<Button leftIcon={Icon.switch} onClick={handleButtonClick.reCall}>Thu hồi</Button>
				<Button leftIcon={Icon.success} onClick={handleButtonClick.receive}>Nhận đơn</Button>
				<Button leftIcon={Icon.delete} onClick={handleButtonClick.cancel}>Hủy đơn</Button>
			</PopperWrapper>
		)
	}
	return (
		<div className="table-action d-flex">
			<Tippy interactive visible={showActionBox} placement="left" offset={[0, 10]} render={renderActionButton} onClickOutside={closeActionButton}>
				<div onClick={() => setShowActionBox(true)} className={'action-btn'}>{Icon.menuVertical}</div>
			</Tippy>
		</div>
	)
}

export default PapersNumberAction;