import {call, put, takeLatest} from "redux-saga/effects";
import {ruleActions} from "./RuleSlice";
import {ruleApi} from "~/api";

function* fetchRuleList(action) {
	try {
		const response = yield call(ruleApi.getsGroupWidthItem, action.payload);
		yield put(ruleActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch project list', error);
		yield put(ruleActions.fetchDataFailed(error.message));
	}
}

export default function* ruleSaga() {
	yield takeLatest(ruleActions.fetchData.type, fetchRuleList);
}