import {useMemo, useEffect} from "react";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,
	Loading,
	Icon
} from "~/components";
import {
	InputField,
	SelectField
} from "~/components/Forms";

function FormPapersTypeImport({papersType, onHandleSubmit}) {

	const papersTypeOptions = useMemo(() => {
		return [{ value: 0, label: 'Chọn loại giấy tờ' }, ...Object.entries(papersType).map(([key, paperType]) => {
			return {
				value: paperType.id,
				label: `${paperType.name}`
			};
		})];
	}, [papersType]);

	const initialValues = {
		content: []
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, setValue, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape())
	});

	const {fields, append, remove} = useFieldArray({control, name: "content"});

	const handleAddDomain = () => {
		append({
			typeId: 0,
			number: 1
		});
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Số lượng giấy tờ</p>
			{fields.map(({number, typeId}, index) => {
				return (
					<Row gutter={10} key={index}>
						<Col className="gutter-row" span={11}>
							<Controller control={control} name={`content[${index}].typeId`} render={({ field }) => (
								<SelectField defaultValue={parseInt(typeId)} options={papersTypeOptions} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={11}>
							<Controller control={control} name={`content[${index}].number`} render={({ field }) => (
								<InputField defaultValue={number} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={2}>
							<Button primary type="button" onClick={() => remove(index)}>{Icon.delete}</Button>
						</Col>
					</Row>
				);
			})}
			<div className="d-flex justify-content-end">
				<Button blue background type="button" onClick={handleAddDomain}>Thêm giấy tờ</Button>
			</div>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Lưu thông tin</Button>
			</div>
		</form>
	)
}

export default FormPapersTypeImport;