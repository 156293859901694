import {useCallback} from "react";
import {
	SelectField
} from "~/components/Forms";
import {strToTime} from "~/utils";

function PointReportSearch({onChange}) {

	let options = [
		{value: 2024, label: 'Năm 2024'},
		{value: 2023, label: 'Năm 2023'},
		{value: 2022, label: 'Năm 2022'},
	]

	const handleTimeChange = useCallback((value) => {
		if(onChange) onChange(value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<SelectField name="time" options={options} onChange={handleTimeChange}/>
			</form>
		</div>
	)
}

export default PointReportSearch;