import Image from "~/components/Image";
import {Pagination, Table} from "antd";
import {
	renderDate,
	numberFormat
} from "~/utils";
import StatusLabel from "~/components/StatusLabel";
import SaleCommitAction from "./SaleCommitAction";

function SaleCommitTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const handleEditClick = (item) => {
		setItemEdit(item);
		openModal('addEdit');
	}

	const handleDeleteClick = (item) => {
		setItemEdit(item);
		openModal('delete');
	}

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			item.user?.id && (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item.user?.avatar}/></div>
				<div>
					<b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
					<p>Ngày tạo {renderDate(item?.created)}</p>
				</div>
			</div>
		))},
		{ title: 'Loại cam kết', dataIndex: 'type', key: "type", width: '300px', render: (_, item) => (
			<div className="d-flex gap align-items-center">
				{item.type == 'mouth' && <StatusLabel small type="green">Tháng</StatusLabel> }
				{item.type == '3-day' && <StatusLabel small type="blue">3 ngày</StatusLabel> }
				{item.type == 'mouth' && <p className="mt-1">Tháng {renderDate(item?.timeStart, 'month')}</p> }
				{item.type == '3-day' && <p className="mt-1">{renderDate(item?.timeStart)} đến {renderDate(item?.timeEnd)}</p>}
			</div>
		)},
		{ title: 'Cam kết', dataIndex: 'price', key: "price", width: '120px', render: (_, item) => (
			<div>
				<p>{numberFormat(item?.price)}</p>
			</div>
		)},
		{ title: 'Kết quả', dataIndex: 'result', key: "result", width: '120px', render: (_, item) => (
			<div>
				<p className="d-flex gap justify-content-between">
					{numberFormat(item?.result)}
					{(item.percent >= 0 && item.percent <= 50) && <StatusLabel small type="red">{item.percent}%</StatusLabel> }
					{(item.percent > 50) && <StatusLabel small type="green">{item.percent}%</StatusLabel> }
				</p>
			</div>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<SaleCommitAction
				key={item.id}
				item={item}
				onClickEdit={handleEditClick}
				onClickDelete={handleDeleteClick}
			/>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:'59vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default SaleCommitTable;