import className from "classnames/bind";
import style from "../../style/SaleReport.module.scss";
import SaleReportDetailGroup from "./ReportDetailGroup";
import SaleReportDetailUser from "./ReportDetailUser";
import SaleReportTeam from "./ReportTeam";
import {Icon} from "~/components";
const cn = className.bind(style);
function SaleReportDetail({onLoad, reportData, teams, time, type}) {
	return (
		<div className={cn('report-detail')}>
			<div className={cn('report-teams')}>
				<div className={cn('report-team')} onClick={() => onLoad(time, reportData.groupId, 'group')}>
					<div className={cn('avatar') + ' avatar'}>{Icon.users}</div>
					<div className={cn('name')}>Nhóm</div>
				</div>
				{
					Object.keys(teams).map((itemKey) => {
						let item = teams[itemKey];
						return <SaleReportTeam key={`SaleReportTeam${item.id}`} item={item} time={time} onLoad={onLoad} />
					})
				}
			</div>
			{(type == 'group') && <SaleReportDetailGroup reportData={reportData} />}
			{(type == 'user') && <SaleReportDetailUser reportData={reportData} />}
		</div>
	)
}
export default SaleReportDetail;