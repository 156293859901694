import {useCallback, useMemo} from "react";
import {
	DateField,
	SelectField
} from "~/components/Forms";
import {StatusLabel} from "~/components";
import {
	useCan,
	useUtilities
} from "~/hooks";

function PaySearchBar({filter, listGroup, onChange}) {

	const {utilities} = useUtilities();

	const can = {
		listAll: useCan('payListAll'),
	};

	const departmentOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả các ban' }, ...Object.entries(utilities.departments).map(([key, item]) => {
			return {value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>};
		})];
	}, [utilities.departments]);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả các nhóm' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const handleTimeChange = useCallback((value) => {
		if (!isNaN(value)) {
			value = Math.round(new Date(+value).getTime() / 1000)
		} else if (value.search('GMT') != -1) {
			value = Math.round(new Date(value).getTime() / 1000)
		}
		if(onChange) onChange('time', value);
	}, [onChange]);

	const handleDepartmentChange = useCallback((value) => {
		if(onChange) onChange('department', value);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('groupId', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<DateField name="time" picker="month" onChange={handleTimeChange}/>
					{can.listAll && <SelectField name="department" value={filter.department} placeholder="Phòng ban" options={departmentOptions} onChange={(value, event) => handleDepartmentChange(value)} />}
					<SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value, event) => handleGroupChange(value)} />
				</form>
			</div>
		</div>
	)
}

export default PaySearchBar;