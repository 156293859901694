import request from "~/utils/http";

const documentApi = {
    getsGroupWidthItem : async (params) => {
        const url = 'documents/group/items';
        return await request.get(url, {params});
    },
    getsGroup : async (params) => {
        const url = 'documents/group';
        return await request.get(url, {params});
    },
    addGroup : async (params) => {
        const url = 'documents/group';
        return await request.post(url, params);
    },
    updateGroup : async (params) => {
        const url = `documents/group/${params.id}`;
        return await request.patch(url, params);
    },
    deleteGroup : async (id) => {
        const url = `documents/group/${id}`;
        return await request.delete(url);
    },

    countItem : async (params) => {
        const url = 'documents/item/count';
        return await request.get(url, {params});
    },
    getsItem : async (params) => {
        const url = 'documents/item';
        return await request.get(url, {params});
    },
    addItem : async (params) => {
        const url = 'documents/item';
        return await request.post(url, params);
    },
    updateItem : async (params) => {
        const url = `documents/item/${params.id}`;
        return await request.patch(url, params);
    },
    deleteItem : async (id) => {
        const url = `documents/item/${id}`;
        return await request.delete(url);
    },
    fileUpload : async (params) => {
        const url = `documents/item/upload`;
        return await request.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    fileRemove : async (params) => {
        const url = `documents/item/upload`;
        return await request.patch(url, params);
    },
};

export default documentApi;