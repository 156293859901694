import {memo} from "react";
import {Table} from "antd";
import {StatusLabel} from "~/components";
import {numberFormat} from "~/utils";
import {useUtilities} from "~/hooks";

function PayDetailRevenue({items = []}) {

	const {utilities} = useUtilities();

	const columns = [
		{ title: 'Hợp đồng', dataIndex: 'code', key: "code", width: '350px', render: (_, item) => (
			<div>
				<p>HD{item.contractId} {item?.contract.name}</p>
				<p>
					{item?.roomSales == 1 && <StatusLabel small type="green">Tính cho phòng</StatusLabel>}
					{item?.roomSales == 0 && <StatusLabel small type="red">Không Tính cho phòng</StatusLabel>}
				</p>
			</div>
		)},
		{title: 'Loại thu', dataIndex: 'service', key: 'service', render:(_, item) => (
			<div>
				<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
				&nbsp;<StatusLabel small type={utilities.services[item.service].color}>{utilities.serviceTypes[item.serviceType].label}</StatusLabel>
			</div>
		)},
		{ title: 'Số tiền', dataIndex: 'price', key: "price", width: '100px', render: (_, item) => (
			<div>
				<p><b>{numberFormat(item?.price)}đ</b></p>
			</div>
		)},
		{ title: '%', dataIndex: 'percent', key: "percent", width: '100px', render: (_, item) => (
			<div>
				<p><b>{item?.percent}%</b></p>
			</div>
		)},
		{ title: 'Hoa hồng', dataIndex: 'commission', key: "commission", width: '100px', render: (_, item) => (
			<div>
				<p><b>{numberFormat(item?.commission)}đ</b></p>
			</div>
		)},
	];

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table
					columns={columns}
					rowKey="id"
					dataSource={items.revenues}
					scroll={{ y:550, x: 'max-content' }}
					pagination={false}
					summary={() => (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0} colSpan={2}><b>Tổng</b></Table.Summary.Cell>
								<Table.Summary.Cell index={1} colSpan={2}><b className="color-red">{numberFormat(items.totalPrice)}</b></Table.Summary.Cell>
								<Table.Summary.Cell index={2}><b className="color-red">{numberFormat(items.totalCommission)}</b></Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
				/>
			</div>
		</div>
	)
}

export default memo(PayDetailRevenue);