import {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	InputField,
	DateField
} from "~/components/Forms";
import {
	useCan,
	useGroup,
	useCurrentUser,
	useUtilities
} from "~/hooks";
import {
	Button,
	Icon,
	Notification,
	StatusLabel
} from "~/components";
import {strToTime} from "~/utils";

function WorkingDaySearchMobile({filter, onChange}) {

	const {utilities} = useUtilities();

	const can = {
		viewAll : useCan('workingDayListAll'),
		viewDepartment : useCan('workingDayListDepartment'),
		viewGroup : useCan('workingDayListGroup'),
	}

	const currentUser   = useCurrentUser();

	const [searchBar, setSearchBar] = useState(false);

	let [listPosition, setListPosition] = useState(utilities.roles);

	if(!can.viewAll && can.viewDepartment && currentUser?.department) {
		listPosition = Object.keys(utilities.roles).filter((key) => {
			return utilities.roles[key].department == currentUser.department;
		}).reduce((obj, key) => {
			obj[key] = utilities.roles[key];
			return obj;
		}, {});
	}

	const positionOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listPosition).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [listPosition]);

	const departmentOptions = [{ value: '', label: <StatusLabel label="Vị trí" type="white" small>Tất cả phòng ban</StatusLabel>}];

	if(can.viewAll) {
		for (const [key, item] of Object.entries(utilities.departments)) {
			departmentOptions.push({ value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>})
		}
	}

	const listGroup = useGroup();

	const groupOptions = [{value: '', label: 'Tất cả các nhóm'}];

	for (const [key, item] of Object.entries(listGroup)) {
		groupOptions.push({value : item.id, label : item.name})
	}

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleTimeChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			time: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const handleDepartmentChange = (value) => {
		if(value !== '') {
			setListPosition(Object.keys(utilities.roles).filter((key) => {
				return utilities.roles[key].department == currentUser.department;
			}).reduce((obj, key) => {
				obj[key] = utilities.roles[key];
				return obj;
			}, {}));
		}
		else {
			setListPosition(utilities.roles);
		}
	};

	const onSubmit = (data) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			time: strToTime(data.time),
			keyword: data.keyword,
			department: data.department,
			role: data.role,
			group: data.group,
			page: 1,
		};
		setSearchBar(false);
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<DateField name="time" placeholder="Thời gian áp dụng" picker="month" onChange={handleTimeChange}/>
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Thời gian áp dụng" picker="month" errors={errors} {...field}/>
					)}/>
					{(can.viewAll || can.viewDepartment || can.viewGroup) &&
						<Controller control={control} name="keyword" render={({field}) => (
							<InputField label="Tên, email, số điện thoại nhân viên" errors={errors} {...field} />
						)}/>
					}
					{(can.viewAll) &&
						<Controller control={control} name="department" render={({field}) => (
							<SelectField label="Phòng ban" options={departmentOptions} errors={errors} {...field} onChange={(value, event) => {
								handleDepartmentChange(value);
								field.onChange(value, event);
							}} />
						)}/>
					}
					{(can.viewAll || can.viewDepartment) &&
						<Controller control={control} name="role" render={({field}) => (
							<SelectField label="Chọn chức vụ" options={positionOptions} errors={errors} {...field} />
						)}/>
					}
					{(can.viewAll || ((can.viewDepartment || can.viewGroup) && currentUser.department == 'sale')) &&
						<Controller control={control} name="group" render={({field}) => (
							<SelectField label="Chọn nhóm" options={groupOptions} errors={errors} {...field} />
						)}/>
					}
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default WorkingDaySearchMobile;