import {Pagination, Table} from "antd";
import {
	Image,
	StatusLabel,
	PopperUserInfo,
} from "~/components";
import UploadWebsiteAction from "./UploadWebsiteAction";
import {renderDate} from "~/utils";
import {useUtilities} from "~/hooks";
import {useContext} from "react";
import {AppContext} from "~/context/AppProvider";
import {DateField} from "~/components/Forms";

function UploadWebsiteTable({items = [], can, pagination, onPaginationChange, setItemEdit, openModal, onChangeDeadline}) {

	const {tableHeight} = useContext(AppContext);

	const {utilities} = useUtilities();

	const PayStatus = ({type, id, amountRemaining}) => {
		if (type == 2) return null;
		return (id && amountRemaining == 0) ? <p className="color-green font-bold">Đã thanh toán</p> : <p className="color-red font-bold">Chưa thanh toán</p>;
	}

	const columns = [
		{ title: 'Website', dataIndex: 'website', key: "website", width: '200px', render: (_, item) => (
			<>
				{(item.websiteType == 2) && <StatusLabel small type="blue">Khách hàng</StatusLabel> }
				{(item.websiteId != 0) && <StatusLabel small type="green">HD{item.websiteId}</StatusLabel> }
				<PayStatus type={item.websiteType} id={item.websiteContract?.id} amountRemaining={item.websiteContract.amountRemaining}/>
			</>
		)},
		{ title: 'Nhân viên', dataIndex: 'status', key: "status", width: '150px', render: (_, item) => (
			item.user?.id &&
			<PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{item.user?.lastname}</b>
						<p>{item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Kỹ thuật', dataIndex: 'dev', key: "dev", width: '150px', render: (_, item) => (
			item.dev?.id &&
			<PopperUserInfo user={item.dev}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.dev?.avatar} /></div>
					<div>
						<b>{item.dev?.lastname}</b>
						<p>{item.dev?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Tên miền', dataIndex: 'domain', key: "domain", width: '230px', render: (_, item) => (
			<>
				<p><b>{item.domain}</b></p>
				{(item.domainType == 2) && <StatusLabel small type="blue">Khách hàng</StatusLabel> }
				{(item.domainId != 0) && <StatusLabel small type="blue">HD{item.domainId}</StatusLabel> }
				<PayStatus type={item.domainType} id={item.domainContract?.id} amountRemaining={item.domainContract.amountRemaining}/>
				<p style={{fontSize:'11px', color:'#cccc'}}>Ngày tạo {renderDate(item?.created)}</p>
			</>
		)},
		{ title: 'Host', dataIndex: 'host', key: "host", width: '200px', render: (_, item) => (
			<>
				{(item.hostType == 2) && <StatusLabel small type="red">Khách hàng</StatusLabel>}
				{(item.hostType == 3) && <StatusLabel small type="red">Hỗ trợ</StatusLabel>}
				{(item.hostId != 0) && <StatusLabel small type="red">HD{item.hostId}</StatusLabel>}
				{item.hostContract?.option && <StatusLabel small type={utilities.services[item.hostContract.service].color}>{utilities.services[item.hostContract.service].options[item.hostContract.option].label}</StatusLabel>}
				<PayStatus type={item.hostType} id={item.hostContract?.id} amountRemaining={item.hostContract.amountRemaining}/>
			</>
		)},
		{ title: 'Loại', dataIndex: 'type', key: "type", width: '150px', render: (_, item) => (
			<>
				{(item.type == 1) && <p>Bàn giao</p> }
				{(item.type == 2) && <p>Chạy trước thanh toán sau</p> }
				{(item.type == 3) && <p>Hỗ trợ</p> }
				{(item.type == 2 && !can.deadline) && <p style={{fontSize:'11px', color:'#cccc'}}>Ngày hết hạn {renderDate(item?.deadline)}</p>}
				{(item.type == 2 && can.deadline) && <DateField value={item?.deadline*1000} bordered={false} onChange={(date) => onChangeDeadline(date, item)}/>}
			</>
		)},
		{ title: 'Zalo OA', dataIndex: 'accountingStatus', key: "accountingStatus", width: '110px', render: (_, item) => (
			<>
				{(item.accountingStatus == 'pending') && <StatusLabel small type="yellow">Đợi duyệt</StatusLabel>}
				{(item.accountingStatus == 'wait') && <StatusLabel small type="yellow">Đợi duyệt</StatusLabel>}
				{(item.accountingStatus == 'confirm') && <StatusLabel small type="green">Đã duyệt</StatusLabel>}
				{(item.accountingStatus == 'cancel') && <StatusLabel small type="red">Từ chối</StatusLabel>}
			</>
		)},
		{ title: 'Kỹ thuật', dataIndex: 'devStatus', key: "devStatus", width: '100px', render: (_, item) => (
			<>
				{(item.devStatus == 'up-wait') && <StatusLabel small type="yellow">Đợi up</StatusLabel>}
				{(item.devStatus == 'success') && <StatusLabel small type="green">Đã úp</StatusLabel>}
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<UploadWebsiteAction
				key={item.id}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
			/>
		)},
	];
	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default UploadWebsiteTable;