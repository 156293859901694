import {Tooltip} from "antd";
import {Button, Icon} from "~/components";
import {
	useCan
} from "~/hooks";

function SaleCommitAction({item, onClickEdit, onClickDelete}) {

	const useEdit   = useCan('salesCommitEdit')

	const useDelete = useCan('salesCommitDelete')

	const handleEditClick = () => {
		if(!onClickEdit) return;
		onClickEdit(item);
	}

	const handleDeleteClick = () => {
		if(!onClickDelete) return;
		onClickDelete(item);
	}

	return (
		<div className="table-action d-flex">
			{useEdit && <Tooltip title="Cập nhật"><Button background blue leftIcon={Icon.edit} onClick={handleEditClick}></Button></Tooltip>}
			{useDelete && <Tooltip title="Xóa cam kết"><Button background red leftIcon={Icon.delete} onClick={handleDeleteClick}></Button></Tooltip>}
		</div>
	)
}

export default SaleCommitAction;