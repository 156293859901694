import {forwardRef} from "react";
import {serviceApi} from "~/api";
import {useUtilities} from "~/hooks";
import {DebounceSelect} from "../index";
import {StatusLabel} from "../../index";

const SelectServiceAll = forwardRef(({options, errors, ...props}, ref) => {
	const {utilities} = useUtilities();
	const onSearch = async (keyword) => {
		return handleSearch(keyword, props?.service)
	}
	const handleSearch = async (keyword, service) => {
		const response = await serviceApi.gets({keyword, service});
		return response.data.map((service) => ({
			label: <div className="d-flex gap-1">
				<StatusLabel small type={utilities.services[service.service].color}>{utilities.services[service.service].label}</StatusLabel>
				<span>{service.domain}</span>
			</div>,
			value: service.id,
		}))
	}

	return (
		<DebounceSelect fetchOptions={onSearch} errors={errors} optionsDefault={options} {...props} ref={ref}/>
	)
});

export default SelectServiceAll;