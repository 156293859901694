import {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading, Button, Icon
} from "~/components";
import {
	saleWorkingActions,
	saleWorkingErrorSelector,
	saleWorkingFilterSelector,
	saleWorkingItemsSelector,
	saleWorkingLoadingSelector,
	saleWorkingPaginationSelector,
} from "../saleWorkingSlice";

import {
	SaleWorkingTable,
	WorkingSearchBar
} from "../components";

function SaleConsultation() {

	const dispatch  = useDispatch();

	const items     = useSelector(saleWorkingItemsSelector);

	const loading   = useSelector(saleWorkingLoadingSelector);

	const error     = useSelector(saleWorkingErrorSelector);

	const pagination = useSelector(saleWorkingPaginationSelector);

	const filter    = useSelector(saleWorkingFilterSelector);

	useEffect(() => {
		handleLoading();
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleLoading = (page) => {
		dispatch(saleWorkingActions.fetchData(filter));
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(saleWorkingActions.setFilter({...filter, page }));
	}

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(saleWorkingActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Hoạt động'}>
				<Button outline onClick={handleLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<WorkingSearchBar filter={filter} onChange={handleFilterChange} />
					{items && <SaleWorkingTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
					/>}
				</div>
			</div>
		</Fragment>
	)
}

export default SaleConsultation;