import {useContext} from "react";
import {Pagination, Table} from "antd";
import {StatusLabel, Image, PopperUserInfo} from "~/components";
import {
	renderDate,
} from "~/utils";
import CreatedWebsiteAction from "./CreatedWebsiteAction";
import {AppContext} from "~/context/AppProvider";
function CreatedWebsiteTable({items = [], pagination, onPaginationChange, onClickPlus, onClickReInsert}) {

	const {tableHeight} = useContext(AppContext);

	const RenderStatus = (status) => {
	    if(status == 0) return <StatusLabel type="blue">Đợi xóa</StatusLabel>;
	    if(status == 1) return <StatusLabel type="green">Đang sử dụng</StatusLabel>;
	    if(status == 2) return <StatusLabel type="red">Lỗi cài đặt</StatusLabel>;
	}

	const columns = [
		{ title: 'Người tạo', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
				<PopperUserInfo user={item.user}>
					<div className="d-flex gap align-items">
						<div className="avatar"><Image src={item.user?.avatar} /></div>
						<div>
							<b>{`${item.user?.firstname} ${item.user?.lastname}`}</b>
							<p>{item.user?.username}</p>
						</div>
					</div>
				</PopperUserInfo>
			)},
		{ title: 'Đường dẫn', dataIndex: 'url', key: "url", width: '180px', render: (_, item) => (
			<>
				<p><b>Admin:</b> <a href={item.urlAdmin} target='_blank'>{item?.name}/admin</a></p>
				<p><b>Client:</b> <a href={item.url} target='_blank'>{item?.name}</a></p>
			</>
		)},
		{ title: 'Tài khoản', dataIndex: 'username', key: "username", width: '100px', render: (_, item) => (<p>{item?.username}</p>)},
		{ title: 'Mật khẩu', dataIndex: 'password', key: "password", width: '100px', render: (_, item) => (<p>{item?.password}</p>)},
		{ title: 'Ngày tạo', dataIndex: 'created', key: "created", width: '110px', render: (_, item) => (<p>{renderDate(item?.created)}</p>)},
		{ title: 'Error', dataIndex: 'error', key: "error", width: '220px', render: (_, item) => (<p>{item?.error?.message}</p>)},
		{ title: 'Trạng thái', dataIndex: 'status', key: 'status',width: '150px', render:(_, item) => (
			RenderStatus(item.status)
		)},
		{ title: '#', dataIndex: 'action', key: 'action',width: '150px', render:(_, item) => (
			<CreatedWebsiteAction item={item} onClickPlus={onClickPlus} onClickReInsert={onClickReInsert} />
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default CreatedWebsiteTable;