import {useCallback} from "react";
import {
	DateField
} from "~/components/Forms";
import {strToTime} from "~/utils";

function FinancialReportSearch({onChange}) {

	const handleTimeChange = useCallback((value) => {
		value = strToTime(value);
		if(onChange) onChange('time', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<DateField name="time" picker="month" onChange={handleTimeChange}/>
			</form>
		</div>
	)
}

export default FinancialReportSearch;