import {useForm, Controller} from "react-hook-form";
import {Col, Row} from "antd";
import {Button, Loading } from "~/components";
import {
	InputField,
	DateField
} from "~/components/Forms";

function ContractFormService({item, onHandleSubmit}) {

	const date = new Date();

	date.setFullYear(date.getFullYear() + 1)

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({defaultValues: {
		expired: date.getTime(),
		domain: item.domain,
	}});


	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="domain" render={({ field }) => (
						<InputField type="text" label="Tên miền" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="expired" render={({ field }) => (
						<DateField type="text" label="Ngày hết hạn" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<div className="form-group d-flex justify-content-end mt-2 modal-bottom">
				<Button primary type="submit">Tạo dịch vụ</Button>
			</div>
		</form>
	)
}

export default ContractFormService;