import {memo, useContext} from "react";
import {Pagination, Table} from "antd";
import GroupAction from "./GroupAction";
import {StatusLabel} from "../../../../components";
import {AppContext} from "~/context/AppProvider";

function GroupTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const {tableHeight} = useContext(AppContext);

	const handleEditClick = (item) => {
		setItemEdit(item);
		openModal('addEdit');
	}
	const handleDeleteClick = (item) => {
		setItemEdit(item);
		openModal('delete');
	}
	const columns = [
		{ title: 'Tên Phòng', dataIndex: 'name', key: "name", render: (_, item) => (item?.name)},
		{ title: 'Số nhân viên', dataIndex: 'totalUser', key: "totalUser", width: '100px', render: (_, item) => (item?.totalUser)},
		{ title: 'Ngày tạo', dataIndex: 'created', key: "created", render: (_, item) => (item?.created)},
		{ title: 'Tình trạng', dataIndex: 'status', key: "status", render: (_, item) => (
			<>
				{
					(item?.totalUser == 0)
					? <StatusLabel small type="red">Dừng hoạt động</StatusLabel>
					: <StatusLabel small type="green">Đang hoạt động</StatusLabel>
				}
			</>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<GroupAction
				key={item.id}
				item={item}
				onClickEdit={handleEditClick}
				onClickDelete={handleDeleteClick}
			/>
		)},
	];
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y: tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(GroupTable);