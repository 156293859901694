import request from "~/utils/http";

const revenueApi = {
	gets : async (params) => {
		const url = 'revenues';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `revenues/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'revenues';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `revenues/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `revenues/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `revenues/status/${params.id}`;
		return await request.post(url, params);
	},
	transfer : async (params) => {
		const url = `revenues/transfer/${params.id}`;
		return await request.post(url, params);
	},
	pay : async (params) => {
		const url = `revenues/pay/${params.id}`;
		return await request.post(url, params);
	},
	report : async (params) => {
		const url = 'revenues/report';
		return await request.get(url, {params});
	},
};

export default revenueApi;