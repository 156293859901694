import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	InputField,
	DateField,
	SelectCustomerAll
} from "~/components/Forms";
import {
	Button,
	Icon,
	Notification,
} from "~/components";
import {strToTime} from "~/utils";

function ServiceSearchMobile({status, filter, onSearchChange, onChange}) {

	const serviceOptions = [
		{ value: 'host', label : 'Host' },
		{ value: 'ssl', label : 'SSL' },
		{ value: 'domain', label : 'Tên miền' },
		{ value: 'email', label : 'Email Server' },
	];

	const [searchBar, setSearchBar] = useState(false);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const onSubmit = (data) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			time: strToTime(data.time),
			keyword: data.keyword,
			customerId: data.customerId,
			service: data.service,
			page: 1,
		};
		setSearchBar(false);
		onSearchChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<InputField placeholder="Tên miền" onChange={handleKeywordChange}/>
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

					<Controller control={control} name="keyword" render={({field}) => (
						<InputField label="Tên miền" errors={errors} {...field} />
					)}/>

					<Controller control={control} name="customerId" render={({field}) => (
						<SelectCustomerAll label="Khách hàng" options={[{value:'', label:'Tất cả'}]} />
					)}/>
					{status != 'will-expired' &&
						<Controller control={control} name="time" render={({ field }) => (
							<DateField label="Thời gian" picker="month" errors={errors} {...field}/>
						)}/>
					}
					<Controller control={control} name="service" render={({field}) => (
						<SelectField label="Dịch vụ" options={serviceOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default ServiceSearchMobile;