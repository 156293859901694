import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Loading,
	Button,
	Modal
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	useCan
} from "~/hooks";

import {
	CreatedWebsiteTable,
	CreatedWebsiteFormAdd
} from "../components";

import {
	createdWebsiteApi
} from "~/api";

import {
	createdWebsiteActions,
	createdWebsiteItemsSelector,
	createdWebsiteLoadingSelector,
	createdWebsiteErrorSelector,
	createdWebsitePaginationSelector,
	createdWebsiteFilterSelector
} from "../createdWebsiteSlice";

function CreatedWebsite() {

	const can = {
		add     : useCan('createdWebsiteAdd'),
		status  : useCan('createdWebsiteStatus'),
	}

	const dispatch  = useDispatch();

	const items     = useSelector(createdWebsiteItemsSelector);

	const loading   = useSelector(createdWebsiteLoadingSelector);

	const error     = useSelector(createdWebsiteErrorSelector);

	const pagination = useSelector(createdWebsitePaginationSelector);

	const filter    = useSelector(createdWebsiteFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [templates, setTemplates] = useState([]);

	//Model
	const [openModal, setOpenModal] = useState({
		add     : false,
		info        : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleReLoading()
	}, [filter]);

	const handleReLoading = () => {
		dispatch(createdWebsiteActions.fetchData(filter));
	}

	useEffect(() => {
		fetchTemplateType().then();
	}, []);

	const fetchTemplateType = async () => {
		let [error, response] = await handleRequest(createdWebsiteApi.template());
		let message = apiError(`Load danh sách loại đơn thất bại`, error, response);
		if (!message) {
			setTemplates(response.data);
		}
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data) => {
		let [error, response] = await handleRequest(createdWebsiteApi.add(data));
		let message = apiError(`Thêm mới website thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm mới website thành công`});
			dispatch(createdWebsiteActions.add(response.data));
			handleModalClose('add')
		}
	}

	//status
	const handlePlus = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có website để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(createdWebsiteApi.status({
			id : item.id,
			status: 'success',
		}));
		let message = apiError(`Cập nhật trạng thái website thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái website thành công`});
			item.status  = response.data.status;
			dispatch(createdWebsiteActions.update(item));
		}
	}

	//reInsert
	const handleReInsert = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có website để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(createdWebsiteApi.reInsert(item.id));
		let message = apiError(`Cài đặt website thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cài đặt website thành công`});
			item.status = response.data.status;
			item.error  = response.data.error;
			dispatch(createdWebsiteActions.update(item));
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(createdWebsiteActions.setFilter({...filter, page }));
	}

	return (
		<Fragment>
			<ActionBar title={'Tạo website'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => handleModalOpen('add')}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <CreatedWebsiteTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onClickPlus={handlePlus}
						onClickReInsert={handleReInsert}
						can={can}
					/>}
				</div>
				{
					(can.add && openModal.add) && <Modal title="Tạo website" visible={openModal.add} onCancel={() => {handleModalClose('add')}}>
						<CreatedWebsiteFormAdd templates={templates} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default CreatedWebsite;