import request from "~/utils/http";

const authApi = {
	token : async (username, password) => {
		const url = 'auth/token';
		return await request.post(url, {username, password});
	},
	refresh : async (params) => {
		const url = 'auth/refreshToken';
		return await request.post(url, params);
	},
	getPermission : async (params) => {
		const url = (params?.role) ? `auth/permission/${params?.role}` : 'auth/permission';
		return await request.get(url, {params});
	},
	updatePermission : async (params) => {
		const url = `auth/permission/${params?.role}`;
		return await request.post(url, params);
	},
};

export default authApi;