import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {workingDayActions} from "./workingDaySlice";
import {workingDayApi} from "~/api";

function* fetchWorkingDayList(action) {
	try {
		const response = yield call(workingDayApi.gets, action.payload);
		yield put(workingDayActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch fetchWorkingDayList list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(workingDayActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(workingDayActions.setFilter(action.payload));
}

export default function* workingDaySaga() {
	yield takeLatest(workingDayActions.fetchData.type, fetchWorkingDayList);
	yield debounce(500, workingDayActions.setFilterWithDebounce.type, handleSearchDebounce)
}