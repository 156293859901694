import {Pagination, Table, Tooltip} from "antd";
import {Button, Icon} from "~/components";
function PapersTypeTable({items = [], pagination, onPaginationChange, setItemEdit, openModal, can}) {

	const handleButtonClick = {
		edit : (item) => {
			setItemEdit(item)
			openModal('edit');
		},
		delete : (item) => {
			setItemEdit(item)
			openModal('delete');
		}
	}

	const columns = [
		{ title: 'Loại đơn', dataIndex: 'name', key: "name", width: '220px', render: (_, item) => (
			<b style={{color:item.color}}>{item.name}</b>
		)},
		{ title: 'Mã số bắt đầu', dataIndex: 'numberFirst', key: "numberFirst", width: '120px', render: (_, item) => (<p>{item?.numberFirst}</p>)},
		{ title: 'Mã số hiện tại', dataIndex: 'numberCurrent', key: "numberCurrent", width: '120px', render: (_, item) => (<p>{item?.numberCurrent}</p>)},
		{ title: 'SL đã xuất', dataIndex: 'number', key: "number", width: '120px', render: (_, item) => (<p>{item?.numberCurrent - item?.numberFirst + 1}</p>)},
		{ title: 'SL đã phát', dataIndex: 'amount', key: "amount", width: '120px', render: (_, item) => (<p>{item?.numberCurrent - item?.numberFirst + 1 - item?.amount}</p>)},
		{ title: 'SL còn lại', dataIndex: 'extant', key: "extant", width: '120px', render: (_, item) => (<b>{item?.amount}</b>)},
		{ title: 'Ghi chú', dataIndex: 'note', key: 'note', width: '120px', render:(_, item) => (<div>{item.note}</div>)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<>
				{(can.edit) && <Tooltip title="Edit thông tin"><Button small background blue leftIcon={Icon.edit} onClick={() => handleButtonClick.edit(item)}></Button></Tooltip>}
				{(can.delete) && <Tooltip title="Xóa"><Button small background red leftIcon={Icon.delete} onClick={() => handleButtonClick.delete(item)}></Button></Tooltip>}
			</>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default PapersTypeTable;