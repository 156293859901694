import {useState, useContext} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {AppContext} from "~/context/AppProvider";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {Col, Row} from "antd";
import {
	DateField,
	InputField,
	InputPriceField,
	SelectField,
	SelectUserAll,
	SelectContractAll
} from "~/components/Forms";
import {useUtilities} from "~/hooks";
import {StatusLabel} from "../index";

function SalesAddAllForm({onHandleSubmit}) {

	const {utilities} = useUtilities();
	/**
	 * Khách Hàng
	 */
	const {country} = useContext(AppContext);

	let cityOptions = [];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const forOptions = [
		{ value: 'canhan', label: 'Cá Nhân'},
		{ value: 'congty', label: 'Công Ty'},
	];

	const date = new Date();

	date.setFullYear(date.getFullYear() + 1)

	const renderCompanyInfo = () => {
		if((forInput == 'congty')) return (
			<>
				<p className="heading">Thông tin khách hàng</p>
				<Row gutter={10}>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="companyName" render={({ field }) => (
							<InputField label="Tên công ty" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="taxCode" render={({ field }) => (
							<InputField label="Mã số thuế" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			</>
		)
	}

	/*
	Hợp đồng
	 */
	const careerOptions = [
		{ value: 'fashion', label: 'Thời trang'},
		{ value: 'cosmetics', label: 'Mỹ phẩm'},
		{ value: 'technology', label: 'Công nghệ'},
		{ value: 'phoneLaptop', label: 'Điện thoại - Laptop'},
		{ value: 'MotorcycleCar', label: 'Xe máy - ô tô'},
		{ value: 'mechanical', label: 'Cơ khí'},
		{ value: 'food', label: 'Thực phẩm'},
		{ value: 'freshFood', label: 'Thực phẩm tươi sóng'},
		{ value: 'functionalFood', label: 'Thực phẩm chức năng'},
		{ value: 'milkTeaDrink', label: 'Trà sữa - thức uống'},
		{ value: 'interior', label: 'Nội thất'},
		{ value: 'householdAppliances', label: 'Đồ gia dụng'},
		{ value: 'build', label: 'Xây dựng'},
		{ value: 'refrigeration', label: 'Điện lạnh'},
		{ value: 'electricity', label: 'Điện máy'},
		{ value: 'electricalEquipment', label: 'Thiết bị điện'},
		{ value: 'sanitaryEquipment', label: 'Thiết bị vệ sinh'},
		{ value: 'realEstate', label: 'Bất động sản'},
		{ value: 'travel', label: 'Du lịch'},
		{ value: 'hotelRestaurant', label: 'Khách sạn - Nhà Hàng'},
		{ value: 'transportation', label: 'Vận tải'},
		{ value: 'beautySpa', label: 'Spa làm đẹp'},
		{ value: 'studio', label: 'Studio'},
		{ value: 'motherBaby', label: 'Mẹ & bé'},
		{ value: 'education', label: 'Giáo dục'},
		{ value: 'stationery', label: 'Văn phòng phẩm'},
		{ value: 'sport', label: 'Thể thao'},
		{ value: 'eventOrganization', label: 'Tổ chức sự kiện'},
		{ value: 'medical', label: 'Y tế'},
		{ value: 'medicinalHerbs', label: 'Dược liệu'},
	];

	let serviceOptions = [];

	for (const [key, value] of Object.entries(utilities.services)) {
		serviceOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	let serviceTypeOptions = [];
	for (const [key, value] of Object.entries(utilities.serviceTypes)) {
		serviceTypeOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	const renderServiceOption = () => {

		let options = [];

		serviceTypeOptions = serviceTypeOptions.filter((item) => {
			return utilities.services[serviceInput].type.includes(item.value)
		});

		if(serviceInput == 'host') {
			for (const [key, value] of Object.entries(utilities.services[serviceInput].options)) {
				options.push({value : key, label : value.label})
			}
			return (
				<Row gutter={10}>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Dung lượng" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(serviceInput == 'ssl') {
			for (const [key, value] of Object.entries(utilities.services[serviceInput].options)) {
				options.push({value : key, label : value.label})
			}
			return (
				<Row gutter={10}>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Loại SSL" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(serviceInput == 'email') {
			for (const [key, value] of Object.entries(utilities.services[serviceInput].options)) {
				options.push({value : key, label : value.label})
			}
			return (
				<Row gutter={10}>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Loại Email" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(serviceInput == 'website') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="contractParent" render={({ field }) => (
							<SelectContractAll service="website" label="Hợp đồng cần nâng cấp" options={[]} errors={errors} {...field} />
						)}/>
					</Col>
				</Row>
			)
		}
		else {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" xs={24} md={12}>
						<Controller control={control} name="serviceType" render={({ field }) => (
							<SelectField label="Loại dịch vụ" options={serviceTypeOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
	}

	/*
	* Doanh Thu
	 */
	const paymentOptions = [
		{ value: 'cod', label: 'Tiền mặc'},
		{ value: 'bank', label: 'Chuyển khoản'},
		{ value: 'momo', label: 'Ví Momo'},
		{ value: 'swipe', label: 'Quẹt thẻ'},
		{ value: 'alePay', label: 'AlePay'},
	];

	const {control, handleSubmit, formState: { isSubmitting, errors }, setValue } = useForm({
		defaultValues: {
			for: 'canhan',
			companyName: undefined,
			taxCode: undefined,
			name: undefined,
			numberId: undefined,
			email: undefined,
			phone: undefined,
			address: undefined,
			userShareId: undefined,

			contractName: undefined,
			domain: undefined,
			career: undefined,
			signing: new Date(),
			price: 0,
			service: 'website',
			serviceType:undefined,
			option: undefined,
			contractParent: undefined,

			time: new Date(),
			revenuePrice: 0,
			payment: 'cod',
		},
		resolver: yupResolver(Yup.object().shape({
			for: Yup.string().required('Bạn chưa chọn loại khách hàng'),
			companyName: Yup.string().when('for', {
				is: 'congty',
				then: Yup.string().required('Tên công ty không được bỏ trống'),
			}),
			taxCode: Yup.string().when('for', {
				is: 'congty',
				then: Yup.string().required('Mã số thuế không được bỏ trống'),
			}),
			name: Yup.string().required('Họ tên khách hàng không được để trống'),
			email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
			phone: Yup.string().required('Số điện thoại không được để trống'),
			city: Yup.string().required('Tỉnh thành không được để trống'),
			district: Yup.string().required('Quận huyện không được để trống'),
			ward: Yup.string().required('Phường xã không được để trống'),


			contractName: Yup.string().required('Tên hợp đồng không được để trống'),
			domain: Yup.string().when('service', {
				is: ['host', 'email', 'ssl'],
				then: Yup.string().required('Tên miền không được bỏ trống'),
			}),
			career: Yup.string().required('Bạn chưa chọn ngành nghề kinh doanh'),
			signing: Yup.string().required('Bạn chưa chọn ngày ký hợp đồng'),
			price: Yup.number().required('Giá trị hợp đồng không được để trống'),
			service: Yup.string().required('Bạn chưa chọn dịch vụ hợp đồng'),
			serviceType: Yup.string().required('Bạn chưa chọn loại dịch vụ hợp đồng'),
			option: Yup.string().when('service', {
				is: 'host',
				then: Yup.string().required('Dung lượng host không được bỏ tróng'),
			}).when('service', {
				is: 'email',
				then: Yup.string().required('Loại email không được bỏ tróng'),
			}).when('service', {
				is: 'ssl',
				then: Yup.string().required('Loại chứng chỉ không được bỏ tróng'),
			}),

			time: Yup.string().required('Bạn chưa chọn ngày thu'),
			revenuePrice: Yup.number().required('Giá trị thu không được để trống'),
			payment: Yup.string().required('Bạn chưa chọn phương thức thanh toán'),
		}))
	});

	const forInput = useWatch({
		control,
		name: "for"
	});

	const serviceInput = useWatch({
		control,
		name: "service"
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data)
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col className="gutter-row" xs={24} md={8}>
					<p className="heading">Khách hàng</p>
					<Controller control={control} name="for" render={({ field }) => (
						<SelectField label="Loại khách hàng" options={forOptions} errors={errors} {...field}/>
					)}/>
					{renderCompanyInfo()}
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="name" render={({field}) => (
								<InputField label="Họ tên (Người đại diện)" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="numberId" render={({ field }) => (
								<InputField label="CCCD / CMND" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="email" render={({ field }) => (
								<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="phone" render={({ field }) => (
								<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col className="gutter-row" span={24}>
							<Controller control={control} name="address" render={({ field }) => (
								<InputField label="Địa chỉ" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={8}>
							<Controller control={control} name="city" render={({ field }) => (
								<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
									handleCityChange(value);
									field.onChange(value, event);
								}}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={8}>
							<Controller control={control} name="district" render={({ field }) => (
								<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
									handleDistrictChange(value);
									field.onChange(value, event);
								}}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={8}>
							<Controller control={control} name="ward" render={({ field }) => (
								<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				</Col>
				<Col className="gutter-row" xs={24} md={8}>
					<p className="heading">Hợp đồng</p>
					<Controller control={control} name="userShareId" render={({ field }) => (
						<SelectUserAll label="Share với nhân viên" options={[]} errors={errors} {...field} />
					)}/>
					<Controller control={control} name="contractName" render={({field}) => (
						<InputField label="Tên hợp đồng" errors={errors} {...field}/>
					)}/>
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="domain" render={({ field }) => (
								<InputField label="Tên miền" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="price" render={({field}) => (
								<InputPriceField label="Giá trị hợp đồng" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="career" render={({field }) => (
								<SelectField label="Ngành nghề" options={careerOptions} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="signing" render={({ field }) => (
								<DateField label="Ngày ký" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<p className="heading">Dịch vụ</p>
					<Controller control={control} name="service" render={({ field }) => (
						<SelectField options={serviceOptions} errors={errors} {...field} />
					)}/>
					{renderServiceOption()}
				</Col>
				<Col className="gutter-row" xs={24} md={8}>
					<p className="heading">Doanh thu</p>
					<Row gutter={10}>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="revenuePrice" render={({field}) => (
								<InputPriceField label="Giá trị thu" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" xs={24} md={12}>
							<Controller control={control} name="time" render={({ field }) => (
								<DateField label="Ngày thu" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<p className="heading">Thanh toán</p>
					<Controller control={control} name="payment" render={({ field }) => (
						<SelectField options={paymentOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Thêm mới</Button>
			</div>
		</form>
	)
}

export default SalesAddAllForm;