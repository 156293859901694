import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	InputField,
	EditorField
} from "~/components/Forms";

function DocumentFormAdd({group, onHandleSubmit}) {

	const initialValues = {
		name: undefined,
		content : undefined
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Tiêu đề tài liệu không được để trống'),
		content: Yup.string().required('Chi tiết tài liệu không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, group);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="name" render={({ field }) => (
				<InputField label="Tiêu đề" rows={5} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="content" render={({ field }) => (
				<EditorField label="Nội dung" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Thêm tài liệu</Button>
			</div>
		</form>
	)
}

export default DocumentFormAdd;