import style from '../scss/Account.module.scss';
import className from 'classnames/bind';
import {Col, Row} from "antd";
import {
	StatusLabel,
} from "~/components";
import {
	useCurrentUser,
	useUtilities
} from "~/hooks";
import {
	renderDate
} from "~/utils";
import {
	USER_STATUS
} from "~/app/constants";

import AccountHeader from "../components/AccoutHeader";

const cn = className.bind(style);

function AccountInfo() {

	const {utilities} = useUtilities();

	const currentUser = useCurrentUser();

	return (
		<div className="container">
			<div className={cn('profile')}>
				<AccountHeader />
				<div className="content pd-2 w-100 text-left">
					<h3 className="heading text-lg">Thông tin cá nhân</h3>
					<Row gutter={10}>
						<Col xs={24} md={8}>
							<div className={cn('group-info')}>
								<label className={cn('label')}>Email</label>
								<p>{currentUser.email}</p>
							</div>
						</Col>
						<Col xs={24} md={8}>
							<div className={cn('group-info')}>
								<label className={cn('label')}>Số điện thoại</label>
								<p>{currentUser.phone}</p>
							</div>
						</Col>
						<Col xs={24} md={8}>
							<div className={cn('group-info')}>
								<label className={cn('label')}>Ngày sinh</label>
								<p>{renderDate(currentUser.birthday)}</p>
							</div>
						</Col>
					</Row>
				</div>
				<div className="content pd-2 w-100 text-left">
					<h3 className="heading text-lg">Thông tin nhân viên</h3>
					<Row gutter={10}>
						<Col xs={24} md={8}>
							<div className={cn('group-info')}>
								<label className={cn('label')}>Loại nhân viên</label>
								<p><StatusLabel small type={USER_STATUS[currentUser.status]?.color}>{USER_STATUS[currentUser.status]?.title}</StatusLabel></p>
							</div>
						</Col>
						<Col xs={24} md={8}>
							<div className={cn('group-info')}>
								<label className={cn('label')}>Phòng ban</label>
								<p><StatusLabel small type={utilities.departments[currentUser.department]?.color}>{utilities.departments[currentUser.department]?.title}</StatusLabel></p>
							</div>
						</Col>
						<Col xs={24} md={8}>
							<div className={cn('group-info')}>
								<label className={cn('label')}>Chức vụ</label>
								<p><StatusLabel small type={utilities.roles[currentUser.role]?.color}>{utilities.roles[currentUser.role]?.title}</StatusLabel></p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}

export default AccountInfo;