import {useCallback, useMemo} from "react";
import {
	DateRangeField,
	SelectField
} from "~/components/Forms";
import {strToTime} from "~/utils";

function SaleEventSearch({listGroup, onChange}) {

	const listViews = [
		{value: 'groups', label: 'Xem theo nhóm'},
		{value: 'users', label: 'Xem theo nhân viên'},
	];

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả nhóm' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const handleTypeChange = useCallback((value) => {
		if(onChange) onChange('type', value);
	}, [onChange]);

	const handleTimeChange = useCallback((value) => {
		let valueNew = [...value]
		valueNew[0] = strToTime(valueNew[0]);
		valueNew[1] = strToTime(valueNew[1]);
		if(onChange) onChange('time', valueNew);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('group', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<SelectField placeholder="Trạng thái" options={listViews} onChange={(value) => handleTypeChange(value)}></SelectField>
				<DateRangeField name="time" onChange={(value) => handleTimeChange(value)} />
				<SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />
			</form>
		</div>
	)
}

export default SaleEventSearch;