import {call, put, takeLatest} from "redux-saga/effects";
import {documentActions} from "./DocumentSlice";
import {documentApi} from "~/api";

function* fetchRuleList(action) {
	try {
		const response = yield call(documentApi.getsGroupWidthItem, action.payload);
		yield put(documentActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch project list', error);
		yield put(documentActions.fetchDataFailed(error.message));
	}
}

export default function* documentSaga() {
	yield takeLatest(documentActions.fetchData.type, fetchRuleList);
}