import {Button, Icon} from "~/components";
import {
	useCan
} from "~/hooks";

function ExtendAction({item, setItemEdit, openModal}) {

	const can = {
		edit: useCan('contractEdit'),
		delete: useCan('contractDelete'),
	};

	const buttonHandlers = {
		edit: () => {
			setItemEdit(item);
			openModal('edit');
		},
	};

	const actionButtons = [
		<Button key="edit" background blue leftIcon={Icon.edit} onClick={buttonHandlers.edit}></Button>,
	];

	return (
		<div className="table-action d-flex">
			{actionButtons}
		</div>
	)
}

export default ExtendAction;