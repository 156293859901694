import {useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {
	SelectField,
	InputField,
} from "~/components/Forms";

function CreatedWebsiteFormAdd({templates, onHandleSubmit}) {

	const templateOptions = useMemo(() => {
		return [{ value: 0, label: 'Chọn giao diện' }, ...Object.entries(templates).map(([key, template]) => {
			return {
				value: key,
				label: template
			};
		})];
	}, [templates]);

	const initialValues = {
		name: undefined,
		username: undefined,
		password: undefined,
		template: undefined,
	}

	const { control, handleSubmit, formState: { isSubmitting, errors } } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape({
			name: Yup.string().required('Bạn chưa điền tên website'),
			username: Yup.string().required('Bạn chưa điền tài khoản admin'),
			password: Yup.string().required('Bạn chưa điền mật khẩu admin'),
			template: Yup.string().required('Bạn chưa chọn giao diện mẫu'),
		}))
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Yêu cầu</p>
			<Controller control={control} name="name" render={({field}) => (
				<InputField label="Tên website" errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="username" render={({field}) => (
				<InputField label="Admin username (tài khoản)" errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="password" render={({field}) => (
				<InputField label="Admin password (mật khẩu)" errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="template" render={({field}) => (
				<SelectField label="Giao diện mẫu" options={templateOptions} errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Tạo website</Button>
			</div>
		</form>
	)
}

export default CreatedWebsiteFormAdd;