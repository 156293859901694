import request from "~/utils/http";

const candidateApi = {
	gets : async (params) => {
		const url = 'candidates';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `candidates/${id}`;
		try {
			return await request.get(url);
		} catch (err) {
			return {
				error: true,
				data: {
					message: err.message
				}
			};
		}
	},
	add : async (params) => {
		const url = 'candidates';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `candidates/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `candidates/${id}`;
		return await request.delete(url);
	}
};

export default candidateApi;