import style from '../Home.module.scss';
import className from 'classnames/bind';
import {Image} from "../../../components";
import {Tooltip} from "antd";
import {numberFormat} from "../../../utils";
const cn = className.bind(style);

const CardMoney = ({image, heading, number1, number2, number3, txt1, txt2, txt3}) => {
	return (
		<div className={cn('card', 'card-money', 'card-while')}>
			<div className={cn('icon')}><Image src={image} /></div>
			<div className={cn('info')}>
				<p className={cn('heading-mini')}>{heading}</p>
				<p className={cn('number')}>
					{(typeof number1 != 'undefined') && <Tooltip title={txt1}><span className="color-red">{numberFormat(number1)}</span></Tooltip>}
					{(typeof number2 != 'undefined') && <Tooltip title={txt2}><span> / {numberFormat(number2)}</span></Tooltip>}
					{(typeof number3 != 'undefined') && <Tooltip title={txt3}><span> / {numberFormat(number3)}</span></Tooltip>}
				</p>
			</div>
		</div>
	)
}

export default CardMoney;