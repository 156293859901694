import {useState} from "react";
import {Tooltip} from "antd";
import Tippy from "@tippyjs/react/headless";
import {
	Button,
	PopperWrapper,
	Icon
} from "~/components";
import {useCan} from "~/hooks";

function SaleEventAction({item, setItemEdit, openModal}) {

	const can = {
	    edit : useCan('salesEventEdit'),
	    delete : useCan('salesEventDelete'),
	    cancel : useCan('salesEventCancel')
	}

	const [showActionBox, setShowActionBox] = useState(false);

	const closeActionButton = () => {
		setShowActionBox(false);
	};

	const handleButtonClick = {
		edit : function () {
			closeActionButton();
			setItemEdit(item);
			openModal('addEdit');
		},
		copy : function () {
			closeActionButton();
			setItemEdit(item);
			openModal('copy');
		},
		delete : function () {
			closeActionButton();
			setItemEdit(item);
			openModal('delete');
		},
		cancel : function () {
			setItemEdit(item);
			openModal('cancel');
		},
		start : function () {
			setItemEdit(item);
			openModal('start');
		},
		end : function () {
			setItemEdit(item);
			openModal('end');
		},
	}

	const renderActionButton = () => {
		return (
			<PopperWrapper className="action-wrapper" style={{minWidth: '220px', minHeight: 'auto' }}>
				{can.edit && <Button leftIcon={Icon.edit} onClick={handleButtonClick.edit}>Cập nhật</Button>}
				{can.edit && <Button leftIcon={Icon.copy} onClick={handleButtonClick.copy}>Dời hẹn</Button>}
				{can.delete && <Button leftIcon={Icon.delete} onClick={handleButtonClick.delete} outline red noneBorder> Xóa lịch hẹn</Button>}
			</PopperWrapper>
		)
	}

	return (
		<div className="table-action d-flex">
			{item.point == 0 && <Tooltip title="Đi gặp khách"><Button background blue leftIcon={Icon.moto} onClick={handleButtonClick.start}></Button></Tooltip>}
			{item.point == 1 && <Tooltip title="Hoàn thành cuộc hẹn"><Button background green leftIcon={Icon.commentCheck} onClick={handleButtonClick.end}></Button></Tooltip>}
			{(can.cancel && item.point != 3 && item.point != 2) && <Tooltip title="Hủy hẹn"><Button background red leftIcon={Icon.commentSlash} onClick={handleButtonClick.cancel}></Button></Tooltip>}
			<Tippy
				interactive
				visible={showActionBox}
				render={renderActionButton}
				onClickOutside={closeActionButton}
				placement="left"
				offset={[0, -10]}
			>
				<div onClick={() => setShowActionBox(true)} className="action-btn">{Icon.menuVertical}</div>
			</Tippy>
		</div>
	)
}

export default SaleEventAction;