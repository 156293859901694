import style from '../Home.module.scss';
import {memo, useCallback, useEffect, useState} from "react";
import className from 'classnames/bind';
import _ from "lodash";
import {Col, Row} from "antd";
import images from "~/assets/images";

import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	dashboardApi
} from "~/api";
import {
	CardMoney,
	CardRanksDesign,
	ContentHeader,
	SidebarAccount
} from "../componets";


const cn = className.bind(style);

function DashboardDesignManager({user, fullTime}) {

	const [dashboardData, setDashboardData] = useState({
		projectCount: { new: 0, wait: 0, confirm: 0, deadline : 0 },
		projectRanks: [],
	});

	useEffect(() => {
		fetchDashboardData().then()
	}, [user]);

	const fetchDashboardData = useCallback(async () => {
		const [
			projectResponse,
			projectRanksResponse,
		] = await Promise.all([
			handleRequest(dashboardApi.designProject()),
			handleRequest(dashboardApi.designRanks()),
		]);
		//Dự án
		let [projectErr, projectData] = projectResponse;
		let projectMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, projectErr, projectData);
		if (!projectMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				projectCount: {
					new: projectData.data.new,
					wait: projectData.data.wait,
					confirm: projectData.data.confirm,
					deadline: projectData.data.deadline,
				},
			}));
		}

		let [projectRanksErr, projectRanksData] = projectRanksResponse;
		let projectRanksMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, projectRanksErr, projectRanksData);
		if (!projectRanksMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				projectRanks: projectRanksData.data,
			}));
		}

	}, [])

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} fullTime={fullTime} />
				<Row gutter={20}>
					<Col span={12}>
						<Row gutter={20}>
							<Col span={12}>
								<CardMoney image={images.dashboard06}
								           heading={'Dự án mới'}
								           number1={dashboardData.projectCount.new} txt1={'Dự án mới'}
								/>
							</Col>
							<Col span={12}>
								<CardMoney image={images.dashboard07}
								           heading={'Đợi duyệt'}
								           number1={dashboardData.projectCount.wait} txt1={'Đợi duyệt'}
								/>
							</Col>
						</Row>

						<Row gutter={20}>
							<Col span={12}>
								<CardMoney image={images.dashboard08}
								           heading={'Đợi giao'}
								           number1={dashboardData.projectCount.wait}
								/>
							</Col>
							<Col span={12}>
								<CardMoney image={images.dashboard09}
								           heading={'Dự án trể'}
								           number1={dashboardData.projectCount.deadline} txt1={'Trể deadline'}
								/>
							</Col>
						</Row>
					</Col>
					<Col span={12}>
						<CardRanksDesign name={'Kết Quả Làm Việc'} items={dashboardData.projectRanks} cardColor={'card-violet'} bgImage={images.dashboard01} />
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={12}>
					</Col>
					<Col span={12}>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default DashboardDesignManager;