import {Fragment} from "react";
import style from './ProjectItem.module.scss';
import className from 'classnames/bind';
import {Table} from "antd";
import {StatusLabel, Image, Button, Icon, PopperUserInfo} from "~/components";
import {renderDate} from "~/utils";
import {
	LIST_TASK_STATUS,
	LIST_PROJECT_PHASE
} from "~/app/constants";
import { useCan, useDevice } from "~/hooks";

import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";

const cn = className.bind(style);

function ProjectItem({project = [], setItemEdit, setProject, openModal}) {

	const {isMobile} = useDevice();

	dayjs.extend(relativeTime)

	const can = {
		add: useCan('taskAdd'),
		edit: useCan('taskEdit'),
		status: useCan('taskStatus'),
		assign: useCan('projectAssign'),
		phase: useCan('projectPhase'),
		delete: useCan('projectDelete'),
	};

	const handleButtonClick = {
		status: (item) => {
			setProject(project);
			setItemEdit(item);
			openModal('status');
		},
		add: (project) => {
			setProject(project);
			openModal('add');
		},
		edit: (item) => {
			setProject(project);
			setItemEdit(item);
			openModal('edit');
		},
		info: (item) => {
			setProject(project);
			setItemEdit(item);
			openModal('info');
		},
		delete: (item) => {
			setProject(project);
			setItemEdit(item);
			openModal('delete');
		}
	}

	let columns = [
		{ title: 'Yêu cầu', dataIndex: 'name', key: "name", width: '430px', render: (_, item) => (
			<Fragment>
				<p onClick={() => handleButtonClick.info(item)} style={{fontSize : '13px'}}>{item?.name}</p>
				{
					isMobile && <>
						<div className="mb-1">
							{
								(can.status)
									? <StatusLabel pointer onClick={() => handleButtonClick.status(item)} small type={LIST_TASK_STATUS[item.status].color}>{LIST_TASK_STATUS[item.status].label}</StatusLabel>
									: <StatusLabel small type={LIST_TASK_STATUS[item.status].color}>{LIST_TASK_STATUS[item.status].label}</StatusLabel>
							}
							<StatusLabel small type={LIST_PROJECT_PHASE[item.phase].color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
						</div>
						<Button small leftIcon={Icon.info} onClick={() => handleButtonClick.info(item)} background blue></Button>
						{can.edit && <Button small leftIcon={Icon.edit} onClick={() => handleButtonClick.edit(item)} background blue></Button>}
						{can.delete && <Button small leftIcon={Icon.delete} onClick={() => handleButtonClick.delete(item)} background red></Button>}
					</>
				}
			</Fragment>
		)},
		{title: 'Giai đoạn', dataIndex: 'phase', key: 'phase', width: '150px', render:(_, item) => (
			<Fragment>
				<StatusLabel small type={LIST_PROJECT_PHASE[item.phase].color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
			</Fragment>
		)},
		{ title: 'Kinh Doanh', dataIndex: 'sales', key: "sales", width: '180px', render: (_, item) => (
			<PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{item.user?.lastname}</b>
						<p>{item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Chỉnh sữa', dataIndex: 'assign', key: "assign", width: '180px', render: (_, item) => (
			item.assign?.id && <PopperUserInfo user={item.assign}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.assign?.avatar} /></div>
					<div>
						<b>{item.assign?.lastname}</b>
						<p>{item.assign?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Deadline', dataIndex: 'deadline', key: "deadline", width: '130px', render: (_, item) => (
			<Fragment>
				<p>{renderDate(item?.deadline)}</p>
				<p style={{fontSize:'12px', color:'#aaa'}}>{dayjs(item.created*1000).fromNow()}</p>
			</Fragment>
		)},
		{title: 'Tình trạng', dataIndex: 'status', key: 'status', width: '180px', render:(_, item) => (
			<Fragment>
				{
					(can.status)
						? <StatusLabel pointer onClick={() => handleButtonClick.status(item)} small type={LIST_TASK_STATUS[item.status].color}>{LIST_TASK_STATUS[item.status].label}</StatusLabel>
						: <StatusLabel small type={LIST_TASK_STATUS[item.status].color}>{LIST_TASK_STATUS[item.status].label}</StatusLabel>
				}
			</Fragment>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<Fragment>
				<Button small leftIcon={Icon.info} onClick={() => handleButtonClick.info(item)} background blue></Button>
				{can.edit && <Button small leftIcon={Icon.edit} onClick={() => handleButtonClick.edit(item)} background blue></Button>}
				{can.delete && <Button small leftIcon={Icon.delete} onClick={() => handleButtonClick.delete(item)} background red></Button>}
			</Fragment>
		)}
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'action'
				&& currentValue.key != 'status'
				&& currentValue.key != 'phase'
			)
		})
	}

	const dataSource= project.tasks.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="content">
			<div className={cn('project-info')+ ' d-flex align-items gap justify-content-between'}>
				<div className={cn('project-heading')}>
					<StatusLabel type="green">{project.contract.code}</StatusLabel>
					<StatusLabel type="blue">{project.contract.domain}</StatusLabel>
					<span className={cn('name')}>{project.contract.name}</span>
					<StatusLabel small type={LIST_PROJECT_PHASE[project.phase].color}>{LIST_PROJECT_PHASE[project.phase].label}</StatusLabel>
					<StatusLabel small background type={'red'}>{project.unfinishedTask}</StatusLabel>
				</div>
				<div>
					{can.add && <Button small outline leftIcon={Icon.plusCircle} onClick={() => handleButtonClick.add(project)}>Thêm mới</Button>}
				</div>
			</div>
			<div className={cn('project-task')}>
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} showHeader={false} />
			</div>
		</div>
	)
}

export default ProjectItem