import {useState} from "react";
import StatusLabel from "~/components/StatusLabel";
import {
	InputField,
	SelectField,
	DateField
} from "~/components/Forms";
import {
	useCan,
	useGroup,
	useCurrentUser,
	useUtilities
} from "~/hooks";
import {strToTime} from "~/utils";

function WorkingDaySearchBar({filter, onSearchChange, onChange}) {

	const {utilities} = useUtilities();

	const currentUser 		= useCurrentUser();

	const canViewAll 		= useCan('workingDayListAll');

	const canViewDepartment = useCan('workingDayListDepartment');

	const canViewGroup 		= useCan('workingDayListGroup');

	let [listPosition, setListPosition] = useState(utilities.roles);

	if(!canViewAll && canViewDepartment && currentUser?.department) {
		listPosition = Object.keys(utilities.roles).filter((key) => {
			return utilities.roles[key].department == currentUser.department;
		}).reduce((obj, key) => {
			obj[key] = utilities.roles[key];
			return obj;
		}, {})
	}

	const positionOptions = [{value: '', label: 'Tất cả vị trí'}];

	for (const [key, item] of Object.entries(listPosition)) {
		positionOptions.push({value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>})
	}

	const departmentOptions = [{ value: '', label: <StatusLabel label="Vị trí" type="white" small>Tất cả phòng ban</StatusLabel>}];

	if(canViewAll) {
		for (const [key, item] of Object.entries(utilities.departments)) {
			departmentOptions.push({ value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>})
		}
	}

	const listGroup = useGroup();

	const groupOptions = [{value: '', label: 'Tất cả các nhóm'}];

	for (const [key, item] of Object.entries(listGroup)) {
		groupOptions.push({value : item.id, label : item.name})
	}

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}
	const handleDepartmentChange = (value) => {
		if (!onChange) return;
		if(value !== '') {
			setListPosition(Object.keys(utilities.roles).filter((key) => {
				return utilities.roles[key].department == currentUser.department;
			}).reduce((obj, key) => {
				obj[key] = utilities.roles[key];
				return obj;
			}, {}));
		}
		else {
			setListPosition(utilities.roles);
		}
		const newFilter = {
			...filter,
			department: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleRoleChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			role: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleGroupChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			group: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};
	const handleTimeChange = (value) => {
		if (!onChange) return;
		value = strToTime(value);
		const newFilter = {
			...filter,
			time: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<DateField name="time" placeholder="Thời gian áp dụng" picker="month" onChange={handleTimeChange}/>
					{(canViewAll || canViewDepartment || canViewGroup) && <InputField name="keyword" placeholder="Tên, email, số điện thoại nhân viên" onChange={handleKeywordChange} />}
					{canViewAll && <SelectField name="department" placeholder="Phòng ban" options={departmentOptions} onChange={(value) => handleDepartmentChange(value)} />}
					{(canViewAll || canViewDepartment) && <SelectField name="roles" placeholder="Chọn chức vụ" options={positionOptions} onChange={(value) => handleRoleChange(value)} />}
					{(canViewAll || ((canViewDepartment || canViewGroup) && currentUser.department == 'sale')) && <SelectField name="group" placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />}
				</form>
			</div>
		</div>
	)
}

export default WorkingDaySearchBar;