import {useState} from "react";
import Tippy from '@tippyjs/react/headless';
import {Tooltip} from "antd";
import {Button, Icon, PopperWrapper} from "~/components";
import { useCan } from "~/hooks";

function RevenueAction({item, onClickInfo, onClickEdit, onClickPay, onClickDelete, onClickTransfer}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const can = {
		add: useCan('revenueAdd'),
		edit: useCan('revenueEdit'),
		transfer: useCan('revenueTransfer'),
		delete: useCan('revenueDelete'),
		status: useCan('revenueStatus'),
	};

	const buttonHandlers = {
		info: () => onClickInfo?.(item),
		edit: () => {
			onClickEdit?.(item);
			setShowActionBox(false);
		},
		pay: () => {
			onClickPay?.(item);
			setShowActionBox(false);
		},
		delete: () => {
			onClickDelete?.(item);
			setShowActionBox(false);
		},
		transfer: () => {
			onClickTransfer?.(item);
			setShowActionBox(false);
		},
	};

	const actionButtons = [
		can.edit && <Button key="edit" leftIcon={Icon.edit} onClick={buttonHandlers.edit}>Cập nhật thông tin</Button>,
		can.transfer && <Button key="transfer" leftIcon={Icon.switch} onClick={buttonHandlers.transfer}>Chuyển nhóm</Button>,
		can.transfer && <Button key="pay" leftIcon={Icon.dola} onClick={buttonHandlers.pay}>Tính lương</Button>,
		can.delete && <Button key="delete" leftIcon={Icon.delete} onClick={buttonHandlers.delete} outline red noneBorder> Xóa doanh thu</Button>,
	];

	return (
		<div className="table-action d-flex">
			<Tooltip title="Thông tin"><Button background blue leftIcon={Icon.info} onClick={buttonHandlers.info}></Button></Tooltip>
			{(can.edit || can.delete || can.transfer) && (
				<Tippy
					interactive
					visible={showActionBox}
					onClickOutside={() => setShowActionBox(false)}
					render={() => (
						<PopperWrapper className="action-wrapper" style={{ minWidth: '220px', minHeight: 'auto' }}>
							{actionButtons}
						</PopperWrapper>
					)}
					placement="left"
					offset={[0, -10]}
				>
					<div onClick={() => setShowActionBox(!showActionBox)} className="action-btn">
						{Icon.menuVertical}
					</div>
				</Tippy>
			)}
		</div>
	)
}

export default RevenueAction;