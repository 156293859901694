import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {expenseActions} from "./expenseSlice";
import {expenseApi} from "~/api";

function* fetchExpenseList(action) {
	try {
		const response = yield call(expenseApi.gets, action.payload);
		yield put(expenseActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch expense list', error);
		yield put(expenseActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(expenseActions.setFilter(action.payload));
}

export default function* expenseSaga() {
	yield takeLatest(expenseActions.fetchData.type, fetchExpenseList);
	yield debounce(500, expenseActions.setFilterWithDebounce.type, handleSearchDebounce)
}