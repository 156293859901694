import { all } from 'redux-saga/effects';
import authSaga from '~/features/Auth/authSaga';
import candidateSaga from '~/features/Candidates/candidatesSaga';
import personnelSaga from '~/features/Personnel/personnelSaga';
import groupSaga from '~/features/Personnel/groupSaga';
import workingDaySaga from '~/features/WorkingDay/workingDaySaga';
import customerSaga from '~/features/Customer/customerSaga';
import customerGroupSaga from '~/features/Customer/customerGroupSaga';
import contractSaga from '~/features/Contract/contractSaga';
import revenueSaga from '~/features/Revenue/revenueSaga';
import expenseSaga from '~/features/Expense/expenseSaga';
import expenseGroupSaga from '~/features/Expense/expenseGroupSaga';
import expenseEventSaga from '~/features/Expense/expenseEventSaga';
import projectSaga from '~/features/Project/projectSaga';
import projectTaskSaga from '~/features/Task/projectTaskSaga';
import formSabbaticalSaga from '~/features/Form/formSabbaticalSaga';
import formSalarySaga from '~/features/Form/formSalarySaga';
import formPaymentSaga from '~/features/Form/formPaymentSaga';
import saleEventSaga from '~/features/Sale/saleEventSaga';
import saleConsultationSaga from '~/features/Sale/saleConsultationSaga';
import saleWorkingSaga from '~/features/Sale/saleWorkingSaga';
import saleCommitSaga from '~/features/Sale/saleCommitSaga';
import uploadWebsiteSaga from '~/features/Website/uploadWebsiteSaga';
import createdWebsiteSaga from '~/features/Website/createdWebsiteSaga';
import buyServiceSaga from '~/features/BuyService/buyServiceSaga';
import saleReportSaga from '~/features/Report/saleReportSaga';
import paySaga from '~/features/Pay/paySaga';
import serviceSaga from '~/features/Service/serviceSaga';
import serviceToDoSaga from '~/features/Service/serviceToDoSaga';
import extendSaga from '~/features/Extend/extendSaga';
import papersSaga from '~/features/Papers/papersSaga';
import papersNumberSaga from '~/features/Papers/papersNumberSaga';
import papersTypeSaga from '~/features/Papers/papersTypeSaga';
import ruleSaga from '~/features/Rules/RuleSaga';
import documentSaga from '~/features/Documents/DocumentSaga';

export default function* rootSaga() {
	yield all([
		candidateSaga(),
		authSaga(),
		personnelSaga(),
		groupSaga(),
		workingDaySaga(),
		customerSaga(),
		customerGroupSaga(),
		contractSaga(),
		revenueSaga(),
		expenseSaga(),
		expenseGroupSaga(),
		expenseEventSaga(),
		projectSaga(),
		projectTaskSaga(),
		formSabbaticalSaga(),
		formSalarySaga(),
		formPaymentSaga(),
		saleEventSaga(),
		saleConsultationSaga(),
		saleWorkingSaga(),
		saleCommitSaga(),
		uploadWebsiteSaga(),
		createdWebsiteSaga(),
		buyServiceSaga(),
		saleReportSaga(),
		paySaga(),
		serviceSaga(),
		serviceToDoSaga(),
		extendSaga(),
		papersSaga(),
		papersNumberSaga(),
		papersTypeSaga(),
		ruleSaga(),
		documentSaga(),
	]);
}