import request from "~/utils/http";

const notificationApi = {
	gets : async (params) => {
		const url = `notifications`;
		return await request.get(url, {params});
	},
	count : async (params) => {
		const url = `notifications/count`;
		return await request.get(url, {params});
	},
	read : async (params) => {
		const url = `notifications/read`;
		return await request.post(url, params);
	},
	open : async (params) => {
		const url = `notifications/open`;
		return await request.post(url, params);
	},

	message : async (params) => {
		const url = `notifications/message`;
		return await request.get(url, {params});
	},
	messageCheck : async (params) => {
		const url = `notifications/message/count`;
		return await request.get(url, {params});
	},
	messageRead : async (params) => {
		const url = `notifications/message/read`;
		return await request.post(url, params);
	},
	messageOpen : async (params) => {
		const url = `notifications/message/open`;
		return await request.post(url, params);
	},
};

export default notificationApi;