import {useState} from "react";
import {Button, Icon} from "~/components";
import {Tooltip} from "antd";

function CreatedWebsiteAction({item, onClickPlus, onClickReInsert}) {

	const [plusLoading, setPlusLoading] = useState(false);
	const [insertLoading, setInsertLoading] = useState(false);

	const buttonHandlers = {
		plus: async () => {
			setPlusLoading(true);
			await onClickPlus?.(item);
			setPlusLoading(false);
		},
		reInsert: async () => {
			setInsertLoading(true);
			await onClickReInsert?.(item);
			setInsertLoading(false);
		},
	};

	return (
		<div className="table-action d-flex">
			{item.status == 0 && <Tooltip title="Giữ lại website"><Button background green loading={plusLoading} leftIcon={Icon.plus} onClick={buttonHandlers.plus}></Button></Tooltip>}
			{item.status == 2 && <Tooltip title="Thử cài lại"><Button background blue loading={insertLoading} leftIcon={Icon.setting} onClick={buttonHandlers.reInsert}></Button></Tooltip>}
		</div>
	)
}

export default CreatedWebsiteAction;