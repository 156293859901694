import style from '../Home.module.scss';
import {memo} from "react";
import className from 'classnames/bind';
import {
	ContentHeader,
	SidebarAccount
} from "../componets";
import TechnicianDownload from "../componets/Technician/TechnicianDownload";
import {Col, Row} from "antd";

const cn = className.bind(style);

function DashBoardTechnician({user, fullTime}) {

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} fullTime={fullTime} />
				<Row gutter={10}>
					<Col md={12}>
						<TechnicianDownload />
					</Col>
					<Col md={12}></Col>
				</Row>
			</div>
		</div>
	)
}

export default memo(DashBoardTechnician);