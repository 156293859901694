import {
	Button,
	Icon
} from "~/components";

import DOMPurify from "dompurify";

function RuleItemInfo({item, group, modalOpen, modalClose}) {
	const handleEditClick = () => {
		modalOpen('editItem');
		modalClose('infoItem')
	}
	return (
		<div className="form">
			<p className="heading">{item.name}</p>
			<div className="content-html" style={{wordBreak : 'break-word'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }} />
			<hr className="mb-0" />
			<div className="form-group d-flex justify-content-center modal-bottom">
				<Button background blue leftIcon={Icon.edit} onClick={handleEditClick}>Chinh sữa</Button>
			</div>
		</div>
	)
}

export default RuleItemInfo;