import style from '../scss/Account.module.scss';
import className from 'classnames/bind';
import AccountHeader from "../components/AccoutHeader";

import {Button, Icon} from "../../../components";
import {globalNavigate} from "../../../routes/GlobalHistory";
import {authActions} from "../../Auth/authSlice";
import {useDispatch} from "react-redux";

const cn = className.bind(style);

function Account() {

	const dispatch = useDispatch();

	const handleLogout = () => {
		localStorage.clear();
		dispatch(authActions.logout());
		globalNavigate("/login");
	}

	return (
		<div className="container">
			<div className={cn('profile')}>
				<AccountHeader />
				<div className={cn('list-action')}>
					<Button className={cn('action-item', 'mb-1')} background white leftIcon={Icon.info} to={'/account/info'}>Thông tin</Button>
					<Button className={cn('action-item', 'mb-1')} background white leftIcon={Icon.lock} to={'/account/password'}>Đổi mật khẩu</Button>
					<Button className={cn('action-item')} background red leftIcon={Icon.logout} onClick={handleLogout}>Đăng xuất</Button>
				</div>
			</div>
		</div>
	)
}

export default Account;