import style from "./WorkingDay.module.scss";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import className from "classnames/bind";
import {notification} from "antd";
import _ from "lodash"
import {
	Loading,
	Modal,
	Button,
	Icon
} from "~/components";
import ActionBar from "~/layout/ActionBar";
import {
	workingDayErrorSelector,
	workingDayItemsSelector,
	workingDayLoadingSelector,
	workingDayPaginationSelector,
	workingDayFilterSelector,
	workingDayActions
} from "../workingDaySlice";
import {
	WorkingDaySearchBar,
	WorkingDaySearchMobile,
	WorkingDayTable,
	WorkingDayFormTimeKeeping
} from "../components";
import workingDayApi from "~/api/workingDayApi";
import {
	apiError,
	handleRequest
} from "~/utils";
import {useDevice} from "~/hooks";
const cn = className.bind(style);
function WorkingDay() {

	const {isMobile} = useDevice();

	const dispatch = useDispatch();

	const items = useSelector(workingDayItemsSelector);

	const loading = useSelector(workingDayLoadingSelector);

	const error = useSelector(workingDayErrorSelector);

	const pagination = useSelector(workingDayPaginationSelector);

	const filter = useSelector(workingDayFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		detail : false,
		timeKeeping : false,
	});

	const handleModalClose = (modal) => {
		if(modal == 'timeKeeping') setOpenModal((prevState) => ({...prevState, timeKeeping: false }))
	}

	//Load data
	useEffect(() => {
		dispatch(workingDayActions.fetchData(filter));
	}, [filter]);

	const handleReLoading = () => {
		dispatch(workingDayActions.fetchData(filter));
	}

	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleTimeKeepingSave = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhân viên để cập nhật'});
			return;
		}

		if (data?.timeKeeping == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin chức vụ cần chuyển'});
			return;
		}

		let dataSave = {timeKeeping: data.timeKeeping, id: item.id, time: filter?.time};

		let [error, response] = await handleRequest(workingDayApi.updateByUser(dataSave));
		let message = apiError(`Cập nhật thông tin nhân viên thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin nhân viên thành công`});
			item.details = response.data.details;
			item.detailsEdit = response.data.detailsEdit;
			item.working = response.data.working;
			item.totalOff = response.data.totalOff;
			dispatch(workingDayActions.update(item));
			handleModalClose('timeKeeping')
		}
	}
	//Search
	const SearchBar = (isMobile) ? WorkingDaySearchMobile : WorkingDaySearchBar;

	const handlePaginationChange = (page) => {
		dispatch(workingDayActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(workingDayActions.setFilterWithDebounce(newFilter));
	};

	const handleFilterChange = (newFilter) => {
		dispatch(workingDayActions.setFilter(newFilter));
	};

	return (
		<>
			<ActionBar title={'Chấm công'}>
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className={cn('container')}>
				<div className={cn('content')}>
					{loading && <Loading/>}
					<SearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />
					{(!_.isEmpty(items)) && <WorkingDayTable
						filter={filter}
						items={items}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						pagination={pagination}
						setOpenModal={setOpenModal}
					/>}
				</div>
			</div>
			{itemEdit?.id &&
				<Modal title="Chấm công" visible={openModal.timeKeeping} onCancel={() => {handleModalClose('timeKeeping')}}>
					<WorkingDayFormTimeKeeping item={itemEdit} onHandleSubmit={handleTimeKeepingSave} time={filter?.time} />
				</Modal>
			}
		</>
	)
}

export default WorkingDay;