import DocumentGroupItem from "./DocumentGroupItem";

function DocumentGroupList({items = [], setItemEdit, setDocumentGroup, openModal, closeModal, can}) {
	return (
		<div className="table-wrapper m-0 pd-1">
			<div className="table-box">
				{items.map((item) => <DocumentGroupItem key={`ruleGroupItem${item.id}`} can={can} setDocumentGroup={setDocumentGroup} setItemEdit={setItemEdit} group={item} openModal={openModal} closeModal={closeModal}/>)}
			</div>
		</div>
	)
}

export default DocumentGroupList
