import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectField
} from "~/components/Forms";

function ProjectFormAssign({item, can, listAssign, listDesign, onHandleSubmit}) {

	const initialValues = {
		assignId: '',
		designId: '',
	}

	let assignOptions = [];
	for (const [key, assign] of Object.entries(listAssign)) {
		assignOptions.push({value: assign.id, label: `${assign.firstname} ${assign.lastname}`})
	}

	let designOptions = [];
	for (const [key, design] of Object.entries(listDesign)) {
		designOptions.push({value: design.id, label: `${design.firstname} ${design.lastname}`})
	}

	/*const validationSchema = Yup.object().shape({
		assignId: Yup.string().required('Bạn chưa chọn hợp đồng'),
	})*/

	const {control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
	});

	useEffect(() => {
		if (item?.id) {
			reset(initialValues)
		}
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			{
				can.assignDesign && <>
					<p className="heading">Nhân viên design</p>
					<Controller control={control} name="designId" render={({field}) => (
						<SelectField placeholder="Chọn nhân viên thiết kế" options={designOptions} errors={errors} {...field} />
					)}/>
				</>
			}
			{
				can.assignDev && <>
					<p className="heading">Nhân viên kỹ thuật</p>
					<Controller control={control} name="assignId" render={({field}) => (
						<SelectField placeholder="Chọn nhân viên kỹ thuật" options={assignOptions} errors={errors} {...field} />
					)}/>
				</>
			}
			{ (can.assignDesign || can.assignDev) &&
				<div className="form-group d-flex justify-content-end mt-2">
					<Button primary type="submit">Cập nhật thông tin</Button>
				</div>
			}
		</form>
	)
}

export default ProjectFormAssign;