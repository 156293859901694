import Image from "~/components/Image";
import {Pagination, Table} from "antd";
import {
	renderDate,
	numberFormat,
} from "~/utils";

import ExpenseAction from "./ExpenseAction";
import {useContext} from "react";
import {AppContext} from "~/context/AppProvider";

function ExpenseTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const {tableHeight} = useContext(AppContext);

	const typeList = {
		'none' : '',
		'minus-wage' : 'Trừ vào lương',
		'plus-wage' : 'Cộng vào lương',
	}

	const handleEditClick = (item) => {
		setItemEdit(item);
		openModal('addEdit');
	}

	const handleDeleteClick = (item) => {
		setItemEdit(item);
		openModal('delete');
	}

	const columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (<div>DT{item?.id}</div>)},
		{ title: 'Ngày chi', dataIndex: 'time', key: "time", width: '120px', render: (_, item) => (<div><p>{renderDate(item?.time)}</p></div>)},
		{title: 'Loại chi', dataIndex: 'category', key: 'category', width: '220px', render:(_, item) => (<div>{item.category.name}</div>)},
		{ title: 'Đối tượng chi', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			item.user?.id && (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item.user?.avatar}/></div>
				<div>
					<b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
					<p>{item.user?.username}</p>
				</div>
			</div>
		))},
		{ title: 'Kiểu tính', dataIndex: 'wage', key: "wage", width: '180px', render: (_, item) => (<p><b>{typeList[item?.type]}</b></p>)},
		{ title: 'Số tiền chi', dataIndex: 'price', key: "price", width: '180px', render: (_, item) => (<p><b>{numberFormat(item?.price)}đ</b></p>)},
		{ title: 'Phương thức', dataIndex: 'status', key: "status", width: '180px', render: (_, item) => (item?.method)},
		{ title: 'Ghi chú', dataIndex: 'note', key: "note", render: (_, item) => (item?.note)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<ExpenseAction
				key={item.id}
				item={item}
				onClickEdit={handleEditClick}
				onClickDelete={handleDeleteClick}
			/>
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default ExpenseTable;