import {forwardRef} from "react";
import {customerApi} from "~/api";
import {DebounceSelect} from "../index";

const SelectCustomerAll = forwardRef(({ options, errors, ...props }, ref) => {

	const onSearchCustomer = async (keyword) => {
		const response = await customerApi.gets({keyword});
		return response.data.map((customer) => ({
			label: `${customer.code} ${customer.companyName} ${customer.name}`,
			value: customer.id,
		}))
	}

	return (
		<DebounceSelect fetchOptions={onSearchCustomer} errors={errors} optionsDefault={options} {...props}/>
	)
});

export default SelectCustomerAll;