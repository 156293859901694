import {useEffect, useState, useContext} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppContext } from "~/context/AppProvider";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {Col, Row} from "antd";
import {
	DateField,
	GroupRadioField,
	InputField,
	InputPriceField,
	SelectField,
	SelectUserAll
} from "~/components/Forms";
import {useCan, useUtilities} from "~/hooks";

function ServiceFormUpgrade({item, onHandleSubmit, type}) {
	const {utilities} = useUtilities();
	const can = {
		plus: useCan('revenuePlus')
	}

	const userOption = (item.user?.id && typeof item.user?.firstname != "undefined") ? [{ label : `${item.user.firstname} ${item.user.lastname}`, value : item.user.id }] : [];

	const userShareOption = (item.userShare?.id && typeof item.userShare?.firstname != "undefined") ? [{ label : `${item.userShare.firstname} ${item.userShare.lastname}`, value : item.userShare.id }] : [];
	/**
	 * Tỉnh thành
	 */
	const {country} = useContext(AppContext);

	let cityOptions = [];

	if(Object.keys(country.city).length !== 0) {
		for (const [value, label] of Object.entries(country.city)) {
			cityOptions.push({value, label})
		}
	}

	let [districtOptions, setDistrictOptions] = useState([]);

	let [wardOptions, setWardOptions] = useState([]);

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const paymentOptions = [
		{ value: 'cod', label: 'Tiền mặc'},
		{ value: 'bank', label: 'Chuyển khoản'},
		{ value: 'momo', label: 'Ví Momo'},
		{ value: 'swipe', label: 'Quẹt thẻ'},
		{ value: 'alePay', label: 'AlePay'},
	];

	/**
	 * Form
	 */
	const contractName = ((type == 'upgrade') ? 'Nâng cấp' : 'Gia hạn') +` ${utilities.services[item.service].label} - ${item.customer.name} - ${item.domain}`;

	const date = new Date();

	date.setFullYear(date.getFullYear() + 1)

	const initialValues = {
		name: item.customer.name,
		numberId: item.customer.numberId,
		email: item.customer.email,
		phone: item.customer.phone,
		address: item.customer.address,
		city: item.customer.city,
		district: item.customer.district,
		ward: item.customer.ward,
		contractName: contractName,
		domain: item.domain,
		option: item.option,
		contractSigning : new Date(),
		contractExpired: date.getTime(),
		time : new Date(),
		payment: 'cod',
		userId: item.userId,
		price: item.price,
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Họ tên khách hàng không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		address: Yup.string().required('Địa chỉ khách hàng không được để trống'),
		contractName: Yup.string().required('Tên hợp đồng không được để trống'),
		domain: Yup.string().required('Tên miền không được bỏ trống'),
		contractSigning: Yup.string().required('Bạn chưa chọn ngày gia hạn'),
		contractExpired: Yup.string().required('Bạn chưa chọn ngày hết hạn'),
		roomSales: (can.plus) ? Yup.number().required('Bạn chưa chọn kiểu tính cho phòng') : false,
		timePlus: (can.plus) ? Yup.string().required('Bạn chưa chọn thời gian tính lương') : false,
		time: Yup.string().required('Bạn chưa chọn ngày thu'),
		price: Yup.number().required('Giá trị thu không được để trống'),
		payment: Yup.string().required('Bạn chưa chọn phương thức thanh toán'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			for (const [cityKey, districtTemp] of Object.entries(country.district)) {
				if(cityKey == item.city) {
					districtOptions = [{
						label : 'Chọn quận huyện',
						value : ''
					}];
					for (const [value, label] of Object.entries(districtTemp)) {
						districtOptions.push({value, label})
					}
					break;
				}
			}
			for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
				if(districtKey == item.district) {
					wardOptions = [{
						label : 'Chọn phường xã',
						value : ''
					}];
					for (const [value, label] of Object.entries(wardTemp)) {
						wardOptions.push({value, label})
					}
					break;
				}
			}
			setDistrictOptions(districtOptions);
			setWardOptions(wardOptions);
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	const renderServiceOption = () => {
		let options = [];
		if(utilities.services[item.service]?.options) {
			for (const [key, value] of Object.entries(utilities.services[item.service].options)) {
				options.push({value : key, label : value.label})
			}
		}
		if(item.service == 'host') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={24}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Dung lượng" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
		else if(item.service == 'ssl') {
			return (
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="option" render={({ field }) => (
							<SelectField label="Loại SSL" options={options} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			)
		}
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Nhân viên quản lý</p>
			<Row gutter={10}>
				{
					(can.plus) && <Col className="gutter-row" span={12}>
						<Controller control={control} name="userId" render={({ field }) => (
							<SelectUserAll placeholder="Chọn nhân viên" options={userOption} errors={errors} {...field} />
						)}/>
					</Col>
				}

				<Col className="gutter-row" span={12}>
					<Controller control={control} name="userShareId" render={({ field }) => (
						<SelectUserAll placeholder="Share với nhân viên" options={userShareOption} errors={errors} {...field} />
					)}/>
				</Col>
			</Row>
			<p className="heading">Thông tin khách hàng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="name" render={({field}) => (
						<InputField label="Họ tên khách hàng (Người đại diện)" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="numberId" render={({ field }) => (
						<InputField label="CCCD / CMND" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			{
				(can.plus) && <Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="email" render={({ field }) => (
							<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="phone" render={({ field }) => (
							<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			}
			<Row gutter={10}>
				<Col className="gutter-row" span={24}>
					<Controller control={control} name="address" render={({ field }) => (
						<InputField label="Địa chỉ" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="city" render={({ field }) => (
						<SelectField placeholder="Chọn tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
							handleCityChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="district" render={({ field }) => (
						<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
							handleDistrictChange(value);
							field.onChange(value, event);
						}}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={8}>
					<Controller control={control} name="ward" render={({ field }) => (
						<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<p className="heading">Thông tin hợp đồng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="contractName" render={({ field }) => (
						<InputField type="text" label="Tên hợp đồng" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="domain" render={({ field }) => (
						<InputField type="text" label="Tên miền" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			{renderServiceOption()}

			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="contractSigning" render={({ field }) => (
						<DateField type="text" label="Ngày gia hạn" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="contractExpired" render={({ field }) => (
						<DateField type="text" label="Ngày hết hạn" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<p className="heading">Thông tin doanh thu</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="price" render={({field}) => (
						<InputPriceField label="Giá trị thu" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Ngày thu" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			{
				can.plus && <>
					<p className="heading">Tính lương</p>
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="roomSales" render={({field}) => (
								<GroupRadioField options={[
									{ value: 0, label : 'Không tính cho phòng'}, { value: 1, label : 'Tính cho phòng'}
								]} errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="timePlus" render={({ field }) => (
								<DateField label="Tháng tính doanh số" picker="month" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				</>
			}

			<p className="heading">Thanh toán</p>
			<Controller control={control} name="payment" render={({ field }) => (
				<GroupRadioField options={paymentOptions} errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{((type == 'upgrade') ? 'Nâng cấp' : 'Gia hạn')}</Button>
			</div>
		</form>
	)
}

export default ServiceFormUpgrade;