import {call, put, takeLatest} from "redux-saga/effects";
import {payActions} from "./paySlice";
import {payApi} from "~/api";

function* fetchPayList(action) {
	try {
		const response = yield call(payApi.gets, action.payload);
		yield put(payActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch pay list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(payActions.fetchDataFailed(message));
	}
}

export default function* paySaga() {
	yield takeLatest(payActions.fetchData.type, fetchPayList);
}