import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading
} from "~/components";
import {Col, Row} from "antd";
import {
	InputField,
} from "~/components/Forms";

function ServiceFormHostEdit({item, onHandleSubmit}) {

	const initialValues = {
		hostIp: item.hostIp,
		hostUser: item.hostName,
	}

	const validationSchema = Yup.object().shape({
		hostIp: Yup.string().required('Bạn chưa điền ip host'),
		hostUser: Yup.string().required('Bạn chưa điền tên người dùng'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin </p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="hostIp" render={({ field }) => (
						<InputField type="text" label="Host Ip" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="hostUser" render={({field}) => (
						<InputField label="Tên đăng nhập" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default ServiceFormHostEdit;