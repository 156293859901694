import {Fragment, useContext} from "react";
import {Link} from "react-router-dom";
import {
	StatusLabel, PopperUserInfo
} from "~/components";
import {Pagination, Table} from "antd";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	useUtilities
} from "~/hooks";
import {
	LIST_PROJECT_STATUS,
	LIST_PROJECT_PHASE
} from "~/app/constants";
import {DateField} from "~/components/Forms";
import {AppContext} from "~/context/AppProvider";
import ProjectAction from "./ProjectAction";
function ProjectTable({items = [], isMobile, pagination, onPaginationChange, setItemEdit, openModal, onChangeDeadline, can}) {

	const {tableHeight} = useContext(AppContext);

	const {utilities} = useUtilities();

	const handleStatusClick = (item) => {
		setItemEdit(item);
		openModal('status');
	}

	const handlePhaseClick = (item) => {
		setItemEdit(item);
		openModal('phase');
	}

	const handleDeadlineChange = (date, item) => {
		onChangeDeadline(date, item);
	}

	const renderProjectType = (price, type) => {
		return (
			<>
				{(price == 0 && type == 'new') && <StatusLabel small type="blue">Website 0đ</StatusLabel>}
				{(price != 0 && can.price) && <StatusLabel small type="blue">{numberFormat(price)}đ</StatusLabel>}
			</>
		)
	}

	const renderProjectPhase = (item) => {
		if(!LIST_PROJECT_PHASE[item.phase]?.color) return item.phase;
		return (
			(can.phase)
			? <StatusLabel pointer onClick={() => handlePhaseClick(item)} small type={LIST_PROJECT_PHASE[item.phase]?.color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
			: <StatusLabel small type={LIST_PROJECT_PHASE[item.phase]?.color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
		)
	}

	let columns = [
		{ title: 'Hợp đồng', dataIndex: 'code', key: "code", width: '330px', render: (_, item) => (
			item?.contract?.id && <>
				{!isMobile &&
					<>
						<Link to={`/task/${item.contractId}`}>
							<p style={{fontSize : '13px'}}>{`${item?.contract.code} ${item?.contract.name}`}</p>
						</Link>
						<p className="mb-1"><a href={'http://'+item?.contract?.domain} target={'_blank'}>{item?.contract?.domain}</a></p>
						<StatusLabel small type={utilities.serviceTypes[item.contract.serviceType].color}>{utilities.serviceTypes[item.contract.serviceType].label}</StatusLabel>
						{renderProjectType(item?.contract?.price, item?.contract?.serviceType)}
					</>
				}
				{isMobile && <>
					<Link to={`/task/${item.contractId}`}>
						<p className="mb-1" style={{fontSize : '13px'}}>{`${item?.contract.code} ${item?.contract.name}`}</p>
					</Link>
					<div className="d-flex align-items">
						<PopperUserInfo user={item.user}><b>{item.user?.lastname}</b></PopperUserInfo>
						{item.userShare?.id && <Fragment>, <PopperUserInfo user={item.userShare}><b>{item.userShare?.lastname}</b></PopperUserInfo></Fragment>}
						{item.design?.id && <Fragment>, <PopperUserInfo user={item.design}><b>{item.design?.lastname}</b></PopperUserInfo></Fragment>}
						{item.assign?.id && <Fragment>, <PopperUserInfo user={item.assign}><b>{item.assign?.lastname}</b></PopperUserInfo></Fragment>}
					</div>
					<StatusLabel small type={utilities.serviceTypes[item.contract.serviceType].color}>{utilities.serviceTypes[item.contract.serviceType].label}</StatusLabel>
					{
						(can.status)
							? <StatusLabel pointer onClick={() => handleStatusClick(item)} small type={LIST_PROJECT_STATUS[item.status]?.color}>{LIST_PROJECT_STATUS[item.status].label}</StatusLabel>
							: <StatusLabel small type={LIST_PROJECT_STATUS[item.status]?.color}>{LIST_PROJECT_STATUS[item.status].label}</StatusLabel>
					}
					{
						(can.phase)
							? <StatusLabel pointer onClick={() => handlePhaseClick(item)} small type={LIST_PROJECT_PHASE[item.phase]?.color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
							: <StatusLabel small type={LIST_PROJECT_PHASE[item.phase]?.color}>{LIST_PROJECT_PHASE[item.phase].label}</StatusLabel>
					}
					{renderProjectType(item?.contract?.price, item?.contract?.serviceType)}
					<p>{renderDate(item?.created)}</p>
				</>}
			</>
		)},
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '180px', render: (_, item) => (
			<div className="d-flex align-items">
				<PopperUserInfo user={item.user}><b>{item.user?.lastname}</b></PopperUserInfo>
				{item.userShare?.id && <Fragment>, <PopperUserInfo user={item.userShare}><b>{item.userShare?.lastname}</b></PopperUserInfo></Fragment>}
				{item.design?.id && <Fragment>, <PopperUserInfo user={item.design}><b>{item.design?.lastname}</b></PopperUserInfo></Fragment>}
				{item.assign?.id && <Fragment>, <PopperUserInfo user={item.assign}><b>{item.assign?.lastname}</b></PopperUserInfo></Fragment>}
			</div>
		)},
		{ title: 'Ngày tạo', dataIndex: 'created', key: "created", width: '120px', render: (_, item) => (
			<div><p>{renderDate(item?.created)}</p></div>
		)},
		{ title: 'Deadline', dataIndex: 'deadline', key: "deadline", width: '130px', render: (_, item) => (
			<Fragment>
				{can.deadline ? <DateField value={item?.deadline*1000} bordered={false} onChange={(date) => handleDeadlineChange(date, item)}/> : renderDate(item?.deadline)}
			</Fragment>
		)},
		{ title: 'Tasks', dataIndex: 'tasks', key: "tasks", width: '150px', render: (_, item) => (
			<Fragment>{`${(item?.totalTask - item?.unfinishedTask)}/${item?.totalTask} Hoàn thành`}</Fragment>
		)},
		{title: 'Tình trạng', dataIndex: 'status', key: 'status',width: '150px', render:(_, item) => (
			<Fragment>
				{
					(can.status)
						? <StatusLabel pointer onClick={() => handleStatusClick(item)} small type={LIST_PROJECT_STATUS[item.status].color}>{LIST_PROJECT_STATUS[item.status].label}</StatusLabel>
						: <StatusLabel small type={LIST_PROJECT_STATUS[item.status].color}>{LIST_PROJECT_STATUS[item.status].label}</StatusLabel>
				}
			</Fragment>
		)},
		{title: 'Giai đoạn', dataIndex: 'phase', key: 'phase', width: '150px', render:(_, item) => (
			renderProjectPhase(item)
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<ProjectAction
				key={item.id}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
				can={can}
			/>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'user'
				&& currentValue.key != 'created'
				&& currentValue.key != 'status'
				&& currentValue.key != 'phase'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default ProjectTable;