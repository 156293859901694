import {Button, Icon} from "~/components";
import {Tooltip} from "antd";

function WorkingDayAction({item, onClickTimeKeeping}) {

	const handleTimeKeepingClick = () => {
		if(!onClickTimeKeeping) return;
		onClickTimeKeeping(item);
	}

	return (
		<div className="table-action d-flex">
			<Tooltip title="Ngày Công">
				<Button background red noneBorder leftIcon={Icon.calendar} onClick={handleTimeKeepingClick}></Button>
			</Tooltip>
		</div>
	)
}

export default WorkingDayAction;