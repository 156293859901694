import {useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import {Col, Row} from "antd";
import {
	InputField,
	SelectField
} from "~/components/Forms";

function CustomerGroupFormAddEdit({item, onHandleSubmit}) {

	const badgeOptions = [
		{
			value: 'white',
			label: <StatusLabel small type={'white'}>white</StatusLabel>
		},
		{
			value: 'black',
			label: <StatusLabel small type={'black'}>black</StatusLabel>
		},
		{
			value: 'red-mint',
			label: <StatusLabel small type={'red-mint'}>red-mint</StatusLabel>
		},
		{
			value: 'red',
			label: <StatusLabel small type={'red'}>red</StatusLabel>
		},
		{
			value: 'red-bold',
			label: <StatusLabel small type={'red-bold'}>red-bold</StatusLabel>
		},
		{
			value: 'blue-mint',
			label: <StatusLabel small type={'blue-mint'}>blue-mint</StatusLabel>
		},
		{
			value: 'blue',
			label: <StatusLabel small type={'blue'}>blue</StatusLabel>
		},
		{
			value: 'blue-bold',
			label: <StatusLabel small type={'blue-bold'}>blue-bold</StatusLabel>
		},
		{
			value: 'yellow-mint',
			label: <StatusLabel small type={'yellow-mint'}>yellow-mint</StatusLabel>
		},
		{
			value: 'yellow',
			label: <StatusLabel small type={'yellow'}>yellow</StatusLabel>
		},
		{
			value: 'yellow-bold',
			label: <StatusLabel small type={'yellow-bold'}>yellow-bold</StatusLabel>
		},
		{
			value: 'green-mint',
			label: <StatusLabel small type={'green-mint'}>green-mint</StatusLabel>
		},
		{
			value: 'green',
			label: <StatusLabel small type={'green'}>green</StatusLabel>
		},
		{
			value: 'purple',
			label: <StatusLabel small type={'purple'}>purple</StatusLabel>
		},
		{
			value: 'bold',
			label: <StatusLabel small type={'bold'}>bold</StatusLabel>
		}
	];

	const isEdit = Boolean(item?.id);

	const initialValues = {
		name: undefined,
		badge: undefined,
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Bạn chưa điền tên nhóm khách hàng'),
		badge: Yup.string().required('Bạn chưa chọn tags'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item.name;
			initialValues.badge = item.badge;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading"></p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="name" render={({ field }) => (
						<InputField label="Tên nhóm khách hàng" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="badge" render={({ field }) => (
						<SelectField label="Tags" options={badgeOptions} errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm'} nhóm</Button>
			</div>
		</form>
	)
}

export default CustomerGroupFormAddEdit;