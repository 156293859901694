import style from '../Home.module.scss';
import className from 'classnames/bind';
import {Carousel} from "antd";
import {Image} from "../../../components";
import images from "../../../assets/images";
const cn = className.bind(style);

const CardToast  = ({items}) => {
	return (
		<div className={cn('card', 'card-toast', 'card-red')}>
			<h4 className={cn('heading')}>Hợp đồng mới!</h4>
			<div className={cn('list-ranks')}>
				<Carousel autoplay>
					{
						items.map((item) => {
							return (
								<div key={`card-toast-customer-${item.id}`} className={cn('user')}>
									<div className={cn('avatar-rank', 'avatar')}><Image src={item.user.avatar} /></div>
									<b className={cn('name')}>{item.user.firstname} {item.user.lastname}</b>
									<p>vừa ký hợp đồng mới</p>
									<p className={cn('revenue')}>{item.name}</p>
								</div>
							)
						})
					}
				</Carousel>
			</div>
			<div className={cn('before')}></div>
			<div className={cn('after')}></div>
			<div className={cn('bg-img', 'bg-img-right')}><Image src={images.iconToastLeft} /></div>
			<div className={cn('bg-img', 'bg-img-left')}><Image src={images.iconToastRight} /></div>
		</div>
	)
}

export default CardToast;