import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectUserAll
} from "~/components/Forms";

function SaleEventFormAssign({item, onHandleSubmit}) {

	const [optionsSelectUserAssign, setOptionsSelectUserAssign] = useState([]);
	const [optionsSelectUserSupport, setOptionsSelectUserSupport] = useState([]);

	const initialValues = {
		assignId: '',
		supportId: '',
	}

	const {control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
	});

	useEffect(() => {
		if (item?.id) {
			reset(initialValues)
		}
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Nhân viên tư vấn</p>
			<Controller control={control} name="assignId" render={({field}) => (
				<SelectUserAll placeholder="Chọn nhân viên tư vấn" options={optionsSelectUserAssign} setOptions={setOptionsSelectUserAssign} errors={errors} {...field} />
			)}/>
			<p className="heading">Nhân viên hỗ trợ</p>
			<Controller control={control} name="supportId" render={({field}) => (
				<SelectUserAll placeholder="Chọn nhân viên hỗ trợ" options={optionsSelectUserSupport} setOptions={setOptionsSelectUserSupport} errors={errors} {...field} />
			)}/>

			<div className="form-group d-flex justify-content_end mt-2">
				<Button primary type="submit">Cập nhật thông tin</Button>
			</div>
		</form>
	)
}

export default SaleEventFormAssign;