import request from "~/utils/http";

const papersApi = {
	gets : async (params) => {
		const url = 'papers';
		return await request.get(url, {params});
	},
	count : async (params) => {
		const url = 'papers/count';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'papers';
		return await request.post(url, params);
	},
	status : async (params) => {
		const url = `papers/status/${params.id}`;
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `papers/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `papers/${id}`;
		return await request.delete(url);
	},
	getsType : async (params) => {
		const url = 'papers/type';
		return await request.get(url, {params});
	},
	addType : async (params) => {
		const url = 'papers/type';
		return await request.post(url, params);
	},
	updateType : async (params) => {
		const url = `papers/type/${params.id}`;
		return await request.patch(url, params);
	},
	importType : async (params) => {
		const url = 'papers/type/import';
		return await request.post(url, params);
	},
	deleteType : async (id) => {
		const url = `papers/type/${id}`;
		return await request.delete(url);
	},
	getsNumber : async (params) => {
		const url = 'papers/number';
		return await request.get(url, {params});
	},
	updateNumber : async (params) => {
		const url = `papers/number/${params.id}`;
		return await request.patch(url, params);
	},
	reCallNumber : async (params) => {
		const url = `papers/number/re-call`;
		return await request.post(url, params);
	},
	receiveNumber : async (params) => {
		const url = `papers/number/receive`;
		return await request.post(url, params);
	},
	cancelNumber : async (params) => {
		const url = `papers/number/cancel`;
		return await request.post(url, params);
	},
};

export default papersApi;