import {useEffect, useState, useContext, useMemo} from "react";
import {useForm, Controller, useWatch} from "react-hook-form";
import { AppContext } from "~/context/AppProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	GroupRadioField,
	SelectField,
	InputField,
	DateField,
} from "~/components/Forms";

function BuyServiceFormPa({item, onHandleSubmit}) {

	const {country} = useContext(AppContext);

	const cityOptions = useMemo(() => {
		return [...Object.entries(country.city).map(([key, value]) => {
			return {
				value: key,
				label: value
			};
		})];
	}, []);

	let [districtOptions, setDistrictOptions] = useState([]);
	let [wardOptions, setWardOptions] = useState([]);

	let [districtUiOptions, setDistrictUiOptions] = useState([]);
	let [wardUiOptions, setWardUiOptions] = useState([]);

	let [districtAdminOptions, setDistrictAdminOptions] = useState([]);
	let [wardAdminOptions, setWardAdminOptions] = useState([]);

	const copyUiOptions = [
		{value : 1,  label : "Copy khách hàng"},
		{value : 2,  label : "Thông tin khác"},
	];

	const copyAdminOptions = [
		{value : 1,  label : "Copy KH"},
		{value : 2,  label : "Copy chủ thể"},
		{value : 3,  label : "Thông tin khác"},
	];

	const forOptions = [
		{ value: 'canhan', label: 'Cá Nhân'},
		{ value: 'congty', label: 'Công Ty'},
	];

	const genderOptions = [
		{ value: 'Nữ', label: 'Nữ'},
		{ value: 'Nam', label: 'Nam'},
	];

	const initialValues = {
		for: item.for,
		companyName: item.companyName,
		address: (item.for == 'canhan') ? item.address : item.companyAddress,
		taxCode: item.taxCode,
		name: item.name,
		numberId: item.numberId,
		email: item.email,
		phone: item.phone,
		province: (item.for == 'canhan') ? item.province : item.companyProvince,
		district: (item.for == 'canhan') ? item.district : item.companyDistrict,
		ward: (item.for == 'canhan') ? item.ward : item.companyWard,
		birthday: item.birthday*1000,
		gender: item.gender,

		copyUi: 1,
		uiFor: item.for,
		uiCompanyName: item.companyName,
		uiAddress: (item.for == 'canhan') ? item.address : item.companyAddress,
		uiTaxCode: item.taxCode,
		uiName: item.name,
		uiNumberId: item.numberId,
		uiEmail: item.email,
		uiPhone: item.phone,
		uiProvince: (item.for == 'canhan') ? item.province : item.companyProvince,
		uiDistrict: (item.for == 'canhan') ? item.district : item.companyDistrict,
		uiWard: (item.for == 'canhan') ? item.ward : item.companyWard,
		uiBirthday: item.birthday*1000,
		uiGender: item.gender,

		copyAdmin: 1,
		adminName: item.name,
		adminAddress: item.address,
		adminNumberId: item.numberId,
		adminEmail: item.email,
		adminPhone: item.phone,
		adminProvince: item.province,
		adminDistrict: item.district,
		adminWard: item.ward,
		adminBirthday: item.birthday*1000,
		adminGender: item.gender,
	}

	const validationSchema = Yup.object().shape({
		for: Yup.string().required('Bạn chưa chọn loại khách hàng'),
		companyName: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Tên công ty không được bỏ trống'),
		}),
		taxCode: Yup.string().when('for', {
			is: 'congty',
			then: Yup.string().required('Mã số thuế không được bỏ trống'),
		}),
		name: Yup.string().when('for', {
			is: 'canhan',
			then: Yup.string().required('Họ tên khách hàng không được để trống'),
		}),
		numberId: Yup.string().when('for', {
			is: 'canhan',
			then: Yup.string().required('CCCD khách hàng không được để trống'),
		}),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		province: Yup.string().required('Tỉnh thành không được để trống'),
		address: Yup.string().required('Địa chỉ không được để trống'),
		district: Yup.string().required('Quận huyện không được để trống'),
		ward: Yup.string().required('Phường xã không được để trống'),
		birthday: Yup.string().when('for', {
			is: 'canhan',
			then: Yup.string().required('Bạn chưa chọn ngày sinh khách hàng'),
		}),
		gender: Yup.string().when('for', {
			is: 'canhan',
			then: Yup.string().required('Bạn chưa chọn giới tính khách hàng'),
		}),

		uiEmail: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		uiPhone: Yup.string().required('Số điện thoại không được để trống'),
		uiProvince: Yup.string().required('Tỉnh thành không được để trống'),
		uiAddress: Yup.string().required('Địa chỉ không được để trống'),
		uiDistrict: Yup.string().required('Quận huyện không được để trống'),
		uiWard: Yup.string().required('Phường xã không được để trống'),
		uiBirthday: Yup.string().when('for', {
			is: 'canhan',
			then: Yup.string().required('Bạn chưa chọn ngày sinh'),
		}),
		uiGender: Yup.string().when('for', {
			is: 'canhan',
			then: Yup.string().required('Bạn chưa chọn giới tính'),
		}),

		adminEmail: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		adminPhone: Yup.string().required('Số điện thoại không được để trống'),
		adminProvince: Yup.string().required('Tỉnh thành không được để trống'),
		adminAddress: Yup.string().required('Địa chỉ không được để trống'),
		adminDistrict: Yup.string().required('Quận huyện không được để trống'),
		adminWard: Yup.string().required('Phường xã không được để trống')
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset, setValue, getValues } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const forInput = useWatch({control, name: "for"});

	useEffect(() => {
		if(item?.id) {
			if(initialValues.province.length != 0) {
				for (const [cityKey, districtTemp] of Object.entries(country.district)) {
					if(cityKey == initialValues.province) {
						districtOptions = [{
							label : 'Chọn quận huyện',
							value : ''
						}];
						for (const [value, label] of Object.entries(districtTemp)) {
							districtOptions.push({value, label})
						}
						break;
					}
				}
				setDistrictOptions(districtOptions);
				setDistrictUiOptions(districtOptions);
				setDistrictAdminOptions(districtOptions);
			}
			if(initialValues.district.length != 0) {
				for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
					if(districtKey == initialValues.district) {
						wardOptions = [{
							label : 'Chọn phường xã',
							value : ''
						}];
						for (const [value, label] of Object.entries(wardTemp)) {
							wardOptions.push({value, label})
						}
						break;
					}
				}
				setWardOptions(wardOptions);
				setWardUiOptions(wardOptions);
				setWardAdminOptions(wardOptions);
			}
		}
	}, [item]);

	const handleCityChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtOptions.push({value, label})
				}
				break;
			}
		}
		setValue('district', '');
		setValue('ward', '');
		setDistrictOptions(districtOptions);
	}

	const handleDistrictChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardOptions.push({value, label})
				}
				break;
			}
		}
		setValue('ward', '');
		setWardOptions(wardOptions);
	}

	const handleCityUiChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtUiOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtUiOptions.push({value, label})
				}
				break;
			}
		}
		setValue('uiDistrict', '');
		setValue('uiWard', '');
		setDistrictUiOptions(districtUiOptions);
	}

	const handleDistrictUiChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardUiOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardUiOptions.push({value, label})
				}
				break;
			}
		}
		setValue('uiWard', '');
		setWardUiOptions(wardUiOptions);
	}

	const handleCityAdminChange = (value) => {
		for (const [cityKey, districtTemp] of Object.entries(country.district)) {
			if(cityKey == value) {
				districtAdminOptions = [{
					label : 'Chọn quận huyện',
					value : ''
				}];
				for (const [value, label] of Object.entries(districtTemp)) {
					districtAdminOptions.push({value, label})
				}
				break;
			}
		}
		setValue('adminDistrict', '');
		setValue('adminWard', '');
		setDistrictAdminOptions(districtAdminOptions);
	}

	const handleDistrictAdminChange = (value) => {
		for (const [districtKey, wardTemp] of Object.entries(country.ward)) {
			if(districtKey == value) {
				wardAdminOptions = [{
					label : 'Chọn phường xã',
					value : ''
				}];
				for (const [value, label] of Object.entries(wardTemp)) {
					wardAdminOptions.push({value, label})
				}
				break;
			}
		}
		setValue('adminWard', '');
		setWardUiOptions(wardAdminOptions);
	}

	const handleCopyUiChange = (value) => {
		if(value == 2) {
			setValue('uiName', '');
			setValue('uiNumberId', '');
			setValue('uiCompanyName', '');
			setValue('uiTaxCode', '');
			setValue('uiEmail', '');
			setValue('uiPhone', '');
			setValue('uiAddress', '');
			setValue('uiProvince', '');
			setValue('uiDistrict', '');
			setValue('uiWard', '');
		}
		if(value == 1) {
			setValue('uiName', getValues('name'));
			setValue('uiNumberId', getValues('numberId'));
			setValue('uiCompanyName', getValues('companyName'));
			setValue('uiTaxCode', getValues('taxCode'));
			setValue('uiEmail', getValues('email'));
			setValue('uiPhone', getValues('phone'));
			setValue('uiAddress', getValues('address'));
			setValue('uiProvince', getValues('province'));
			setValue('uiDistrict', getValues('district'));
			setValue('uiWard', getValues('ward'));
		}
	}

	const handleCopyAdminChange = (value) => {
		if(value == 3) {
			setValue('adminName', '');
			setValue('adminNumberId', '');
			setValue('adminEmail', '');
			setValue('adminPhone', '');
			setValue('adminAddress', '');
			setValue('adminProvince', '');
			setValue('adminDistrict', '');
			setValue('adminWard', '');
			setValue('adminBirthday', '');
			setValue('adminGender', '');
		}
		if(value == 1) {
			setValue('adminName', getValues('name'));
			setValue('adminNumberId', getValues('numberId'));
			setValue('adminEmail', getValues('email'));
			setValue('adminPhone', getValues('phone'));
			setValue('adminAddress', getValues('address'));
			setValue('adminProvince', getValues('province'));
			setValue('adminDistrict', getValues('district'));
			setValue('adminWard', getValues('ward'));
			setValue('adminBirthday', getValues('birthday'));
			setValue('adminGender', getValues('gender'));
		}
		if(value == 2) {
			setValue('adminName', getValues('uiName'));
			setValue('adminNumberId', getValues('uiNumberId'));
			setValue('adminEmail', getValues('uiEmail'));
			setValue('adminPhone', getValues('uiPhone'));
			setValue('adminAddress', getValues('uiAddress'));
			setValue('adminProvince', getValues('uiProvince'));
			setValue('adminDistrict', getValues('uiDistrict'));
			setValue('adminWard', getValues('uiWard'));
			setValue('adminBirthday', getValues('uiBirthday'));
			setValue('adminGender', getValues('uiGender'));
		}
	}

	const renderInfo = () => {
		return (
			<>
				<Controller control={control} name="for" render={({ field }) => (
					<GroupRadioField options={forOptions} errors={errors} {...field}/>
				)}/>
				{
					(forInput == 'canhan') &&
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="name" render={({field}) => (
								<InputField label="Họ tên khách hàng" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="numberId" render={({ field }) => (
								<InputField label="CCCD / CMND" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				}
				{
					(forInput == 'congty') &&
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="companyName" render={({ field }) => (
								<InputField label="Tên công ty" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="taxCode" render={({ field }) => (
								<InputField label="Mã số thuế" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				}

				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="email" render={({ field }) => (
							<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="phone" render={({ field }) => (
							<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="address" render={({ field }) => (
							<InputField label="Địa chỉ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="province" render={({ field }) => (
							<SelectField label="Tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
								handleCityChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="district" render={({ field }) => (
							<SelectField placeholder="Chọn quận huyện" options={districtOptions} errors={errors} {...field} onChange={(value, event) => {
								handleDistrictChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="ward" render={({ field }) => (
							<SelectField placeholder="Chọn phường xã" options={wardOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				{
					(forInput == 'canhan') &&
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="birthday" render={({field}) => (
								<DateField label="Ngày sinh" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="gender" render={({field}) => (
								<SelectField label="Giới tính" options={genderOptions} errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				}
			</>
		)
	}

	const renderUi = () => {
		return (
			<>
				<Controller control={control} name="copyUi" render={({ field }) => (
					<GroupRadioField options={copyUiOptions} errors={errors} {...field} onChange={(event) => {
						handleCopyUiChange(event.target.value);
						field.onChange(event);
					}} />
				)}/>
				{
					(forInput == 'canhan') &&
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="uiName" render={({field}) => (
								<InputField label="Họ tên khách hàng" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="uiNumberId" render={({ field }) => (
								<InputField label="CCCD / CMND" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				}
				{
					(forInput == 'congty') &&
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="uiCompanyName" render={({ field }) => (
								<InputField label="Tên công ty" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="uiTaxCode" render={({ field }) => (
								<InputField label="Mã số thuế" errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				}

				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="uiEmail" render={({ field }) => (
							<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="uiPhone" render={({ field }) => (
							<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="uiAddress" render={({ field }) => (
							<InputField label="Địa chỉ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="uiProvince" render={({ field }) => (
							<SelectField label="Tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
								handleCityUiChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="uiDistrict" render={({ field }) => (
							<SelectField placeholder="Chọn quận huyện" options={districtUiOptions} errors={errors} {...field} onChange={(value, event) => {
								handleDistrictUiChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="uiWard" render={({ field }) => (
							<SelectField placeholder="Chọn phường xã" options={wardUiOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				{
					(forInput == 'canhan') &&
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="uiBirthday" render={({field}) => (
								<DateField label="Ngày sinh" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="uiGender" render={({field}) => (
								<SelectField label="Giới tính" options={genderOptions} errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
				}
			</>
		)
	}

	const renderAdmin = () => {
		return (
			<>
				<Controller control={control} name="copyAdmin" render={({ field }) => (
					<GroupRadioField options={copyAdminOptions} errors={errors} {...field} onChange={(event) => {
						handleCopyAdminChange(event.target.value);
						field.onChange(event);
					}}/>
				)}/>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminName" render={({field}) => (
							<InputField label="Họ tên khách hàng" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminNumberId" render={({ field }) => (
							<InputField label="CCCD / CMND" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>

				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminEmail" render={({ field }) => (
							<InputField type="email" label="Email liên hệ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminPhone" render={({ field }) => (
							<InputField type="tel" label="Số điện thoại" errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminAddress" render={({ field }) => (
							<InputField label="Địa chỉ" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminProvince" render={({ field }) => (
							<SelectField label="Tỉnh thành" options={cityOptions} errors={errors} {...field} onChange={(value, event) => {
								handleCityAdminChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminDistrict" render={({ field }) => (
							<SelectField placeholder="Chọn quận huyện" options={districtAdminOptions} errors={errors} {...field} onChange={(value, event) => {
								handleDistrictAdminChange(value);
								field.onChange(value, event);
							}}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="adminWard" render={({ field }) => (
							<SelectField placeholder="Chọn phường xã" options={wardAdminOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="birthday" render={({field}) => (
							<DateField label="Ngày sinh" errors={errors} {...field}/>
						)}/>
					</Col>
					<Col className="gutter-row" span={12}>
						<Controller control={control} name="gender" render={({ field }) => (
							<SelectField label="Giới tính" options={genderOptions} errors={errors} {...field}/>
						)}/>
					</Col>
				</Row>
			</>
		)
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col className="gutter-row" sm={24} md={8}>
					<p className="heading">THÔNG TIN KHÁCH HÀNG</p>
					{renderInfo()}
				</Col>
				<Col className="gutter-row" sm={24} md={8}>
					<p className="heading">THÔNG TIN CHỦ THỂ</p>
					{renderUi()}
				</Col>
				<Col className="gutter-row" sm={24} md={8}>
					<p className="heading">THÔNG TIN NGƯỜI QUẢN LÝ</p>
					{renderAdmin()}
				</Col>
			</Row>

			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Mua dịch vụ</Button>
			</div>
		</form>
	)
}

export default BuyServiceFormPa;