import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {extendActions} from "./extendSlice";
import {extendApi} from "~/api";

function* fetchExtendList(action) {
	try {
		const response = yield call(extendApi.gets, action.payload);
		yield put(extendActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch extend list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(extendActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(extendActions.setFilter(action.payload));
}

export default function* extendSaga() {
	yield takeLatest(extendActions.fetchData.type, fetchExtendList);
	yield debounce(500, extendActions.setFilterWithDebounce.type, handleSearchDebounce)
}