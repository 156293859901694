import {useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import {
    Button,
    StatusLabel,
    Loading
} from "~/components";
import _ from "lodash";
import {
    GroupCheckBoxField
} from "~/components/Forms";
import {customerGroupApi} from "../../../../api";
import {apiError, handleRequest} from "../../../../utils";

function CustomerFormGroup({item, onHandleSubmit, groupOptions}) {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialValues = {
        groups: undefined,
    }

    const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => {
        if (item?.id) {

            if(!_.isEmpty(item?.groups)) {
                initialValues.groups = Object.entries(item?.groups).map(([key, value]) => {
                    return value.id
                })
            }
            reset(initialValues)
        }
    }, [item]);

    const onSubmit = async (data) => {
        await onHandleSubmit(data, item);
    }

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            {isSubmitting && <Loading/>}

            <p className="heading">Nhân viên share</p>
            <Controller control={control} name="groups" render={({ field }) => (
                <GroupCheckBoxField options={groupOptions} errors={errors} {...field}></GroupCheckBoxField>
            )}/>

            <div className="form-group d-flex justify-content-end modal-bottom mt-2">
                <Button primary type="submit">Cập nhật thông tin</Button>
            </div>
        </form>
    )
}

export default CustomerFormGroup;