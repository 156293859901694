import {useCallback, useMemo, useState} from "react";
import {
	SelectUserAll,
	SelectContractAll,
	SelectField,
	DateField
} from "~/components/Forms";
import {
	LIST_REVENUE_STATUS
} from "~/app/constants";
import {StatusLabel} from "~/components";
import {useCan, useUtilities} from "~/hooks";
import {strToTime} from "~/utils";

function RevenueSearchBar({filter, onChange}) {

	const {utilities} = useUtilities();

	const can = {
		listAll: useCan('revenueListAll'),
		listGroup: useCan('revenueListGroup'),
	};

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const serviceOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.services).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [utilities.services]);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_REVENUE_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_REVENUE_STATUS]);

	const handleUserChange = useCallback((value) => {
		if (onChange) { onChange('userId', value); }
	}, [onChange]);

	const handleContractChange = useCallback((value) => {
		if (onChange) { onChange('contractId', value); }
	}, [onChange]);

	const handleServiceChange = useCallback((value) => {
		if (onChange) { onChange('service', value); }
	}, [onChange]);

	const handleStatusChange = useCallback((value) => {
		if (onChange) { onChange('status', value); }
	}, [onChange]);

	const handleTimeChange = useCallback((value) => {
		value = strToTime(value);
		if(onChange) onChange('timePlus', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<SelectContractAll value={filter.contractId} placeholder="Hợp đồng" options={[{value:'', label:'Tất cả'}]} onChange={handleContractChange} />
					{(can.listAll || can.listGroup) && <SelectUserAll  value={filter.userId} placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />}
					<SelectField value={filter.service} placeholder="Dịch vụ" options={serviceOptions} onChange={handleServiceChange}/>
					<SelectField value={filter.status} placeholder="Trạng thái" options={statusOptions} onChange={handleStatusChange}/>
					<DateField value={filter.time} name="timePlus" placeholder="Tính lương" picker="month" onChange={handleTimeChange}/>
				</form>
			</div>
		</div>
	)
}

export default RevenueSearchBar;