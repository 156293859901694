import request from "~/utils/http";

const formSalaryApi = {
	count : async (params) => {
		const url = 'forms/salary/count';
		return await request.get(url, {params});
	},
	gets : async (params) => {
		const url = 'forms/salary';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'forms/salary';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `forms/salary/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `forms/salary/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `forms/salary/status/${params.id}`;
		return await request.post(url, params);
	},
};

export default formSalaryApi;