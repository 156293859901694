import {createSlice} from "@reduxjs/toolkit";
import expenseEventSaga from "./expenseSaga";
import {DATA_LIMIT} from "../../app/constants";

const expense = createSlice({
	name: "expense",
	initialState: {
		loading: false,
        error: null,
		filter:{
			page : 1,
			limit : DATA_LIMIT
		},
        items: [],
		pagination : {
			page : 1,
			limit : DATA_LIMIT,
			totalRows: 0
		}
	},
	reducers: {
		fetchData(state) {
			state.loading = true;
            state.error = null;
			return state;
		},
		fetchDataSuccess(state, action) {
			state.loading = false;
			state.items = action.payload.data;
			state.pagination = action.payload.pagination;
			state.error = null;
			return state;
		},
		fetchDataFailed(state, action) {
			state.loading = false;
			state.error = action.payload;
			return state;
		},
		add(state, action) {
			state.items.unshift(action.payload);
			return state;
		},
		update(state, action) {
			let objIndex = state.items.findIndex((item => item.id == action.payload.id));
			state.items[objIndex] = {...state.items[objIndex], ...action.payload};
			return state;
		},
		delete(state, action) {
			state.items = state.items.filter(function(item) {
				return item.id !== action.payload
			})
		},
		//Action search
		setFilter(state, action) {
			state.filter = action.payload;
			return state;
		},
		setFilterWithDebounce(state, action) {}
	}
})
//Action
export const expenseActions = expense.actions;
//Reducers
export const expenseReducer = expense.reducer;
//Selectors
export const expenseItemsSelector = (state) => state.expense.items;
export const expenseLoadingSelector = (state) => state.expense.loading;
export const expenseErrorSelector = (state) => state.expense.error;
export const expenseFilterSelector = (state) => state.expense.filter;
export const expensePaginationSelector = (state) => state.expense.pagination;