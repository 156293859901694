import {useCallback, useMemo} from "react";
import {
	DateField,
	SelectField
} from "~/components/Forms";
import {strToTime} from "~/utils";

function ExpenseSearchBar({onChange, categories}) {

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả loại chi' }, ...Object.entries(categories).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [categories]);

	const handleTimeChange = useCallback((value) => {
		value = strToTime(value);
		if(onChange) onChange('time', value);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('groupId', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<DateField name="time" picker="month" onChange={handleTimeChange}/>
					<SelectField placeholder="Chọn loại" options={groupOptions} onChange={(value, event) => handleGroupChange(value)} />
				</form>
			</div>
		</div>
	)
}

export default ExpenseSearchBar;