import style from '../Home.module.scss';
import {memo, useCallback, useEffect, useState} from "react";
import className from 'classnames/bind';
import {Col, Row, Tooltip} from "antd";
import images from "~/assets/images";
import {
	apiError,
	handleRequest,
	numberFormat
} from "~/utils";

import {
	dashboardApi
} from "~/api";

import {
	CardToast,
	ContentHeader,
	SidebarAccount
} from "../componets";
import {Image} from "../../../components";

const cn = className.bind(style);

function DashboardSales({user, fullTime}) {

	const [dashboardData, setDashboardData] = useState({
		revenue: { all: 0, plus: 0, commit: 0 },
		revenueRankHigh: [],
		contracts: [],
		customer: { all: 0, confirm: 0 },
		saleCommit: { commit: 0, result: 0 },
	});

	useEffect(() => {
		fetchDashboardData().then()
	}, [user]);

	const fetchDashboardData = useCallback(async () => {
		const [
			revenueResponse,
			customerResponse,
			contractResponse,
			saleCommitResponse,
			revenueRankHighResponse
		] = await Promise.all([
			handleRequest(dashboardApi.revenue()),
			handleRequest(dashboardApi.customer()),
			handleRequest(dashboardApi.contractNew()),
			handleRequest(dashboardApi.saleCommit()),
			handleRequest(dashboardApi.revenueRanks()),
		]);
		//Doanh số
		let [revenueErr, revenueData] = revenueResponse;
		let revenueMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueErr, revenueData);
		if (!revenueMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenue: {
					all: revenueData.data.all,
					plus: revenueData.data.plus,
					commit: revenueData.data.commit,
				},
			}));
		}

		//Khách hàng
		let [customerErr, customerData] = customerResponse;
		let customerMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, customerErr, customerData);
		if (!customerMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				customer: {
					all: customerData.data.all,
					confirm: customerData.data.confirm,
				},
			}));
		}

		//Khách hàng
		let [contractErr, contractData] = contractResponse;
		let contractMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, contractErr, contractData);
		if (!contractMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				contracts: contractData.data,
			}));
		}

		//Cam kết
		let [saleCommitErr, saleCommitData] = saleCommitResponse;
		let saleCommitMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, saleCommitErr, saleCommitData);
		if (!saleCommitMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				saleCommit: {
					commit: saleCommitData.data.commit,
					result: saleCommitData.data.result,
				},
			}));
		}

		//Xếp hạng
		let [revenueRankHighErr, revenueRankHighData] = revenueRankHighResponse;
		let revenueRankHighMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueRankHighErr, revenueRankHighData);
		if (!revenueRankHighMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenueRankHigh: revenueRankHighData.data,
			}));
		}
	}, [])

	const CardRanks = ({name, items, cardColor, cardMedal}) => {
		return (
			<div className={cn('card', 'card-ranks', (cardMedal) ? 'card-ranks-medal' : '', cardColor)}>
				<h4 className={cn('heading')}>{name}</h4>
				<div className={cn('list-ranks')}>
					{
						items.map((item) => {
							return <div key={`card-rank-${item.id}`} className={cn('user', 'd-flex', 'gap', 'align-items')}>
								<div className={cn('medal')}>
									<small></small>
								</div>
								<div className={cn('avatar-rank', 'avatar')}>
									<Image src={item.avatar} />
								</div>
								<div>
									<b className={cn('name', 'color-white')}>{item.firstname} {item.lastname}</b>
									<p className={cn('info-more', 'color-white')}>{item.username}</p>
									<p className={cn('revenue', 'text-left')}><b>{numberFormat(item.total)}</b></p>
								</div>
							</div>
						})
					}
				</div>
			</div>
		)
	}

	const CardCount = ({image, heading, number1, number2, number3, txt1, txt2, txt3, txtSize}) => {
		return (
			<div className={cn('card', 'card-while', 'pd-1')}>
				<div className={cn('icon')}><Image src={image} /></div>
				<div className={cn('info')}>
					<p className={cn('heading-mini')}>{heading}</p>
					<p className={cn('number', txtSize)}>
						{(typeof number1 != 'undefined') && <Tooltip title={txt1}><span className="color-red">{numberFormat(number1)}</span></Tooltip>}
						{(typeof number2 != 'undefined') && <Tooltip title={txt2}><span> / {numberFormat(number2)}</span></Tooltip>}
						{(typeof number3 != 'undefined') && <Tooltip title={txt3}><span> / {numberFormat(number3)}</span></Tooltip>}
					</p>
				</div>
			</div>
		)
	}

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
				<CardToast items={dashboardData.contracts} />
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} fullTime={fullTime} />
				<Row gutter={20}>
					<Col span={14}>
						<Row gutter={20}>
							<Col span={12}>
								<CardCount
									image={images.dashboard06}
									heading={'Cam kết tháng'}
									// number1={dashboardData.revenue.all} txt1={'Tất cả'}
									// number2={dashboardData.revenue.plus} txt2={'Tính lương'}
									number1={dashboardData.revenue.commit} txt1={'Cam kết'}
								/>
							</Col>
							<Col span={12}>
								<CardCount
									image={images.dashboard07}
						            heading={'Khách hàng'}
						            number1={dashboardData.customer.confirm} txt1={'Đã duyệt'}
						            number2={dashboardData.customer.all} txt2={'Tất cả'}
								/>
							</Col>
							<Col span={12}>
								<CardCount
									image={images.dashboard09}
						            heading={'Cam kết 3 ngày'}
						            number1={dashboardData.saleCommit.result} txt1={'Kết quả'}
									number2={dashboardData.saleCommit.commit} txt2={'Cam kết'}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default memo(DashboardSales);
