import { Fragment, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "~/context/AppProvider";
import { notification } from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Button,
	Loading,
	Modal,
	Icon
} from "~/components";
import {
	saleEventActions,
	saleEventErrorSelector,
	saleEventFilterSelector,
	saleEventItemsSelector,
	saleEventLoadingSelector,
	saleEventPaginationSelector
} from "../saleEventSlice";
import {
	apiError,
	handleRequest,
	strToTime
} from "~/utils";
import {
	useCan,
	useGroup
} from "~/hooks";
import {
	saleApi
} from "~/api";
import {
	SaleEventTable,
	SaleEventFormAddEdit,
	SaleEventFormStart,
	SaleEventFormAssign,
	EventSearchBar,
	EventSearchMobile,
	SaleEventFormCopy
} from "../components";

function SaleEvent() {

	const {isMobile, isDesktop} = useContext(AppContext);

	const canAdd = useCan('salesEventAdd');

	const canEdit = useCan('salesEventEdit');

	const dispatch  = useDispatch();

	const items     = useSelector(saleEventItemsSelector);

	const loading   = useSelector(saleEventLoadingSelector);

	const error     = useSelector(saleEventErrorSelector);

	const pagination = useSelector(saleEventPaginationSelector);

	const filter    = useSelector(saleEventFilterSelector);

	const listGroup = useGroup();

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		cancel      : false,
		start       : false,
		end         : false,
		assign      : false,
		copy        : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	useEffect(() => {
		handleReLoading();
	}, [filter]);

	const handleReLoading = () => {
		dispatch(saleEventActions.fetchData(filter));
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if(!canEdit) {
				notification.error({message: 'Thất bại', description: `Bạn không có quyền sử dụng chức năng này`});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}
		else {
			if(!canAdd) {
				notification.error({message: 'Thất bại', description: `Bạn không có quyền sử dụng chức năng này`});
				return;
			}
		}

		data.day = strToTime(data.day);

		let [error, response] = (item?.id) ? await handleRequest(saleApi.eventUpdate(data)) : await handleRequest(saleApi.eventAdd(data));
		let message = apiError(`${messageAction} lịch hẹn thất bại`, error, response);
		if(!message) {
			if(!response?.data?.id) {
				notification.error({message: 'Thất bại', description: `Chưa thêm được lịch hẹn`});
			}
			else {
				notification.success({message: 'Thành công', description: `${messageAction} lịch hẹn thành công`});
				if(!item?.id) {
					dispatch(saleEventActions.add(response.data));
				}
				else {
					dispatch(saleEventActions.update(response.data));
				}
				handleModalClose('addEdit')
			}
		}
	}

	//copy
	const handleCopy = async (data, item) => {
		if(item?.point == 1) {
			notification.error({message: 'Thất bại', description: 'Cuộc hẹn đang đi không thể dời hẹn'});
			return;
		}
		data.day = strToTime(data.day);
		let [error, response] = await handleRequest(saleApi.eventAdd(data));
		let message = apiError(`Dời lịch hẹn thất bại`, error, response);
		if(!message) {
			if(!response?.data?.id) {
				notification.error({message: 'Thất bại', description: `Chưa dời được lịch hẹn`});
			}
			else {
				notification.success({message: 'Thành công', description: `Dời lịch hẹn thành công`});
				dispatch(saleEventActions.add(response.data));
				handleModalClose('copy')
			}
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin lịch hẹn để xóa'});
			return;
		}
		let [error, response] = await handleRequest(saleApi.eventDelete(itemEdit.id));
		let message = apiError(`xóa lịch hẹn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa lịch hẹn thành công`});
			dispatch(saleEventActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//Cancel
	const handleCancel = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin lịch hẹn để hủy'});
			return;
		}
		let [error, response] = await handleRequest(saleApi.eventCancel(itemEdit.id));
		let message = apiError(`Hủy lịch hẹn thất bại`, error, response);
		if(!message && response?.data?.point) {
			notification.success({message: 'Thành công', description: `Hủy lịch hẹn thành công`});
			itemEdit.point = response.data.point;
			dispatch(saleEventActions.update(itemEdit));
			handleModalClose('cancel')
		}
		else {
			notification.error({message: 'Thất bại', description: `Chưa thêm được lịch hẹn`});
		}
	}

	const handleStart = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin lịch hẹn để thay đổi'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(saleApi.eventStart(data));
		let message = apiError(`Bắt đầu lịch hẹn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Bắt đầu lịch hẹn thành công`});
			item.point = response.data.point;
			dispatch(saleEventActions.update(item));
			handleModalClose('start')
		}
	}

	const handleEnd = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin lịch hẹn để thay đôir'});
			return;
		}
		let [error, response] = await handleRequest(saleApi.eventEnd(itemEdit.id));
		let message = apiError(`Hoàn thành lịch hẹn thất bại`, error, response);
		if(!message && response?.data?.point) {
			notification.success({message: 'Thành công', description: `Hoàn thành lịch hẹn thành công`});
			itemEdit.point = response.data.point;
			dispatch(saleEventActions.update(itemEdit));
			handleModalClose('end')
		}
		else {
			notification.error({message: 'Thất bại', description: `Cập nhật thông tin thất bại`});
		}
	}

	//assign
	const handleAssign = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có cuộc hẹn để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(saleApi.eventAssign(data));
		let message = apiError(`Phân công cuộc hẹn không thành công`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Phân công cuộc hẹn thành công`});
			item.assignId   = response.data.assignId;
			item.assign     = response.data.assign;
			item.supportId  = response.data.supportId;
			item.support    = response.data.support;
			dispatch(saleEventActions.update(item));
			handleModalClose('assign');
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(saleEventActions.setFilter({...filter, page }));
	}

	const handleFilterChange = (newFilter) => {
		dispatch(saleEventActions.setFilter(newFilter));
	};

	const handleSearchChange = (newFilter) => {
		dispatch(saleEventActions.setFilterWithDebounce(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Lịch hẹn'}>
				{canAdd && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{isDesktop && <EventSearchBar filter={filter} onChange={handleFilterChange} onSearchChange={handleSearchChange} listGroup={listGroup} />}
					{isMobile && <EventSearchMobile filter={filter} onChange={handleFilterChange} listGroup={listGroup} />}
					{items && <SaleEventTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
					/>}
				</div>
				{
					(canAdd || canEdit) && <Modal title="Lịch hẹn" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
						<SaleEventFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal title="Dời hẹn" visible={openModal.copy} onCancel={() => {handleModalClose('copy')}}>
						<SaleEventFormCopy item={itemEdit} onHandleSubmit={handleCopy} />
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal title="Xóa lịch hẹn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
						<p className="mt-2">Bạn muốn xóa lịch hẹn <b>{itemEdit?.name}</b>?</p>
						<div className="d-flex justify-content-end gap modal-bottom pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
						</div>
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal title="Hủy hẹn" visible={openModal.cancel} onCancel={() => {handleModalClose('cancel')}}>
						<p className="mt-2">Bạn muốn hủy cuộc hẹn với khách <b>{itemEdit?.name}</b>?</p>
						<div className="d-flex justify-content-end modal-bottom pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('cancel')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleCancel}> Hủy </Button>
						</div>
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal title="Giao hẹn" visible={openModal.assign} onCancel={() => {handleModalClose('assign')}}>
						<SaleEventFormAssign item={itemEdit} onHandleSubmit={handleAssign} />
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal title="Bắt đầu gặp khách" visible={openModal.start} onCancel={() => {handleModalClose('start')}}>
						<SaleEventFormStart item={itemEdit} onHandleSubmit={handleStart} />
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal title="Hoàn thành cuộc hẹn" visible={openModal.end} onCancel={() => {handleModalClose('end')}}>
						<p className="mt-2">Xác nhận cuộc hẹn với khách hàng <b>{itemEdit?.name}</b> đã hoàn thành ?</p>
						<div className="d-flex justify-content-end modal-bottom pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('end')}}> Đóng </Button>
							<Button blue outline leftIcon={Icon.success} onClick={handleEnd}> Xác nhận </Button>
						</div>
					</Modal>
				}
			</div>
		</Fragment>
	)
}

export default SaleEvent;