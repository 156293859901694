import {useContext} from "react";
import {Pagination, Table} from "antd";
import {
	Image,
	Button,
	StatusLabel,
	PopperUserInfo
} from "~/components";
import {
	renderDate,
	numberFormat,
	arrayToMap
} from "~/utils";
import {
	LIST_REVENUE_STATUS
} from "~/app/constants";
import RevenueAction from "./RevenueAction";
import {useDevice, useUtilities, useGroup} from "~/hooks";
import {AppContext} from "~/context/AppProvider";

function RevenueTable({items = [], filter, pagination, onPaginationChange, onChangeFilter, setItemEdit, openModal, handleStatus}) {

	const {tableHeight} = useContext(AppContext);

	const {utilities} = useUtilities();

	const {isMobile} = useDevice();

	const listGroup = useGroup();

	const groupMap = arrayToMap(listGroup);

	const handleInfoClick = (item) => {
		setItemEdit(item);
		openModal('info');
	}

	const handleStatusClick = (item) => {
		setItemEdit(item);
		handleStatus(item);
	}

	const handleEditClick = (item) => {
		setItemEdit(item);
		openModal('addEdit');
	}

	const handlePayClick = (item) => {
		setItemEdit(item);
		openModal('pay');
	}

	const handleDeleteClick = (item) => {
		setItemEdit(item);
		openModal('delete');
	}

	const handleTransferClick = (item) => {
		setItemEdit(item);
		openModal('transfer');
	}

	const handleContractClick = (contractId) => {
		if (!onChangeFilter) return;
		const newFilter = {
			...filter,
			contractId: contractId,
			status: '',
			userId: '',
			service: '',
			page: 1,
		};
		onChangeFilter(newFilter);
	}

	let columns = [
		{ title: 'Hợp đồng', dataIndex: 'code', key: "code", width: '350px', render: (_, item) => (
			<div>
				<Button small onClick={() => handleContractClick(item.contractId)}><b style={{color : 'var(--primary)'}}>HD{item.contractId}</b></Button>
				 {item?.contract.name}
				<p>
					{item?.roomSales == 1 && <StatusLabel small type="green">Tính cho phòng</StatusLabel>}
					{item?.roomSales == 0 && <StatusLabel small type="red">Không Tính cho phòng</StatusLabel>}
					{isMobile &&
						<>
							<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
							&nbsp;<StatusLabel small type={utilities.services[item.service].color}>{utilities.serviceTypes[item.serviceType].label}</StatusLabel>
						</>
					}
				</p>
				{isMobile && <p className="mt-1" style={{fontSize:'12px', color:'#ccc'}}>Ngày thu {renderDate(item?.time)}</p>}
			</div>
		)},
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			<PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
						<p>{(item?.groupId != 0) ? groupMap[item?.groupId]?.name : item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{title: 'Loại thu', dataIndex: 'service', key: 'service', render:(_, item) => (
			<div>
				<StatusLabel small type={utilities.services[item.service].color}>{utilities.services[item.service].label}</StatusLabel>
				&nbsp;<StatusLabel small type={utilities.services[item.service].color}>{utilities.serviceTypes[item.serviceType].label}</StatusLabel>
			</div>
		)},
		{ title: 'Số tiền', dataIndex: 'price', key: "price", width: '180px', render: (_, item) => (
			<div>
				<p><b>{numberFormat(item?.price)}đ</b></p>
			</div>
		)},
		{ title: 'Hoa hồng', dataIndex: 'percent', key: "percent", width: '180px', render: (_, item) => (
			<div>
				<p><b>{numberFormat(item?.commission)}đ ({item?.percent}%)</b></p>
			</div>
		)},
		{ title: 'Lương', dataIndex: 'pay', key: "pay", width: '180px', render: (_, item) => (
			<div>
				{item?.plus == 'confirm' && <StatusLabel small type="green">{renderDate(item.timePlus, 'month')}</StatusLabel>}
				{item?.plus == 'pending' && <StatusLabel small type="red">Chưa tính</StatusLabel>}
			</div>
		)},
		{ title: 'Ngày thu', dataIndex: 'signing', key: "signing", width: '120px', render: (_, item) => (
			<div><p>{renderDate(item?.time)}</p></div>
		)},
		{title: 'Trạng thái', dataIndex: 'status', key: "status", width: '130px', render: (_, item) => (
			<StatusLabel small type={LIST_REVENUE_STATUS[item.status].color}>{LIST_REVENUE_STATUS[item.status].label}</StatusLabel>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<RevenueAction
				key={item.id}
				item={item}
				onClickInfo={handleInfoClick}
				onClickStatus={handleStatusClick}
				onClickPay={handlePayClick}
				onClickEdit={handleEditClick}
				onClickDelete={handleDeleteClick}
				onClickTransfer={handleTransferClick}
			/>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'service' && currentValue.key != 'signing')
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default RevenueTable;