import style from "../../style/Financial.module.scss";
import className from "classnames/bind";
import {Fragment, useState, useEffect, memo, useRef} from "react";
import {Col, Row} from "antd";
import _ from "lodash";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Icon,
} from "~/components";
import {
	apiError,
	handleRequest,
	numberFormat
} from "~/utils";
import {reportApi} from "~/api";
import {useUtilities} from "~/hooks";
import {FinancialReportSearch} from "../../components";
import {Bar, Pie} from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const cn = className.bind(style);

function FinancialReport() {

	const {utilities} = useUtilities();

	const chartRef = useRef(null);

	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState([]);

	const [chartData, setChartData] = useState({
		labels: [],
		datasets : [
			{
				label: 'Doanh thu',
				backgroundColor: 'rgb(255, 99, 132)',
				gradientStart: 'rgba(255, 99, 132, 0.3)',
				gradientEnd: 'rgba(255, 99, 132, 1)',
			},
			{
				label: 'Chi phí',
				backgroundColor: 'rgb(53, 162, 235)',
				gradientStart : 'rgba(53, 162, 235, 0.3)',
				gradientEnd : 'rgba(53, 162, 235, 1)',
			},
		]
	});

	const [reportData, setReportData] = useState({
		total: {
			revenue : 0,
			expense : 0,
		},
		services: [],
		expenses: [],
		pays: [],
		paysChart: {
			labels: [],
			values: [],
			colors: [],
		}
	});

	const options = {
		responsive: true,
		scales: {
			y: {
				grid: {
					display:false
				}
			},
			x: {
				grid: {
					display:false
				}
			},
		},
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Biểu đồ thu chi',
			},
		},
	};

	//Load data
	useEffect(() => { handleLoad().then(); }, [filter]);

	useEffect(() => {
		if(chartRef.current) {
			const gradient = chartRef.current.ctx.createLinearGradient(0, chartRef.current.chartArea.bottom, 0, chartRef.current.chartArea.top);
			gradient.addColorStop(0, chartData.datasets[0].gradientStart);
			gradient.addColorStop(1, chartData.datasets[0].gradientEnd);
			chartData.datasets[0].backgroundColor = gradient;
			setChartData(chartData);
		}
	}, [chartRef.current]);

	const handleLoad = async () => {
		setLoading(true);
		let [error, response] = await handleRequest(reportApi.financial(filter));
		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			chartData.labels            =  [...response.data?.chartData.labels];
			chartData.datasets[0].data  = response.data?.chartData.revenues;
			chartData.datasets[1].data  = response.data?.chartData.expenses;
			reportData.total.revenue = response.data?.total.revenue;
			reportData.total.expense = response.data?.total.expense;
			reportData.services = response.data?.services;
			reportData.expenses = response.data?.expenses;
			reportData.pays = response.data?.pays;
			let paysChartData = {
				labels: [],
				values: [],
				colors: [],
			};
			Object.keys(reportData.pays).map((itemKey) => {
				reportData.pays[itemKey].name = utilities.departments[itemKey]?.title;
				paysChartData.labels.push(utilities.departments[itemKey]?.title);
				paysChartData.colors.push(utilities.departments[itemKey]?.colorCode);
				if(reportData.pays[itemKey].total <= 0) {
					paysChartData.values.push(0);
				}
				else {
					paysChartData.values.push(reportData.pays[itemKey].total);
				}

			})
			reportData.paysChart = paysChartData;
			setReportData(reportData)
			setChartData(chartData)
		}
		setLoading(false);
	}

	const pieOptions = {
		labels: ['Doanh thu', 'Chi phí'],
		datasets: [
			{
				data: [reportData.total.revenue, reportData.total.expense],
				backgroundColor: [
					'rgb(255, 99, 132)',
					'rgb(53, 162, 235)',
				],
			},
		],
	};

	const paysChartData = {
		labels: reportData.paysChart.labels,
		datasets: [
			{
				data: reportData.paysChart.values,
				backgroundColor: reportData.paysChart.colors,
			},
		],
	};

	const ReportItem = ({item}) => {
		return (
			<div className={cn('item', 'mb-1')}>
				<div className={cn('info', 'w-100', 'd-flex', 'justify-content-between', 'align-items-center')}>
					<h3>{item?.name}</h3>
					<p>{numberFormat(item?.total)}</p>
				</div>
			</div>
		)
	}

	//Search
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		setFilter(newFilter);
	};

	return (
		<Fragment>
			<ActionBar title={'Thu chi'}></ActionBar>
			<div className="container d-block">
				<FinancialReportSearch onChange={handleFilterChange} />
				<div className="content-scroll">
					<div className="content-shadow pd-1 w-100">
						{loading && <Loading/>}
						<Row gutter={10}>
							<Col xs={24} md={11}>{!loading && <Bar ref={chartRef} options={options} data={chartData} />}</Col>
							<Col xs={24} md={5}>{!loading && <Pie options={{plugins: {legend: {position: 'right',},},}} data={pieOptions} />}</Col>
							<Col xs={24} md={6}>{!loading && <Pie options={{plugins: {legend: {position: 'right',},},}} data={paysChartData} />}</Col>
						</Row>
					</div>
					<div className="content-shadow pd-1 w-100">
						<Row gutter={10}>
							<Col xs={24} md={8}>
								<div className={cn('item', 'item-red', 'mb-1')}>
									<div className={cn('icon')}>{Icon.plusCircle}</div>
									<div className={cn('info')}>
										<h3>Tổng thu</h3>
										<p>{numberFormat(reportData.total.revenue)}</p>
									</div>
								</div>
								{
									(reportData.services && !_.isEmpty(reportData.services)) && Object.keys(reportData.services).map((itemKey) => {
										return <ReportItem key={`report-item-${itemKey}`} item={reportData.services[itemKey]} />
									})
								}
							</Col>
							<Col xs={24} md={8}>
								<div className={cn('item', 'item-green', 'mb-1')}>
									<div className={cn('icon')}>{Icon.plusCircle}</div>
									<div className={cn('info')}>
										<h3>Lương</h3>
										<p>{numberFormat(reportData.expenses[0]?.total)}</p>
									</div>
								</div>
								{
									(reportData.pays && !_.isEmpty(reportData.pays)) && Object.keys(reportData.pays).map((itemKey) => {
										return <ReportItem key={`report-item-${itemKey}`} item={reportData.pays[itemKey]} />
									})
								}
							</Col>
							<Col xs={24} md={8}>
								<div className={cn('item', 'item-blue', 'mb-1')}>
									<div className={cn('icon')}>{Icon.dola}</div>
									<div className={cn('info')}>
										<h3>Tổng chi</h3>
										<p>{numberFormat(reportData.total.expense)}</p>
									</div>
								</div>
								{
									(reportData.expenses && !_.isEmpty(reportData.expenses)) && Object.keys(reportData.expenses).map((itemKey) => {
										return <ReportItem key={`report-item-${itemKey}`} item={reportData.expenses[itemKey]} />
									})
								}
							</Col>
						</Row>

					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default memo(FinancialReport);