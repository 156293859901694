import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {serviceToDoActions} from "./serviceToDoSlice";
import {serviceToDoApi} from "~/api";

function* fetchServiceToDoList(action) {
	try {
		const response = yield call(serviceToDoApi.gets, action.payload);
		yield put(serviceToDoActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch serviceToDo list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(serviceToDoActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(serviceToDoActions.setFilter(action.payload));
}

export default function* serviceToDoSaga() {
	yield takeLatest(serviceToDoActions.fetchData.type, fetchServiceToDoList);
	yield debounce(500, serviceToDoActions.setFilterWithDebounce.type, handleSearchDebounce)
}