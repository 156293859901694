import Image from "~/components/Image";
import {Pagination, Table, Tooltip} from "antd";
import PopperUserInfo from "~/components/PopperUserInfo";
import StatusLabel from "~/components/StatusLabel";
import {
	renderDate,
} from "~/utils";
import {LIST_PAPER_NUMBER_STATUS} from "~/app/constants";
import PapersNumberAction from "./PapersNumberAction";

function PapersNumberTable({items = [], pagination, onPaginationChange, setItemEdit, openModal, can}) {

	const columns = [
		{ title: 'Mã đơn', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (<div>{item?.code}</div>)},
		{ title: 'Người nhận', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			item.user?.id && <PopperUserInfo user={item.user}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.user?.avatar} /></div>
					<div>
						<b>{`${item.user?.firstname} ${item.user?.lastname}`}</b>
						<p>{item.user?.username}</p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{ title: 'Loại', dataIndex: 'type', key: "type", width: '200px', render: (_, item) => (<b style={{color:item?.type.color}}>{item?.type.name}</b>)},
		{ title: 'Ngày lấy', dataIndex: 'timePick', key: "timePick", width: '120px', render: (_, item) => (<p>{renderDate(item?.timePick)}</p>)},
		{ title: 'Ngày trả', dataIndex: 'timePay', key: "timePay", width: '120px', render: (_, item) => (<p>{renderDate(item?.timePay)}</p>)},
		{ title: 'Trạng thái', dataIndex: 'status', key: 'status',width: '150px', render:(_, item) => (
			<StatusLabel pointer small type={LIST_PAPER_NUMBER_STATUS[item.status].color}>{LIST_PAPER_NUMBER_STATUS[item.status].label}</StatusLabel>
		)},
		{ title: 'Ghi chú', dataIndex: 'note', key: 'note', width: '220px', render:(_, item) => (<div>{item.note}</div>)},
		{ title: '#', dataIndex: 'action', key: "action", width: '180px', render: (_, item) => (
			can.edit && <PapersNumberAction item={item} setItemEdit={setItemEdit} openModal={openModal} />
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default PapersNumberTable;