import {createContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import { io } from "socket.io-client";
import {
	apiError,
	handleRequest,
	checkAuthorization
} from "~/utils";
import {
	userApi,
	groupApi,
	taskApi,
	notificationApi,
	formSalaryApi,
	formSabbaticalApi,
	utilitiesApi,
	formPaymentApi,
	papersApi
} from "~/api";
import {Loading} from "../components";
import {useViewport} from "~/hooks";
import {authActions} from "~/features/Auth/authSlice";

const AppContext = createContext(undefined);

// APP GLOBAL DATA
function AppProvider({ children }) {

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);

	const [onlineUsers, setOnlineUsers] = useState([]);

	const [loginAsUsers, setLoginAsUsers] = useState([]);

	const [notificationCount, setNotificationCount] = useState({
		comment: 0,
		notification: 0,
		tasks: 0,
		form: {
			sabbatical: 0,
			salary: 0,
			payment: 0,
			papers:0,
		}
	});

	//Danh sách tỉnh thành quận huyện
	const [country, setCountry] = useState({
		'city' 		: [],
		'district' 	: [],
		'ward' 		: [],
	});

	const [utilities, setUtilities] = useState({
		'key'               : '',
		'roles'             : [],
		'departments' 	    : [],
		'services' 		    : [],
		'serviceTypes'      : [],
		'payTypes' 		    : [],
		'devSource'         : [],
		'reasonStopReNew'   : [],
	});

	//Danh sách kỹ thuật
	const [listAssign, setListAssign] = useState([]);

	//Danh sách design
	const [listDesign, setListDesign] = useState([]);

	const [groups, setGroups] = useState([]);

	const [socket, setSocket] = useState({});

	const [userLogin, setUserLogin] = useState(false);

	const [tableHeight, setTableHeight] = useState('60vh');

	useEffect(() => {
		const socketConnect = io("ws://"+process.env.REACT_APP_SOCKET_URL+":"+process.env.REACT_APP_SOCKET_PORT, { withCredentials: false, });
		setSocket(socketConnect);
		return () => {
			socketConnect.disconnect();
		}
	}, [])

	useEffect(() => {
		if(checkAuthorization()) {
			(async () => {
				//Kiểm tra login
				let [errorUser, responseUser] = await handleRequest(userApi.getCurrent());
				let messageUser = apiError(`Lấy thông tin tài khoản thất bại`, errorUser, responseUser);
				if (!messageUser) {
					dispatch(authActions.loginSuccess(responseUser.data));
					let permissions = responseUser.data?.permissions;
					/* Danh sách nhóm */
					let [error, response] = await handleRequest(groupApi.gets({public: 1}));
					let message = apiError(`Load danh sách nhóm thất bại`, error, response);
					if (!message) {
						setGroups(response.data);
					}

					/* Các Thông tin khác */
					utilities.key = localStorage.getItem('utilities-key');
					if (responseUser.data.utilitiesKey != utilities.key) {
						let [error, response] = await handleRequest(utilitiesApi.gets());
						let message = apiError(`Load thông tin thất bại`, error, response);
						if (!message) {
							utilities.roles = response.data.roles;
							utilities.departments = response.data.departments;
							utilities.services = response.data.services;
							utilities.serviceTypes = response.data.serviceTypes;
							utilities.payTypes = response.data.payTypes;
							utilities.devSource = response.data.devSource;
							utilities.reasonStopReNew = response.data.reasonStopReNew;
							localStorage.setItem('utilities-roles', JSON.stringify(utilities.roles));
							localStorage.setItem('utilities-departments', JSON.stringify(utilities.departments));
							localStorage.setItem('utilities-services', JSON.stringify(utilities.services));
							localStorage.setItem('utilities-serviceTypes', JSON.stringify(utilities.serviceTypes));
							localStorage.setItem('utilities-payTypes', JSON.stringify(utilities.payTypes));
							localStorage.setItem('utilities-devSource', JSON.stringify(utilities.devSource));
							localStorage.setItem('utilities-reasonStopReNew', JSON.stringify(utilities.reasonStopReNew));
							localStorage.setItem('utilities-key', responseUser.data.utilitiesKey);
							setUtilities(utilities);

							country.city = response.data.cities;
							country.district = response.data.districts;
							country.ward = response.data.wards;

							localStorage.setItem('cityList', JSON.stringify(country.city));
							localStorage.setItem('districtList', JSON.stringify(country.district));
							localStorage.setItem('wardList', JSON.stringify(country.ward));
							setCountry(country);
						}
					} else {
						utilities.roles = JSON.parse(localStorage.getItem('utilities-roles'));
						utilities.departments = JSON.parse(localStorage.getItem('utilities-departments'));
						utilities.services = JSON.parse(localStorage.getItem('utilities-services'));
						utilities.serviceTypes = JSON.parse(localStorage.getItem('utilities-serviceTypes'));
						utilities.payTypes = JSON.parse(localStorage.getItem('utilities-payTypes'));
						utilities.devSource = JSON.parse(localStorage.getItem('utilities-devSource'));
						utilities.reasonStopReNew = JSON.parse(localStorage.getItem('utilities-reasonStopReNew'));
						setUtilities(utilities);

						country.city = JSON.parse(localStorage.getItem('cityList'));
						country.district = JSON.parse(localStorage.getItem('districtList'));
						country.ward = JSON.parse(localStorage.getItem('wardList'));
						setCountry(country);
					}

					/* Số tin nhắn */
					[error, response] = await handleRequest(notificationApi.messageCheck());
					message = apiError(`Load số message mới thất bại`, error, response);
					if (!message) {
						setNotificationCount((
							prevState) => ({
							...prevState,
							comment: response.data.count,
						}))
					}

					/* Số thông báo */
					[error, response] = await handleRequest(notificationApi.count());
					message = apiError(`Load số thông báo mới thất bại`, error, response);
					if (!message) {
						setNotificationCount((
							prevState) => ({
							...prevState,
							notification: response.data.count,
						}))
					}

					/* Số Tasks */
					if (permissions?.taskView == true) {
						[error, response] = await handleRequest(taskApi.count());
						message = apiError(`Load số tasks mới thất bại`, error, response);
						if (!message) {
							setNotificationCount((
								prevState) => ({
								...prevState,
								tasks: response.data.number,
							}))
						}
					}

					/* Form Xin nghỉ phép */
					if (
						permissions?.formSabbaticalStatusAssign == true ||
						permissions?.formSabbaticalStatusManager == true ||
						permissions?.formSabbaticalStatusCeo == true
					) {
						[error, response] = await handleRequest(formSabbaticalApi.count());
						message = apiError(`Load số đơn xin nghỉ phép chưa duyệt thất bại`, error, response);
						if (!message) {
							notificationCount.form.sabbatical = response.data.count;
						}
					}

					/* Form Xin ứng lương */
					if (
						permissions?.formSalaryStatusAssign == true ||
						permissions?.formSalaryStatusManager == true ||
						permissions?.formSalaryStatusCeo == true
					) {
						[error, response] = await handleRequest(formSalaryApi.count());
						message = apiError(`Load số đơn xin ứng lương chưa duyệt thất bại`, error, response);
						if (!message) {
							notificationCount.form.salary = response.data.count;
						}
					}

					/* Form Phiếu chi */
					if (
						permissions?.formPaymentStatusAssign == true ||
						permissions?.formPaymentStatusManager == true
					) {
						[error, response] = await handleRequest(formPaymentApi.count());
						message = apiError(`Load số phiếu chi chưa duyệt thất bại`, error, response);
						if (!message) {
							notificationCount.form.payment = response.data.count;
						}
					}

					/* Xin giấy tờ */
					if (
						permissions?.papersStatus == true
					) {
						[error, response] = await handleRequest(papersApi.count());
						message = apiError(`Load số đơn xin giấy tờ chưa duyệt thất bại`, error, response);
						if (!message) {
							notificationCount.form.papers = response.data.count;
						}
					}

					setNotificationCount((
						prevState) => ({
						...prevState,
						form: notificationCount.form,
					}))
				}
				setLoading(false);
			})();
		}
		else {
			setLoading(false);
		}
	}, [userLogin]);

	// Handle reponsive
	const viewport = useViewport();
	const [isMobile, setIsMobile] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		if (viewport.width < 768) {
			setIsMobile(true);
			setIsDesktop(false);
			setTableHeight('50vh');
		} else {
			setIsMobile(false);
			setIsDesktop(true);
			setTableHeight('60vh');
		}
	}, [viewport.width]);

	if (loading) return <Loading />

	return (
		<AppContext.Provider value={{
			country,
			setCountry,
			isMobile,
			isDesktop,
			onlineUsers,
			setOnlineUsers,
			socket,
			setSocket,
			notificationCount,
			setNotificationCount,
			utilities,
			loginAsUsers,
			setLoginAsUsers,
			userLogin,
			setUserLogin,
			setGroups,
			groups,
			listAssign,
			setListAssign,
			listDesign,
			setListDesign,
			tableHeight
		}}>
			{children}
		</AppContext.Provider>
	)
}

export { AppContext };
export default AppProvider;