import {Fragment, useState, useEffect, memo} from "react";
import _ from "lodash";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
	Button,
	Icon,
} from "~/components";

import {
	apiError,
	numberFormat,
	handleRequest
} from "~/utils";

import {
	reportApi
} from "~/api";

import {useUtilities} from "~/hooks";
import {
	PointReportSearch,
	PointTable
} from "../../components";

function PointReport() {

	const {utilities} = useUtilities();

	const [items, setItems] = useState({});

	const [loading, setLoading] = useState(false);

	const [filter, setFilter] = useState({
		time: ''
	});

	const [pagination, setPagination] = useState({
		page: 1,
		limit: 50,
		totalRows:0
	});

	const handleEvent = {
		search : async (time) => {
			setLoading(true);
			const filter = {
				time: time,
			};
			let [error, response] = await handleRequest(reportApi.point(filter));
			let message = apiError(`Tìm kiếm thành viên thất bại`, error, response);
			if (!message) {

				console.log(response.data)

				setItems([
					...response.data
				])
				setFilter({
					time: time
				})
				setLoading(false);
			}
		},
	}

	//Load data
	useEffect(() => {
		handleEvent.search({}).then()
	}, []);

	return (
		<Fragment>
			<ActionBar title={'Điểm'}></ActionBar>
			<div className="container">
				<div className="content pd-1">
					{loading && <Loading/>}
					<PointReportSearch filter={filter} onChange={handleEvent.search} />
					<div>
						{!_.isEmpty(items) && <PointTable
							items={items}
							pagination={pagination}
							handleEvent={handleEvent}
						/>}
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default memo(PointReport);