import request from "~/utils/http";

const serviceToDoApi = {
	gets : async (params) => {
		const url = 'services/todos';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = `services/todos`;
		return await request.post(url, params);
	},
	addDomain : async (params) => {
		const url = `services/todos/domain`;
		return await request.post(url, params);
	},
	status : async (params) => {
		const url = `services/todos/status`;
		return await request.post(url, params);
	},
	finished : async (params) => {
		const url = `services/todos/finished`;
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `services/todos/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `services/todos/${id}`;
		return await request.delete(url);
	}
};

export default serviceToDoApi;