import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import { Icon, Button, Loading, Modal } from "~/components";
import {
	revenueErrorSelector,
	revenueFilterSelector,
	revenueItemsSelector,
	revenueLoadingSelector,
	revenuePaginationSelector,
	revenueActions
} from "../revenueSlice";
import {
	RevenueTable,
	RevenueFormAddEdit,
	RevenueFormTransfer,
	RevenueFormInfo,
	RevenueFormPay,
	RevenueSearchBar,
	RevenueSearchMobile
} from "../components";
import {
	apiError,
	strToTime,
	handleRequest
} from "~/utils";
import {
	useCan,
	useDevice
} from "~/hooks";
import {
	revenueApi
} from "~/api";

function Revenue() {

	const {isMobile} = useDevice();

	const can = {
		add: useCan('revenueAdd'),
		edit: useCan('revenueEdit'),
		transfer: useCan('revenueTransfer'),
		delete: useCan('revenueDelete'),
		status: useCan('revenueStatus'),
		plus: useCan('revenuePlus'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(revenueItemsSelector);

	const loading   = useSelector(revenueLoadingSelector);

	const error     = useSelector(revenueErrorSelector);

	const pagination = useSelector(revenuePaginationSelector);

	const filter    = useSelector(revenueFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		transfer    : false,
		info        : false,
		pay         : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(revenueActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		dispatch(revenueActions.fetchData(filter));
	}

	//Submit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin doanh thu để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		data.time = strToTime(data.time);
		let [error, response] = (isEdit) ? await handleRequest(revenueApi.update(data)) : await handleRequest(revenueApi.add(data));
		let message = apiError(`${messageAction} doanh thu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} doanh thu thành công`});
			if(!isEdit) {
				dispatch(revenueActions.add(response.data));
			}
			else {
				setItemEdit(response.data);
				dispatch(revenueActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin doanh thu để xóa'});
			return;
		}
		if (itemEdit.status != 'pending') {
			notification.error({message: 'Lỗi', description: 'Doanh thu đã duyệt không thể xóa'});
			return;
		}
		let [error, response] = await handleRequest(revenueApi.delete(itemEdit.id));
		let message = apiError(`xóa doanh thu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa doanh thu thành công`});
			dispatch(revenueActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//transfer
	const handleTransfer = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin doanh thu để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(revenueApi.transfer(data));

		let message = apiError(`Chuyển nhóm doanh thu không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Chuyển nhóm doanh thu thành công`});
			if(response.data?.groupId) {
				item.groupId = response.data.groupId;
			}
			dispatch(revenueActions.update(item));
			handleModalClose('transfer');
		}
	}

	//Pay
	const handlePay = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin doanh thu để cập nhật'});
			return;
		}

		data.time = strToTime(data.time);

		data.id = item.id;

		let [error, response] = await handleRequest(revenueApi.pay(data));

		let message = apiError(`Tính lương doanh thu không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Tính lương doanh thu thành công`});
			if(response.data?.plus)         item.plus       = response.data.plus;
			if(response.data?.timePlus)     item.timePlus   = response.data.timePlus;
			if(response.data?.percent)      item.percent    = response.data.percent;
			if(response.data?.commission)   item.commission = response.data.commission;
			dispatch(revenueActions.update(item));
			handleModalClose('pay');
		}
	}

	//status
	const handleStatus = async (status) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin doanh thu để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(revenueApi.status({ id: itemEdit.id, status}));
		let message = apiError(`Cập nhật trạng thái doanh thu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái doanh thu thành công`});
			itemEdit.status = status;
			setItemEdit(itemEdit);
			dispatch(revenueActions.update(itemEdit));
		}
	}

	//Search
	const SearchBar = (isMobile) ? RevenueSearchMobile : RevenueSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(revenueActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(revenueActions.setFilterWithDebounce(newFilter));
	};

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(revenueActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Doanh thu'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<SearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />
					{items && <RevenueTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
						filter={filter}
					/>}
				</div>
			</div>
			{
				(can.add || can.edit) &&
				<Modal title="Doanh thu" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
					<RevenueFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} can={can} />
				</Modal>
			}
			{itemEdit?.id &&
				<Modal zIndex={99} title="Thông tin doanh thu" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
					<RevenueFormInfo item={itemEdit} onClickStatus={handleStatus} modalOpen={handleModalOpen}/>
				</Modal>
			}
			{itemEdit?.id &&
				<Modal zIndex={99} title="Tính Lương" visible={openModal.pay} onCancel={() => {handleModalClose('pay')}}>
					<RevenueFormPay item={itemEdit} onHandleSubmit={handlePay}/>
				</Modal>
			}
			{
				(can.transfer && itemEdit?.id) &&
				<Modal title="Chuyển nhân viên" visible={openModal.transfer} onCancel={() => {handleModalClose('transfer')}}>
					<RevenueFormTransfer item={itemEdit} onHandleSubmit={handleTransfer}/>
				</Modal>
			}
			{
				(can.delete && itemEdit?.id) &&
				<Modal title="Xóa doanh thu" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
					<p>Bạn muốn xóa doanh thu <b>{itemEdit?.name}</b>?</p>
					<div className="d-flex justify-content-end gap modal-bottom pd-1">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
					</div>
				</Modal>
			}
		</Fragment>
	)
}

export default Revenue;