import {useEffect, useMemo, useContext} from "react";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button, Loading
} from "~/components";
import {DateField, InputField, SelectField, InputPriceField} from "~/components/Forms";
import {AppContext} from "~/context/AppProvider";
import {v4 as uuidv4} from 'uuid';

function PersonnelFormPay({item, onHandleSubmit}) {

	const {utilities} = useContext(AppContext);

	const listCommissionType = useMemo(() => {
		return [...Object.entries(utilities.payTypes).map(([key, label]) => {
			return {
				value: key,
				label: label
			};
		})];
	}, [utilities.payTypes]);

	const initialValues = {
		pay: undefined,
		payTime: undefined,
		payType: 'nowage',
		payTypeTime: undefined,
		managerMilestone: []
	}

	const validationSchema = Yup.object().shape({
		managerMilestone: Yup.array().of(
			Yup.object().shape({
				from: Yup.number().required('Khoản từ không bỏ trống'),
				to  : Yup.number().required('Khoản đến không bỏ trống'),
				pay : Yup.number().required('Lương không bỏ trống')
			})
		),
	})

	const {control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	const {fields, append, remove} = useFieldArray({
		control,
		name: "managerMilestone"
	});

	const manager = (item?.role == 'salesManager' || item?.role == 'salesSenior' || item?.role == 'salesSupper');

	useEffect(() => {
		if(item?.id) {
			initialValues.pay = item.pay
			initialValues.payType = item.payType
			const payManagerMilestone = JSON.parse(JSON.stringify(item.payManagerMilestone));
			payManagerMilestone.reduce((map, payMilestone) => {
				payMilestone.id = uuidv4();
				map.push(payMilestone);
				return map;
			}, []);
			initialValues.managerMilestone = payManagerMilestone;
		}
		reset(initialValues);
	}, [item]);
	
	const handleAddManagerMilestone = () => {
		append({ id: uuidv4(), from: 0, to: 0, pay: 0});
	}

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Lương cứng</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="pay" render={({ field }) => (
						<InputPriceField label="Lương" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="payTime" render={({ field }) => (
						<DateField label="Thời gian áp dụng" picker="month" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<p className="heading">Lương kinh doanh</p>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="payType" render={({ field }) => (
						<SelectField label="Loại hoa hồng" options={listCommissionType} errors={errors} {...field} />
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="payTypeTime" render={({ field }) => (
						<DateField label="Thời gian áp dụng" picker="month" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			{
				manager && (
					<>
						<p className="heading">Lương quản lý</p>
						<Row gutter={10}>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Từ</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Đến</label>
							</Col>
							<Col className="gutter-row" span={7}>
								<label htmlFor="">Lương</label>
							</Col>
							<Col className="gutter-row" span={3}></Col>
						</Row>
						{fields.map(({ id, from, to, pay }, index) => {
							return (
								<Row gutter={10} key={id}>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`managerMilestone[${index}].from`} render={({ field }) => (
											<InputField defaultValue={from} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`managerMilestone[${index}].to`} render={({ field }) => (
											<InputField defaultValue={to} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={7}>
										<Controller control={control} name={`managerMilestone[${index}].pay`} render={({ field }) => (
											<InputField defaultValue={pay} errors={errors} {...field}/>
										)}/>
									</Col>
									<Col className="gutter-row" span={3}>
										<Button primary type="button" onClick={() => remove(index)}>Xóa</Button>
									</Col>
								</Row>
							);
						})}
						<div className="d-flex justify-content-end">
							<Button blue background type="button" onClick={handleAddManagerMilestone}>Thêm khoản lương</Button>
						</div>
					</>
				)
			}

			<hr />
			<div className="d-flex justify-content-end">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default PersonnelFormPay;