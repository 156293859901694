import {memo} from "react";
import {Pagination, Table} from "antd";
import {Image, PopperUserInfo} from "~/components";
import {ceil} from "lodash";

function SaleAnalyticTable({items = [], pagination, onPaginationChange}) {

	const percentEvent = (call, event) => {
		if(event == 0) return 0;
		let percent = ceil(call / event);
		return (
			<>
				<b>{percent}</b> cuộc gọi / hẹn
			</>
		)
	}

	const percentEventCancel = (event, eventCancel) => {
		if(eventCancel == 0) return 0;
		let percent = ceil(eventCancel / event *100);
		return (
			<b className={((percent >= 50 && eventCancel > 10) ? 'color-red' : 'color')}>{percent}%</b>
		)
	}

	const percentContract = (eventSuccess, contract) => {
		if(contract == 0) return 0;
		let percent = ceil(eventSuccess / contract );
		return (
			<>
				<b>{percent}</b> tư vấn  / hợp đồng
			</>
		)
	}

	const kpiCalculator = (item) => {

		if(item.contract == 0) return (<>Không tính được</>)
		if(item.event == 0) return (<>Không tính được</>)

		//KPI 4 HD
		let eventSuccessKPI = ceil(item.eventSuccess / item.contract ) * 4;

		let percentSuccess = 100 - ceil(item.eventCancel / item.event *100);

		let callKPI = ceil(((eventSuccessKPI/percentSuccess)*100)*ceil(item.call / item.event));

		return (
			<>
				Dự kiến bạn cần <b>{callKPI}</b>
				<p>cuộc gọi trong 30 ngày</p>
			</>
		)
	}

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", render: (_, item) => (
			<>
				<PopperUserInfo user={item}>
					<div className="d-flex gap align-items">
						<div className="avatar"><Image src={item?.avatar} /></div>
						<div>
							<b>{`${item?.firstname} ${item?.lastname}`}</b>
							<p>{item?.username}</p>
						</div>
					</div>
				</PopperUserInfo>
			</>
		)},
		{ title: 'Cuộc gọi', dataIndex: 'call', key: "call", width: '90px', render: (_, item) => (
			<>
				{item.call}
			</>
		)},
		{ title: 'Hẹn', dataIndex: 'event', key: "event", width: '80px', render: (_, item) => (
			<>
				{item.event}
			</>
		)},
		{ title: 'Tỷ lệ hẹn', dataIndex: 'percent', key: "status", render: (_, item) => (
			<>
				{percentEvent(item.call,item.event)}
			</>
		)},
		{ title: 'Tư vấn', dataIndex: 'eventSuccess', key: "eventSuccess", width: '90px', render: (_, item) => (
			<>
				{item.eventSuccess}
			</>
		)},
		{ title: 'Tỷ lệ hủy', dataIndex: 'percentCancel', key: "percentCancel", width: '100px', render: (_, item) => (
			<>
				{percentEventCancel(item.event, item.eventCancel)}
			</>
		)},
		{ title: 'Hợp đồng', dataIndex: 'contract', key: "contract", width: '100px', render: (_, item) => (
			<>
				{item.contract}
			</>
		)},

		{ title: 'Tỷ lệ ký', dataIndex: 'percentContract', key: "percentContract", width: '180px', render: (_, item) => (
			<>
				{percentContract(item.eventSuccess, item.contract)}
			</>
		)},

		{ title: 'KPI 4 HD', dataIndex: 'kpi', key: "kpi", width: '200px', render: (_, item) => (
			<>
				{kpiCalculator(item)}
			</>
		)},
	];
	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:550, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(SaleAnalyticTable);