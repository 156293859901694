import {Fragment, useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Col, Row} from "antd";
import {
	Button,
	Comments,
	Loading,
	StatusLabel
} from "~/components";
import {
	GroupRadioField,
} from "~/components/Forms";
import {LIST_FORM_STATUS} from "~/app/constants";
import {
	renderDate,
	numberFormat
} from "~/utils";
import {
	useCan
} from "~/hooks";

function FormSalaryInfo({item, onHandleSubmit}) {

	const can = {
	    statusAssign: useCan('formSalaryStatusAssign'),
	    statusManager: useCan('formSalaryStatusManager'),
	}

	const statusOptions = [];

	for (const [key, value] of Object.entries(LIST_FORM_STATUS)) {
		statusOptions.push({value : key, label : <StatusLabel small type={value.color}>{value.label}</StatusLabel>})
	}

	let initialValues = {}

	if(can.statusManager) initialValues = {...initialValues, statusManager : '', noteManager : ''}
	if(can.statusAssign) initialValues = {...initialValues, statusAssign : '', noteAssign : ''}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues
	});

	useEffect(() => {
		if(item?.id) {
			if(can.statusManager) initialValues.statusManager  = item.statusManager;
			if(can.statusAssign) initialValues.statusAssign   = item.statusAssign;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<Row className="mb-2" gutter={10}>
			<Col className="gutter-row" xs={24} md={12}>
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{isSubmitting && <Loading/>}
					<p className="heading">Thông tin ứng lương</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Người làm đơn:</Col>
						<Col className="gutter-row" span={18}>{`${item.user.firstname} ${item.user.lastname}`}</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Ngày ứng:</Col>
						<Col className="gutter-row" span={18}>{`${renderDate(item.time)}`}</Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Số tiền:</Col>
						<Col className="gutter-row" span={18}><b>{`${numberFormat(item.price)}`}</b></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Lý do:</Col>
						<Col className="gutter-row" span={18}>{item.note}</Col>
					</Row>
					<p className="heading">Các cấp</p>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Quản lý:</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusManager].color}>{LIST_FORM_STATUS[item.statusManager].label}</StatusLabel></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Kế toán:</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusAssign].color}>{LIST_FORM_STATUS[item.statusAssign].label}</StatusLabel></Col>
					</Row>
					<Row className="mb-2" gutter={10}>
						<Col className="gutter-row" span={6}>Giám đốc:</Col>
						<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusCeo].color}>{LIST_FORM_STATUS[item.statusCeo].label}</StatusLabel></Col>
					</Row>
					{
						can.statusAssign &&
						<Fragment>
							<p className="heading">Duyệt thông tin (Kế toán)</p>
							<Controller control={control} name="statusAssign" render={({field}) => (
								<GroupRadioField options={statusOptions} errors={errors} {...field}/>
							)}/>
						</Fragment>
					}
					{
						can.statusManager &&
						<Fragment>
							<p className="heading">Duyệt thông tin (Quản lý)</p>
							<Controller control={control} name="statusManager" render={({field}) => (
								<GroupRadioField options={statusOptions} errors={errors} {...field}/>
							)}/>
						</Fragment>
					}
					{
						(can.statusManager || can.statusAssign) &&
						<div className="form-group d-flex justify-content-end modal-bottom">
							<hr />
							<Button primary type="submit">Lưu</Button>
						</div>
					}

				</form>
			</Col>
			<Col className="gutter-row" xs={24} md={12}><Comments type="form-salary" objectId={item.id} /></Col>
		</Row>
	)
}

export default FormSalaryInfo;