import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {papersNumberActions} from "./papersNumberSlice";
import {papersApi} from "~/api";

function* fetchPapersNumberList(action) {
	try {
		const response = yield call(papersApi.getsNumber, action.payload);
		yield put(papersNumberActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch papersNumber list', error);
		yield put(papersNumberActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(papersNumberActions.setFilter(action.payload));
}

export default function* papersNumberSaga() {
	yield takeLatest(papersNumberActions.fetchData.type, fetchPapersNumberList);
	yield debounce(500, papersNumberActions.setFilterWithDebounce.type, handleSearchDebounce)
}