import {memo, useContext} from "react";
import {
	Pagination,
	Table
} from "antd";
import {
	Image,
	StatusLabel
} from "~/components";
import {
	numberFormat
} from "~/utils";
import {useUtilities} from "~/hooks";
import {AppContext} from "~/context/AppProvider";
import {USER_STATUS} from "../../../../app/constants";

function PointTable({items = [], pagination, handleEvent}) {

	const {tableHeight} = useContext(AppContext);

	const {utilities} = useUtilities();

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '300px', render: (_, item) => (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item?.avatar} /></div>
				<div>
					<b className={(item.role == 'salesManager') ? 'color-theme' : undefined }>{item?.firstname + ' ' + item?.lastname}</b>
					<p>{item?.username}</p>
					<p>{<StatusLabel small type={utilities.roles[item.role]?.color}>{utilities.roles[item.role]?.title}</StatusLabel>}</p>
				</div>
			</div>
		)},
		{ title: 'Loại', dataIndex: 'status', key: "status", width: '120px', render: (_, item) => <StatusLabel small type={USER_STATUS[item.status]?.color}>{USER_STATUS[item.status]?.title}</StatusLabel>},
		{ title: 'Tháng 1', dataIndex: 'month1', key: "month1", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month1))},
		{ title: 'Tháng 2', dataIndex: 'month2', key: "month2", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month2))},
		{ title: 'Tháng 3', dataIndex: 'month3', key: "month3", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month3))},
		{ title: 'Tháng 4', dataIndex: 'month4', key: "month4", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month4))},
		{ title: 'Tháng 5', dataIndex: 'month5', key: "month5", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month5))},
		{ title: 'Tháng 6', dataIndex: 'month6', key: "month6", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month6))},
		{ title: 'Tháng 7', dataIndex: 'month7', key: "month7", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month7))},
		{ title: 'Tháng 8', dataIndex: 'month8', key: "month8", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month8))},
		{ title: 'Tháng 9', dataIndex: 'month9', key: "month9", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month9))},
		{ title: 'Tháng 10', dataIndex: 'month10', key: "month10", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month10))},
		{ title: 'Tháng 11', dataIndex: 'month11', key: "month11", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month11))},
		{ title: 'Tháng 12', dataIndex: 'month12', key: "month12", width: '100px', render: (_, item) => (numberFormat(item?.coins?.month12))},
		{ title: 'Tổng iểm', dataIndex: 'coin', key: "coin", width: '150px', render: (_, item) => (
			<>{numberFormat(item?.coinTotal)}</>
		)},
	];

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
		</div>
	)
}

export default memo(PointTable);