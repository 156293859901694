import {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	SelectUserAll,
	DateField
} from "~/components/Forms";
import {
	useCan
} from "~/hooks";
import {
	Button,
	Icon,
	Notification
} from "~/components";
import {strToTime} from "~/utils";

function CommitSearchMobile({listGroup, filter, onChange}) {

	const can = {
		viewAll : useCan('salesCommitListAll'),
		viewGroup : useCan('salesCommitListGroup'),
		viewLeader : useCan('salesCommitListLeader'),
	}

	const [searchBar, setSearchBar] = useState(false);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất các nhóm' }, ...Object.entries(listGroup).map(([key, group]) => {
			return {
				value: group.id,
				label: group.name
			};
		})];
	}, [listGroup]);

	const typeOptions = [
		{value : '', label : 'Tất cả'},
		{value : '3-day', label : 'Cam kết 3 ngày'},
		{value : 'mouth', label : 'Cam kết tháng'},
	]

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleDateChange = (value) => {
		if (!onChange) return;

		value = strToTime(value);

		const newFilter = {
			...filter,
			time: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	const onSubmit = (data) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			time: strToTime(data.time),
			group: data.group,
			userId: data.userId,
			type: data.type,
			page: 1,
		};
		setSearchBar(false);
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<DateField placeholder="Chọn ngày cam kết" onChange={(value, event) => handleDateChange(value)} />
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="time" render={({ field }) => (
						<DateField label="Chọn ngày cam kết" errors={errors} {...field}/>
					)}/>
					{(can.viewAll || can.viewGroup) &&
						<Controller control={control} name="group" render={({field}) => (
							<SelectField label="Chọn nhóm" options={groupOptions} errors={errors} {...field} />
						)}/>
					}
					{(can.viewAll || can.viewGroup || can.viewLeader) &&
						<Controller control={control} name="userId" render={({field}) => (
							<SelectUserAll label="Nhân viên" options={[{value: '', label: 'Tất cả'}]} errors={errors} {...field}/>
						)}/>
					}
					<Controller control={control} name="type" render={({ field }) => (
						<SelectField label="Loại cam kết" options={typeOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default CommitSearchMobile;