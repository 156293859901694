import style from '../../Home.module.scss';
import * as Yup from "yup";
import {useState, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Button} from "~/components";
import {InputField, SelectField} from "~/components/Forms";
import className from 'classnames/bind';
import {notification} from "antd";
import {apiError, handleRequest} from "../../../../utils";
import {uploadWebsiteApi} from "../../../../api";
import {useCan, useUtilities} from "../../../../hooks";
const cn = className.bind(style);

function TechnicianDownload() {

	const {utilities} = useUtilities();

	const sourceTypeOption = useMemo(() => {
		return [...Object.entries(utilities.devSource).map(([key, name]) => {
			return {
				value: key,
				label: name
			};
		})];
	}, [utilities.devSource]);

	const [sourceData, setSourceData] = useState({
		source: '', database: ''
	});

	const { control: controlSource, handleSubmit: handleSubmitSource, formState: { isSubmitting: isSubmittingSource, errors: errorsSource }} = useForm({
		defaultValues: {
			sourceDev: '',
			sourcePath: 'sikidodemo.com',
			sourceFolder: '',
		},
		resolver: yupResolver(Yup.object().shape({
			sourcePath: Yup.string().required('Bạn chưa chọn đường dẫn source code'),
			sourceFolder: Yup.string().required('Bạn chưa điền folder source code'),
		}))
	});

	const handleSource = async (data) => {
		let [error, response] = await handleRequest(uploadWebsiteApi.source(data));
		let message = apiError(`Nén source cho yêu cầu thất bại`, error, response);
		if (!message) {
			notification.success({ message: 'Thành công', description: `Nén source cho yêu cầu thành công`});
			sourceData.source = response.data.urlSource;
			sourceData.database = response.data.urlDatabase;
			setSourceData(sourceData)
		}
	}

	return (
		<div className={cn('card', 'card-while', 'd-block')}>
			<p className="heading">Nén Source Code</p>
			<form className="form" onSubmit={handleSubmitSource(handleSource)}>
				{
					useCan('uploadWebsiteSourceAll') && <Controller control={controlSource} name="sourceDev" render={({ field }) => (
						<InputField label="Kỹ thuật" errors={errorsSource} {...field}/>
					)}/>
				}
				<Controller control={controlSource} name="sourcePath" render={({ field }) => (
					<SelectField label="Đường dẫn" options={sourceTypeOption} errors={errorsSource} {...field}/>
				)}/>
				<Controller control={controlSource} name="sourceFolder" render={({field}) => (
					<InputField label="Tên thư mục demo" errors={errorsSource} {...field}/>
				)}/>
				<div className="form-group d-flex justify-content-end">
					<Button loading={isSubmittingSource} background red>Nén source</Button>
					{
						(sourceData.source) && <Button background blue href={sourceData.source} download>Tải source đã nén</Button>
					}
					{
						(sourceData.database) && <Button background red href={sourceData.database} download>Tải database đã nén</Button>
					}
				</div>
			</form>
		</div>
	)
}

export default TechnicianDownload;