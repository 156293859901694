import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {formPaymentActions} from "./formPaymentSlice";
import {formPaymentApi} from "~/api";

function* fetchFormPaymentList(action) {
	try {
		const response = yield call(formPaymentApi.gets, action.payload);
		yield put(formPaymentActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch formPayment list', error);
		yield put(formPaymentActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(formPaymentActions.setFilter(action.payload));
}

export default function* formPaymentSaga() {
	yield takeLatest(formPaymentActions.fetchData.type, fetchFormPaymentList);
	yield debounce(500, formPaymentActions.setFilterWithDebounce.type, handleSearchDebounce)
}