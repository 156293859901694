import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {personnelActions} from "./personnelSlice";
import {userApi} from "~/api";

function* fetchPersonnelList(action) {
	try {
		const response = yield call(userApi.gets, action.payload);
		yield put(personnelActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed  to fetch candidate list', error);
		yield put(personnelActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(personnelActions.setFilter(action.payload));
}

export default function* personnelSaga() {
	yield takeLatest(personnelActions.fetchData.type, fetchPersonnelList);
	yield debounce(500, personnelActions.setFilterWithDebounce.type, handleSearchDebounce)
}