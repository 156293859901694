import {useCallback, memo} from "react";
import {
	DateField,
} from "~/components/Forms";
import {strToTime} from "~/utils";

function SaleKpiSearch({filter, setFilter}) {

	const handleTimeChange = useCallback((value) => {
		setFilter({...filter, ...{time: strToTime(value)}})
	}, []);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<DateField name="time" picker="month" onChange={handleTimeChange}/>
			</form>
		</div>
	)
}

export default memo(SaleKpiSearch);