import style from './Modal.module.scss';
import className from 'classnames/bind';
import FontAwesomeIcon from "~/components/FontAwesome";
import Button from "~/components/Button";
import {memo} from "react";
const cn = className.bind(style);

function Modal({
   children,
   size="",
   title="This is a modal",
   description,
   isHeader = true,
   visible= false,
   okButton = true,
   onCancel,
   zIndex = 100
}) {
	let isMobile = false;
	let style = {zIndex: zIndex}
	if(size != "") size = "modal-"+size;
	return (
		<div className={cn("modal-wrapper", {
			"modal-main": true,
			"modal-show": visible,
			"modal-hide": !visible,
			[size] : size
		})} style={style}>
			<div onClick={onCancel} className={cn("overlay")}></div>
			<div className={cn("modal", { isMobile: isMobile })}>
				{isHeader && (
					<>
						<div className={cn("header-box")}>
							<div className={cn("header")}>
								<h4 className={cn("title", 'text-lg')}>{title}</h4>
								{description && <p>{description}</p>}
							</div>
							<Button type="button" small outline className={cn("close")} onClick={onCancel}><FontAwesomeIcon icon="fa-thin fa-xmark" /></Button>
							<hr className="mb-0" />
						</div>
					</>
				)}
				<div className={cn("content")}>{children}</div>

				{okButton && (
					<div className={cn("controls")}>
						<div className={cn("ok-btn-wrap")}>{okButton}</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(Modal);