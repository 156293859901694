import Image from "~/components/Image";
import {Pagination, Table} from "antd";

import StatusLabel from "../../../../components/StatusLabel";

function SaleWorkingTable({items = [], pagination, onPaginationChange}) {

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			item?.id && (
			<div className="d-flex gap align-items">
				<div className="avatar"><Image src={item?.avatar}/></div>
				<div>
					<b>{item?.firstname + ' ' + item?.lastname}</b>
				</div>
			</div>
		))},
		{ title: 'Hoạt động', dataIndex: 'action', key: "action", width: '120px', render: (_, item) => (
			<div>
				{item?.working ? <StatusLabel small type="green">Đang tư vấn</StatusLabel> : <StatusLabel small type="yellow">Không tư vấn</StatusLabel>}
				{item?.dicung?.firstname && <p>Đi cùng với <b>{item.dicung.firstname + ' ' + item.dicung.lastname}</b></p>}
			</div>
		)},
		{ title: 'Loại tư vấn', dataIndex: 'event', key: "event", width: '250px', render: (_, item) => (
			item?.working &&
			<p className="gap-1 d-flex">
				{item.event?.type == 'new' ? <StatusLabel small type="green">Khách mới</StatusLabel> : <StatusLabel small type="red">Khách củ</StatusLabel>}
				{item.event?.type2 == 'tuvan' ? <StatusLabel small type="green">Tư vấn</StatusLabel> : <StatusLabel small type="red">Bàn giao</StatusLabel>}
				{item.event?.type3 == 'online' ? <StatusLabel small type="yellow">Online</StatusLabel> : <StatusLabel small type="blue">Trực tiếp</StatusLabel>}
			</p>
		)},
		{ title: 'Địa điểm', dataIndex: 'address', key: "address", width: '220px', render: (_, item) => (
			item.event?.address && <p>{item.event?.address}</p>)
		},
		{ title: 'Thời gian', dataIndex: 'hours', key: "hours", width: '220px', render: (_, item) => (
			item.event?.hours && <p>{item.event?.hours}</p>)
		},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550, x : "max-content" }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default SaleWorkingTable;