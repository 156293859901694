import request from "~/utils/http";

const projectApi = {
	gets : async (params) => {
		const url = 'projects';
		return await request.get(url, {params});
	},
	history : async (id) => {
		const url = `projects/history/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'projects';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `projects/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `projects/${id}`;
		return await request.delete(url);
	},
	status : async (params) => {
		const url = `projects/status/${params.id}`;
		return await request.post(url, params);
	},
	phase : async (params) => {
		const url = `projects/phase/${params.id}`;
		return await request.post(url, params);
	},
	install : async (params) => {
		const url = `projects/install/${params.id}`;
		return await request.post(url, params);
	},
	assign : async (params) => {
		const url = `projects/assign/${params.id}`;
		return await request.post(url, params);
	},
	fileUploadDev : async (params) => {
		const url = `projects/upload/dev`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	fileRemoveDev : async (params) => {
		const url = `projects/upload/dev`;
		return await request.patch(url, params);
	},
	fileUploadDesign : async (params) => {
		const url = `projects/upload/design`;
		return await request.post(url, params, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	},
	fileRemoveDesign : async (params) => {
		const url = `projects/upload/design`;
		return await request.patch(url, params);
	},
	getsWithTask : async (params) => {
		const url = `projects/tasks`;
		return await request.get(url, {params});
	},
};

export default projectApi;