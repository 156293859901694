import {Button, Icon} from "~/components";
import {
	useCan
} from "~/hooks";
import {Tooltip} from "antd";

function UploadWebsiteAction({item, setItemEdit, openModal}) {

	const can = {
		edit: useCan('uploadWebsiteEdit'),
		upload: useCan('uploadWebsiteDevStatus'),
		delete: useCan('uploadWebsiteDelete'),
	};

	const buttonHandlers = {
		info: () => {
			setItemEdit(item);
			openModal('info');
		},
		edit: () => {
			setItemEdit(item);
			openModal('addEdit');
		},
		upload: () => {
			setItemEdit(item);
			openModal('upload');
		},
		addHost: () => {
			setItemEdit(item);
			openModal('addHost');
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
		},
	};

	const actionButtons = [
		can.edit && <Button key="edit" background blue leftIcon={Icon.edit} onClick={buttonHandlers.edit}></Button>,
		(can.edit && item.type == 2 && item.hostId == 0) && <Button key="Bổ sung host" background white leftIcon={Icon.plus} onClick={buttonHandlers.addHost}></Button>,
		can.upload && <Tooltip key="upload" title="Upload website"><Button background green leftIcon={Icon.server} onClick={buttonHandlers.upload}></Button></Tooltip>,
		can.delete && <Button key="delete" background red leftIcon={Icon.delete} onClick={buttonHandlers.delete}></Button>,
	];

	return (
		<div className="table-action d-flex">
			<Tooltip title="Thông tin" key="info"><Button background blue leftIcon={Icon.info} onClick={buttonHandlers.info}></Button></Tooltip>
			{actionButtons}
		</div>
	)
}

export default UploadWebsiteAction;