import className from 'classnames/bind';
import style from './PermissionForm.module.scss';
import {Fragment, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {Col, Row} from "antd";
import {
	Button,
	Loading,
	StatusLabel
} from "~/components";
import {
	GroupRadioField,
	CheckBoxField
} from "~/components/Forms";
import _ from "lodash"
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	authApi
} from "~/api";

const cn = className.bind(style);

function PermissionForm({listRole, setListRole, onSubmit}) {

	const [roleLoading, setRoleLoading] = useState(false);

	let roleOptions = [];

	if(!_.isEmpty(listRole.roles)) {
		for (const [value, label] of Object.entries(listRole.roles)) {
			roleOptions.push({value, label})
		}
	}

	let initialValues = {
		role: listRole.roleCurrent,
		... listRole.permissionCurrent
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues
	});

	const handleRoleChange = (value) => {
		(async () => {
			setRoleLoading(true);
			let [error, response] = await handleRequest(authApi.getPermission({role : value}));
			let message = apiError(`Load danh sách quyền`, error, response);
			if (!message) {
				initialValues = {
					role: response.data.roleCurrent,
					... response.data.permissionCurrent
				}
				reset(initialValues);
				setListRole(response.data);
			}
			setRoleLoading(false);
		})()
	}

	const renderRoleName = (name) => {
		let nameNew = {
			status: '',
			label: name
		}
		if(name.search("View") != -1) {
			nameNew.status = <StatusLabel small bold type="black">View</StatusLabel>;
			nameNew.label = name.replace("View", "");
		}
		if(name.search("Add") != -1) {
			nameNew.status = <StatusLabel small bold type="green">Add</StatusLabel>;
			nameNew.label = name.replace("Add", "");
		}
		if(name.search("Edit") != -1) {
			nameNew.status = <StatusLabel small bold type="blue">Edit</StatusLabel>;
			nameNew.label = name.replace("Edit", "");
		}
		if(name.search("Delete") != -1) {
			nameNew.status = <StatusLabel small bold type="red">Delete</StatusLabel>;
			nameNew.label = name.replace("Delete", "");
		}

		return (
			<Fragment>{nameNew.status} {nameNew.label}</Fragment>
		)
	}

	const permissionList = (listRole, control, errors) => {
		return (
			<>
				{
					listRole?.permission && Object.keys(listRole.permission).map((permissionKey) => {
						let permissionGroup = listRole.permission[permissionKey];
						return (
							<Col className="gutter-row" span={24} key={permissionKey}>
								<div className="content pd-1">
									<p className="heading">{permissionGroup.label} {roleLoading && <Loading noFixed bar/>}</p>
									<Row gutter={10}>
										{
											Object.keys(permissionGroup.permission).map((permissionItemKey) => {
												return <Col className="gutter-row" span={12} key={permissionItemKey}><Controller control={control} name={`${permissionItemKey}`} render={({ field }) => (
													<CheckBoxField errors={errors} {...field}>{renderRoleName(permissionGroup.permission[permissionItemKey])}</CheckBoxField>
												)}/></Col>
											})
										}
									</Row>
								</div>
							</Col>
						)
					})
				}
			</>
		)
	}

	return (
		<form id="permissionForm" className={cn('form')+' form'} onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Row gutter={10}>
				<Col className="gutter-row" span={6}>
					<div className="content pd-1">
						<p className="heading">Chức vụ</p>
						<Controller control={control} name="role" render={({ field }) => (
							<GroupRadioField options={roleOptions} errors={errors} {...field} onChange={(event) => {
								handleRoleChange(event.target.value);
								field.onChange(event);
							}}/>
						)}/>
					</div>
				</Col>
				<Col className="gutter-row" span={16} style={{ overflow:'auto', maxHeight: '80vh'}}>
					<Row gutter={10}>
						{permissionList(listRole, control, errors)}
					</Row>
				</Col>
			</Row>
		</form>
	)
}

export default PermissionForm;