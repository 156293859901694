import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	InputField,
	EditorField,
	FileUpload,
	FileList
} from "~/components/Forms";
import _ from "lodash";

function DocumentFormEdit({item, group, onHandleSubmit, onUpload, onRemoveFile}) {

	let [files, setFiles] = useState([]);

	const initialValues = {
		name: undefined,
		content : undefined
	}

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Tiêu đề quy định không được để trống'),
		content: Yup.string().required('Chi tiết quy định không được để trống'),
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(item?.id) {
			initialValues.name = item.name;
			initialValues.content = item.content;

			files = [];
			if(!_.isEmpty(item.attachFile)) {
				for (const [path, name] of Object.entries(item.attachFile)) {
					files.push({path, name})
				}
			}
			setFiles(files);
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item, group);
	}

	const handleRemoveFileClick = (filename) => {
		setFiles(files.filter(file => file.name !== filename))
		onRemoveFile(filename);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin quy định</p>
			<Controller control={control} name="name" render={({field}) => (
				<InputField label="Tiêu đề" rows={5} errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="content" render={({field}) => (
				<EditorField label="Nội dung" errors={errors} {...field}/>
			)}/>
			<p className="heading mt-1">Đính kèm</p>
			<FileUpload name="file" files={files} setFiles={setFiles} onChange={onUpload}/>
			<FileList files={files} removeFile={handleRemoveFileClick}/>
			<hr/>
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}

export default DocumentFormEdit;