import { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import StatusLabel from "~/components/StatusLabel";
import { InputField, SelectField, DateField, SelectUserAll } from "~/components/Forms";
import { Col, Row, Select } from "antd";
import {LIST_SOURCE} from "~/app/constants";
import {useUtilities} from "../../../../hooks";

function CandidateFormAddEdit({item, onHandleSubmit}) {

	const {utilities} = useUtilities();

	const isEdit = Boolean(item?.id);

	const positionOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.roles).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.title}</StatusLabel>
			};
		})];
	}, [utilities.roles]);

	const userInterviewOption = (isEdit && typeof item.userInterviewData?.firstname != "undefined") ? [{ label : `${item.userInterviewData.firstname} ${item.userInterviewData.lastname}`, value : item.userInterviewData.id }] : [];

	const initialValues = {
		userInterview: '',
		appointment: Date.now(),
		fullname: '',
		email: '',
		phone: '',
		birthday : Date.now(),
		apply : utilities.roles[Object.keys(utilities.roles)[0]].key,
		source : LIST_SOURCE[0].key,
		...item
	}

	const validationSchema = Yup.object().shape({
		userInterview: Yup.string().required('Bạn chưa chọn người phỏng vấn'),
		appointment: Yup.string().required('Ngày phỏng vấn không được để trống'),
		fullname: Yup.string().required('Họ tên không được để trống'),
		email: Yup.string().email('Email không đúng định dạng').required('Email không được để trống'),
		phone: Yup.string().required('Số điện thoại không được để trống'),
		birthday: Yup.string().required('Ngày sinh không được để trống')
	})

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if(typeof item.userInterviewData?.firstname == "undefined") {
			initialValues.userInterview = '';
		}
		if(item?.appointment) {
			initialValues.appointment = item?.appointment*1000;
		}
		if(item?.birthday) {
			initialValues.birthday = item?.birthday*1000;
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, isEdit);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Phỏng vấn</p>

			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="userInterview" render={({ field }) => (
						<SelectUserAll label="Người phỏng vấn" options={userInterviewOption} errors={errors} {...field} />
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="appointment" render={({ field }) => (
						<DateField label="Ngày phỏng vấn" placeholder="Ngày phỏng vấn" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<p className="heading">Thông tin hồ sơ</p>
			<Controller control={control} name="fullname" render={({ field }) => (
				<InputField placeholder="Họ tên ứng viên" errors={errors} {...field}/>
			)}/>
			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="email" render={({ field }) => (
						<InputField type="email" placeholder="Email ứng viên" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="phone" render={({ field }) => (
						<InputField type="tel" placeholder="Số điện thoại ứng viên" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>

			<Controller control={control} name="birthday"
	            render={({ field }) => (
		            <DateField placeholder="Ngày sinh ứng viên" errors={errors} {...field}/>
	            )}
			/>
			<p className="heading">Chuyên môn</p>

			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="apply" render={({ field }) => (
						<SelectField errors={errors} options={positionOptions} {...field} />
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="source" render={({ field }) => (
						<SelectField errors={errors} {...field}>
							{LIST_SOURCE.map((item, index) =>
								<Select.Option key={item.key} value={item.key} label={item.title}>
									<StatusLabel type={item.color} small>{item.title}</StatusLabel>
								</Select.Option>
							)}
						</SelectField>
					)}/>
				</Col>
			</Row>

			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">{isEdit ? 'Cập nhật' : 'Thêm ứng viên'}</Button>
			</div>
		</form>
	)
}

export default CandidateFormAddEdit;