import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
} from "~/components";
import {
	apiError,
	handleRequest
} from "~/utils";
import {
	extendApi
} from "~/api";
import {
	extendActions,
	extendErrorSelector,
	extendFilterSelector,
	extendItemsSelector,
	extendLoadingSelector,
	extendPaginationSelector
} from "../extendSlice";
import {
	ExtendTable,
	ExtendSearchBar,
	ExtendFormEdit
} from "../components";

function Extend() {

	const dispatch  = useDispatch();

	const items     = useSelector(extendItemsSelector);

	const loading   = useSelector(extendLoadingSelector);

	const error     = useSelector(extendErrorSelector);

	const pagination = useSelector(extendPaginationSelector);

	const filter    = useSelector(extendFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		edit : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(extendActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}
	const handleSave = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để cập nhật'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(extendApi.updateService(data));
		let message = apiError(`Cập nhật thông tin thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.services = response.data.services;
			itemNew.total = response.data.total;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleEmailStatus = async (item, status) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(extendApi.statusEmail({'status' : status, id : item.id}));
		let message = apiError(`Cập nhật thông tin thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.emailStatus = status;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleEmailSave = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để cập nhật'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(extendApi.saveEmail(data));
		let message = apiError(`Cập nhật thông tin thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.emailContent= response.data.content;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleZaloStatus = async (item, status) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(extendApi.statusZalo({'status' : status, id : item.id}));
		let message = apiError(`Cập nhật thông tin thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thông tin thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.zaloStatus = status;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleEmailSend = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để gửi'});
			return;
		}
		let [error, response] = await handleRequest(extendApi.sendEmail({id : item.id}));
		let message = apiError(`Gửi email gia hạn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Gửi email gia hạn thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.emailSend = response.data.emailSend;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleEmailSendTest = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để gửi'});
			return;
		}
		let [error, response] = await handleRequest(extendApi.sendEmail({id : item.id, mode: 'test'}));
		let message = apiError(`Gửi email gia hạn thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Gửi email gia hạn thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.emailSend = response.data.emailSend;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleZaloSend = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để gửi'});
			return;
		}
		let [error, response] = await handleRequest(extendApi.sendZalo({id : item.id}));
		let message = apiError(`Gửi tin nhắn zalo thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Gửi tin nhắn zalo thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.zaloSend = response.data.zaloSend;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	const handleZaloSendTest = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin để gửi'});
			return;
		}
		let [error, response] = await handleRequest(extendApi.sendZalo({id : item.id, mode: 'test'}));
		let message = apiError(`Gửi tin nhắn zalo thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Gửi tin nhắn zalo thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.zaloSend = response.data.zaloSend;
			setItemEdit(itemNew)
			dispatch(extendActions.update(itemNew));
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(extendActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(extendActions.setFilterWithDebounce(newFilter));
	};

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(extendActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Thông báo gia hạn'}></ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<ExtendSearchBar filter={filter} onChange={handleFilterChange} onSearchChange={handleSearchChange} />
					{items && <ExtendTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
					/>}
					{
						(itemEdit?.id) && <Modal zIndex={99} size="xl" title="Thông tin" visible={openModal.edit} onCancel={() => {handleModalClose('edit')}}>
							<ExtendFormEdit
								item={itemEdit}
								onHandleSubmit={handleSave}
								handleEmailStatus={handleEmailStatus}
								handleEmailSave={handleEmailSave}
								handleZaloStatus={handleZaloStatus}
								handleZaloSend={handleZaloSend}
								handleZaloSendTest={handleZaloSendTest}
								handleEmailSend={handleEmailSend}
								handleEmailSendTest={handleEmailSendTest}
							/>
						</Modal>
					}
				</div>
			</div>
		</Fragment>
	)
}
export default Extend;