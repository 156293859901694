import {Pagination, Table} from "antd";
import {
	Image,
	StatusLabel
} from "~/components";
import {
	renderDate,
	renderDateTime
} from "~/utils";
import {useDevice} from "~/hooks";
import SaleConsultationAction from "./SaleConsultationAction";

function SaleConsultationTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

	const {isMobile} = useDevice();

	const handleAssignClick = (item) => {
		setItemEdit(item);
		openModal('assign');
	}

	let columns = [
		{ title: 'Khách hàng', dataIndex: 'customer', key: "customer", width: '400px', render: (_, item) => (
			<div>
				<b>{item.name}</b>
				<p>{item.address}</p>
				<b className="color-red">{item.phone}</b>
				<p className="gap-1 d-flex">
					{item.type == 'new' ? <StatusLabel small type="green">Khách mới</StatusLabel> : <StatusLabel small type="red">Khách củ</StatusLabel>}
					{item.type2 == 'tuvan' ? <StatusLabel small type="green">Tư vấn</StatusLabel> : <StatusLabel small type="red">Bàn giao</StatusLabel>}
					{item.type3 == 'online' ? <StatusLabel small type="yellow">Online</StatusLabel> : <StatusLabel small type="blue">Trực tiếp</StatusLabel>}
					{(isMobile && item.point == 0) && <StatusLabel small type="yellow">Đợi đi</StatusLabel> }
					{(isMobile && item.point == 1) && <StatusLabel small type="blue">Đang gặp khách</StatusLabel> }
					{(isMobile && item.point == 2) && <StatusLabel small type="green">Hoàn thành</StatusLabel> }
					{(isMobile && item.point == 3) && <StatusLabel small type="red">Đã hủy</StatusLabel> }
				</p>
				<p><i>{item.note}</i></p>
				{isMobile && <>
					<p className="mt-1">{renderDate(item?.time)} <b>{item?.hours}</b></p>
					<p style={{fontSize:'10px', color:'#cccc'}}>Ngày tạo {renderDate(item?.created)}</p>
				</>}
			</div>
		)},
		{ title: 'Gọi điện', dataIndex: 'user', key: "user", width: '220px', render: (_, item) => (
			<>
				{
					item.user?.id &&
					<div className="d-flex gap align-items">
						<div className="avatar"><Image src={item.user?.avatar}/></div>
						<div>
							<b>{item.user?.firstname + ' ' + item.user?.lastname}</b>
							<p>{item.point == 2 && ((item.result?.user) ? <StatusLabel small type="green">Đã báo cáo</StatusLabel> : <StatusLabel small type="red">Chưa báo cáo</StatusLabel>)}</p>
						</div>
					</div>
				}
				{
					isMobile && (
						item.assign?.id ? (
							<div className="d-flex gap align-items pointer mt-1" onClick={() => handleAssignClick(item)}>
								<div className="avatar"><Image src={item.assign?.avatar}/></div>
								<div>
									<b>{item.assign?.firstname + ' ' + item.assign?.lastname}</b>
									<p>{item.point == 2 && ((item.result?.assign) ? <StatusLabel small type="green">Đã báo cáo</StatusLabel> : <StatusLabel small type="red">Chưa báo cáo</StatusLabel>)}</p>
								</div>
							</div>
						) : (
							<div className="d-flex gap align-items pointer mt-1" onClick={() => handleAssignClick(item)}>
								<div className="avatar"><Image src=""/></div>
								<div>Giao cho</div>
							</div>
						)
					)
				}
				{
					isMobile && (
						item.support?.id ? (
							<div className="d-flex gap align-items pointer mt-1" onClick={() => handleAssignClick(item)}>
								<div className="avatar"><Image src={item.support?.avatar}/></div>
								<div>
									<b>{item.support?.firstname + ' ' + item.support?.lastname}</b>
									<p>{item.point == 2 && ((item.result?.support) ? <StatusLabel small type="green">Đã báo cáo</StatusLabel> : <StatusLabel small type="red">Chưa báo cáo</StatusLabel>)}</p>
								</div>
							</div>
						) : (
							<div className="d-flex gap align-items pointer mt-1" onClick={() => handleAssignClick(item)}>
								<div className="avatar"><Image src=""/></div>
								<div>Giao cho</div>
							</div>
						)
					)
				}
			</>
		)},
		{ title: 'Tư vấn', dataIndex: 'assign', key: "assign", width: '220px', render: (_, item) => (
			item.assign?.id ? (
			<div className="d-flex gap align-items pointer" onClick={() => handleAssignClick(item)}>
				<div className="avatar"><Image src={item.assign?.avatar}/></div>
				<div>
					<b>{item.assign?.firstname + ' ' + item.assign?.lastname}</b>
					<p>{item.point == 2 && ((item.result?.assign) ? <StatusLabel small type="green">Đã báo cáo</StatusLabel> : <StatusLabel small type="red">Chưa báo cáo</StatusLabel>)}</p>
				</div>
			</div>
			) : (
				<div className="d-flex gap align-items pointer" onClick={() => handleAssignClick(item)}>
					<div className="avatar"><Image src=""/></div>
					<div>Giao cho</div>
				</div>
			)
		)},
		{ title: 'Hỗ trợ', dataIndex: 'support', key: "support", width: '220px', render: (_, item) => (
			item.support?.id ? (
				<div className="d-flex gap align-items pointer" onClick={() => handleAssignClick(item)}>
					<div className="avatar"><Image src={item.support?.avatar}/></div>
					<div>
						<b>{item.support?.firstname + ' ' + item.support?.lastname}</b>
						<p>{item.point == 2 && ((item.result?.support) ? <StatusLabel small type="green">Đã báo cáo</StatusLabel> : <StatusLabel small type="red">Chưa báo cáo</StatusLabel>)}</p>
					</div>
				</div>
			) : (
				<div className="d-flex gap align-items pointer" onClick={() => handleAssignClick(item)}>
					<div className="avatar"><Image src=""/></div>
					<div>Giao cho</div>
				</div>
			)
		)},
		{ title: 'Ngày hẹn', dataIndex: 'day', key: "day", width: '120px', render: (_, item) => (
			<>
				<p>{renderDate(item?.time)} <b>{item?.hours}</b></p>
				<p style={{fontSize:'10px', color:'#cccc'}}>Ngày tạo {renderDateTime(item?.created)}</p>
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '120px', render: (_, item) => (
			<div>
				{item.point == 0 && <StatusLabel small type="yellow">Đợi đi</StatusLabel> }
				{item.point == 1 && <StatusLabel small type="blue">Đang gặp khách</StatusLabel> }
				{item.point == 2 && <StatusLabel small type="green">Hoàn thành</StatusLabel> }
				{item.point == 3 && <StatusLabel small type="red">Đã hủy</StatusLabel> }
			</div>
		)},
		{ title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<SaleConsultationAction
				key={item.id}
				item={item}
				setItemEdit={setItemEdit}
				openModal={openModal}
			/>
		)},
	];

	if(isMobile) {
		columns = columns.filter(function (currentValue) {
			return (currentValue.key != 'day'
				&& currentValue.key != 'assign'
				&& currentValue.key != 'support'
				&& currentValue.key != 'status'
			)
		})
	}

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:'59vh', x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default SaleConsultationTable;