import {memo, useMemo} from "react";
import {Table} from "antd";
import {numberFormat} from "~/utils";

import {
	Image,
	PopperUserInfo
} from "~/components";

function RevenueReportUser({items, totalService, total}) {

	const columns = [
		{ title: 'Nhân viên', dataIndex: 'user', key: "user", render: (_, item) => (
				<>
					<PopperUserInfo user={item.user}>
						<div className="d-flex gap align-items">
							<div className="avatar"><Image src={item?.user?.avatar} /></div>
							<div>
								<b>{`${item?.user?.firstname} ${item?.user?.lastname}`}</b>
								<p>{item?.user?.username}</p>
							</div>
						</div>
					</PopperUserInfo>
				</>
			)},
		{ title: 'Website', dataIndex: 'website', key: "website", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.website?.total)}</>
			)},
		{ title: 'Website (*)', dataIndex: 'websiteSale', key: "websiteSale", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.website?.totalSales)}</>
			)},
		{ title: 'Host', dataIndex: 'host', key: "host", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.host?.total)}</>
			)},
		{ title: 'Host (*)', dataIndex: 'hostSale', key: "hostSale", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.host?.totalSales)}</>
			)},
		{ title: 'SSL', dataIndex: 'ssl', key: "ssl", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.ssl?.total)}</>
			)},
		{ title: 'SSL (*)', dataIndex: 'sslSale', key: "sslSale", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.ssl?.totalSales)}</>
			)},
		{ title: 'Email', dataIndex: 'emailServer', key: "emailServer", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.emailServer?.total)}</>
			)},
		{ title: 'Email (*)', dataIndex: 'emailServerSale', key: "emailServerSale", width: '110px', render: (_, item) => (
				<>{numberFormat(item?.emailServer?.totalSales)}</>
			)},
		{ title: 'Tổng', dataIndex: 'total', key: "total", width: '110px', fixed: 'right', render: (_, item) => (
				<>{numberFormat(item?.total)}</>
			)},
	];

	let dataSource = useMemo(() => {
		return Object.values(items)
	}, [items]);

	dataSource= dataSource.map(row => ({
		id : row.user.id,
		user : row.user,
		website : {
			total : row.website.total,
			totalSales : row.website.totalSales
		},
		host : {
			total : row.host.total,
			totalSales : row.host.totalSales
		},
		ssl : {
			total : row.ssl.total,
			totalSales : row.ssl.totalSales
		},
		emailServer : {
			total : row.email.total,
			totalSales : row.email.totalSales
		},
		total : row.total,
	}));

	return (
		<div className="table-box">
			<p className="mb-1" style={{fontStyle:'italic'}}>(*) Doanh thu không tính doanh số phòng</p>
			<Table
				columns={columns}
				rowKey="id"
				dataSource={dataSource}
				scroll={{ x: 'max-content' }}
				pagination={false}
				summary={() => (
					<Table.Summary fixed>
						<Table.Summary.Row>
							<Table.Summary.Cell index={0}><b>Tổng</b></Table.Summary.Cell>
							<Table.Summary.Cell index={1} colSpan={2}>{numberFormat(totalService.website)}</Table.Summary.Cell>
							<Table.Summary.Cell index={2} colSpan={2}>{numberFormat(totalService.host)}</Table.Summary.Cell>
							<Table.Summary.Cell index={3} colSpan={2}>{numberFormat(totalService.ssl)}</Table.Summary.Cell>
							<Table.Summary.Cell index={4} colSpan={2}>{numberFormat(totalService.email)}</Table.Summary.Cell>
							<Table.Summary.Cell index={9}><b>{numberFormat(total)}</b></Table.Summary.Cell>
						</Table.Summary.Row>
					</Table.Summary>
				)}
			/>
		</div>
	)
}

export default memo(RevenueReportUser);