import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Button, Loading } from "~/components";
import {
	SelectUserAll,
	CheckBoxField
} from "~/components/Forms";
import {Alert} from "antd";

function ContractFormTransfer({item, onHandleSubmit}) {

	const initialValues = {
		userId: '',
		userShareId: '',
		userShareRemove: false
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
	});

	useEffect(() => {
		if (item?.id) {
			reset(initialValues)
		}
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Nhân viên</p>
			<Controller control={control} name="userId" render={({ field }) => (
				<SelectUserAll placeholder="Chọn nhân viên" options={[]} errors={errors} {...field} />
			)}/>

			<p className="heading">Nhân viên share</p>
			<Controller control={control} name="userShareRemove" render={({ field }) => (
				<CheckBoxField placeholder="Share với nhân viên" errors={errors} {...field}>Gỏ bỏ nhân viên share (nếu có)</CheckBoxField>
			)}/>
			<Controller control={control} name="userShareId" render={({ field }) => (
				<SelectUserAll placeholder="Share với nhân viên" options={[]} errors={errors} {...field} />
			)}/>

			<Alert
				message="Lưu ý nhân viên của dịch vụ và dự án sẽ được chuyển theo, Còn nhân viên của doanh thu sẽ không được chuyển."
				type="error"
				closable
			/>

			<div className="form-group d-flex justify-content_end mt-2">
				<Button primary type="submit">Cập nhật thông tin</Button>
			</div>
		</form>
	)
}

export default ContractFormTransfer;