import request from "~/utils/http";

const customerGroupApi = {
	get : async (id) => {
		const url = `customers/group/${id}`;
		return await request.get(url);
	},
	gets : async (params) => {
		const url = 'customers/group';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'customers/group';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `customers/group/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `customers/group/${id}`;
		return await request.delete(url);
	}
};

export default customerGroupApi;