import {useState} from "react";
import style from '../scss/Account.module.scss';
import className from 'classnames/bind';
import {
	Image,
	Loading,
	Icon
} from "~/components";
import {
	useCurrentUser,
} from "~/hooks";
import {
	apiError,
	handleRequest,
} from "~/utils";
import {
	userApi
} from "~/api";
import {useDispatch} from "react-redux";

import {authActions} from "../../Auth/authSlice";

const cn = className.bind(style);

function AccountHeader() {

	const dispatch = useDispatch();

	const currentUser = useCurrentUser();

	const [avatar, setAvatar] = useState(currentUser.avatar);

	const [avatarLoading, setAvatarLoading] = useState(false);

	const handleUploadAvatar = async (event) => {

		setAvatarLoading(true);

		const file = event.target.files[0];

		if (!file) return;

		// upload file
		const formData = new FormData();

		formData.append("file", file, file.name);

		let [error, response] = await handleRequest(userApi.avatar(formData));

		let message = apiError(`Cập nhật avatar thất bại`, error, response);

		if (!message) {
			setAvatar(response.data.path);
			const itemNew = JSON.parse(JSON.stringify(currentUser));
			itemNew.avatar = response.data.path;
			dispatch(authActions.update(itemNew));
		}

		setAvatarLoading(false);
	}

	return (
		<div className={cn('profile-heading')}>
			<div className={cn('profile-avatar')}>
				<div className={cn('profile-img')}>
					<Image src={avatar} />
					{
						avatarLoading && <div className={cn('loading')}><Loading noFixed noBar spin className="w-100"/></div>
					}
					<div className={cn("file-inputs")}>
						<input name="avatar" type="file" onChange={handleUploadAvatar} />
						<button>{Icon.camera}</button>
					</div>
				</div>
			</div>
			<div className={cn('profile-name')}>
				<p className={cn('profile-name-main', 'text-xl')}>{currentUser.firstname} {currentUser.lastname}</p>
				<p className={cn('profile-name-mini', 'text-sm')}>@{currentUser.username}</p>
			</div>
		</div>
	)
}

export default AccountHeader;