import request from "~/utils/http";

const groupApi = {
	gets : async (params) => {
		const url = 'groups';
		return await request.get(url, {params});
	},
	getById : async (id) => {
		const url = `groups/${id}`;
		return await request.get(url);
	},
	add : async (params) => {
		const url = 'groups';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `groups/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `groups/${id}`;
		return await request.delete(url);
	}
};

export default groupApi;