import _ from "lodash";
import {useEffect, useState} from "react";
import ActionBar from "~/layout/ActionBar";
import {
	SaleAnalyticSearch,
	SaleAnalyticTable,
} from "../../components";
import {apiError, handleRequest} from "../../../../utils";
import {saleApi} from "../../../../api";
import {Loading} from "../../../../components";
import {DATA_LIMIT} from "../../../../app/constants";
import {useGroup} from "../../../../hooks";

function SaleAnalytic() {

	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState({});
	const [pagination, setPagination] = useState({
		page : 1,
		limit : DATA_LIMIT,
		totalRows: 0
	});

	const listGroup = useGroup();

	//Load data
	useEffect(() => {
		handleLoadData().then();
	}, [filter])

	const handleLoadData = async () => {

		setLoading(true);

		let [error, response] = await handleRequest(saleApi.reportAnalytic(filter));

		let message = apiError(`Load data không thành công`, error, response);

		if(!message) {
			setItems(response.data);
			setPagination(response.pagination);
		}

		setLoading(false);
	}

	//Search
	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		setFilter(newFilter);
	};

	//Search
	const handlePaginationChange = (page) => {
		setFilter({...filter, page });
	}

	return (
		<>
			<ActionBar title={'Hiệu Quả Làm Việc'}></ActionBar>
			<div className="container d-block">
				<SaleAnalyticSearch listGroup={listGroup} onChange={handleFilterChange} />
				<div className="content">
					{loading && <Loading />}
					{(!_.isEmpty(items) && items) && <SaleAnalyticTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						onChangeFilter={handleFilterChange}
					/>}
				</div>
			</div>
		</>
	)
}

export default SaleAnalytic;