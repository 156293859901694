import {useCallback, useMemo} from "react";
import {
	DateField,
	SelectField
} from "~/components/Forms";
import {strToTime} from "~/utils";

function SaleAnalyticSearch({listGroup, onChange}) {

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả nhóm' }, ...Object.entries(listGroup).map(([item]) => {
			return {value : listGroup[item].id, label : listGroup[item].name};
		})];
	}, [listGroup]);

	const handleTimeChange = useCallback((value) => {
		value = strToTime(value);
		if(onChange) onChange('time', value);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('group', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<DateField picker="month" onChange={handleTimeChange}/>
				<SelectField placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />
			</form>
		</div>
	)
}

export default SaleAnalyticSearch;