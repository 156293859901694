import style from '../style/Document.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
	documentActions,
	documentErrorSelector,
	documentFilterSelector,
	documentItemsSelector,
	documentLoadingSelector,
	documentPaginationSelector
} from "../DocumentSlice";
import {notification} from "antd";
import ActionBar from "../../../layout/ActionBar";
import {
	Button,
	Icon,
	Loading,
	Modal
} from "../../../components";
import {
	DocumentFormAdd,
	DocumentFormEdit,
	DocumentGroupList,
	DocumentItemInfo
} from "../components";
import className from "classnames/bind";
import {apiError, handleRequest} from "../../../utils";
import {documentApi} from "../../../api";
import {useCan} from "../../../hooks";

const cn = className.bind(style);

function Documents() {

	const can = {
		add: useCan('documentsAdd'),
		edit: useCan('documentsEdit'),
		delete: useCan('documentsDelete'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(documentItemsSelector);

	const loading   = useSelector(documentLoadingSelector);

	const error     = useSelector(documentErrorSelector);

	const pagination = useSelector(documentPaginationSelector);

	const filter    = useSelector(documentFilterSelector);

	const [itemEdit, setItemEdit]   = useState({});

	const [documentGroup, setDocumentGroup]     = useState(0);

	//Model
	const [openModal, setOpenModal] = useState({
		addItem         : false,
		editItem        : false,
		deleteItem      : false,
		infoItem        : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleReLoading();
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		let newFilter = {...filter}
		dispatch(documentActions.fetchData(newFilter));
	}

	const handleAdd = async (data, group) => {

		if (group?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin nhóm tài liệu để thêm tài liệu'});
			return;
		}

		data.groupId = group.id;

		let [error, response] = await handleRequest(documentApi.addItem(data));

		let message = apiError(`Thêm mới tài liệu thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm mới tài liệu thành công`});
			let newProject = JSON.parse(JSON.stringify(group));
			newProject.items.unshift({...response.data.item})
			setDocumentGroup(newProject);
			dispatch(documentActions.update(newProject));
			handleModalClose('addItem')
		}
	}

	const handleEdit = async (data, documentItem, group) => {

		if (documentItem?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin tài liệu để cập nhật'});
			return;
		}

		data.id = documentItem.id;

		let [error, response] = await handleRequest(documentApi.updateItem(data));
		let message = apiError(`Cập nhật tài liệu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật tài liệu thành công`});
			let newProject = JSON.parse(JSON.stringify(group));
			for (const [index, item] of Object.entries(newProject.items)) {
				if(item.id === documentItem.id) {
					if(response.data?.name) newProject.items[index].name = response.data.name;
					if(response.data?.content) newProject.items[index].content = response.data.content;
				}
			}
			dispatch(documentActions.update(newProject));
			handleModalClose('editItem')
		}
	}

	//Delete
	const handleDelete = async (item, group) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin tài liệu để xóa'});
			return;
		}
		let [error, response] = await handleRequest(documentApi.deleteItem(item.id));
		let message = apiError(`xóa tài liệu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa tài liệu thành công`});

			let newProject = JSON.parse(JSON.stringify(group));
			newProject.items = newProject.items.filter(function(document) {
				return item.id !== document.id
			})
			setDocumentGroup(newProject);
			dispatch(documentActions.update(newProject));
			handleModalClose('deleteItem')
		}
	}

	const handleUpload = async (data, group) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin tài liệu để cập nhật'});
			return;
		}
		data.append('id', itemEdit.id);
		let [error, response] = await handleRequest(documentApi.fileUpload(data));
		let message = apiError(`Cập nhật tài liệu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Upload File thành công`});
			let newProject = JSON.parse(JSON.stringify(group));
			for (const [index, item] of Object.entries(newProject.items)) {
				if(item.id === itemEdit.id) {
					if(response.data?.attachFile) newProject.items[index].attachFile = response.data.attachFile;
					if(response.data?.attachFileTotal) newProject.items[index].attachFileTotal = response.data.attachFileTotal;
				}
			}
			dispatch(documentActions.update(newProject));
		}
	}

	const handleUploadDelete = async (filename, group) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dự án để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(documentApi.fileRemove({id: itemEdit.id, filename: filename}));
		let message = apiError(`Cập nhật tài liệu thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật tài thành công`});
			let newProject = JSON.parse(JSON.stringify(group));
			for (const [index, item] of Object.entries(newProject.items)) {
				if(item.id === itemEdit.id) {
					if(response.data?.attachFile) newProject.items[index].attachFile = response.data.attachFile;
					if(response.data?.attachFileTotal) newProject.items[index].attachFileTotal = response.data.attachFileTotal;
				}
			}
			dispatch(documentActions.update(newProject));
		}
	}

	return (
		<>
			<ActionBar title={'Tài liệu'}>
				<Button outline small onClick={handleReLoading} style={{fontSize:'20px'}}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className={cn('wrapper', 'pd-1')}>
					{loading && <Loading/>}
					{items && <DocumentGroupList
						items={items}
						pagination={pagination}
						setItemEdit={setItemEdit}
						setDocumentGroup={setDocumentGroup}
						openModal={handleModalOpen}
						closeModal={handleModalClose}
						can={can}
					/>}
				</div>
				{
					(documentGroup?.id && openModal.addItem) && <Modal title="Thêm tài liệu" size={'xl'} visible={openModal.addItem} onCancel={() => {handleModalClose('addItem')}}>
						<DocumentFormAdd group={documentGroup} modalOpen={handleModalOpen} onHandleSubmit={handleAdd} />
					</Modal>
				}
				{
					(documentGroup?.id && itemEdit?.id && openModal.infoItem) && <Modal title="Chi tiết nội quy" size={'xl'} visible={openModal.infoItem} onCancel={() => {handleModalClose('infoItem')}}>
						<DocumentItemInfo group={documentGroup} item={itemEdit} modalOpen={handleModalOpen} modalClose={handleModalClose} />
					</Modal>
				}
				{
					(documentGroup?.id && openModal.editItem) && <Modal title="Cập nhật tài liệu" size={'xl'} visible={openModal.editItem} onCancel={() => {handleModalClose('editItem')}}>
						<DocumentFormEdit
							group={documentGroup}
							item={itemEdit}
							onHandleSubmit={handleEdit}
							onRemoveFile={handleUploadDelete}
							onUpload={handleUpload}
						/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.deleteItem) && <Modal title="Xóa tài liệu" visible={openModal.deleteItem} onCancel={() => {handleModalClose('deleteItem')}}>
						<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
						<div className="d-flex justify-content-end modal-bottom pd-2">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('deleteItem')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={() => handleDelete(itemEdit, documentGroup)}> Xóa </Button>
						</div>
					</Modal>
				}
			</div>
		</>
	)
}

export default Documents;