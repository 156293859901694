import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Icon,
	Loading,
	Button,
	Modal
} from "~/components";
import {
	apiError,
	strToTime,
	handleRequest
} from "~/utils";
import {
	useCan
} from "~/hooks";

import {
	FormPaymentTable,
	FormPaymentAddEdit,
	FormPaymentInfo
} from "../components";

import {
	formPaymentApi,
	expenseApi
} from "~/api";

import {
	formPaymentActions,
	formPaymentItemsSelector,
	formPaymentLoadingSelector,
	formPaymentErrorSelector,
	formPaymentPaginationSelector,
	formPaymentFilterSelector
} from "../formPaymentSlice";

function FormPayment() {

	const can = {
	  	add             : useCan('formPaymentAdd'),
		statusAssign    : useCan('formPaymentStatusAssign'),
		statusManager   : useCan('formPaymentStatusManager'),
	}

	const dispatch  = useDispatch();

	const items     = useSelector(formPaymentItemsSelector);

	const loading   = useSelector(formPaymentLoadingSelector);

	const error     = useSelector(formPaymentErrorSelector);

	const pagination = useSelector(formPaymentPaginationSelector);

	const filter    = useSelector(formPaymentFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	const [categories, setCategories] = useState([]);

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(formPaymentActions.fetchData(filter));
	}, [filter]);

	useEffect(() => {
		fetchFormCategories().then();
	}, []);

	const fetchFormCategories = async () => {
		if(can.statusAssign) {
			let [error, response] = await handleRequest(expenseApi.groupGets());

			let message = apiError(`Load data không thành công`, error, response);

			if (!message) {
				setCategories(response.data);
			}
		}
	}

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	//Submit
	const handleSaveItem = async (data, item) => {
		let messageAction = 'Thêm mới';
		if(item?.id) {
			if (item?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin đơn xin nghỉ phép để cập nhật'});
				return;
			}
			data.id = item.id;
			messageAction = 'Cập nhật';
		}

		data.time = strToTime(data.time);

		let [error, response] = (item?.id) ? await handleRequest(formPaymentApi.update(data)) : await handleRequest(formPaymentApi.add(data));
		let message = apiError(`${messageAction} phiếu chi thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} phiếu chi thành công`});
			if(item?.id) {
				dispatch(formPaymentActions.update(response.data));
			}
			else {
				dispatch(formPaymentActions.add(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có phiếu chi để xóa'});
			return;
		}
		let [error, response] = await handleRequest(formPaymentApi.delete(itemEdit.id));
		let message = apiError(`xóa đơn nghỉ phép thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa phiếu chi thành công`});
			dispatch(formPaymentActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có phiếu chi để cập nhật'});
			return;
		}

		data.id = item.id;

		data.time = strToTime(data.time);

		let [error, response] = await handleRequest(formPaymentApi.status(data));

		let message = apiError(`Cập nhật trạng thái phiếu chi thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái phiếu chi thành công`});
			item.status    = response.data.status;
			item.statusAssign    = response.data.statusAssign;
			item.statusManager    = response.data.statusManager;
			item.timePay   = response.data.timePay;
			item.assign    = response.data.assign;
			dispatch(formPaymentActions.update(item));
			handleModalClose('info')
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(formPaymentActions.setFilter({...filter, page }));
	}

	//Modal
	return (
		<Fragment>
			<ActionBar title={'Phiếu chi'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <FormPaymentTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
					/>}
				</div>
			</div>
			<Modal title="Phiếu chi" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
				<FormPaymentAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
			</Modal>
			{
				(itemEdit?.id) && <Modal title="Thông tin" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
					<FormPaymentInfo item={itemEdit} can={can} categories={categories} onHandleSubmit={handleStatus} />
				</Modal>
			}
			{
				(itemEdit?.id) && <Modal title="Xóa đơn" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
					<p>Bạn muốn xóa <b>{itemEdit?.name}</b>?</p>
					<br />
					<div className="d-flex justify-content-end gap button-modal">
						<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
						<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
					</div>
				</Modal>
			}
		</Fragment>
	)
}
export default FormPayment;