import {call, put, takeLatest} from "redux-saga/effects";
import {projectTaskActions} from "./projectTaskSlice";
import {projectApi} from "~/api";

function* fetchProjectList(action) {
	try {
		const response = yield call(projectApi.getsWithTask, action.payload);
		yield put(projectTaskActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch project list', error);
		yield put(projectTaskActions.fetchDataFailed(error.message));
	}
}

export default function* projectTaskSaga() {
	yield takeLatest(projectTaskActions.fetchData.type, fetchProjectList);
}