import request from "~/utils/http";

const dashboardApi = {
	saleCommit : async (params) => {
		const url = 'dashboard/sale/commit';
		return await request.get(url, {params});
	},
	salePoint : async (params) => {
		const url = 'dashboard/sale/point';
		return await request.get(url, {params});
	},
	saleKpi : async (params) => {
		const url = 'dashboard/sale/kpi';
		return await request.get(url, {params});
	},
	revenue : async (params) => {
		const url = 'dashboard/revenue';
		return await request.get(url, {params});
	},
	revenueRanks : async (params) => {
		const url = 'dashboard/revenue/ranks';
		return await request.get(url, {params});
	},
	revenueGroup : async (params) => {
		const url = 'dashboard/revenue/group';
		return await request.get(url, {params});
	},
	contractNew : async (params) => {
		const url = 'dashboard/contract/new';
		return await request.get(url, {params});
	},
	customer : async (params) => {
		const url = 'dashboard/customer';
		return await request.get(url, {params});
	},
	customerGroup : async (params) => {
		const url = 'dashboard/customer/group';
		return await request.get(url, {params});
	},
	designProject : async (params) => {
		const url = 'dashboard/design/project';
		return await request.get(url, {params});
	},
	designRanks : async (params) => {
		const url = 'dashboard/design/ranks';
		return await request.get(url, {params});
	},
	personnelCount : async (params) => {
		const url = 'dashboard/personnel/count';
		return await request.get(url, {params});
	},
	personnelBirthday : async (params) => {
		const url = 'dashboard/personnel/birthday';
		return await request.get(url, {params});
	},
};

export default dashboardApi;