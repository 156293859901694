import RuleGroupItem from "../RuleGroup/RuleGroupItem";

function RuleGroupList({items = [], setItemEdit, setRuleGroup, openModal, closeModal, can}) {
	return (
		<div className="table-wrapper m-0 pd-1">
			<div className="table-box">
				{items.map((item) => <RuleGroupItem key={`ruleGroupItem${item.id}`} can={can} setRuleGroup={setRuleGroup} setItemEdit={setItemEdit} group={item} openModal={openModal} closeModal={closeModal}/>)}
			</div>
		</div>
	)
}

export default RuleGroupList
