import {useMemo, useCallback, useState} from "react";
import {
	SelectUserAll,
	SelectContractAll,
	SelectField
} from "~/components/Forms";
import {LIST_PROJECT_STATUS} from "~/app/constants";
import StatusLabel from "~/components/StatusLabel";
import { useCan } from "~/hooks";

function ProjectSearchBar({filter, listAssign = [], listDesign = [], onChange}) {
	const can = {
		listAll: useCan('projectListAll'),
		listGroup: useCan('projectListGroup'),
		listDesign: useCan('projectListPhaseDesign'),
		listProgram: useCan('projectListPhaseProgram'),
	};

	const [optionsUser, setOptionsUser] = useState([{value:'', label:'Tất cả'}]);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_PROJECT_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, []);

	const assignOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listAssign).map(([key, assign]) => {
			return {
				value: assign.id,
				label: `${assign.firstname} ${assign.lastname}`
			};
		})];
	}, [listAssign]);

	const designOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(listDesign).map(([key, design]) => {
			return {
				value: design.id,
				label: `${design.firstname} ${design.lastname}`
			};
		})];
	}, [listDesign]);

	const handleUserChange = useCallback((value) => {
		if(onChange) onChange('userId', value);
	}, [onChange]);

	const handleContractChange = useCallback((value) => {
		if(onChange) onChange('contractId', value);
	}, [onChange]);

	const handleAssignChange = useCallback((value) => {
		if(onChange) onChange('assignId', value);
	}, [onChange]);

	const handleDesignChange = useCallback((value) => {
		if(onChange) onChange('designId', value);
	}, [onChange]);

	const handleStatusChange = useCallback((value) => {
		if(onChange) onChange('status', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<SelectContractAll service="website" value={filter.contractId} placeholder="Hợp đồng" options={[{value:'', label:'Tất cả'}]} onChange={handleContractChange} />
					{(can.listAll || can.listGroup || can.listProgram || can.listDesign) && <SelectUserAll value={filter.userId} placeholder="Nhân viên" options={optionsUser} setOptions={setOptionsUser} onChange={handleUserChange} />}
					{(!can.listDesign) && <SelectUserAll placeholder="Kỹ thuật" department="technical" value={filter.assignId} options={assignOptions} onChange={handleAssignChange}/>}
					<SelectField value={filter.designId} placeholder="Design" options={designOptions} onChange={handleDesignChange}/>
					<SelectField value={filter.status} placeholder="Trạng thái" options={statusOptions} onChange={handleStatusChange}/>
				</form>
			</div>
		</div>
	)
}

export default ProjectSearchBar;