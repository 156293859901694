import StatusLabel from "~/components/StatusLabel";
import {renderDate, arrayToMap} from "~/utils";
import {Pagination, Table} from "antd";
import CandidateAction from "./CandidateAction";
import {LIST_SOURCE} from "~/app/constants";
import {useUtilities} from "../../../../hooks";

function CandidateTable({items = [], pagination, setItemEdit, onPaginationChange, openModal}) {

	const {utilities} = useUtilities();

	//Tạo map nguồn tìm ứng viên
	const sourceMap = arrayToMap(LIST_SOURCE);

	const columns = [
		{ title: 'Ứng viên', dataIndex: 'fullname', key: "fullname" },
		{ title: 'Phỏng vấn', dataIndex: 'appointment', key: "appointment", render: (_, item) => (
			<>
				<b>{item?.userInterviewData?.id && item?.userInterviewData?.firstname + ' ' + item?.userInterviewData?.lastname}</b>
				<p style={{"fontSize" : "13px"}}>{renderDate(item?.appointment)}</p>
			</>
		)},
		{ title: 'Trạng thái', dataIndex: 'status', key: "status", width: '150px', render: (_, item) => (
			<StatusLabel small type={(item.userId) ? 'green' : 'red'}>{(item.userId) ? 'Đã nhận việc' : 'Chưa nhận việc'}</StatusLabel>
		)},
		{ title: 'Số điện thoại', dataIndex: 'phone', key: "phone" },
		{ title: 'Email', dataIndex: 'email', key: "email" },
		{ title: 'Ngày sinh', dataIndex: 'birthday', key: "birthday", render: (_, item) => (renderDate(item?.birthday)) },
		{ title: 'Vị trí', dataIndex: 'apply', key: "apply", width: '120px', render: (_, item) => (<StatusLabel small type={utilities.roles[item.apply]?.color}>{utilities.roles[item.apply]?.title}</StatusLabel>) },
		{ title: 'Nguồn', dataIndex: 'source', key: "source", width: '120px', render: (_, item) => (<StatusLabel small type={sourceMap[item.source]?.color}>{sourceMap[item.source]?.title}</StatusLabel>) },
		{ title: '#', dataIndex: 'action', key: "action", width: '50px', render: (_, item) => (
			<CandidateAction item={item} openModal={openModal} setItemEdit={setItemEdit} />
		)},
	];

	const dataSource= items.map(row => ({
		key: row.id, // I added this line
		...row
	}));

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default CandidateTable;