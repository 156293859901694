import style from '../style/Form.module.scss';
import {Link} from "react-router-dom";
import className from 'classnames/bind';
import ActionBar from "~/layout/ActionBar";
import Icon from "~/components/Icon";
import {useCan} from "~/hooks";
const cn = className.bind(style);

function Form() {
	const can = {
		'payment': useCan('formPaymentView'),
		'salary': useCan('formSalaryView'),
		'sabbatical': useCan('formSabbaticalView'),
		'papers': useCan('papersView'),
	}
	/* Đơn nghỉ phép*/
	const ItemSalary = () => {
		if(!can.salary) return null;
		return <div className={cn('item')}>
			<div className={cn('icon')}>{Icon.chart}</div>
			<div className={cn('info')}>
				<Link to={'salary'}>
					<h3 className={'text-lg'}>Nghỉ phép</h3>
					<p>Tạo đơn xin nghỉ phép</p>
				</Link>
			</div>
		</div>
	}
	/*Sale: Điểm tư vấn*/
	const ItemSabbatical = () => {
		if(!can.sabbatical) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.dola}</div>
			<div className={cn('info')}>
				<Link to={'sabbatical'}>
					<h3 className={'text-lg'}>Ứng Lướng</h3>
					<p>Đơn xin ứng lương</p>
				</Link>
			</div>
		</div>)
	}
	/*Sale: Lịch hẹn*/
	const ItemPayment = () => {
		if(!can.payment) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.dola}</div>
			<div className={cn('info')}>
				<Link to={'payment'}>
					<h3 className={'text-lg'}>Phiếu chi</h3>
					<p>Đề nghị chi tiền</p>
				</Link>
			</div>
		</div>)
	}
	/*Sale: Báo cáo công việc*/
	const ItemPapers = () => {
		if(!can.papers) return null;
		return (<div className={cn('item')}>
			<div className={cn('icon')}>{Icon.chart}</div>
			<div className={cn('info')}>
				<Link to={'sales/report'}>
					<h3 className={'text-lg'}>Giấy tờ</h3>
					<p>Đơn xin giấy tờ, hợp đồng, phiếu thu...</p>
				</Link>
			</div>
		</div>)
	}
	return (
		<>
			<ActionBar title={'Đơn từ'}></ActionBar>
			<div className="container">
				<div className="content">
					<div className={cn('items', 'gap-1')}>
						<ItemSalary />
						<ItemSabbatical />
						<ItemPayment />
						<ItemPapers />
					</div>
				</div>
			</div>
		</>
	)
}

export default Form;