import {
	InputField
} from "~/components/Forms";

function BuyServiceSearchBar({filter, onSearchChange, onChange}) {

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField placeholder="Tên miền" onChange={handleKeywordChange} />
				</form>
			</div>
		</div>
	)
}

export default BuyServiceSearchBar;