import {Fragment, useState, useEffect, useCallback} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	Loading,
	Modal,
	Button,
	Icon,
} from "~/components";
import {
	apiError,
	strToTime,
	handleRequest
} from "~/utils";
import {
	useDevice,
	useSocket,
	useCan
} from "~/hooks";
import {
	serviceApi,
	callSupportApi
} from "~/api";
import {
	serviceActions,
	serviceErrorSelector,
	serviceFilterSelector,
	serviceItemsSelector,
	serviceLoadingSelector,
	servicePaginationSelector
} from "../serviceSlice";
import {
	ServiceTable,
	ServiceFormUpgrade,
	ServiceSearchBar,
	ServiceSearchMobile,
	ServiceFormCallSupport,
	ServiceFormDomain,
	ServiceFormClose,
	ServiceFormTemporary,
	ServiceFormEdit,
	ServiceFormHostInfo,
	ServiceFormHostAdd,
	ServiceFormHostEdit
} from "../components";
import CustomerFormInfo from "../../Contract/components/Forms/CustomerFormInfo";
import debounce from "lodash/debounce";

function Service() {

	const {socket} = useSocket();

	const {isMobile} = useDevice();

	const {status} = useParams();

	const dispatch  = useDispatch();

	const items     = useSelector(serviceItemsSelector);

	const loading   = useSelector(serviceLoadingSelector);

	const error     = useSelector(serviceErrorSelector);

	const pagination = useSelector(servicePaginationSelector);

	const filter    = useSelector(serviceFilterSelector);

	const [itemEdit, setItemEdit] = useState([]);

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const can = {
		support: useCan('serviceSupport'),
		domain: useCan('serviceDomain'),
		extend: useCan('serviceExtendSr'),
		extendPa: useCan('serviceExtendPa'),
		close: useCan('serviceClose'),
		edit: useCan('serviceEdit'),
		hostAdd: useCan('serviceHostAdd'),
		hostEdit: useCan('serviceHostEdit'),
		hostUpdate: useCan('serviceHostUpdate'),
		customer: useCan('customerView'),
	};

	//Model
	const [openModal, setOpenModal] = useState({
		upgrade   	    : false,
		extend    	    : false,
		extendPa  	    : false,
		support  	    : false,
		domain  	    : false,
		close  		    : false,
		edit  		    : false,
		hostInfo  	    : false,
		hostAdd  	    : false,
		hostEdit  	    : false,
		infoCustomer    : false,
		temporary       : false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		handleLoading()
	}, [filter, status]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleLoading = () => {
		const statusMapping = {
			'will-expired': 'willexpired',
			'expiry': 'expired',
		};
		const newFilter = {
			...filter,
			status: statusMapping[status] || '',
		};
		dispatch(serviceActions.fetchData(newFilter));
	}

	//Cập nhật
	const handleEdit = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ để cập nhật'});
			return;
		}
		data.id = item.id;
		data.expired  = strToTime(data.expired);

		let [error, response] = await handleRequest(serviceApi.update(data));
		let message = apiError(`Cập nhật dịch vụ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật dịch vụ thành công`});
			let itemNew = JSON.parse(JSON.stringify(item));
			itemNew.status = response.data.status;
			itemNew.expired = response.data.expired;
			itemNew.price = response.data.price;
			dispatch(serviceActions.update(itemNew));
			handleModalClose('edit')
		}
	}

	//Gia hạn
	const handleExtend = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ để gia hạn'});
			return;
		}
		data.id = item.id;

		data.contractSigning    = strToTime(data.contractSigning);
		data.contractExpired    = strToTime(data.contractExpired);
		data.time               = strToTime(data.time);
		data.timePlus           = strToTime(data.timePlus);

		let [error, response] = await handleRequest(serviceApi.extend(data));
		let message = apiError(`Gia hạn dịch vụ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Gia hạn dịch vụ thành công`});
			dispatch(serviceActions.update(response.data.item));
			if(!_.isEmpty(response.data.notification)) {
				socket.emit("set-notification-user", response.data.notification);
			}
			handleModalClose('extend')
		}
	}

	//Gia hạn trên pavietnam
	const handleExtendPa = async (item) => {
		setLoadingSubmit(true);
		if (item?.id == 'undefined') {
			setLoadingSubmit(false);
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ để gia hạn'});
			return;
		}
		let [error, response] = await handleRequest(serviceApi.extendPa(item.id));
		let message = apiError(`Gia hạn dịch vụ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Gia hạn dịch vụ thành công`});
			handleModalClose('extendPa')
		}
		setLoadingSubmit(false);
	}

	//Nâng cấp
	const handleUpgrade = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ để nâng cấp'});
			return;
		}
		data.id = item.id;

		data.contractSigning    = strToTime(data.contractSigning);
		data.contractExpired    = strToTime(data.contractExpired);
		data.time               = strToTime(data.time);
		data.timePlus           = strToTime(data.timePlus);

		let [error, response] = await handleRequest(serviceApi.upgrade(data));
		let message = apiError(`Nâng cấp dịch vụ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Nâng cấp dịch vụ thành công`});
			dispatch(serviceActions.update(response.data.item));
			if(!_.isEmpty(response.data.notification)) {
				socket.emit("set-notification-user", response.data.notification);
			}
			handleModalClose('upgrade')
		}
	}

	//Tên tên miền
	const handleDomain = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ'});
			return;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(serviceApi.updateDomain(data));
		let message = apiError(`Cập nhật tên miền dịch vụ thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật tên miền dịch vụ thành công`});
			let newItem = JSON.parse(JSON.stringify(item));
			newItem.domain = data.domain;
			dispatch(serviceActions.update(newItem));
			handleModalClose('domain')
		}
	}

	const handleHostAdd = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ'});
			return false;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(serviceApi.hostAdd(data));
		let message = apiError(`Tạo host thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Tạo host cho dịch vụ thành công`});
			let newItem = JSON.parse(JSON.stringify(item));
			newItem.hostIp = response.data.hostIp;
			newItem.hostName = response.data.hostName;
			dispatch(serviceActions.update(newItem));
			return response;
		}
		return false;
	}

	const handleHostEdit = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ'});
			return false;
		}
		data.id = item.id;
		let [error, response] = await handleRequest(serviceApi.hostEdit(data));
		let message = apiError(`Cập nhật host thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật host cho dịch vụ thành công`});
			let newItem = JSON.parse(JSON.stringify(item));
			newItem.hostIp = response.data.hostIp;
			newItem.hostName = response.data.hostName;
			dispatch(serviceActions.update(newItem));
			return response;
		}
		return false;
	}

	//support
	const handleCallSupport = useCallback(
		async (data, item, timeLineSupport, setTimeLineSupport) => {
			if (item?.customerId == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để cập nhật'});
				return;
			}
			data.customerId = item.customerId;

			let [error, response] = await handleRequest(callSupportApi.add(data));

			let message = apiError(`Thêm cuộc gọi thất bại`, error, response);

			if(!message) {
				notification.success({message: 'Thành công', description: `Thêm cuộc gọi thành công`});
				let newItem = JSON.parse(JSON.stringify(item));
				newItem.supportId   = response.data.id;
				newItem.supportTime = response.data.time;
				if(_.isEmpty(timeLineSupport)) {
					setTimeLineSupport([response.data]);
				}
				else {
					let timeLineAdded = false;
					timeLineSupport.map((timeLine, index) => {
						if(timeLine.date == response.data.date) {
							timeLineAdded = true;
							timeLineSupport[index].list.unshift(response.data.list[0]);
						}
					})
					if(!timeLineAdded) {
						timeLineSupport.unshift(response.data);
					}
					setTimeLineSupport(timeLineSupport);
				}
				dispatch(serviceActions.update(newItem));
				handleModalClose('support')
			}
		}, [],
	);

	//Close
	const handleClose = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ để cập nhật'});
			return;
		}
		data.id = item.id;

		let [error, response] = await handleRequest(serviceApi.close(data));

		let message = apiError(`Thêm yêu cầu thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm yêu cầu thành công`});
			if(!_.isEmpty(response.data.notification)) {
				socket.emit("set-notification-user", response.data.notification);
			}
			handleModalClose('close')
		}
	}


	const handleTemporary = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin dịch vụ để cập nhật'});
			return;
		}
		data.time   = strToTime(data.time);
		data.id = item.id;

		let [error, response] = await handleRequest(serviceApi.temporary(data));

		let message = apiError(`Thêm yêu cầu thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm yêu cầu thành công`});
			if(!_.isEmpty(response.data.notification)) {
				socket.emit("set-notification-user", response.data.notification);
			}
			handleModalClose('temporary')
		}
	}

	//Search
	const SearchBar = (isMobile) ? ServiceSearchMobile : ServiceSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(serviceActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(serviceActions.setFilterWithDebounce(newFilter));
	};

	const debouncedSearchChange = debounce(handleSearchChange, 1000);

	const handleFilterChange = (key, value, reset = false) => {
		let newFilter = {
			...filter,
			page: 1,
		};
		if(reset) {
			newFilter.customerId = '';
			newFilter.time = '';
			newFilter.keyword = '';
			newFilter.service = '';
		}
		newFilter = {
			...newFilter,
			[key]: value,
			page: 1
		};
		dispatch(serviceActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Dịch vụ'}>
				<Button outline small onClick={handleLoading} style={{fontSize:'20px'}}>{Icon.reload}</Button>
			</ActionBar>
			<SearchBar status={status} filter={filter} onChange={handleFilterChange} onSearchChange={debouncedSearchChange} />
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{items && <ServiceTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
						can={can}
					/>}
				</div>
				{
					(itemEdit?.id && openModal.edit) && <Modal title={`Cập nhật dịch vụ ${itemEdit.domain}`} visible={openModal.edit} onCancel={() => {handleModalClose('edit')}}>
						<ServiceFormEdit item={itemEdit} onHandleSubmit={handleEdit}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.upgrade) && <Modal title={`Nâng cấp ${itemEdit.domain}`} visible={openModal.upgrade} onCancel={() => {handleModalClose('upgrade')}}>
						<ServiceFormUpgrade item={itemEdit} type={'upgrade'} onHandleSubmit={handleUpgrade}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.extend) && <Modal title={`Gia hạn ${itemEdit.domain}`} visible={openModal.extend} onCancel={() => {handleModalClose('extend')}}>
						<ServiceFormUpgrade item={itemEdit} type={'extend'} onHandleSubmit={handleExtend}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.extendPa) && <Modal title={`Gia hạn`} visible={openModal.extendPa} onCancel={() => {handleModalClose('extendPa')}}>
						<p>Gia hạn tên miền <b>{itemEdit?.domain}</b>?</p>
						<div className="d-flex justify-content-end gap modal-bottom pd-1">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('extendPa')}}> Đóng </Button>
							<Button loading={loadingSubmit} background blue leftIcon={Icon.delete} onClick={() => handleExtendPa(itemEdit)}> Gia hạn </Button>
						</div>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.support) && <Modal title="Hỗ trợ" size={'xl'} visible={openModal.support} onCancel={() => {handleModalClose('support')}}>
						<ServiceFormCallSupport item={itemEdit} onHandleSubmit={handleCallSupport}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.close) && <Modal title="Yêu cầu đóng dịch vụ" visible={openModal.close} onCancel={() => {handleModalClose('close')}}>
						<ServiceFormClose item={itemEdit} onHandleSubmit={handleClose}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.temporary) && <Modal title="Yêu cầu mở tạm dịch vụ" visible={openModal.temporary} onCancel={() => {handleModalClose('temporary')}}>
						<ServiceFormTemporary item={itemEdit} onHandleSubmit={handleTemporary}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.domain) && <Modal title="Tên miền dịch vụ" visible={openModal.domain} onCancel={() => {handleModalClose('domain')}}>
						<ServiceFormDomain item={itemEdit} onHandleSubmit={handleDomain}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.hostInfo) && <Modal title="Thông tin host" visible={openModal.hostInfo} onCancel={() => {handleModalClose('hostInfo')}}>
						<ServiceFormHostInfo item={itemEdit}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.hostAdd) && <Modal title="Chia host" visible={openModal.hostAdd} onCancel={() => {handleModalClose('hostAdd')}}>
						<ServiceFormHostAdd item={itemEdit} onHandleHost={handleHostAdd}/>
					</Modal>
				}
				{
					(itemEdit?.id && openModal.hostEdit) && <Modal title="Cập nhật host" visible={openModal.hostEdit} onCancel={() => {handleModalClose('hostEdit')}}>
						<ServiceFormHostEdit item={itemEdit} onHandleSubmit={handleHostEdit}/>
					</Modal>
				}
				{
					(itemEdit?.customer && openModal.infoCustomer) && <Modal title="Thông tin khách hàng" visible={openModal.infoCustomer} onCancel={() => {handleModalClose('infoCustomer')}}>
						<CustomerFormInfo item={itemEdit.customer} />
					</Modal>
				}
			</div>
		</Fragment>
	)
}
export default Service;