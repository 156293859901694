import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import {
	SelectUserAll,
	CheckBoxField
} from "~/components/Forms";
import {Alert} from "antd";

function CustomerFormTransfer({item, onHandleSubmit}) {

	const initialValues = {
		userId: '',
		userShareId: '',
		userShareRemove: false
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
	});

	useEffect(() => {
		if (item?.id) {
			reset(initialValues)
		}
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	let message = '<strong>Lưu ý:</strong>';
	message += '<p>- Nhân viên Share của khách hàng sẽ được chuyển</p>'
	message += '<p>- Nhân viên Share <b>Hợp đồng</b> của khách hàng <b>không thay đổi</b></p>';
	message += '<p>- Nhân viên Share <b>Dự án</b> của khách hàng <b>không thay đổi</b></p>';
	message += '<p>- Nếu muốn chuyển vui lòng truy cập hợp đồng để chuyển nhân viên</p>';

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Nhân viên</p>
			<Controller control={control} name="userId" render={({ field }) => (
				<SelectUserAll placeholder="Chọn nhân viên" options={[]} errors={errors} {...field} />
			)}/>

			<p className="heading">Nhân viên share</p>
			<Controller control={control} name="userShareRemove" render={({ field }) => (
				<CheckBoxField placeholder="Share với nhân viên" errors={errors} {...field}>Gỏ bỏ nhân viên share (nếu có)</CheckBoxField>
			)}/>
			<Controller control={control} name="userShareId" render={({ field }) => (
				<SelectUserAll placeholder="Share với nhân viên" options={[]} errors={errors} {...field} />
			)}/>

			<Alert
				message={<span dangerouslySetInnerHTML={{ __html: message }} />}
				type="error"
				closable
			/>

			<div className="form-group d-flex justify-content-end modal-bottom mt-2">
				<Button primary type="submit">Cập nhật thông tin</Button>
			</div>
		</form>
	)
}

export default CustomerFormTransfer;