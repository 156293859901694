import {useCallback, useMemo} from "react";
import {
	DateRangeField,
	SelectField
} from "~/components/Forms";
import {strToTime} from "~/utils";
import {useCan} from "~/hooks";

function RevenueReportSearch({filter, listGroup, onChange}) {

	const plus = useCan('revenuePlus');

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả các nhóm' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const confirmOptions = [
		{value: 0, label: 'Đã duyệt hoặc tính lương'},
		{value: 1, label: 'Doanh thu đã duyệt'},
		{value: 2, label: 'Tất cả loại doanh thu'},
	];

	const typeOptions = [
		{value: 0, label: 'Doanh số & doanh thu'},
		{value: 1, label: 'Chỉ doanh số'},
	];

	const type1Options = [
		{value: 0, label: 'Không tính 500k demo'},
		{value: 1, label: 'Tính 500k demo'},
	];

	const type2Options = [
		{value: 0, label: 'Tính khi đủ KPI (5 triệu)'},
		{value: 1, label: 'Tính khi không đủ KPI'},
	];

	const handleTimeChange = useCallback((value) => {
		let valueNew = [...value]
		valueNew[0] = strToTime(valueNew[0]);
		valueNew[1] = strToTime(valueNew[1]);
		if(onChange) onChange('time', valueNew);
	}, [onChange]);

	const handleGroupChange = useCallback((value) => {
		if(onChange) onChange('groupId', value);
	}, [onChange]);

	const handleConfirmChange = useCallback((value) => {
		if(onChange) onChange('confirm', value);
	}, [onChange]);

	const handleTypeChange = useCallback((value) => {
		if(onChange) onChange('type', value);
	}, [onChange]);

	const handleType1Change = useCallback((value) => {
		if(onChange) onChange('type1', value);
	}, [onChange]);

	const handleType2Change = useCallback((value) => {
		if(onChange) onChange('type2', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<DateRangeField name="time" onChange={(value) => handleTimeChange(value)} />
				<SelectField defaultValue={filter.groupId} placeholder="Chọn nhóm" options={groupOptions} onChange={(value) => handleGroupChange(value)} />
				<SelectField defaultValue={filter.confirm} options={confirmOptions} onChange={(value) => handleConfirmChange(value)} />
				{plus && <SelectField defaultValue={filter.type} options={typeOptions} onChange={(value) => handleTypeChange(value)} />}
				<SelectField defaultValue={filter.type1} options={type1Options} onChange={(value) => handleType1Change(value)} />
				<SelectField defaultValue={filter.type2} options={type2Options} onChange={(value) => handleType2Change(value)} />
			</form>
		</div>
	)
}

export default RevenueReportSearch;