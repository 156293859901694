import {Fragment, useEffect, useMemo} from "react";
import {useForm, Controller} from "react-hook-form";
import {Col, Row} from "antd";
import {
	Loading,
	Button,
	StatusLabel
} from "~/components";
import {
	GroupRadioField,
	SelectField,
	DateField
} from "~/components/Forms";
import {LIST_FORM_STATUS} from "~/app/constants";
import {
	renderDate,
	numberFormat,
} from "~/utils";

function FormPaymentInfo({item, can, categories, onHandleSubmit}) {

	const paymentOptions = [
		{ value: 0, label: 'Chuyển khoản'},
		{ value: 1, label: 'Tiền mặc'},
	];

	const statusOptions = useMemo(() => {
		return [...Object.entries(LIST_FORM_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_FORM_STATUS]);

	const categoriesOptions = useMemo(() => {
		return [...Object.entries(categories).map(([key, item]) => {
			return {
				value: item.id,
				label: item.name
			};
		})];
	}, [categories]);

	let initialValues = {
		time: new Date(),
		categoryId: undefined,
		payType: 0
	}

	if(can.statusManager) initialValues = {...initialValues, statusManager : ''}
	if(can.statusAssign) initialValues = {...initialValues, statusAssign : ''}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues
	});

	useEffect(() => {
		if(item?.id && can.status) {
			if(can.statusManager) initialValues.statusManager  = item.statusManager;
			if(can.statusAssign) {
				initialValues.statusAssign   = item.statusAssign;
				initialValues.payType   = item.payType;
			}
		}
		reset(initialValues);
	}, [item]);

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Thông tin ứng lương</p>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Người làm đơn:</Col>
				<Col className="gutter-row" span={18}>{`${item.user.firstname} ${item.user.lastname}`}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Ngày ứng:</Col>
				<Col className="gutter-row" span={18}>{`${renderDate(item.time)}`}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Số tiền:</Col>
				<Col className="gutter-row" span={18}><b>{`${numberFormat(item.price)}`}</b></Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Lý do:</Col>
				<Col className="gutter-row" span={18}>{item.note}</Col>
			</Row>
			<p className="heading">Thông tin ngân hàng</p>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Số tài khoản:</Col>
				<Col className="gutter-row" span={18}>{item.bankNumber}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tên chủ tài khoản:</Col>
				<Col className="gutter-row" span={18}>{item.bankName}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Tên ngân hàng:</Col>
				<Col className="gutter-row" span={18}>{item.bank}</Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Chi nhánh:</Col>
				<Col className="gutter-row" span={18}>{item.bankBranch}</Col>
			</Row>

			<p className="heading">Các cấp</p>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Quản lý:</Col>
				<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusManager].color}>{LIST_FORM_STATUS[item.statusManager].label}</StatusLabel></Col>
			</Row>
			<Row className="mb-2" gutter={10}>
				<Col className="gutter-row" span={6}>Kế toán:</Col>
				<Col className="gutter-row" span={18}><StatusLabel small type={LIST_FORM_STATUS[item.statusAssign].color}>{LIST_FORM_STATUS[item.statusAssign].label}</StatusLabel></Col>
			</Row>
			{
				(item.status == 'pending' && can.statusManager) &&
				<Fragment>
					<p className="heading">Duyệt thông tin (Quản lý)</p>
					<Controller control={control} name="statusManager" render={({field}) => (
						<GroupRadioField options={statusOptions} errors={errors} {...field}/>
					)}/>
				</Fragment>
			}
			{
				(item.status == 'pending' && can.statusAssign) &&
				<Fragment>
					<p className="heading">Duyệt thông tin (Kế toán)</p>
					<Controller control={control} name="statusAssign" render={({field}) => (
						<GroupRadioField options={statusOptions} errors={errors} {...field}/>
					)}/>
					<Row gutter={10}>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="time" render={({ field }) => (
								<DateField label="Ngày chi" errors={errors} {...field}/>
							)}/>
						</Col>
						<Col className="gutter-row" span={12}>
							<Controller control={control} name="categoryId" render={({ field }) => (
								<SelectField label="Loại chi" options={categoriesOptions} errors={errors} {...field}/>
							)}/>
						</Col>
					</Row>
					<Controller control={control} name="payType" render={({ field }) => (
						<GroupRadioField label="Thanh toán" options={paymentOptions} errors={errors} {...field}/>
					)}/>
				</Fragment>
			}
			{
				(item.status == 'pending' && (can.statusManager || can.statusAssign)) && <div className="form-group d-flex justify-content-end modal-bottom">
					<Button primary type="submit">Duyệt đơn</Button>
				</div>
			}
		</form>
	)
}

export default FormPaymentInfo;