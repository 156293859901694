import request from "~/utils/http";

const expenseApi = {
	gets : async (params) => {
		const url = 'expenses';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'expenses';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `expenses/${params.id}`;
		return await request.patch(url, params);
	},
	delete : async (id) => {
		const url = `expenses/${id}`;
		return await request.delete(url);
	},

	groupGets: async (params) => {
		const url = 'expenses/groups';
		return await request.get(url, {params});
	},
	groupAdd : async (params) => {
		const url = 'expenses/groups';
		return await request.post(url, params);
	},
	groupUpdate : async (params) => {
		const url = `expenses/groups/${params.id}`;
		return await request.patch(url, params);
	},
	groupDelete : async (id) => {
		const url = `expenses/groups/${id}`;
		return await request.delete(url);
	},

	eventGets: async (params) => {
		const url = 'expenses/events';
		return await request.get(url, {params});
	},
	eventAdd : async (params) => {
		const url = 'expenses/events';
		return await request.post(url, params);
	},
	eventUpdate : async (params) => {
		const url = `expenses/events/${params.id}`;
		return await request.patch(url, params);
	},
	eventDelete : async (id) => {
		const url = `expenses/events/${id}`;
		return await request.delete(url);
	},

};

export default expenseApi;