import style from './Notification.module.scss';
import className from 'classnames/bind';
import {useEffect, useRef} from "react";
import Header from "~/components/Notification/Header";

const cn = className.bind(style);

function Notification({children, title, description, icon, show = false, outSide = () => {}, onClose}) {

	const ref = useRef(null);

	if(!onClose) onClose = outSide;

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				outSide();
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	return (<div ref={ref} className={cn('notification', (show) ? 'active' : '')}>
		<Header icon={icon} title={title} description={description} onClose={onClose}/>
		<div className={cn('wrapper')}>{children}</div>
	</div>);
}

export default Notification;