import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
    Loading,
    Modal,
    Button,
    Icon,
} from "~/components";
import {
    apiError,
    handleRequest
} from "~/utils";
import {
    useCan
} from "~/hooks";
import {
    customerGroupApi
} from "~/api";
import {
    customerGroupActions,
    customerGroupErrorSelector,
    customerGroupFilterSelector,
    customerGroupItemsSelector,
    customerGroupLoadingSelector,
    customerGroupPaginationSelector
} from "../customerGroupSlice";
import {
    CustomerGroupTable,
    CustomerGroupFormAddEdit
} from "../components";

function CustomerGroup() {

    const can = {
        add: useCan('customerGroupAdd'),
        edit: useCan('customerGroupEdit'),
        delete: useCan('customerGroupDelete'),
    };

    const dispatch  = useDispatch();

    const items     = useSelector(customerGroupItemsSelector);

    const loading   = useSelector(customerGroupLoadingSelector);

    const error     = useSelector(customerGroupErrorSelector);

    const pagination = useSelector(customerGroupPaginationSelector);

    const filter    = useSelector(customerGroupFilterSelector);

    const [itemEdit, setItemEdit] = useState({});

    const [loadingDelete, setLoadingDelete] = useState(false);

    //Model
    const [openModal, setOpenModal] = useState({
        add         : false,
        edit        : false,
        delete      : false,
    });

    const handleModalOpen = (modal) => {
        openModal[modal] = true;
        setOpenModal({...openModal})
    }

    const handleModalClose = (modal) => {
        openModal[modal] = false;
        setOpenModal({...openModal});
    }

    //Load data
    useEffect(() => {
        dispatch(customerGroupActions.fetchData(filter));
    }, [filter]);

    //Show Error
    if (error) {
        notification.error({message: 'Lỗi', description: error});
    }

    //Submit
    const handleSaveItem = async (data, item) => {
        let messageAction = 'Thêm mới';
        if(item?.id) {
            data.id = item.id;
            messageAction = 'Cập nhật';
        }
        let [error, response] = (item?.id) ? await handleRequest(customerGroupApi.update(data)) : await handleRequest(customerGroupApi.add(data));
        let message = apiError(`${messageAction} group thất bại`, error, response);
        if(!message) {
            notification.success({message: 'Thành công', description: `${messageAction} group thành công`});
            if(!item?.id) {
                dispatch(customerGroupActions.add(response.data));
            }
            else {
                dispatch(customerGroupActions.update(response.data));
            }
            handleModalClose('addEdit')
        }
    }

    //Delete
    const handleDelete = async () => {
        setLoadingDelete(true);
        if (!itemEdit?.id) {
            setLoadingDelete(false);
            notification.error({message: 'Lỗi', description: 'Không có thông tin group để xóa'});
            return;
        }
        let [error, response] = await handleRequest(customerGroupApi.delete(itemEdit.id));
        let message = apiError(`xóa dự án thất bại`, error, response);
        if(!message) {
            notification.success({message: 'Thành công', description: `Group cầu thành công`});
            dispatch(customerGroupActions.delete(itemEdit.id));
            handleModalClose('delete')
        }
        setLoadingDelete(false);
    }
    //Search
    const handlePaginationChange = (page) => {
        dispatch(customerGroupActions.setFilter({...filter, page }));
    }

    const handleFilterChange = (key, value) => {
        const newFilter = {
            ...filter,
            [key]: value,
            page: 1
        };
        dispatch(customerGroupActions.setFilter(newFilter));
    };

    return (
        <Fragment>
            <ActionBar title={'Nhóm Khách Hàng'}>
                {can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({}); handleModalOpen('addEdit')}}>Thêm mới</Button>}
                <Button outline blue to="/customer">{Icon.user}</Button>
                <Button background blue to="/customer/group">{Icon.users}</Button>
            </ActionBar>
            <div className="container">
                <div className="content">
                    {loading && <Loading/>}
                    {items && <CustomerGroupTable
                        items={items}
                        pagination={pagination}
                        onPaginationChange={handlePaginationChange}
                        setItemEdit={setItemEdit}
                        openModal={handleModalOpen}
                        onChangeFilter={handleFilterChange}
                    />}
                </div>
            </div>
            {
                (can.add || can.edit) &&
                <Modal title="Group" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
                    <CustomerGroupFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
                </Modal>
            }
            {
                (can.delete && itemEdit?.id) &&
                <Modal title="Xóa group" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
                    <p>Bạn muốn xóa group <b>{itemEdit?.name}</b>?</p>
                    <br />
                    <div className="d-flex justify-content_end gap">
                        <Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
                        <Button loading={loadingDelete} primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
                    </div>
                </Modal>
            }
        </Fragment>
    )
}
export default CustomerGroup;