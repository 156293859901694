import {useCallback} from "react";
import {
	DateField,
	InputField
} from "~/components/Forms";

function ExtendSearchBar({filter, onSearchChange, onChange}) {

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleTimeChange = useCallback((value) => {
		if (!isNaN(value)) {
			value = Math.round(new Date(+value).getTime() / 1000)
		} else if (value.search('GMT') != -1) {
			value = Math.round(new Date(value).getTime() / 1000)
		}
		if(onChange) onChange('time', value);
	}, [onChange]);

	return (
		<div className="search-bar">
			<div className="container">
				<form className="form" autoComplete="off">
					<InputField placeholder="Tên miền" onChange={handleKeywordChange}/>
					<DateField name="time" picker="month" onChange={handleTimeChange}/>
				</form>
			</div>
		</div>
	)
}

export default ExtendSearchBar;