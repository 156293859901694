import style from '../Home.module.scss';
import className from 'classnames/bind';
import {Image, StatusLabel} from "../../../components";
import {numberFormat} from "../../../utils";
const cn = className.bind(style);

const CardRanksDesign = ({name, items, cardColor, bgImage}) => {
	return (
		<div className={cn('card', 'card-ranks', cardColor)}>
			<h4 className={cn('heading')}>{name}</h4>
			<div className={cn('list-ranks')}>
				{
					items.map((item) => {
						return <div key={`card-rank-${item.id}`} className={cn('user', 'user-white', 'd-flex', 'gap', 'align-items')}>
							<div className={cn('avatar-rank', 'avatar')}><Image src={item.avatar} /></div>
							<div>
								<b className={cn('name')}>{item.firstname} {item.lastname}</b>
								<p className={cn('info-more')}>{item.username}</p>
							</div>
							<div>
								<StatusLabel type="yellow" small>Đa nhận {numberFormat(item.assign)}</StatusLabel>
								<StatusLabel type="green" className="ml-1" small>Hoàn thành {numberFormat(item.success)}</StatusLabel>
							</div>
						</div>
					})
				}
			</div>
			<div className={cn('bg')}><Image src={bgImage} /></div>
		</div>
	)
}

export default CardRanksDesign