import request from "~/utils/http";

const uploadWebsiteApi = {
	gets : async (params) => {
		const url = 'upload-website';
		return await request.get(url, {params});
	},
	add : async (params) => {
		const url = 'upload-website';
		return await request.post(url, params);
	},
	update : async (params) => {
		const url = `upload-website/${params.id}`;
		return await request.patch(url, params);
	},
	deadline : async (params) => {
		const url = `upload-website/deadline/${params.id}`;
		return await request.post(url, params);
	},
	host : async (params) => {
		const url = `upload-website/host/${params.id}`;
		return await request.post(url, params);
	},
	source : async (params) => {
		const url = `upload-website/source`;
		return await request.post(url, params);
	},
	status : async (params) => {
		const url = `upload-website/status/${params.id}`;
		return await request.post(url, params);
	},
	addHost : async (params) => {
		const url = `upload-website/add-host/${params.id}`;
		return await request.post(url, params);
	},
	delete : async (id) => {
		const url = `upload-website/${id}`;
		return await request.delete(url);
	}
};

export default uploadWebsiteApi;