import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {serviceActions} from "./serviceSlice";
import {serviceApi} from "~/api";

function* fetchServiceList(action) {
	try {
		const response = yield call(serviceApi.gets, action.payload);
		yield put(serviceActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch service list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(serviceActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(serviceActions.setFilter(action.payload));
}

export default function* serviceSaga() {
	yield takeLatest(serviceActions.fetchData.type, fetchServiceList);
	yield debounce(500, serviceActions.setFilterWithDebounce.type, handleSearchDebounce)
}