import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import store from "./app/store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from "./GlobalStyles";
import AppProvider from "~/context/AppProvider";
import { ConfigProvider } from "antd";
import vi_VN from "antd/lib/locale/vi_VN";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <GlobalStyles>
            <Provider store={store}>
                <ConfigProvider locale={vi_VN}>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </ConfigProvider>
            </Provider>
        </GlobalStyles>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
