import {useMemo, useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Col, Row} from "antd";
import {
	Button,
	Loading
} from "~/components";
import {
	InputField,
	TextAreaField,
	TwitterPickerField
} from "~/components/Forms";

function FormPapersTypeAdd({onHandleSubmit}) {

	const initialValues = {
		name: undefined,
		color: '#000',
		numberFirst: 0,
		numberCurrent:0,
		note:undefined
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, setValue, reset } = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(Yup.object().shape({
			name: Yup.string().required('Bạn chưa điền tên loại đơn'),
			numberFirst: Yup.number().required('Bạn chưa điền số bắt đầu'),
			numberCurrent: Yup.string().required('Bạn chưa điền số kết thúc'),
		}))
	});

	const onSubmit = async (data) => {
		await onHandleSubmit(data);
	}

	return (
		<form className="form" onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<Controller control={control} name="name" render={({ field }) => (
				<InputField label="Tên loại giấy tờ"  errors={errors} {...field}/>
			)}/>
			<Controller control={control} name="color" render={({ field }) => (
				<TwitterPickerField
					label="Màu giấy tờ"
					width="100%"
					colors={[
						'#000000',
						'#440000', '#6C0000', '#860000', '#A30000', '#BE0000', '#D90000', '#F30101', '#FF0000', '#FE1F1F', '#FF3C3C', '#FF5B5B',
						'#59002C', '#79003B', '#8F0046', '#AB0054', '#C50060', '#DD006C', '#F30077', '#FF007D', '#FF1989', '#FF3698', '#FF4FA5', '#FF6DB4',
						'#02004B', '#030061', '#030081', '#04009A', '#0500B0', '#0500CA', '#0600E1', '#0700F8', '#1C16FF', '#3832FF', '#504BFF', '#736FFF',
						'#003C4D', '#004C61', '#005F7A', '#00789A', '#0091BB', '#00A4D2', '#00B5E9', '#00C7FF', '#17CCFF', '#32D2FF', '#44D6FF', '#63DCFF',
						'#005444', '#006D58', '#00836A', '#01977B', '#01AF8E', '#02C9A3', '#02DFB5', '#00FFCF', '#16FACF', '#31FBD5', '#4FFFDE', '#73FFE5',
						'#004004', '#005506', '#006E08', '#008109', '#009A0B', '#00BE0D', '#00D90F', '#00EE10', '#00FF11', '#1AFF2A', '#34FF42', '#5BFF66',
						'#464800', '#656800', '#787B00', '#939701', '#AFB401', '#C9CE02', '#DFE501', '#F8FF00', '#F9FF13', '#F9FF27', '#FAFF44', '#F4F85E',
						'#4B2E00', '#694100', '#814F00', '#A36400', '#BC7400', '#D58300', '#EB9100', '#FF9D00', '#FFA515', '#FFAD2A', '#FFB33A', '#FFBC51',
						'#592C00', '#874200', '#A85200', '#C56000', '#D96A00', '#F87A00', '#FF7D00', '#FF8C1D',
					]}
					errors={errors}
					{...field}
				/>
			)}/>

			<Row gutter={10}>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="numberFirst" render={({ field }) => (
						<InputField label="Số bắt đầu" errors={errors} {...field}/>
					)}/>
				</Col>
				<Col className="gutter-row" span={12}>
					<Controller control={control} name="numberCurrent" render={({ field }) => (
						<InputField label="Số hiện tại" errors={errors} {...field}/>
					)}/>
				</Col>
			</Row>
			<Controller control={control} name="note" render={({ field }) => (
				<TextAreaField label="Ghi chú" errors={errors} {...field}/>
			)}/>
			<hr />
			<div className="form-group d-flex justify-content-end modal-bottom">
				<Button primary type="submit">Lưu thông tin</Button>
			</div>
		</form>
	)
}

export default FormPapersTypeAdd;