import style from '../Home.module.scss';
import {useCallback, useEffect, useState} from "react";
import className from 'classnames/bind';
import _, {ceil} from "lodash";
import {Col, Row, Tooltip} from "antd";
import images from "~/assets/images";
import {Image} from "~/components";
import {
	apiError,
	handleRequest,
	numberFormat
} from "~/utils";
import {
	dashboardApi
} from "~/api";
import {
	CardToast,
	ContentHeader,
	SidebarAccount
} from "../componets";
import {
	SalePointGroup
} from "../../Report/components";

const cn = className.bind(style);

function DashboardSaleManager({user}) {

	const [dashboardData, setDashboardData] = useState({
		revenue: { all: 0, plus: 0, commit: 0 },
		revenueGroup: { all: 0, plus: 0, commit: 0, users : []},
		revenueRankHigh: [],
		contracts: [],
		customer: { all: 0, confirm: 0, allGroup: 0, confirmGroup: 0 },
		saleCommit: { commit: 0, result: 0, commitGroup: 0, resultGroup: 0 },
		salePoint: [],
		kpi: 0,
	});

	useEffect(() => {
		fetchDashboardData().then()
	}, [user]);

	const fetchDashboardData = useCallback(async () => {
		const [
			revenueResponse,
			customerResponse,
			customerGroupResponse,
			contractResponse,
			saleCommitResponse,
			salePointResponse,
			revenueRankHighResponse,
			revenueGroupResponse,
			kpiResponse,
		] = await Promise.all([
			handleRequest(dashboardApi.revenue()),
			handleRequest(dashboardApi.customer()),
			handleRequest(dashboardApi.customerGroup()),
			handleRequest(dashboardApi.contractNew()),
			handleRequest(dashboardApi.saleCommit()),
			handleRequest(dashboardApi.salePoint()),
			handleRequest(dashboardApi.revenueRanks()),
			handleRequest(dashboardApi.revenueGroup()),
			handleRequest(dashboardApi.saleKpi()),
		]);
		//Doanh số
		let [revenueErr, revenueData] = revenueResponse;
		let revenueMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueErr, revenueData);
		if (!revenueMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenue: {
					all: revenueData.data.all,
					plus: revenueData.data.plus,
					commit: revenueData.data.commit,
				},
			}));
		}

		//Doanh số nhóm
		let [revenueGroupErr, revenueGroupData] = revenueGroupResponse;
		let revenueGroupMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueGroupErr, revenueGroupData);
		if (!revenueGroupMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenueGroup: {
					all: revenueGroupData.data.all,
					plus: revenueGroupData.data.plus,
					commit: revenueGroupData.data.commit,
					users: revenueGroupData.data.users,
				},
			}));
		}

		//Khách hàng
		let [customerErr, customerData] = customerResponse;
		let customerMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, customerErr, customerData);
		if (!customerMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				customer: {
					...prevState.customer,
					all: customerData.data.all,
					confirm: customerData.data.confirm,
				},
			}));
		}

		let [customerGroupErr, customerGroupData] = customerGroupResponse;
		let customerGroupMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, customerGroupErr, customerGroupData);
		if (!customerGroupMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				customer: {
					...prevState.customer,
					allGroup: customerGroupData.data.allGroup,
					confirmGroup: customerGroupData.data.confirmGroup,
				},
			}));
		}

		//Hợp đồng
		let [contractErr, contractData] = contractResponse;
		let contractMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, contractErr, contractData);
		if (!contractMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				contracts: contractData.data,
			}));
		}

		//Cam kết
		let [saleCommitErr, saleCommitData] = saleCommitResponse;
		let saleCommitMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, saleCommitErr, saleCommitData);
		if (!saleCommitMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				saleCommit: {
					commit: saleCommitData.data.commit,
					result: saleCommitData.data.result,
					commitGroup: saleCommitData.data.commitGroup,
					resultGroup: saleCommitData.data.resultGroup,
				},
			}));
		}

		//Điểm làm việc
		let [salePointErr, salePointData] = salePointResponse;
		let salePointMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, salePointErr, salePointData);
		if (!salePointMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				salePoint: salePointData.data,
			}));
		}

		//Xếp hạng
		let [revenueRankHighErr, revenueRankHighData] = revenueRankHighResponse;
		let revenueRankHighMsg = apiError(`Load danh sách nhân viên kỹ thuật thất bại`, revenueRankHighErr, revenueRankHighData);
		if (!revenueRankHighMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				revenueRankHigh: revenueRankHighData.data,
			}));
		}

		//Kpi
		let [kpiErr, kpiData] = kpiResponse;
		let kpiMsg = apiError(`Load thông tin kpi thất bại`, kpiErr, kpiData);
		if (!kpiMsg) {
			setDashboardData((prevState) => ({
				...prevState,
				kpi: kpiData.data.commit,
			}));
		}
	}, [])

	const CardCount = ({image, heading, number1, number2, number3, txt1, txt2, txt3, txtSize}) => {
		return (
			<div className={cn('card', 'card-while', 'pd-1')}>
				<div className={cn('icon')}><Image src={image} /></div>
				<div className={cn('info')}>
					<p className={cn('heading-mini')}>{heading}</p>
					<p className={cn('number', txtSize)}>
						{(typeof number1 != 'undefined') && <Tooltip title={txt1}><span className="color-red">{numberFormat(number1)}</span></Tooltip>}
						{(typeof number2 != 'undefined') && <Tooltip title={txt2}><span> / {numberFormat(number2)}</span></Tooltip>}
						{(typeof number3 != 'undefined') && <Tooltip title={txt3}><span> / {numberFormat(number3)}</span></Tooltip>}
					</p>
				</div>
			</div>
		)
	}

	const CardRanks = ({name, items, cardColor}) => {
		return (
			<div className={cn('card', 'card-ranks', cardColor)}>
				<h4 className={cn('heading')}>{name}</h4>
				<div className={cn('list-ranks')}>
					{
						items.map((item) => {
							return <div key={`card-rank-${item.id}`} className={cn('user', 'd-flex', 'gap', 'align-items')}>
								<div className={cn('avatar-rank', 'avatar')}><Image src={item.avatar} /></div>
								<div>
									<b className={cn('name', 'color-white')}>{item.firstname} {item.lastname}</b>
									<p className={cn('info-more', 'color-white')}>{item.username}</p>
									<p className={cn('revenue', 'text-left')}><b>{numberFormat(item.total)}</b></p>
								</div>
							</div>
						})
					}
				</div>
			</div>
		)
	}

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
				<h4 className={cn('heading')}>Thông tin cá nhân</h4>
				<CardCount image={images.dashboard06}
				           heading={'Doanh số'}
				           number1={dashboardData.revenue.all} txt1={'Tất cả'}
				           number2={dashboardData.revenue.plus} txt2={'Tính lương'}
				           number3={dashboardData.revenue.commit} txt3={'Cam kết'}
				           txtSize={'text-sm'}
				/>
				<CardCount image={images.dashboard07}
				           heading={'Khách hàng'}
				           number1={dashboardData.customer.confirm} txt1={'Đã duyệt'}
				           number2={dashboardData.customer.all} txt2={'Tất cả'}
				           txtSize={'text-sm'}
				/>
				<CardCount image={images.dashboard09}
				           heading={'Cam kết 3 ngày'}
				           number1={dashboardData.saleCommit.result} txt1={'Kết quả'}
				           number2={dashboardData.saleCommit.commit} txt2={'Cam kết'}
				           txtSize={'text-sm'}
				/>
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} />
				<Row gutter={20}>
					<Col xs={24} md={6}>
						<CardCount
							image={images.dashboard06}
				            heading={'Doanh số'}
				            number1={dashboardData.revenueGroup.all} txt1={'Tất cả'}
				            number2={dashboardData.revenueGroup.plus} txt2={'Tính lương'}
				            number3={dashboardData.revenueGroup.commit} txt3={'Cam kết'}
							txtSize={'text-base'}
						/>
					</Col>
					<Col xs={24} md={6}>
						<CardCount
							image={images.dashboard07}
						    heading={'Khách hàng'}
						    number1={dashboardData.customer.confirmGroup} txt1={'Đã duyệt'}
						    number2={dashboardData.customer.allGroup} txt2={'Tất cả'}
						/>
					</Col>
					<Col xs={24} md={6}>
						<CardCount
							image={images.dashboard09}
							heading={'Cam kết 3 ngày'}
							number1={dashboardData.saleCommit.resultGroup} txt1={'Kết quả'}
							number2={dashboardData.saleCommit.commitGroup} txt2={'Cam kết'}
						/>
					</Col>
					<Col xs={24} md={6}>
						<div className={cn('card', 'card-while', 'pd-1')}>
							<div className={cn('icon')}><Image src={images.dashboard08} /></div>
							<div className={cn('info')}>
								<p className={cn('heading-mini')}>{'KPI đến hôm nay'}</p>
								<p className={cn('number', 'text-base')}>
									<Tooltip title={'Đã đạt'}><span className="color-red">{numberFormat(dashboardData.revenueGroup.all)}</span></Tooltip>
									<Tooltip title={'Cam kết'}><span> / {numberFormat(dashboardData.kpi)}</span></Tooltip>
									<Tooltip title={'Phần trăm'}><span> / {ceil(dashboardData.revenueGroup.all/dashboardData.kpi*100) + '%'}</span></Tooltip>
								</p>
							</div>
						</div>
					</Col>
					<Col xs={24} md={7}>
						<CardRanks
							name={'Top 10 doanh số cao nhất'}
							items={dashboardData.revenueRankHigh}
							cardColor={'card-blue'}
							bgImage={images.dashboard01}
						/>
					</Col>
					<Col xs={24} md={7}>
						<CardRanks
							name={'Doanh số phòng'}
							items={dashboardData.revenueGroup.users}
							cardColor={'card-theme'}
							bgImage={images.dashboard01}
						/>
					</Col>
					<Col xs={24} md={10}>
						<div className={cn('card', 'card-block', 'card-while', 'pd-1')}>
							<h4 className={cn('heading')}>Điểm</h4>
							{!_.isEmpty(dashboardData.salePoint) && <SalePointGroup item={dashboardData.salePoint} />}
						</div>
						<CardToast items={dashboardData.contracts} />
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default DashboardSaleManager;