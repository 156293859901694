import style from "./PersonelForm.module.scss";
import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import className from "classnames/bind";
import * as Yup from "yup";
import Button from "~/components/Button";
import Loading from "~/components/Loading";
import Image from "~/components/Image";
import {GroupCheckBoxField} from "~/components/Forms";
import {userApi} from "~/api";
const cn = className.bind(style);
function PersonnelFormLeader({item, onHandleSubmit}) {

	const [listUser, setListUser] = useState([]);

	const initialValues = {
		users: [],
	}

	const validationSchema = Yup.object().shape({
		users: Yup.array().required('Nhóm nhân viên không được để trống'),
	})

	const { control, handleSubmit, formState: {isSubmitting, errors}, reset} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		if (item?.id) {
			userApi.gets({group: item.group, role: 'sales'}).then(response => {
				setListUser(response.data);
			});

			initialValues.users = item.leaderUserList;
		}
		reset(initialValues);
	}, [item]);

	const userItem = (user) => {
		return (<div className="d-flex gap align-items">
			<div className="avatar"><Image src={user?.avatar} /></div>
			<div>
				<b>{user?.firstname + ' ' + user?.lastname}</b>
				<p>{user?.username}</p>
			</div>
		</div>)
	}
	const listUsersOption = listUser.reduce((users, user) => {
		users.push({
			label : userItem(user),
			value: user.id
		})
		return users;
	}, [])

	const onSubmit = async (data) => {
		await onHandleSubmit(data, item);
	}
	return (
		<form className={cn("form") + " form"} onSubmit={handleSubmit(onSubmit)}>
			{isSubmitting && <Loading/>}
			<p className="heading">Chọn Thành viên</p>
			{
				listUser &&
				<Controller control={control} name="users" render={({field }) => (
					<GroupCheckBoxField className={cn('checkbox-group')} errors={errors} {...field} options={listUsersOption}></GroupCheckBoxField>
				)}/>
			}
			<hr />
			<div className="d-flex justify-content_end">
				<Button primary type="submit">Cập nhật</Button>
			</div>
		</form>
	)
}
export default PersonnelFormLeader;