import style from './FileUpload.module.scss';
import className from 'classnames/bind';
import FontAwesomeIcon from "../../FontAwesome";
import {forwardRef} from "react";
const cn = className.bind(style);

const FileUpload = forwardRef(({ name, onChange, files, setFiles, ...props }, ref) => {

	const uploadHandler = (event) => {

		const fileList = event.target.files;

		if(!fileList) return;

		// upload file
		const formData = new FormData();

		for (const [i, file] of Object.entries(fileList)) {
			formData.append(`files[${i}]`, file, file.name);
		}

		onChange(formData)

		setFiles([...files, ...fileList])
	}

	return (
		<>
			<div className={cn("file-card")}>
				<div className={cn("file-inputs")}>
					<input name={name} type="file" onChange={uploadHandler} {...props} ref={ref} multiple />
					<button><span><FontAwesomeIcon icon="fa-light fa-plus" /></span> Upload</button>
				</div>
			</div>
		</>
	)
});

export default FileUpload