import className from 'classnames/bind';
import style from './Menu.module.scss';
import MenuItems from "~/components/Menu/MenuItems";

const cn = className.bind(style);

function Dropdown({ submenus, depthLevel, className }) {
	depthLevel = depthLevel + 1;
	return (
		<ul className={cn('dropdown', className)}>
			{submenus.map((submenu, index) => (
				<MenuItems key={index} depthLevel={depthLevel} items={submenu} />
			))}
		</ul>
	);
}

export default Dropdown;