import style from "../style/Info.module.scss";
import className from "classnames/bind";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {apiError, handleRequest} from "../../../utils";
import {userApi} from "../../../api";
import {Image} from "../../../components";
import {useUtilities} from "../../../hooks";
const cn = className.bind(style);
function Info() {
	const {utilities} = useUtilities();

	const {username, token} = useParams();

	const [user, setUser] = useState({});
	
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		loadUser().then();
	}, [username, token])
	
	const loadUser = async () => {
		setLoading(true);
		let [error, response] = await handleRequest(userApi.info({
			username, token
		}));
		let message = apiError(`Lấy thông tin báo cáo thất bại`, error, response);
		if (!message) {
			setUser(response.data)
		}
		setLoading(false);
	}

	return (
		<>
			<div className={cn("user-information")}>
				<div className={cn("container")}>
					<div className={cn("user-information-header")}>
						<div className={cn("user-header-content")}>
							<p className={cn("txt-hello")}>Xin chào, tôi là</p>
							<p className={cn("txt-name")}>{user?.firstname} <strong>{user?.lastname}</strong>
							</p>
							<p className={cn("txt-office")}>{utilities.roles[user.role]?.title}</p>
							<div className={cn("item-list")}>
								<div className={cn("item")}>
									<div className={cn("img")}></div>
									<div className={cn("title")}>Sáng tạo</div>
									<div className={cn("des")}>Là “chìa khóa” cốt lõi tạo ra những sản phẩm website hoàn hảo và hiệu quả.</div>
								</div>
								<div className={cn("item")}>
									<div className={cn("img")}></div>
									<div className={cn("title")}>Sức trẻ</div>
									<div className={cn("des")}>Nội lực “đặc biệt" này giúp cho chúng tôi luôn giữ vững phong độ chinh phục mọi thử thách.</div>
								</div>
								<div className={cn("item")}>
									<div className={cn("img")}></div>
									<div className={cn("title")}>Sự chuyên nghiệp</div>
									<div className={cn("des")}>Từ những chi tiết nhỏ nhất. Được tạo ra bởi phong thái làm việc, tiếp xúc khách hàng và sản xuất dự án.</div>
								</div>
								<div className={cn("item")}>
									<div className={cn("img")}></div>
									<div className={cn("title")}>Đam mê</div>
									<div className={cn("des")}>Chính là khởi nguồn tạo ra Sikido và ngọn lửa đam mê này sẽ tiếp tục được duy trì mãi mãi.</div>
								</div>
							</div>
						</div>
						<div className={cn("user-header-avatar")}>
							<div className={cn("user-avatar-border")}>
								<div className={cn("circle")}><Image src={"https://sikido.vn/uploads/source/header/logo-(1).png"} /></div>
								<div className={cn("user-img")}>
									<Image src={user?.avatar} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={cn("user-information-content")}>
				<div className={cn("user-content")}>
					<p className={cn("txt-company text-center")}>CÔNG TY TNHH TRUYỀN THÔNG & QUẢNG CÁO</p>
					<p className={cn("txt-company-name text-center")}>SIÊU KINH DOANH</p>
					<div className={cn("info")}>
						<div className={cn("info-title")}>MSNV:</div>
						<div className={cn("info-content")}>{user?.username}</div>
					</div>
					<div className={cn("info")}>
						<div className={cn("info-title")}>PHONE:</div>
						<div className={cn("info-content")}>{user?.phone}</div>
					</div>
					<div className={cn("info")}>
						<div className={cn("info-title")}>EMAIL:</div>
						<div className={cn("info-content")}>{user?.email}</div>
					</div>
					<div className={cn("info")}>
						<div className={cn("info-title")}>ĐỊA CHỈ:</div>
						<div className={cn("info-content")}>36 D5, Phường 25, Quận Bình Thạnh, TP.HCM</div>
					</div>
					<div className={cn("info")}>
						<div className={cn("info-title")}>PHÒNG BAN:</div>
						<div className={cn("info-content")}>{utilities.departments[user?.department]?.title}</div>
					</div>
				</div>
			</div>
			<div className={cn("header")}>
				<div>
					<svg className={cn("waves")} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
						<defs>
							<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
						</defs>
						<g className={cn("parallax")}>
							<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)"/>
							<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
							<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
							<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"/>
						</g>
					</svg>
				</div>
			</div>
		</>
	)
}

export default Info;