import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {formSalaryActions} from "./formSalarySlice";
import {formSalaryApi} from "~/api";

function* fetchFormSalaryList(action) {
	try {
		const response = yield call(formSalaryApi.gets, action.payload);
		yield put(formSalaryActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch formSalary list', error);
		yield put(formSalaryActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(formSalaryActions.setFilter(action.payload));
}

export default function* formSalarySaga() {
	yield takeLatest(formSalaryActions.fetchData.type, fetchFormSalaryList);
	yield debounce(500, formSalaryActions.setFilterWithDebounce.type, handleSearchDebounce)
}