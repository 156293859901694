import {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	InputField,
	SelectUserAll
} from "~/components/Forms";
import {
	Button,
	Icon,
	Notification,
	StatusLabel
} from "~/components";
import {
	LIST_PAPER_NUMBER_STATUS
} from "~/app/constants";

function PapersNumberSearchMobile({listGroup, filter, onChange, onSearchChange}) {

	const [searchBar, setSearchBar] = useState(false);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_PAPER_NUMBER_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_PAPER_NUMBER_STATUS]);

	const groupOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả loại đơn' }, ...Object.entries(listGroup).map(([key, item]) => {
			return {value : item.id, label : item.name};
		})];
	}, [listGroup]);

	const handleKeywordChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const onSubmit = (data) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			keyword: data.keyword,
			type: data.type,
			status: data.status,
			userId: data.userId,
			page: 1,
		};
		setSearchBar(false);
		onChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<InputField name="keyword" placeholder="Mã đơn" onChange={handleKeywordChange} />
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="keyword" render={({ field }) => (
						<InputField label="Mã đơn" errors={errors} {...field} />
					)}/>
					<Controller control={control} name="userId" render={({field}) => (
						<SelectUserAll label="Nhân viên" options={[{value:'', label:'Tất cả'}]} errors={errors} {...field} />
					)}/>
					<Controller control={control} name="type" render={({field}) => (
						<SelectField label="Chọn loại đơn" options={groupOptions} errors={errors} {...field} />
					)}/>
					<Controller control={control} name="status" render={({ field }) => (
						<SelectField label="Trạng thái" options={statusOptions} errors={errors} {...field}/>
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default PapersNumberSearchMobile;