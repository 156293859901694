import style from '../Home.module.scss';
import {memo} from "react";
import className from 'classnames/bind';
import {
	ContentHeader,
	SidebarAccount
} from "../componets";

const cn = className.bind(style);

function DashboardDefault({user, fullTime}) {

	return (
		<div className={cn('dashboard')}>
			<div className={cn('sidebar')}>
				<SidebarAccount user={user} />
			</div>
			<div className={cn('content')}>
				<ContentHeader user={user} fullTime={fullTime} />
			</div>
		</div>
	)
}

export default memo(DashboardDefault);
