import {useCallback, useMemo} from "react";
import {
	DateField,
	SelectField
} from "~/components/Forms";
import {strToTime} from "../../../../../utils";

function SalePointSearch({filter, setFilter}) {

	const handleTimeChange = useCallback((value) => {
		setFilter({...filter, ...{time: strToTime(value)}})
	}, []);

	return (
		<div className="search-bar">
			<form className="form" autoComplete="off">
				<DateField name="time" onChange={handleTimeChange}/>
			</form>
		</div>
	)
}

export default SalePointSearch;