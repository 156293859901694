import {Button, Icon} from "~/components";
import {
	useCan
} from "~/hooks";

function CustomerGroupAction({item, onClickEdit, onClickDelete}) {

	const can = {
		edit: useCan('customerGroupEdit'),
		delete: useCan('customerGroupDelete'),
	};

	const buttonHandlers = {
		edit: () => {onClickEdit?.(item);},
		delete: () => {onClickDelete?.(item);},
	};

	const actionButtons = [
		can.edit && <Button key="edit" background blue leftIcon={Icon.edit} onClick={buttonHandlers.edit}></Button>,
		can.delete && <Button key="delete" background red leftIcon={Icon.delete} onClick={buttonHandlers.delete}></Button>,
	];

	return (
		<div className="table-action d-flex">
			{actionButtons}
		</div>
	)
}

export default CustomerGroupAction;