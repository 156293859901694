import {
	Fragment,
	useState,
	useEffect
} from "react";
import {
	authApi
} from "~/api";
import {
	handleRequest,
	apiError
} from "~/utils";
import ActionBar from "../../../layout/ActionBar";
import PermissionForm from "../components/Forms/PermissionForm";
import Button from "../../../components/Button";
import {notification} from "antd";

function Permission() {

	let [listRole, setListRole] = useState([]);

	useEffect(() => {
		(async () => {
			let [error, response] = await handleRequest(authApi.getPermission());
			let message = apiError(`Load danh sách quyền`, error, response);
			if (!message) setListRole(response.data);
		})()
	}, []);

	const handleSubmit = async (data) => {
		let permission = {
			role:data.role,
			permission: data
		}
		let [error, response] = await handleRequest(authApi.updatePermission(permission));
		let message = apiError(`Cập nhật thất bại`, error, response);
		if (!message) {
			notification.success({message: 'Thành công', description: `Cập nhật thành công`});
		}
	}

	return (
		<Fragment>
			<ActionBar title={'Vai trò'}>
				<Button primary type="submit" form="permissionForm">Cập nhật</Button>
			</ActionBar>
			<div style={{width:'100%'}}>
				{listRole?.permission && <PermissionForm onSubmit={handleSubmit} listRole={listRole} setListRole={setListRole} />}
			</div>
		</Fragment>
	)
}

export default Permission;