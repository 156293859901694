import {useEffect, useMemo, useRef, useState} from "react";
import {Select, Spin} from 'antd';
import debounce from 'lodash/debounce';
import {forwardRef} from "react";

const DebounceSelect = forwardRef(({ fetchOptions, name, value, label, placeholder, errors, onChange, optionsDefault = [], ...props }, ref) => {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState(optionsDefault);
	const fetchRef = useRef(0);
	useEffect(() => {
		optionsDefault && setOptions(optionsDefault)
	}, [optionsDefault])
	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			fetchOptions(value).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					return;
				}
				setOptions(newOptions);
				setFetching(false);
			});
		};
		return debounce(loadOptions, 500);
	}, [fetchOptions, 500]);
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<Select
				value={value}
				name={name}
				placeholder={placeholder}
				filterOption={false}
		        onSearch={debounceFetcher} showSearch={true}
		        notFoundContent={fetching ? <Spin size="small" /> : null}
		        dropdownStyle={{zIndex:10000}}
				onChange={onChange}
                options={options}
				ref={ref}
			/>
			{errors && errors[name]?.message && (<p className="error-message">{errors[name]?.message}</p>)}
		</div>
	)
});

export default DebounceSelect;