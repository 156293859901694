import axios from "axios";

import {memoizedRefreshToken} from "./refreshToken";

const axiosConfig = {
	baseURL: process.env.REACT_APP_SERVICE_URL,
	headers: {
		'Content-Type': 'application/json',
	}
}

const request = axios.create(axiosConfig)

request.interceptors.request.use(
	async (config) => {

		const storedToken = JSON.parse(localStorage.getItem('access_token'));

		const loginAsToken = localStorage.getItem('access_token_as');

		if (storedToken?.accessToken) {
			config.headers = {
				...config.headers,
				"Authorization": `Bearer ${storedToken.accessToken}`,
				"loginAsToken" : loginAsToken,
			};
		}

		return config;
	},
	(error) => Promise.reject(error)
);

request.interceptors.response.use(
	(response) => response.data,
	async (error) => {
		const config = error?.config;
		if (error?.response?.status === 401 && !config?.sent) {

			config.sent = true;

			const result = await memoizedRefreshToken();

			if (result?.accessToken) {
				config.headers = {
					...config.headers,
					authorization: `Bearer ${result?.accessToken}`,
				};
			}
			else {
				localStorage.clear();
			}

			return request(config);
		}
		return Promise.reject(error);
	}
);


export default request;