import {useState} from "react";
import Tippy from '@tippyjs/react/headless';
import {Tooltip} from "antd";
import {Button, Icon, PopperWrapper} from "~/components";

function ProjectAction({item, setItemEdit, openModal, can}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const buttonHandlers = {
		info: () => {
			setItemEdit(item);
			openModal('info');
		},
		edit: () => {
			setItemEdit(item);
			openModal('addEdit');
		},
		assign: () => {
			setItemEdit(item);
			openModal('assign');
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
		},
		install: () => {
			setItemEdit(item);
			openModal('install');
		},
	};

	const actionButtons = [
		can.edit && <Button key="edit" leftIcon={Icon.edit} onClick={buttonHandlers.edit}>Cập nhật thông tin</Button>,
		can.delete && <Button key="delete" leftIcon={Icon.delete} onClick={buttonHandlers.delete} outline red noneBorder> Xóa dự án</Button>,
	];

	return (
		<div className="table-action d-flex">
			<Tooltip title="Thông tin"><Button background blue leftIcon={Icon.info} onClick={buttonHandlers.info}></Button></Tooltip>
			{can.install && <Tooltip title="Cài đặt source"><Button background white leftIcon={Icon.setting} onClick={buttonHandlers.install}></Button></Tooltip>}
			{(can.assignDesign || can.assignDev) && <Button key="transfer" background green leftIcon={Icon.switch} onClick={buttonHandlers.assign}></Button>}
			{(can.edit || can.delete) && (
				<Tippy
					interactive placement="left" offset={[0, -10]}
					visible={showActionBox}
					onClickOutside={() => setShowActionBox(false)}
					render={() => (
						<PopperWrapper className="action-wrapper" style={{minWidth: '220px', }}>
							{actionButtons}
						</PopperWrapper>
					)}
				>
					<div onClick={() => setShowActionBox(!showActionBox)} className="action-btn">
						{Icon.menuVertical}
					</div>
				</Tippy>
			)}
		</div>
	)
}

export default ProjectAction;