import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {expenseGroupActions} from "./expenseGroupSlice";
import {expenseApi} from "~/api";

function* fetchExpenseGroupList(action) {
	try {
		const response = yield call(expenseApi.groupGets, action.payload);
		yield put(expenseGroupActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch expenseGroup list', error);
		yield put(expenseGroupActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(expenseGroupActions.setFilter(action.payload));
}

export default function* expenseGroupSaga() {
	yield takeLatest(expenseGroupActions.fetchData.type, fetchExpenseGroupList);
	yield debounce(500, expenseGroupActions.setFilterWithDebounce.type, handleSearchDebounce)
}