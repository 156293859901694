import {forwardRef} from "react";
import {TwitterPicker} from "react-color";

const TwitterPickerField = forwardRef(({ name, onChange, onBlur, label, value, placeholder, picker, errors, ...props }, ref) => {
	if(label && typeof placeholder == 'undefined') placeholder = label;
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<TwitterPicker
				name={name}
				onChange={onChange} onBlur={onBlur}
				color={value}
				className={((errors && errors[name]) && 'error') + " form-control"}
				placeholder={placeholder}
				picker={picker}
				{...props}
				ref={ref}
			/>
			{errors && errors[name]?.message && (<p className="error-message">{errors[name]?.message}</p>)}
		</div>
	)
});

export default TwitterPickerField;