import {forwardRef, useCallback, useEffect, useMemo} from "react";
import {userApi} from "~/api";
import {DebounceSelect} from "../index";
import SearchUserOption from "./SearchUserOption";

const SelectUserAll = forwardRef(({ options, setOptions, errors, ...props }, ref) => {

	const onSearch = useCallback(async (keyword) => {
		return handleSearch(keyword, props?.department, props?.status)
	}, [props]);

	const handleSearch = useCallback(async (keyword, department, status) => {
		const response = await userApi.search({keyword, department, status});
		response.data.unshift({ id: '', firstname: 'Tất cả', lastname: 'nhân viên' })
		return response.data.map((user) => ({
			label: <SearchUserOption item={user}/>,
			value: user.id,
		}))
	}, [])

	const optionsDefault = useMemo(() => options, [options]);

	useEffect(() => {
		if(typeof setOptions !== "undefined") {
			setOptions(optionsDefault)
		}
	}, [optionsDefault, setOptions]);

	return (
		<DebounceSelect fetchOptions={onSearch} errors={errors} optionsDefault={optionsDefault} {...props} ref={ref}/>
	)
});

export default SelectUserAll;