import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {revenueActions} from "./revenueSlice";
import {revenueApi} from "~/api";

function* fetchRevenueList(action) {
	try {
		const response = yield call(revenueApi.gets, action.payload);
		yield put(revenueActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch revenue list', error);
		yield put(revenueActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(revenueActions.setFilter(action.payload));
}

export default function* revenueSaga() {
	yield takeLatest(revenueActions.fetchData.type, fetchRevenueList);
	yield debounce(500, revenueActions.setFilterWithDebounce.type, handleSearchDebounce)
}