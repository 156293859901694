import style from "./RevenuReport.module.scss";
import className from "classnames/bind";
import {memo} from "react";
import {Col, Row} from "antd";
import {numberFormat} from "~/utils";
import {useUtilities} from "~/hooks";
const cn = className.bind(style);
function RevenueReportTotal({items = []}) {

	const {utilities} = useUtilities();

	function RevenueReportServiceTotal({item, serviceKey}) {
		return (
			<div className={cn('card', {[serviceKey] : serviceKey})}>
				<p className="heading">{utilities.services[serviceKey].label}</p>
				{
					Object.keys(item).map((itemKey) => {
						let total = item[itemKey];
						let cls = (itemKey == 'total') ? 'txt-total-big' : null;
						return (
							<Row key={itemKey} gutter={10} align="middle">
								<Col className="gutter-row" span={10}><span className={cn('txt')}>{(itemKey == 'total') ? 'Tổng' : utilities.serviceTypes[itemKey].label}</span></Col>
								<Col className="gutter-row" span={10}><span className={cn('txt-total', [cls])}>{numberFormat(total)}</span></Col>
							</Row>
						)
					})
				}
			</div>
		)
	}

	return (
		<div className={cn('total-wrapper')}>
			<Row gutter={10} className="mb-2" align="middle">
				<Col className="gutter-row" span={10}><span className={cn('txt')}>Doanh thu</span></Col>
				<Col className="gutter-row" span={10}><span className={cn('txt-total')}>{numberFormat(items.total)}</span></Col>
			</Row>
			<Row gutter={10} className="mb-2" align="middle">
				<Col className="gutter-row" span={10}><span className={cn('txt')}>Doanh số</span></Col>
				<Col className="gutter-row" span={10}><span className={cn('txt-total')}>{numberFormat(items.totalRevenue)}</span></Col>
			</Row>

			{
				Object.keys(items.service).map((itemKey) => {
					let item = items.service[itemKey];
					return <RevenueReportServiceTotal key={`RevenueReportServiceTotal${itemKey}`} item={item} serviceKey={itemKey} />
				})
			}


		</div>
	)
}

export default memo(RevenueReportTotal);