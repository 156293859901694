import style from "./Template.module.scss";
import className from "classnames/bind";
import {
	Image, Loading, Button
} from "~/components"
import {numberFormat, renderDate} from "~/utils";
import {useUtilities} from "~/hooks";
import {Controller, useForm} from "react-hook-form";
import {EditorField} from "../../../../components/Forms";
import {useEffect} from "react";
const cn = className.bind(style);
function EmailTemplate({item, handleEmailSave}) {
	
	const {utilities} = useUtilities();
	
	const renderService = (services) => {
		return Object.keys(services).map((itemKey) => {
			let service = services[itemKey];
			return (
				<tr key={`email-service-${service.id}`}>
					<td width="35%"><b>{utilities.services[service.service].label} &nbsp;{service.option.length != 0 && utilities.services[service.service].options[service.option].label}</b></td>
					<td width="30%">{renderDate(service.expired)}</td>
					<td width="35%">{numberFormat(service.price)} vnđ/năm</td>
				</tr>
			)
		})
	}
	
	const emailDefault = "<p className=\"mt-2\"><strong><u>Khuyến mãi tặng kèm khi sử dụng dịch vụ Hosting:</u></strong></p>\n" +
		"\t\t\t\t\t\t<p><span style={{fontFamily:'Wingdings'}}>ü</span> Nhận ngay 1 SSL trị giá : 500.000 vnd để bảo vệ cho web của bạn.</p>\n" +
		"\t\t\t\t\t\t<p><span style={{fontFamily:'Wingdings'}}>ü</span> Nhận ngay 1 (2) bài viết để quảng bá cho website của bạn trên top3.vn trị giá 1.000.000 vnd.</p>\n" +
		"\t\t\t\t\t\t<p><span style={{fontFamily:'Wingdings'}}>ü</span> Nhận ngay 1 (2) bài viết để quảng bá thương hiệu cá nhân của bạn trên doanhnhanthanhcong.vn trị giá 1.000.000 vnd.</p>\n" +
		"\t\t\t\t\t\t<p><span style={{fontFamily:'Wingdings'}}>ü</span> Miễn phí Tham gia khóa học hàng tuần về quảng cáo, marketing và quản lý của hàng, doanh nghiệp do SIKIDO tổ chức!</p>" +
		"<p className=\"mt-2\"><strong><u>Quyền lợi khi sử dụng dịch vụ Hosting tại Sikido:</u></strong></p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Nhiều gói Hosting với đầy đủ tính năng thuận tiện trong quá trình quản lý.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Linh hoạt chuyển đổi các gói hosting cho khách hàng khi khách hàng có nhu cầu nâng cấp các gói hosting mới đáp ứng tốt hơn nhu cầu vận hành của website.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Miễn phí tạo, quản lý hộp thư điện tử (Email) theo tên miền riêng.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Miễn phí tạo tên miền con (Subdomain).</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Miễn phí backup dữ liệu hàng tuần.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Quản lý đăng nhập, giám sát thông số băng thông và dung lượng.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Sao lưu dự phòng và khôi phục dữ liệu.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Đội ngũ hỗ trợ khách hàng 24/7 để xử lý mọi tình huống trong quá trình sử dụng.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Bảo mật &amp; An Toàn.</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Hỗ trợ bảo trì website</span>\n" +
		"\t\t\t\t\t\t\t</p>\n" +
		"\t\t\t\t\t\t\t<p className=\"mb-1 ml-1\">\n" +
		"\t\t\t\t\t\t\t\t<span style={{fontFamily:'Wingdings'}}>ü</span>\n" +
		"\t\t\t\t\t\t\t\t<span>Quản lý đăng nhập, giám sát thông số băng thông và dung lượng.</span>\n" +
		"\t\t\t\t\t\t\t</p>";

	const initialValues = {
		emailContent: emailDefault,
	}

	const { control, handleSubmit, formState: { isSubmitting, errors }, reset } = useForm({
		defaultValues: initialValues,
	});

	useEffect(() => {
		if (item.emailContent) {
			initialValues.emailContent = item.emailContent;
			reset(initialValues)
		}
	}, [item]);

	const onSubmit = async (data) => {
		await handleEmailSave(data, item);
	}

	return (
		<div className={cn('template-email')}>
			<div className={cn('wrapper')}>
				<div className={cn('wrapper-inner')}>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>

						{isSubmitting && <Loading/>}

						<div className={cn('header')}>
							<div className={cn('logo')}>
								<Image src="https://sikido.vn/uploads/source/header/logo-(1).png" />
							</div>
							<div className={cn('title')}>
								<p>Công Ty TNHH Truyền Thông và Quảng Cáo Siêu Kinh Doanh</p>
							</div>
						</div>
						<div className={cn('header-info')}>
							<div className={cn('inner')}>
								<h2>Thư Thông Báo Gia Hạn Dịch Vụ</h2>
								<p>(Thành Thật Xin Lỗi, Nếu Trong Thời Gian Thư Đang Chuyển Đi Mà Quý Khách Đã Thực Hiện Gia Hạn)</p>
							</div>
						</div>
						<div className={cn('content')}>
							<p>Kính gửi quý khách: <strong style={{fontSize: '18px'}}>{item.customer.name}</strong></p>
							<p>Điện thoại: {item.customer.phone}</p>

							<p className="mt-2 mb-1">Dịch vụ của quý khách đang sử dụng sẽ hết hạn vào ngày <b style={{color:'red'}}>{renderDate(item.expired)}</b>:</p>
							<table border="0" cellSpacing="1" cellPadding="0" width="100%">
								<tbody>
								<tr>
									<td colSpan="3" style={{background :'rgb(192,0,0)'}}>
										<p><span>Danh sách dịch vụ cần gia hạn</span></p>
									</td>
								</tr>
								<tr>
									<td width="35%"><b>Tên dịch vụ</b></td>
									<td width="30%"><b>Ngày hết hạn</b></td>
									<td width="35%"><b>Phí gia hạn</b></td>
								</tr>
								{renderService(item.services)}
								</tbody>
							</table>
							<p className="font-bold text-right">Tổng thanh toán: <span className="color-red" style={{fontSize: '17pt'}}>{numberFormat(item.total)} vnđ</span></p>
							<p className="mb-2" style={{fontSize: '13px', fontStyle :'italic'}}>* phí trên chưa bao gồm VAT 10%</p>

							<Controller control={control} name="emailContent" render={({ field }) => (
								<EditorField errors={errors} {...field}/>
							)}/>

							<Button primary type="submit" className="mb-2">Lưu email</Button>

							<h3 className="color-red text-lg">CÁCH THANH TOÁN:</h3>
							<p>Quý khách có thể thanh toán 1 trong các hình thức sau:</p>
							<p className="color-blue font-bold"><u>1. Thanh toán trực tiếp tại văn phòng</u></p>
							<p>Quý khách có thể đến văn phòng giao dịch SKD gia hạn &amp; thanh toán trực tiếp với nhân viên:</p>
							<p>CÔNG TY TNHH TRUYỀN THÔNG VÀ QUẢNG CÁO SIÊU KINH DOANH</p>
							<p>Địa chỉ: 213/14 Đường D2, Phường 25, Quận Bình Thạnh, TP HCM</p>
							<p>Điện thoại: (028) 6294 1556</p>

							<p className="color-blue font-bold"><u>2. Chuyển khoản ngân hàng</u></p>
							<p>Quý khách chuyển vào tài khoản của chúng tôi:</p>
							<p className="mt-0 mb-0">Ngân hàng: <b>Vietcombank</b></p>
							<p className="mt-0 mb-0">Chủ tài khoản: <b>PHAN ĐÌNH CHUYỀN</b></p>
							<p className="mt-0 mb-0">Số tài khoản: 0531 0025 32519</p>
							<p className="mt-0">Chi nhánh: Đông Sài Gòn</p>

							<p className="mb-0">Ngân hàng: <b>Techcombank</b></p>
							<p className="mt-0 mb-0">Chủ tài khoản: <b>PHAN ĐÌNH CHUYỀN</b></p>
							<p className="mt-0 mb-0">Số tài khoản: 1903 24153 76464</p>
							<p className="mt-0">Chi nhánh: Gia Định</p>

							<p className="mb-0">TMCP <b>Đông Nam Á</b> (SeABank)</p>
							<p className="mt-0 mb-0">Chủ tài khoản: <b>Phan Đình Chuyền</b></p>
							<p className="mt-0 mb-0">Số tài khoản: 7999227999</p>
							<p className="mt-0">Chi nhánh: Lê Quang Định</p>

							<p className="color-blue font-bold"><u>3. Thanh toán qua Momo</u></p>
							<p>Qúy khách check mã QR, nhập số tiền và nội dung chuyển tiền.</p>
							<p><Image src="https://sikido.vn/uploads/source/footer/a3e93507dd9e27c07e8f.jpg"/></p>
							<p>Qúy khách vui lòng xác nhận thông tin để chúng tôi hỗ trợ.</p>
							<p>Mong sớm nhận được phản hồi của quý khách hàng.</p>
						</div>
						<div className={cn('footer')}>
							<p className="font-bold">SIKIDO không chịu trách nhiệm trong trường hợp mất mát dịch vụ nếu Quý khách gia hạn dịch vụ sau thời gian hết hạn nêu trên.</p>
							<p>Quý khách hàng cần hỗ trợ thêm thông tin, vui lòng liên hệ lại Hotline <b>028.6294.1556</b> hoặc email <b>hotro@sikido.vn.</b></p>
							<p className="color-blue">Lưu ý: Để dịch vụ được hoạt động tốt nhất, quý khách nên gia hạn dịch vụ khi nhận được thông báo này.</p>
							<p>Cám ơn Quý khách! </p>
							<p>Chúc Quý khách một ngày làm việc hiệu quả. Hân hạnh được phục vụ quý khách, chào trân trọng!</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
export default EmailTemplate