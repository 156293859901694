import {Button, Icon} from "~/components";
import {Pagination, Table, Tooltip} from "antd";

function ExpenseGroupTable({items = [], pagination, onPaginationChange, setItemEdit, openModal}) {

    const typeList = {
        'none' : '',
        'minus-wage' : 'Trừ vào lương',
        'plus-wage' : 'Cộng vào lương',
    }

    const handleButtonClick = {
        edit: (item) => {
            setItemEdit(item);
            openModal('addEdit');
        },
        delete : (item) => {
            setItemEdit(item);
            openModal('delete');
        }
    }

    const columns = [
        { title: 'Mã loại', dataIndex: 'code', key: "code", width: '100px', render: (_, item) => (<div>LC{item?.id}</div>)},
        { title: 'Tên chi', dataIndex: 'name', key: 'name', render:(_, item) => (<div>{item.name}</div>)},
        { title: 'Kiểu tính', dataIndex: 'wage', key: "wage", width: '180px', render: (_, item) => (<p><b>{typeList[item?.wage]}</b></p>)},
        { title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
            <div className="table-action d-flex">
                <Tooltip title="Edit thông tin"><Button background blue leftIcon={Icon.edit} onClick={() => handleButtonClick.edit(item)}></Button></Tooltip>
                <Tooltip title="Xóa"><Button background red leftIcon={Icon.delete} onClick={() => handleButtonClick.delete(item)}></Button></Tooltip>
            </div>
        )},
    ];

    const dataSource= items.map(row => ({
        key: row.id, // I added this line
        ...row
    }));

    return (
        <div className="table-wrapper">
            <div className="table-box">
                <Table columns={columns} dataSource={dataSource} scroll={{ y:550 }} pagination={false} />
            </div>
            <Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
        </div>
    )
}

export default ExpenseGroupTable;