import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {formSabbaticalActions} from "./formSabbaticalSlice";
import {formSabbaticalApi} from "~/api";

function* fetchFormSabbaticalList(action) {
	try {
		const response = yield call(formSabbaticalApi.gets, action.payload);
		yield put(formSabbaticalActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch formSabbatical list', error);
		yield put(formSabbaticalActions.fetchDataFailed(error.message));
	}
}

function* handleSearchDebounce(action) {
	yield put(formSabbaticalActions.setFilter(action.payload));
}

export default function* formSabbaticalSaga() {
	yield takeLatest(formSabbaticalActions.fetchData.type, fetchFormSabbaticalList);
	yield debounce(500, formSabbaticalActions.setFilterWithDebounce.type, handleSearchDebounce)
}