import {useState, useMemo, useCallback} from "react";
import {Controller, useForm} from "react-hook-form";
import {
	SelectField,
	SelectUserAll,
	SelectContractAll
} from "~/components/Forms";
import {
	StatusLabel,
	Button,
	Icon,
	Notification
} from "~/components";
import {
	LIST_REVENUE_STATUS
} from "~/app/constants";
import {
	useCan,
	useUtilities
} from "~/hooks";

function RevenueSearchMobile({filter, onChange, onSearchChange}) {

	const {utilities} = useUtilities();

	const can = {
		listAll: useCan('contractListAll'),
		listGroup: useCan('contractListGroup'),
		add: useCan('contractAdd'),
		edit: useCan('contractEdit'),
		transfer: useCan('contractTransfer'),
		delete: useCan('contractDelete'),
		status: useCan('contractStatus'),
	};

	const serviceOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(utilities.services).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [utilities.services]);

	const statusOptions = useMemo(() => {
		return [{ value: '', label: 'Tất cả' }, ...Object.entries(LIST_REVENUE_STATUS).map(([key, value]) => {
			return {
				value: key,
				label: <StatusLabel small type={value.color}>{value.label}</StatusLabel>
			};
		})];
	}, [LIST_REVENUE_STATUS]);

	const [searchBar, setSearchBar] = useState(false);

	const {control, handleSubmit, formState: { errors }} = useForm({defaultValues: {}});

	const handleContractChange = useCallback((value) => {
		if (onChange) { onChange('contractId', value); }
	}, [onChange]);

	const onSubmit = (data) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			contractId: data.contractId,
			userId: data.userId,
			service: data.service,
			status: data.status,
			page: 1,
		};
		setSearchBar(false);
		onSearchChange(newFilter);
	}

	return (
		<div className="search-bar">
			<div className="container">
				<div className="form">
					<Button type="button" leftIcon={Icon.filter} onClick={() => setSearchBar(true)}>Lọc</Button>
					<SelectContractAll value={filter.contractId} placeholder="Hợp đồng" options={[{value:'', label:'Tất cả'}]} onChange={handleContractChange} />
				</div>
			</div>
			<Notification
				onClose={() => setSearchBar(false)}
				show={searchBar}
				title={'Tìm kiếm'}
				icon={Icon.search}>
				<form className="form form-mobile text-left" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<Controller control={control} name="contractId" render={({field}) => (
						<SelectContractAll label="Hợp đồng" options={[{value:'', label:'Tất cả'}]} errors={errors} {...field} />
					)}/>
					{(can.listAll || can.listGroup) &&
						<Controller control={control} name="userId" render={({ field }) => (
							<SelectUserAll label="Chọn nhân viên" options={[{value:'', label:'Tất cả'}]} errors={errors} {...field} />
						)}/>
					}
					<Controller control={control} name="service" render={({field}) => (
						<SelectField label="Dịch vụ" options={serviceOptions} errors={errors} {...field} />
					)}/>
					<Controller control={control} name="status" render={({field}) => (
						<SelectField label="Trạng thái" options={statusOptions} errors={errors} {...field} />
					)}/>
					<div className="d-flex justify-content-between" style={{position:"absolute", bottom:'10px', width:'calc(100% - 20px)'}}>
						<Button background blue style={{width:'100%'}}>Áp dụng</Button>
					</div>
				</form>
			</Notification>
		</div>
	)
}

export default RevenueSearchMobile;