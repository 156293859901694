import {memo, useContext} from "react";
import {Pagination, Table} from "antd";
import {
	renderDate,
} from "~/utils";
import {
	Image,
	StatusLabel,
	PopperUserInfo
} from "~/components";
import {useUtilities} from "~/hooks";
import {AppContext} from "~/context/AppProvider";
import {ServiceToDoAction} from "../index";

function ServiceToDoTable({items = [], pagination, onPaginationChange, openModal, handleStatus, handleFinished, setItemEdit}) {

	const {tableHeight} = useContext(AppContext);

	const {utilities} = useUtilities();

	const renderStatus = (status) => {
		return (
			<>
				{status == 'pending' && <StatusLabel small type="yellow">Đợi duyệt</StatusLabel> }
				{status == 'confirm' && <StatusLabel small type="green">Đã duyệt</StatusLabel> }
				{status == 'cancel' && <StatusLabel small type="red">Không duyệt</StatusLabel> }
			</>
		)
	}

	const renderStatusFinished = (status) => {
		return (
			<>
				{status == 'pending' && <StatusLabel small type="yellow">Đợi thực thi</StatusLabel> }
				{status == 'success' && <StatusLabel small type="green">Đã thực thi</StatusLabel> }
				{status == 'cancel' && <StatusLabel small type="red">Không làm</StatusLabel> }
			</>
		)
	}

	const renderType = (status) => {
		return (
			<>
				{status == 'ssl' && <StatusLabel small type="green">Cài ssl</StatusLabel> }
				{status == 'open' && <StatusLabel small type="green">Mở dịch vụ</StatusLabel> }
				{status == 'closed' && <StatusLabel small type="red">Đóng dịch vụ</StatusLabel> }
				{status == 'temporary' && <StatusLabel small type="blue">Mở tạm dịch vụ</StatusLabel> }
				{status == 'upgrade' && <StatusLabel small type="green">Nâng cấp</StatusLabel> }
				{status == 'extend' && <StatusLabel small type="green">Gia hạn</StatusLabel> }
				{status == 'downgrade' && <StatusLabel small type="red">Hạ cấp</StatusLabel> }
				{status == 'domainNew' && <StatusLabel small type="blue">Thêm tên miền</StatusLabel> }
				{status == 'domainReplace' && <StatusLabel small type="blue">Đổi tên miền</StatusLabel> }
				{status == 'domainRemove' && <StatusLabel small type="blue">Xóa tên miền</StatusLabel> }
			</>
		)
	}

	let columns = [
		{title: 'Dịch vụ', dataIndex: 'service', key: "service", width: '150px',render: (_, item) => (
			<>
				<p className="gap-1 d-flex mb-1">
					<StatusLabel small type={utilities.services[item.serviceType].color}>{utilities.services[item.serviceType].label}</StatusLabel>
					<b>{item.service.domain}</b>
				</p>
				{item.user?.id && <p>{`${item.user?.firstname} ${item.user?.lastname}`}</p>}
				{item.userShare?.id && <p>{`${item.userShare?.firstname} ${item.userShare?.lastname}`}</p>}
				<p className={'text-xs'} style={{color:'#ccc'}}>{renderDate(item?.time)}</p>
			</>
		)},
		{title: 'Hành động', dataIndex: 'action', key: "action", width: '140px', render: (_, item) => (
			<>
				{renderType(item?.type)}
				<p className={'mt-1'}>{item.service?.hostIp}</p>
			</>
		)},
		{title: 'Người tạo', dataIndex: 'user', key: "user", width: '240px', render: (_, item) => (
			item.userAdd?.id && <PopperUserInfo user={item.userAdd}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.userAdd?.avatar} /></div>
					<div>
						<p>{item.userAdd?.firstname} {item.userAdd?.lastname}</p>
						<p><StatusLabel small type={utilities.roles[item.userAdd.role]?.color}>{utilities.roles[item.userAdd.role]?.title}</StatusLabel></p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{title: 'Người làm', dataIndex: 'userAction', key: "userAction", width: '210px', render: (_, item) => (
			item.userAction?.id && <PopperUserInfo user={item.userAction}>
				<div className="d-flex gap align-items">
					<div className="avatar"><Image src={item.userAction?.avatar} /></div>
					<div>
						<p>{item.userAction?.firstname} {item.userAction?.lastname}</p>
						<p><StatusLabel small type={utilities.roles[item.userAction.role]?.color}>{utilities.roles[item.userAction.role]?.title}</StatusLabel></p>
					</div>
				</div>
			</PopperUserInfo>
		)},
		{title: 'Lý do', dataIndex: 'note', key: "note", width: '300px'},
		{title: 'Duyệt', dataIndex: 'status', key: "status", width: '150px', render: (_, item) => (renderStatus(item?.status)) },
		{title: 'Kỹ thuật', dataIndex: 'finished', key: "finished", width: '150px', render: (_, item) => (renderStatusFinished(item?.finished)) },
		{title: '#', dataIndex: 'action', key: "action", width: '150px', render: (_, item) => (
			<ServiceToDoAction item={item} handleStatus={handleStatus} openModal={openModal} handleFinished={handleFinished} setItemEdit={setItemEdit} />
		)},
	];

	return (
		<div className="table-wrapper">
			<div className="table-box">
				<Table columns={columns} rowKey="id" dataSource={items} scroll={{ y:tableHeight, x: 'max-content' }} pagination={false} />
			</div>
			<Pagination current={pagination.page} defaultCurrent={1} defaultPageSize={20} pageSize={pagination.limit} total={pagination.totalRows} onChange={onPaginationChange}  showSizeChanger={false} />
		</div>
	)
}

export default memo(ServiceToDoTable);