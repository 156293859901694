import style from './ActionBar.module.scss';
import className from 'classnames/bind';
const cn = className.bind(style);

function ActionBar({title, children}) {
	return (
		<div className={cn('action-bar')}>
			<div className={cn('container') + ' container'}>
				<div className={cn('header-title')}>
					<span className={cn('header-animation')}></span>
					<span>{title}</span>
				</div>
				<div className="">
					{children}
				</div>
			</div>
		</div>
	)
}

export default ActionBar;