import {call, put, takeLatest, debounce} from "redux-saga/effects";
import {createdWebsiteActions} from "./createdWebsiteSlice";
import {createdWebsiteApi} from "~/api";

function* fetchDemoList(action) {
	try {
		const response = yield call(createdWebsiteApi.gets, action.payload);
		yield put(createdWebsiteActions.fetchDataSuccess(response))
	}
	catch (error) {
		console.log('Failed to fetch createdWebsite list', error);
		let message = error.message;
		if(error?.response?.data?.message) message = error.response.data.message;
		yield put(createdWebsiteActions.fetchDataFailed(message));
	}
}

function* handleSearchDebounce(action) {
	yield put(createdWebsiteActions.setFilter(action.payload));
}

export default function* createdWebsiteSaga() {
	yield takeLatest(createdWebsiteActions.fetchData.type, fetchDemoList);
	yield debounce(500, createdWebsiteActions.setFilterWithDebounce.type, handleSearchDebounce)
}