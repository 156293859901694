import {Fragment, useContext, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes, PrivateRoutes } from "~/routes";
import { DefaultLayout } from "~/layout";
import {GlobalHistory} from "./routes/GlobalHistory";
import { useCurrentUser } from "./hooks";
import {AppContext} from "./context/AppProvider";
import checkNotificationStatus from "./utils/notification";
import {apiError, handleRequest} from "./utils";
import {
    taskApi,
    formSabbaticalApi,
    formSalaryApi
} from "./api";

function App() {

    const userCurrent = useCurrentUser();

    const {
        socket,
        setOnlineUsers,
        notificationCount,
        setNotificationCount
    } = useContext(AppContext);

    useEffect(() => { document.title = "CRM Sikido"; }, [])

    useEffect(() => {
        if(userCurrent?.id) {
            //Socket
            socket.emit("new-user-add", userCurrent?.id);

            socket.on("get-users", (users) => {
                setOnlineUsers(users);
            });

            socket.on("get-notification", (data) => {
                console.log('get-notification');
                console.log(data);
                setNotificationCount((prevState) => ({
                    ...prevState,
                    notification: prevState.notification+1,
                }))
                checkNotificationStatus(data);
            });

            socket.on("get-notification-comment", (data) => {
                console.log('get-notification-comment');
                console.log(data);
                setNotificationCount((prevState) => ({
                    ...prevState,
                    comment: prevState.comment+1,
                }))
                checkNotificationStatus(data);
            });

            socket.on("get-notification-menu-count", async (data) => {
                console.log('get-notification-menu-count');
                console.log(data);
                if(data.type === 'task') {
                    let [error, response] = await handleRequest(taskApi.count());
                    let message = apiError(`Load số tasks mới thất bại`, error, response);
                    if (!message) {
                        setNotificationCount((
                            prevState) => ({
                            ...prevState,
                            tasks: response.data.number,
                        }))
                    }
                }
                if(data.type === 'form-sabbatical') {
                    let [error, response] = await handleRequest(formSabbaticalApi.count());
                    let message = apiError(`Load số đơn xin nghỉ phép chưa duyệt thất bại`, error, response);
                    if(!message) {
                        notificationCount.form.sabbatical = response.data.count;
                        setNotificationCount(notificationCount)
                    }
                }
                if(data.type === 'form-salary') {
                    let [error, response] = await handleRequest(formSalaryApi.count());
                    let message = apiError(`Load số đơn xin ứng lương chưa duyệt thất bại`, error, response);
                    if(!message) {
                        notificationCount.form.salary = response.data.count;
                        setNotificationCount(notificationCount)
                    }
                }
            })
        }
    }, [userCurrent?.id]);

    return (
        <Router>
            <GlobalHistory />
            <div className="App">
                <Routes>
                    {
                        publicRoutes.map((route) => {
                            let Layout;

                            if(route.layout) {
                                Layout = route.layout;
                            }
                            else if(route.layout === null) {
                                Layout = Fragment;
                            }
                            else {
                                Layout = DefaultLayout;
                            }

                            const Page = route.component;

                            return (
                                <Route key={route.path} path={route.path} element={<Layout><Page /></Layout>}/>
                            )
                        })
                    }
                    {
                        privateRoutes.map((route) => {
                            let Layout;

                            if(route.layout) {
                                Layout = route.layout;
                            }
                            else if(route.layout === null) {
                                Layout = Fragment;
                            }
                            else {
                                Layout = DefaultLayout;
                            }

                            const Page = route.component;

                            return (
                                <Route key={route.path} path={route.path} element={<PrivateRoutes><Layout><Page /></Layout></PrivateRoutes>}/>
                            )
                        })
                    }
                </Routes>
            </div>
        </Router>
    );
}

export default App;
