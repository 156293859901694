import {useRef, useState} from "react";
import ReactToPrint from "react-to-print";
import Tippy from "@tippyjs/react/headless";
import {
	Button,
	Icon
} from "~/components";
import {PopperWrapper} from "~/components/Popper";
import {
	useCan
} from "~/hooks";
import {Tooltip} from "antd";
import {PrintDomainQT, PrintDomainVN} from "../index";

function BuyServiceAction({item, setItemEdit, openModal}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const printRef = useRef();

	const can = {
		edit: useCan('buyServiceEdit'),
		delete: useCan('buyServiceDelete'),
	};

	const buttonHandlers = {
		info: () => {
			setItemEdit(item);
			openModal('info');
			setShowActionBox(false);
		},
		edit: () => {
			setItemEdit(item);
			openModal('addEdit');
			setShowActionBox(false);
		},
		buy: () => {
			setItemEdit(item);
			openModal('buy');
			setShowActionBox(false);
		},
		delete: () => {
			setItemEdit(item);
			openModal('delete');
			setShowActionBox(false);
		},
	};

	const actionButtons = [
		(item.contract.service == 'domain') && <Button key={`btn-buy-${item.id}`} leftIcon={Icon.shopping} onClick={buttonHandlers.buy}>Mua dịch vụ</Button>,
		(item.contract.service == 'domain') && <ReactToPrint key={`btn-print-${item.id}`}
			trigger={() => <Button leftIcon={Icon.print}>In bản khai</Button>}
			content={() => printRef.current}
		/>,
		can.edit && <Button key={`btn-edit-${item.id}`} leftIcon={Icon.edit} onClick={buttonHandlers.edit}>Cập nhật</Button>,
		can.delete && <Button key={`btn-delete-${item.id}`} red leftIcon={Icon.delete} onClick={buttonHandlers.delete}>Xóa yêu cầu</Button>,
	];

	const PrintDomainComponent = item.domain.endsWith(".vn") ? PrintDomainVN : PrintDomainQT;

	return (
		<div className="table-action d-flex">
			<Tooltip title="Thông tin">
				<Button background blue leftIcon={Icon.info} onClick={buttonHandlers.info}></Button>
			</Tooltip>
			<Tippy interactive visible={showActionBox} onClickOutside={() => setShowActionBox(false)} placement="left" offset={[0, -10]}
				render={() => (
					<PopperWrapper className="action-wrapper" style={{minWidth: '220px', minHeight: 'auto'}}>{actionButtons}</PopperWrapper>
				)}
			>
				<div onClick={() => setShowActionBox(!showActionBox)} className="action-btn">
					{Icon.menuVertical}
				</div>
			</Tippy>
			<PrintDomainComponent key={`buyService-print-${item.id}`} item={item} ref={printRef} />
		</div>
	)
}

export default BuyServiceAction;