import style from '../css/Kpi.module.scss';
import {useEffect, useState} from "react";
import className from 'classnames/bind';
import {Col, Row, Skeleton} from "antd";
import ActionBar from "~/layout/ActionBar";
import {
	apiError,
	handleRequest,
	numberFormat,
	renderDate
} from "~/utils";
import {saleApi} from "~/api";
import _ from "lodash";
import {SaleKpiSearch} from "../components";
const cn = className.bind(style);

function SaleKpi () {

	const [items, setItems]     = useState([]);
	const [loading, setLoading] = useState(true);
	const [filter, setFilter]   = useState({});

	useEffect(() => {
		handleLoadData().then();
	}, [filter])

	const handleLoadData = async () => {

		setLoading(true);

		let [error, response] = await handleRequest(saleApi.kpi(filter));

		let message = apiError(`Load data không thành công`, error, response);

		if(!message) {
			setItems(response.data);
		}

		setLoading(false);
	}

	return (
		<>
			<ActionBar title={'KPI'}></ActionBar>
			<div className="container">
				<div className="content pd-1">
					<SaleKpiSearch filter={filter} setFilter={setFilter}/>
					{loading && <Skeleton />}
					{(!loading && !_.isEmpty(items)) &&

							<Row gutter={10}>
								<Col span="18">
									<div className={cn('kpi-wrapper', 'w-100')}>
										<div className={cn('heading')}>KPI</div>
										<div className={cn('revenue-total')}>{numberFormat(items.commits.total)}</div>
										<Row className="pd-0">
											<Col span="12" className="pd-0">
												<div className={cn('heading-mini', 'heading-mini-blue')}>2 Tuần đầu (40% cam kết)</div>
												<div className={cn('revenue')}>{numberFormat(items.commits.commitStage1)}</div>
												<Row className="pd-0">
													<Col span="12" className="pd-0">
														<div className={cn('heading-mini', 'heading-mini-red')}>
															<p>1 Tuần đầu (40% cam kết)</p>
															<p className={cn('txt')}>{renderDate(items.stage[1].start) + ' đến ' + renderDate(items.stage[2].end)}</p>
														</div>
														<div className={cn('revenue')}>{numberFormat(items.commits.commitWeek1)}</div>
														<Row gutter={0} className="pd-0">
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày đầu</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[1].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[1].revenue)}</div>
															</Col>
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày sau</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[2].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[2].revenue)}</div>
															</Col>
														</Row>
													</Col>
													<Col span="12" className="pd-0">
														<div className={cn('heading-mini', 'heading-mini-red')}>
															<p>1 Tuần sau (60% cam kết)</p>
															<p className={cn('txt')}>{renderDate(items.stage[3].start) + ' đến ' + renderDate(items.stage[4].end)}</p>
														</div>
														<div className={cn('revenue')}>{numberFormat(items.commits.commitWeek2)}</div>
														<Row className="pd-0">
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày đầu</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[3].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[3].revenue)}</div>
															</Col>
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày sau</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[4].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[4].revenue)}</div>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
											<Col span="12" className="pd-0">
												<div className={cn('heading-mini', 'heading-mini-blue')}>2 Tuần sau (60% cam kết)</div>
												<div className={cn('revenue')}>{numberFormat(items.commits.commitStage2)}</div>
												<Row className="pd-0">
													<Col span="12" className="pd-0">
														<div className={cn('heading-mini', 'heading-mini-red')}>
															<p>1 Tuần đầu (40% cam kết)</p>
															<p className={cn('txt')}>{renderDate(items.stage[5].start) + ' đến ' + renderDate(items.stage[6].end)}</p>
														</div>
														<div className={cn('revenue')}>{numberFormat(items.commits.commitWeek3)}</div>
														<Row className="pd-0">
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày đầu</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[5].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[5].revenue)}</div>
															</Col>
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày sau</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[6].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[6].revenue)}</div>
															</Col>
														</Row>
													</Col>
													<Col span="12" className="pd-0">
														<div className={cn('heading-mini', 'heading-mini-red')}>
															<p>1 Tuần sau (60% cam kết)</p>
															<p className={cn('txt')}>{renderDate(items.stage[7].start) + ' đến ' + renderDate(items.stage[8].end)}</p>
														</div>
														<div className={cn('revenue')}>{numberFormat(items.commits.commitWeek4)}</div>
														<Row className="pd-0">
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày đầu</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[7].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[7].revenue)}</div>
															</Col>
															<Col span="12" className="pd-0">
																<div className={cn('heading-mini', 'heading-mini-green')}>3 Ngày sau</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[8].commit)}</div>
																<div className={cn('revenue')}>{numberFormat(items.stage[8].revenue)}</div>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									</div>
								</Col>
								<Col span="6">
									<div className={cn('kpi-wrapper', 'w-100')}>
										<div className={cn('heading')}>Doanh số</div>
										<div className={cn('revenue-total')}>{numberFormat(items.commits.revenue)}</div>
										<div className={cn('heading-mini', 'heading-mini-blue')}>% Đạt được</div>
										<div className={cn('revenue')}>{numberFormat(items.commits.percent)}%</div>
									</div>
								</Col>
							</Row>


					}
				</div>
			</div>
		</>
	)
}

export default SaleKpi;