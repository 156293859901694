import style from './CandidateSearchBar.module.scss';
import className from 'classnames/bind';
import StatusLabel from "~/components/StatusLabel";
import {InputField, SelectField} from "~/components/Forms";
import {useUtilities} from "../../../../hooks";
const cn = className.bind(style);

function CandidateSearchBar({filter, onSearchChange, onChange}) {

	const {utilities} = useUtilities();

	const roleOptions = [];

	for (const [key, item] of Object.entries(utilities.roles)) {
		roleOptions.push({value : item.key, label : <StatusLabel small type={item.color}>{item.title}</StatusLabel>})
	}

	const handleSearchChange = (e) => {
		if (!onSearchChange) return;
		const newFilter = {
			...filter,
			keyword: e.target.value,
			page: 1,
		};
		onSearchChange(newFilter);
	}

	const handleApplyChange = (value) => {
		if (!onChange) return;
		const newFilter = {
			...filter,
			apply: value || undefined,
			page: 1,
		};
		onChange(newFilter);
	};

	return (
		<div className={cn('search-bar') + ' search-bar'}>
			<div className={cn('container') + ' container'}>
				<form className={cn('form') + ' form'} autoComplete="off">
					<InputField name="keyword" placeholder="Tên, email ứng viên" onChange={handleSearchChange} />
					<SelectField name="apply" placeholder="Vị trí" options={roleOptions} onChange={(value, event) => handleApplyChange(value)} />
				</form>
			</div>
		</div>
	)
}

export default CandidateSearchBar;